/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios"

import PaymentForm from "./PaymentForm";
import "./App.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_live_51KvOrSFC0lZO0HPVsKgC2peZJ9jXl7aWATEPPI6CfxvGFI8xoh9svwGZ9KleJLASB4cD1s5jX4ie38RMCx6fp31U00bwvfMSFr");


export default function StripeContainer(props) {

  const [secret, setSecret] = useState(false)



  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret: secret,
    appearance
  };


  useEffect(() => {
    async function fetchSecret() {
      try {
        const response = await axios.post("http://localhost:4000/secret", {
          amount: props.amount,
        })

        // const response = await axios.post("https://us-central1-market-me-346916.cloudfunctions.net/app/secret", {
        //   amount: props.amount,
        // })
        if (response.data) {
          setSecret(response.data.clientSecret)
        }
      } catch (err) {
        console.log("Error", err)
      }
    }
    fetchSecret()
  }, [])

  return (
    <div>
      {secret && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentForm features={props.features} planSelected={props.planSelected} setOpenStripeModal={props.setOpenStripeModal} secret={secret} amount={props.amount} />
        </Elements>
      )}
    </div>
  );
}