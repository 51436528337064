import { userTypes } from 'variables/config';
import { DataTypes } from 'variables/constants';
import { getAccessorsForForm, addSteps } from '../form';

export const config = {
    basicDetails: {
        fields: {
            email:           { mandatory: true },
            password:        { mandatory: true },
            confirmPassword: { mandatory: true },
            acceptedTerms:   { mandatory: true, type: DataTypes.BOOLEAN },
            // userType: { mandatory: true, default: 'ANALYST', values: userTypes },
        }
    },
    commonProfile: {
        fields: {
            firstName:  { mandatory: true },
            lastName: { mandatory: true },
            middleInitial: { mandatory: false },
            companyName: {mandatory: true },
            officePhone: { mandatory: true },
            cellPhone: { mandatory: true },
            dateOfBirth: { type: DataTypes.DATETIME },
            profilePic: { mandatory: false },
        }
    },
}

export default addSteps(getAccessorsForForm(config, "form", "registration"), ["basicDetails", "commonProfile"]);

