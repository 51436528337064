import React, { useEffect } from "react";
import { useParams } from 'react-router';
import { firebase } from 'firebase/client';
import { DateTime } from 'luxon';
import BoundForm from "components/Form/BoundForm";
import BoundFormFields from "components/Form/BoundFormFields";
import EditorForm from "./EditorForm";
import { connect } from "react-redux";
import { OpenMode } from 'variables/constants';
import { addToStack } from 'store/actions/navigation.js';
import { getRecord } from 'firebase/datasource';

function BoundEditorForm(props) {
    const { key } = useParams();
    const { initialize, mode, editField } = props;
    if (window.location.pathname.includes('customers') && !window.location.pathname.includes('new')) {
        getRecord('customers', key)
            .then((docData) => {
                editField('docs', docData.docs);
            })
    }
    if (window.location.pathname.includes('customers/new') || window.location.pathname.includes('customers/editor')) {
        setTimeout(() => {editField('lastUpdated', firebase.firestore.Timestamp.fromMillis(DateTime.fromJSDate(new Date()).toMillis()))}, 10);
    }
    useEffect(() => {
        initialize((mode && OpenMode[mode]) || OpenMode.UPDATE, key);
    },[key, mode, initialize]);
    if (window.location.pathname.includes('editor')) {
        props.addToStack(key);
    }
    return (
        <EditorForm {...props}/>
    )
}

function mapStateToProps(actions, accessors) {

    const Form = BoundForm(actions, accessors);
    const FormFields = BoundFormFields(actions, accessors);

    return (state)=> {
        return {
            Form,
            FormFields,
            mode: state.router.location && state.router.location.query && state.router.location.query.mode
        }
    };
}

function mapDispatchToProps(actions){
    return (dispatch) => {
        return {
            initialize: (mode, key)=>dispatch(actions.initialize(mode, key)),
            addToStack: (id)=>dispatch(addToStack(id)),
            editField: (field, value)=>dispatch(actions.editField(field, value)),
        };
    }
}

export default (actions, accessors)=>connect(mapStateToProps(actions, accessors), mapDispatchToProps(actions))(BoundEditorForm);