/*eslint-disable*/
const lists = []
const initDataRetrieved = false
const listsIds = []

export const initState = {
  lists,
  initDataRetrieved,
  listsIds
}


export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_LISTS':
      var toReturn = {...state}
      toReturn.lists = action.data
      toReturn.initDataRetrieved = true
      toReturn.listsIds = [...action.ids]
      return toReturn

    default:
      return state
  }
}
