import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { connect } from "react-redux";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import styles from "assets/jss/material-dashboard-pro-react/components/explorerStyle";
import { NeedsAttentionExplorer } from "./NeedsAttentionExplorer";
import LeadsExplorer from "./LeadsExplorer";

import { actionsForRecordset } from 'store/actions/recordset.js';

const useStyles = makeStyles(styles);


export function Explorer(props) {

    const [attentionSelected, setattentionSelected] = React.useState(false);

    const classes = useStyles();

    const { ExplorerCard, SearchControl, SearchDialog, routeName, searchFields, cardFields, userType } = props;

    let userName = `${props.firstName} ${props.lastName}`
    let filteredRecords = props.records.filter((record) => {
        return record.fields.loanOfficer == userName
    })

    let filteredLeads = props.leads.filter((lead) => {
        return lead.fields.loanOfficer == userName
        }
    )
    // const fetchMoreData = () => {

    //     a fake async api call like which sends
    //     20 more records in 1.5 secs
    //     setTimeout(() => {
    //         this.setState({
    //             items: props.records.concat(Array.from({ length: 20 }))
    //         });
    //     }, 1500);
    // };


    return (
        <div>
            {attentionSelected == true ? <NeedsAttentionExplorer {...props}></NeedsAttentionExplorer> :
                <div>
                    {props.userType == "ANALYST" || props.userType == "ADMIN" || props.userType == "CREDIT_ANALYST" ?
                        <div>
                            {props.routeName == "/admin/leads" ?
                                <LeadsExplorer {...props}></LeadsExplorer> :
                                <div>
                                    <SearchControl />
                                    <SearchDialog order={searchFields} />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={attentionSelected}
                                                onChange={event => setattentionSelected(event.target.checked)}
                                                value="attentionSelected"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                            />
                                        }
                                        classes={{
                                            label: classes.label
                                        }}
                                        label="Show only customers that Need Attention"
                                    />

                                    {/* Conditional Explorer cards based on status. There is most likely a cleaner way to do this. */}
                                    {/* Customer Status Options */}
                                    <h1>Service Agreements</h1>
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Service Agreements") && (record.fields.needsAttention == true) || (record.fields.status === undefined)) ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                firstName={record.fields.firstName}
                                                lastName={record.fields.lastName}
                                                joinDate={record.fields.joinDate}
                                                description={record.fields.description}
                                                estimatedPullDate={record.fields.estimatedPullDate}
                                                lastFourSSN={record.fields.socialSecurityNumber ? Number(record.fields.socialSecurityNumber.toString().split('').slice(-4).join('')) : record.fields.socialSecurityNumber}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Service Agreements") && (record.fields.needsAttention !== true) || (record.fields.status === undefined)) ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                firstName={record.fields.firstName}
                                                lastName={record.fields.lastName}
                                                joinDate={record.fields.joinDate}
                                                description={record.fields.description}
                                                estimatedPullDate={record.fields.estimatedPullDate}
                                                lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    <h1>POP/Neg List</h1>
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "POP/Neg List") && (record.fields.needsAttention == true)) ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                firstName={record.fields.firstName}
                                                lastName={record.fields.lastName}
                                                joinDate={record.fields.joinDate}
                                                description={record.fields.description}
                                                estimatedPullDate={record.fields.estimatedPullDate}
                                                lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "POP/Neg List") && (record.fields.needsAttention !== true)) ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                firstName={record.fields.firstName}
                                                lastName={record.fields.lastName}
                                                joinDate={record.fields.joinDate}
                                                description={record.fields.description}
                                                estimatedPullDate={record.fields.estimatedPullDate}
                                                lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    <h1>Send Letters</h1>
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Send Letters") && (record.fields.needsAttention == true)) && (props.userType == "ANALYST" || props.userType == "CREDIT_ANALYST") ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}

                                            /> : null
                                    ))}
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Send Letters") && (record.fields.needsAttention !== true)) && (props.userType == "ANALYST" || props.userType == "CREDIT_ANALYST") ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}

                                            /> : null
                                    ))}
                                    <h1>Current Customer</h1>
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Current Customer") && (record.fields.needsAttention == true)) ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                firstName={record.fields.firstName}
                                                lastName={record.fields.lastName}
                                                joinDate={record.fields.joinDate}
                                                description={record.fields.description}
                                                estimatedPullDate={record.fields.estimatedPullDate}
                                                lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Current Customer") && (record.fields.needsAttention !== true)) ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                firstName={record.fields.firstName}
                                                lastName={record.fields.lastName}
                                                joinDate={record.fields.joinDate}
                                                description={record.fields.description}
                                                estimatedPullDate={record.fields.estimatedPullDate}
                                                lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    <h1>Promo</h1>
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Promo") && (record.fields.needsAttention == true)) ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                firstName={record.fields.firstName}
                                                lastName={record.fields.lastName}
                                                joinDate={record.fields.joinDate}
                                                description={record.fields.description}
                                                estimatedPullDate={record.fields.estimatedPullDate}
                                                lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Promo") && (record.fields.needsAttention !== true)) ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                firstName={record.fields.firstName}
                                                lastName={record.fields.lastName}
                                                joinDate={record.fields.joinDate}
                                                description={record.fields.description}
                                                estimatedPullDate={record.fields.estimatedPullDate}
                                                lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    <h1>Completed</h1>
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Completed") && (record.fields.needsAttention == true)) ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                firstName={record.fields.firstName}
                                                lastName={record.fields.lastName}
                                                joinDate={record.fields.joinDate}
                                                description={record.fields.description}
                                                estimatedPullDate={record.fields.estimatedPullDate}
                                                lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Completed") && (record.fields.needsAttention !== true)) ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                firstName={record.fields.firstName}
                                                lastName={record.fields.lastName}
                                                joinDate={record.fields.joinDate}
                                                description={record.fields.description}
                                                estimatedPullDate={record.fields.estimatedPullDate}
                                                lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    <h1>Declined</h1>
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Declined") && (record.fields.needsAttention == true)) ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                firstName={record.fields.firstName}
                                                lastName={record.fields.lastName}
                                                joinDate={record.fields.joinDate}
                                                description={record.fields.description}
                                                estimatedPullDate={record.fields.estimatedPullDate}
                                                lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Declined") && (record.fields.needsAttention !== true)) ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                firstName={record.fields.firstName}
                                                lastName={record.fields.lastName}
                                                joinDate={record.fields.joinDate}
                                                description={record.fields.description}
                                                estimatedPullDate={record.fields.estimatedPullDate}
                                                lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    <h1>Needs to be sent to Collections</h1>
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Needs to be sent to Collections") && (record.fields.needsAttention == true)) && (props.userType == "ANALYST" || props.userType == "CREDIT_ANALYST") ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Needs to be sent to Collections") && (record.fields.needsAttention !== true)) && (props.userType == "ANALYST" || props.userType == "CREDIT_ANALYST") ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    <h1>Collections</h1>
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Collections") && (record.fields.needsAttention == true)) && (props.userType == "ANALYST" || props.userType == "CREDIT_ANALYST") ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    {props.records.map((record, index) => (
                                        ((record.fields.status === "Collections") && (record.fields.needsAttention !== true)) && (props.userType == "ANALYST" || props.userType == "CREDIT_ANALYST") ?
                                            <ExplorerCard
                                                needsAttention={record.fields.needsAttention}
                                                status={record.fields.status}
                                                key={index}
                                                index={index}
                                                order={cardFields}
                                                editRoute={`${routeName}/editor/${record.key}`}
                                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                                notesRoute={`${routeName}/notes/${record.key}`}
                                            /> : null
                                    ))}
                                    <Fab className={classes.newRecord} onClick={e => props.openChild("New Record", `${routeName}/new`)}><AddIcon /></Fab>
                                </div>}
                        </div>
                        :

                        <div>
                            {/* Explorer Page view for all user types aside from Admin and  */}
                            <SearchControl />
                            <SearchDialog order={searchFields} />
                            <h1>Leads</h1>
                            {props.leads.map((lead, index) => (
                                <ExplorerCard
                                    firstName={lead.fields.firstName}
                                    lastName={lead.fields.lastName}
                                    joinDate={lead.fields.joinDate}
                                    estimatedPullDate={lead.fields.estimatedPullDate}
                                />
                            ))}
                            <h1>Contract Needed</h1>
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "Service Agreements") && (record.fields.needsAttention == true) || (record.fields.status === undefined)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "Service Agreements") && (record.fields.needsAttention !== true) || (record.fields.status === undefined)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Awaiting Credit Report</h1>
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "POP/Neg List") && (record.fields.needsAttention == true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "POP/Neg List") && (record.fields.needsAttention !== true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Current Customer</h1>
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "Current Customer") && (record.fields.needsAttention == true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "Current Customer") && (record.fields.needsAttention !== true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Promo</h1>
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "Promo") && (record.fields.needsAttention == true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "Promo") && (record.fields.needsAttention !== true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Completed</h1>
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "Completed") && (record.fields.needsAttention == true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "Completed") && (record.fields.needsAttention !== true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Declined</h1>
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "Declined") && (record.fields.needsAttention == true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        lastFourSSN={record.fields.socialSecurityNumber ? record.fields.socialSecurityNumber.toString().split('').slice(-4).join('') : record.fields.socialSecurityNumber}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                        </div>
                    }
                </div>
            }
            <Fab className={classes.newRecord} onClick={e => props.openChild("New Record", `${window.location.pathname.slice(0, -9)}/new`)}><AddIcon /></Fab>
        </div>
    );
}

Explorer.propTypes = {
    routeName: PropTypes.string
}

function mapStateToProps(state) {
    return {
        firstName: state.auth.user.firstName,
        lastName: state.auth.user.lastName,
        records: state.recordset.customers.records,
        leads: state.recordset.leads.records
    }
}

function mapDispatchToProps(dispatch) {
    return {
        search: (query) => dispatch(actionsForRecordset('customers').search(query))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Explorer);

