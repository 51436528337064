
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

import {
    container,
    cardTitle,
    bodyText,
    linkText,
    dangerColor
  } from "assets/jss/material-dashboard-pro-react.js";

  import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";

  import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle";

  const stepStyle = theme => ({
    ...modalStyle(theme),
    bodyText,
    textDanger: {
      ...bodyText,
      fontSize: '12px',
      color: dangerColor,
      textAlign: "center"
    },
    linkText,
    ...customCheckboxRadioSwitch,
    cardTitle: {
      ...cardTitle,
      textAlign: "center",
      fontSize: '1.8rem',
      color: 'white',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: "35px"
      }
    },
    container: {
      position: "relative",
      zIndex: "3",
      height: "100%"
    },
    cardSignup: {
      borderRadius: "6px",
      boxShadow:
        "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
      marginBottom: "100px",
      padding: "40px 0px",
      marginTop: "15vh",
    },
    center: {
      textAlign: "center"
    },
    right: {
      textAlign: "right"
    },
    left: {
      textAlign: "left"
    },
    form: {
      padding: "0 30px",
      position: "relative"
    },
    socialTitle: {
      fontSize: "18px"
    },
    inputAdornment: {
      marginRight: "18px",
      position: "relative"
    },
    inputAdornmentIcon: {
      color: "#555"
    },
    customFormControlClasses: {
      margin: "0 12px"
    },
    checkboxLabelControl: {
      margin: "0"
    },
    checkboxLabel: {
      marginLeft: "6px",
      color: "rgba(0, 0, 0, 0.26)"
    },
    errorMessage: {
      color: "#000 !important",
      textAlign: "center",
      marginTop: "20px"
    },
    largeImg: {
      height: "38vh",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto"
    },
    inputCard: {
      paddingLeft: '20px',
      width: "48%",
      height: "80px"
    },
    confirmationContainer: {
      display: "block"
    },
    header: {
      color: "#000",
      margin: "20px",
      display: "flex",
      justifyContent: "center"
    },
    inputContainer: {
     display: "flex",
     alignItems: "center",
     justifyContent: "center",
     flexDirection: "column",
     height: "100%"
    },
    ...buttonStyle,
    signupCard: {
      background: "linear-gradient(to bottom, rgba(94, 177, 227, 1) 0.48%, rgba(39, 75, 115, 1) 99.05%) !important",
      height: "85%",
      [theme.breakpoints.down('sm')]: {
       height: "100%"
      }
    },
    cardBody: {
      padding: "0 20px 20px 20px !important"
    },
    detailsGridContainer: {
      height: "100%",
      [theme.breakpoints.down('sm')]: {
        display: "flex",
      }
    },
    gridItem: {
      display: "flex",
      flexDirection: "column",
      padding: "20px !important",
      // justifyContent: "space-between"
    },
    uploadPictureDiv: {
      display: "flex",
      flexDirection: "column",
       alignItems: "center",
       "&:hover": {
         cursor: "pointer"
       }
    },
    img: {
      borderRadius: "100%",
      width: "65%",
      marginBottom: "20px"
    },
    uploadText: {
      color: "#EEBD09",
      fontSize: "20px",
      fontWeight: 400,
      marginTop: "30px"
    },
    backBtn: {
      height: "70px",
      backgroundColor: "rgba(238, 189, 9, 1)",
      color: "white"
    },
    submitBtn: {
      height: "70px",
      backgroundColor: "rgba(94, 177, 227, 1)",
      color: "white"
    },
    smallInputField: {
      width: "80%",
      // marginRight: "5%"
    },
    inputField: {
      width: "95%",
      marginRight: "5%"
    },
    selectLabel: {
      color: "white",
      fontSize: "20px"
    },
    aboutLabel: {
      color: "white",
      fontSize: "17px"
    },
    select: {
      background: "white",
      width: "95%",
      marginRight: "5%",
      height: "28px"
    },
    selectFormControl: {
      marginTop: "0px !important",
      paddingTop: "19px",
      height: "41px"
    },
    disclaimerText: {
      margin: "50px 0px 0px 0px",
      color: "white",
      fontSize: "22px",
      [theme.breakpoints.down('sm')]: {
        fontSize: "13px",
      }
    }

  });

  export default stepStyle;
