import React, { useState } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import 'assets/css/material-dashboard-pro-react.css';



const TargetScore = (props) => {
    const { classes, creditData } = props
    const [targetScore, setTargetScore] = useState(creditData.targetScore);

  return (
    // <GridItem
    // id={'grid-item'}
    // sx={{
    //     direction: 'row',
    //     justifyContent: 'space-around',
    //     alignItems: 'center',
    //     flex: '1 275px',
    // }}

    // xs={12}
    // sm={12}
    // md={4}
    // lg={4}
    // >
        <Card style={{
            height: '100%',
            margin: '0',
            //margin: '25px 0px 15px 0px'
        }}>
            <CardHeader color='primary'>
                <h3
                style={{
                    textAlign: 'center',
                    margin: '5px',
                    fontFamily: "Metal Core",
                    fontSize: "30px"
                }}
                className={classes.cardTitle}
                >
                Target Score
                </h3>
            </CardHeader>
            <CardBody style={{ textAlign: 'center' }}>
                <h1
                style={{
                    margin: '20px 0',
                    fontSize: '3.5rem',
                }}
                >
                {targetScore}
                </h1>
                <form
                onSubmit={(e) => setTargetScore(e.target.value)}
                style={{ marginBottom: '10px' }}
                >
                <label>
                    Set Target Score:
                    <input type='text' name='name' />
                </label>
                <input type='submit' value='Submit' />
                </form>
            </CardBody>
        </Card>
    // </GridItem>
  )
}

export default TargetScore