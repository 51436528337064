import React, { useEffect, useState } from 'react'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import CardBody from 'components/Card/CardBody.js';
import Table from 'components/Table/Table.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card.js';
import styles from 'assets/jss/material-dashboard-pro-react/components/tableStyle.js';
import { makeStyles } from '@material-ui/core/styles';
import './NegativeFactors.css'

const NegativeDetails = () => {
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    const tableHead = ['Late Payment Date', 'Account Name', 'Overdue Days'];
    const tableData = [
        ['01/12/2022', 'PNC Bank Debit', '8'],
        ['11/09/2021', 'Chase Visa Credit', '2'],
    ];

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  return (
    <GridItem
      id='grid-item-details'
      xs={12}
      sm={12}
      md={8}
      lg={8}
      style={{
        alignItems: 'flex-start',
        display: 'flex',
        flex: '1'
      }}
    >
      <Card style={{ display: 'flex', flex: '1', margin: '0' }}>
        { windowSize.width > 767 ? 
          <CardIcon
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <DescriptionOutlinedIcon
              style={{
                display: 'flex',
                fontSize: '50',
                marginLeft: '5%',
                marginTop: '5%',
              }}
            />
            <h3
              style={{
                alignItems: 'center',
                display: 'flex',
                margin: '0',
                paddingLeft: '11%',
                paddingTop: '9%',
                fontSize: '31px',
                fontWeight: '700',
              }}
            >
              Details
            </h3>
          </CardIcon> : 
          <CardHeader color='primary' id='late-payment-header'> 
            <h3>Late Payment Details</h3>
          </CardHeader>
        }
        <CardBody>
          <Table
            tableHead={tableHead}
            tableData={tableData}
            customCellClasses={[
              classes.center,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 4, 5]}
            customHeadCellClasses={[
              classes.center,
              classes.right,
              classes.right,
            ]}
            customHeadClassesForCells={[0, 4, 5]}
          />
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default NegativeDetails