/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";

import NumberBubble from "components/NumberBubble/NumberBubble";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Input from "@material-ui/core/Input";
import FormGroup from '@material-ui/core/FormGroup';

import FacebookIcon from "assets/img/market-me/facebookIcon.png";
import CameraLogo from "assets/img/market-me/cameraLogo.png";
import CampaignModal1 from "assets/img/market-me/FacebookCampaign/campaignModal1.png";
import CampaignModal2 from "assets/img/market-me/FacebookCampaign/campaignModal2.png";
import CampaignModal3 from "assets/img/market-me/FacebookCampaign/campaignModal3.png";
import CampaignModal4 from "assets/img/market-me/FacebookCampaign/campaignModal4.png";
import campaignModal5 from "assets/img/market-me/FacebookCampaign/campaignModal5.png";
import CampaignModal6 from "assets/img/market-me/FacebookCampaign/campaignModal6.png";
import { connect } from "react-redux";
import campaignsStyle from "assets/jss/material-dashboard-pro-react/views/campaignsStyle.js";

import { push } from "connected-react-router";

const useStyles = makeStyles(campaignsStyle);

const Step3 = (props) => {
  const classes = useStyles();
  const { setStep, campaignForm, handleChange, setPlatforms, platforms, goBack } = props;
  let [ modals, setModals ] = useState({});

  return (
    <>
      <GridContainer className={classes.mainStepContainer}>
        <GridItem
          className={classes.stepHeader}
          style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px", width: "-webkit-fill-available", display: "flex", alignItems: "center" }}
        >
          <NumberBubble value="3." color="yellow" size="2.5rem" />
          <h4 style={{ fontWeight: 600, marginLeft: "1rem" }}>Configure Direct Messaging Campaign</h4>
        </GridItem>
        <GridItem>
          {/* MODAL SECTION*/}
          <div
            className={classes.modal}
            style={modals.step1 ? { display: "block" } : null}
            onClick={()=>setModals({ step1: false})}
          >
            <img src={CampaignModal1} className={classes.modalContent} style={{ color: "red"}} />
          </div>
          <div
            className={classes.modal}
            style={modals.step2 ? { display: "block" } : null}
            onClick={()=>setModals({ step2: false})}
          >
            <img src={CampaignModal2} className={classes.modalContent} style={{ color: "blue"}} />
          </div>
          <div
            className={classes.modal}
            style={modals.step3 ? { display: "block" } : null}
            onClick={()=>setModals({ step3: false})}
          >
            <img src={CampaignModal3} className={classes.modalContent} style={{ color: "green"}} />
          </div>
          <div
            className={classes.modal}
            style={modals.step4 ? { display: "block" } : null}
            onClick={()=>setModals({ step4: false})}
          >
            <img src={CampaignModal4} className={classes.modalContent} style={{ color: "yellow"}} />
          </div>
          <div
            className={classes.modal}
            style={modals.step5 ? { display: "block" } : null}
            onClick={()=>setModals({ step5: false})}
          >
            <img src={campaignModal5} className={classes.modalContent} style={{ color: "pink"}} />
          </div>
          <div
            className={classes.modal}
            style={modals.step6 ? { display: "block" } : null}
            onClick={()=>setModals({ step6: false})}
          >
            <img src={CampaignModal6} className={classes.modalContent} style={{ color: "black"}} />
          </div>
          <GridContainer style={{ alignItems: "center" }}>
            <GridItem xs={5} style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{ fontWeight: 800 }}>Set up Facebook Campaign</h4>
              <p>When you get into Facebook, follow the steps to create your campaign.</p>
            </GridItem>
            <GridItem xs={3} style={{ marginLeft: "-20px", marginRight: "20px" }}>
              <Button
                style={{
                  background: "linear-gradient(180deg, #1D9BC9 0%, #005479 100%)",
                  borderRadius: "4px",
                  color: "white"
                }}
              >
                <img
                  src={FacebookIcon}
                  style={{
                    width: "5%",
                    marginLeft: "-0.6rem",
                    marginRight: "0.9rem"
                  }}
                  />
                Initiate Facebook Campaign</Button>
            </GridItem>
            <GridItem xs={4} style={{ display: "flex" }}>
              <p style={{ fontWeight: 800 }}>Copy Campaign ID Here:</p>
              <CustomInput
                formControlProps={{
                fullWidth: true,
                style: {
                  paddingTop: "0px",
                }
                }}
                inputProps={{
                  placeholder: "Find in Ads Manager",
                  required: true,
                  type: "text",
                  disableUnderline: true,
                  // onChange: (e) => handleChange('targetCustomers', e.target.value),
                  style: {
                    border: "2px solid #E60023",
                    borderRadius: "5px",
                    height: "2.5rem"
                  }
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem>
          <GridContainer style={{ display: "flex", flexDirection: "column" }}>
            <GridItem className={classes.step3Container}>
              <div className={classes.buttonContainer} style={{ alignItems: "center" }}>
                <NumberBubble value="1." color="blue" size="1.5rem" />
                <h5 style={{ marginLeft: "1rem" }}>Go to <b>Ad Creation</b> within Facebook Ads Manager and select the <b>Messages</b> objective.</h5>
                <img src={CameraLogo} style={{ width: "3%", marginLeft: "1rem" }} onClick={()=>setModals({ step1: true })}/>
              </div>
              <p style={{ marginLeft: "3rem" }}>- Open your Facebook Ads Manager and create a new ad by selecting <b>Ad Creation</b>.
Then, select the Messages objective, as seen by clicking the camera icon.</p>
            </GridItem>
            <GridItem className={classes.step3Container}>
              <div className={classes.buttonContainer} style={{ alignItems: "center" }}>
                <NumberBubble value="2." color="blue" size="1.5rem" />
                <h5 style={{ marginLeft: "1rem" }}>Under the <b>Message Destination</b> section, choose <b>Instagram Direct</b>.</h5>
                <img src={CameraLogo} style={{ width: "3%", marginLeft: "1rem" }} onClick={()=>setModals({ step2: true })}/>
              </div>
              <p style={{ marginLeft: "3rem" }}>- Then, choose your Facebook and Instagram Page. Note that you will need an <b>Instagram Page</b>, but you don’t have to use a Facebook Page to receive messages.</p>
            </GridItem>
            <GridItem className={classes.step3Container}>
              <div className={classes.buttonContainer} style={{ alignItems: "center" }}>
                <NumberBubble value="3." color="blue" size="1.5rem" />
                <h5 style={{ marginLeft: "1rem" }}>Edit your Audience, Placements, Budget, and Schedule.</h5>
                <img src={CameraLogo} style={{ width: "3%", marginLeft: "1rem" }} onClick={()=>setModals({ step3: true })}/>
              </div>
              <p style={{ marginLeft: "3rem" }}>- When it comes to <b>Placements</b>, choose Instagram only as you will not be able to reply to DM ads elsewhere.  Then, click <b>Next</b>.</p>
            </GridItem>
            <GridItem className={classes.step3Container}>
              <div className={classes.buttonContainer} style={{ alignItems: "center" }}>
                <NumberBubble value="4." color="blue" size="1.5rem" />
                <h5 style={{ marginLeft: "1rem" }}>Select your ad format to complete your Instagram Direct Ad setup.</h5>
                <img src={CameraLogo} style={{ width: "3%", marginLeft: "1rem" }} onClick={()=>setModals({ step4: true })}/>
              </div>
              <p style={{ marginLeft: "3rem" }}>- For the CTA (call to action), we suggest using “Send Message”.</p>
            </GridItem>
            <GridItem className={classes.step3Container}>
              <div className={classes.buttonContainer} style={{ alignItems: "center" }}>
                <NumberBubble value="5." color="blue" size="1.5rem" />
                <h5 style={{ marginLeft: "1rem" }}>Under <b>Message Template</b>, choose to either <b>Create New</b> or <b>Use Existing</b> template.</h5>
                <img src={CameraLogo} style={{ width: "3%", marginLeft: "1rem" }} onClick={()=>setModals({ step5: true })}/>
              </div>
              <p style={{ marginLeft: "3rem" }}>- After choosing the <b>Create New</b>, click + <b>Create</b> to create your dialogue flow.</p>
            </GridItem>
            <GridItem className={classes.step3Container}>
              <div className={classes.buttonContainer} style={{ alignItems: "center" }}>
                <NumberBubble value="6." color="blue" size="1.5rem" />
                <h5 style={{ marginLeft: "1rem" }}>Create your Click to Instagram Direct ad dialogue flow.</h5>
                <img src={CameraLogo} style={{ width: "3%", marginLeft: "1rem" }} onClick={()=>setModals({ step6: true })}/>
              </div>
              <p style={{ marginLeft: "3rem" }}>- You can customize the Greeting text and Frequently Asked Questions to anything you want, and then preview it on the right-hand side of the screen.</p>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.nextButtonsContainer}>
        <Button onClick={()=>setStep((prev) => {
          if (prev === 1) {
            goBack();
          } else {
            return (prev-1);
          }
          })} className={classes.backButton}>Back</Button>
        <Button onClick={()=>setStep((prev) => (prev+1))} className={classes.nextButton}>Next</Button>
      </GridContainer>
    </>
  )
}

function mapStateToProps(state) {
  return {
  };
}

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(campaignsStyle)
)(Step3));