import React, { useState, useEffect } from "react";

import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridItem from 'components/Grid/GridItem';

import { startCase } from "lodash";

import { getRecords } from 'firebase/datasource';

import styles from "assets/jss/material-dashboard-pro-react/components/customSelectStyle.js";

const useStyles = makeStyles(styles);

export default function NewLeadSelect(props) {
  const classes = useStyles();

  const { handleChange, label } = props;
  const [ values, setValues ] = useState([]);

  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: undefined
  });

  const getMenuItems = () => {
    return values.map((value, index) => {
      return <MenuItem key={index} value={value}>{value.firstName + ' ' + value.lastName}</MenuItem>
    })
  }

  useEffect(() => {
    getRecords('users')
      .then((users) => {
        setValues(users.filter((user) => {
          if (label === 'analyst') {
            return user.userType === 'ANALYST' || user.userType === 'CREDIT_ANALYST';
          } else {
            return user.userType === 'LOAN_OFFICER';
          }
        }));
      }
      )
  }, [])

  return (
    <GridItem xs={6} s={6}>
      <FormControl
        className={classes.formControl}
        >
        <InputLabel
          className={classes.labelRoot}
        >
          {startCase(label)}
        </InputLabel>
        <Select
          classes={{ select: inputClasses,
              root: undefined,
              disabled: classes.disabled
          }}
          onChange={(e) => {
            handleChange(label, e.target.value);
          }}

        >
          {getMenuItems()}
        </Select>
      </FormControl>
    </GridItem>
  )
}