// ##############################
// // // Dashboard View styles
// #############################

import {
  // bigNumbers,
  primaryColor,
  dangerColor,
  successColor,
  tooltip,
  card,
  cardTitle,
  cardSubtitle,
  title
} from "assets/jss/material-dashboard-pro-react";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle";

import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle";

const textBlastStyle = theme => ({
  ...modalStyle(theme),
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",
  },
  fab: {
    position: "fixed",
    color: "#FFFFFF",
    right: "25px",
    bottom: "20px",
    backgroundColor: "#3f51b5",
    [theme.breakpoints.down("sm")]: {
    right: "25px",
    bottom: "90px"
  }
  },

  dangerIcon: {
    color: dangerColor
  },
  successIcon: {
    color: successColor
  },
  // bigNumbers: {
  //   ...bigNumbers
  // },
  ...card,
  ...hoverCardStyle,
  tooltip,
  chat: {
    height: "45vh",
    backgroundColor: "white"
  },
  cardTitle: {
    ...cardTitle,
    color: "#fff",
    marginTop: "0px",
    marginBottom: "3px"
  },
  cardTitle2: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  title: {
    ...title,
    color: "#fff"
  },
  cardSubtitle: {
    ...title,
    color: "#fff",
    marginTop: "0",
    marginBottom: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center"
  },
  cardCategory: {
    color: "#999999",
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: "#999999"
  },
  stats: {
    color: "#999999",
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0"
  },
  successText: {
    color: successColor
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: "17px",
    height: "17px"
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px"
    }
  }
});

export default textBlastStyle;