/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import { useLocation } from "react-router-dom";

// react plugin for creating charts
import ChartistGraph from "react-chartist";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DeleteIcon from '@material-ui/icons/Delete';
import CustomerExplorer from "./customers/CustomerExplorer";
import LOCustomerExplorer from "./customers/LOCustomerExplorer";
import CustomerRefresh from "./customers/CustomerRefresh";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import SearchBox from 'components/SearchBox/SearchBox.jsx';
import Hidden from "@material-ui/core/Hidden";
import explorerPageStyle from "assets/jss/material-dashboard-pro-react/views/explorerPageStyle.js";
import avatar from 'assets/img/default-avatar.png';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { getCustomers, deleteCustomer } from 'store/actions/customerActions'
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";

import AddLogo from 'assets/img/market-me/addLogo.png'
import EditLogo from 'assets/img/market-me/editLogo.png'
import TrashLogo from 'assets/img/market-me/trashLogo.png'


import {
	simpleBarChart,
} from "variables/charts.js"

const useStyles = makeStyles(explorerPageStyle)


const Transition = (props) => {
	return <Slide direction="down" {...props} />;
};


export function CustomerExplorerPage(props) {

	const classes = useStyles()
	const { uid, customersData, initDataRetrieved, history } = props
	const [data, setData] = useState([])
	const [areYouSureDeleteModal, setAreYouSureDeleteModal] = useState(false)
	const [selectedCustomer, setSelectedCustomer] = useState({})
	const [successSnackbarOnReroute, setSuccessSnackbarOnReroute] = useState(false)
	const [convertedCustomerToLead, setConvertedCustomerToLead] = useState(false)

	const location = useLocation();

	useEffect(() => {
		console.log('initDataRetrieved', initDataRetrieved, uid)
		if (!initDataRetrieved && uid) {
			props.getCustomers(uid)
		}
		if (initDataRetrieved) {
			setData(customersData)
		}
	}, [props])


	useEffect(() => {
		if (location.state && location.state.succesfulConversionBackToLead && !successSnackbarOnReroute) {
			setSuccessSnackbarOnReroute(true)
			setConvertedCustomerToLead(true)
		}
	}, [location])





	const tableHeaders = ['Photo', 'Name', "Email Address", "Phone Number", "Account Status", "Actions"]



	const renderTableHeader = () => {
		return tableHeaders.map((key, index) => {
			return <th style={{fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px"}} className={classes.tableHeader} key={index}>{key}  </th>
		})
	}

	const onSearchInput = (query) => {
		var input = query.toLowerCase()
		const term = input.trim();
		if (term) {
			var result = data.filter((d) => {
				if (d.firstName.toLowerCase().includes(input) || d.lastName.toLowerCase().includes(input)) {
					return true
				}
			})
			setData(result)
		} else {
			setData(customersData)
		}
	};


	const renderTableData = () => {
		return data.map((d, index) => {
			return (
				<tr style={index % 2 === 1 ? { background: "rgba(242, 242, 242, 1)", marginBottom: "20px", height: "150px", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" } : { height: "150px", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" }}>
					<td className={classes.tableCell}>
						<img style={{ width: "100px", height: "100px", borderRadius: "10px", margin: "20px" }} src={d.profilePic ? d.profilePic : avatar} />
					</td>
					<td className={classes.tableCell}>
						{d.firstName + ' ' + d.lastName}
					</td>
					<td className={classes.tableCell}>
						{d.email}
					</td>
					<td className={classes.tableCell}>
						{d.cellPhone}
					</td>
					<td className={classes.tableCell}>
						{d.accountCreation}
					</td>
					<td style={{ display: "flex", width: "100%", justifyContent: "space-around", height: "150px", alignItems: "center" }} className={classes.tableCell}>
						<Button onClick={() => history.push(`/admin/customer-detail/${d.id}`, { customerData: d })} className={classes.customerBtns}>
							<p style={{ margin: "0px" }}>View</p>
							<OpenInNewIcon />
						</Button>
						<Button onClick={() => {
							setSelectedCustomer(d)
							setAreYouSureDeleteModal(true)

						}} style={{ backgroundColor: "rgba(188, 70, 70, 1)" }} className={classes.customerBtns}>
							<p style={{ color: "white", margin: "0px" }}>Remove</p>
							<DeleteIcon style={{ color: "white" }} />
						</Button>
					</td>
				</tr>
			)
		})
	}

	return (
		<div className={classes.mainContainer}>

			<Hidden className={classes.fullWidth} smDown implementation="css">
				<p className={classes.subTitle}>
					Review information about customers and reach out to them
				</p>
			</Hidden>


			<Card className={classes.filterCardBody}>
				<SearchBox id="investmentsQuery" onInput={onSearchInput} />
			</Card>
			<Card style={{ boxShadow: "0px 1px 6px grey", }} className={classes.customerCardBody}>
				<CardHeader className={classes.cardHeader}>
					Existing Customers
				</CardHeader>


				<Hidden smDown implementation="css">
					<table style={{ width: "100%", padding: "0px 20px" }}>
						<tbody>
							<tr style={{ height: "50px", width: "100%", borderBottom: "1px solid red !important" }}>{renderTableHeader()} </tr>
							{renderTableData()}
						</tbody>
					</table>
				</Hidden>


				<Hidden mdUp implementation="css">
					<table style={{ width: "100%" }}>
						<tbody>
							{data.map((d, index) => {
								return (
									<div style={{ margin: "10px auto" }}>
										<div style={{ display: "flex", justifyContent: 'space-between', width: "90%", marginBottom: "50px", height: "150px", margin: "10px auto", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" }}>
											<div style={{ display: "flex", height: "50px", width: "100%", padding: "5px", height: "150px" }}>
												<div style={{ width: "30%", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "20px" }}>
													<img style={{ width: "auto", borderRadius: "100%", marginBottom: "10px", maxHeight: '115px' }} src={d.profilePic ? d.profilePic : avatar} />
												</div>
												<div style={{ display: "flex", flexDirection: "column", width: "70%", justifyContent: "space-evenly" }}>
													<p>
														<b style={{ fontSize: "16px", marginRight: "10px" }}>
															Name:
														</b>
														{d.firstName + ' ' + d.lastName}
													</p>
													<p>
														<b style={{ fontSize: "16px", marginRight: "10px" }}>
															Email:
														</b>
														{' ' + d.email}
													</p>
													<p>
														<b style={{ fontSize: "16px", marginRight: "10px" }}>
															Cell Phone:
														</b>
														{d.cellPhone ? d.cellPhone : "N/A"}
													</p>
													<p>
														<b style={{ fontSize: "16px", marginRight: "10px" }}>
															Account Status:
														</b>
														{d.accountCreation}
													</p>
												</div>
											</div>
										</div>

										<div style={{ display: "flex", flexDirection: "column" }}>
											<div style={{
												display: "flex",
												padding: "0px 20px"
											}}>
												<Button onClick={() => history.push(`/admin/customer-detail/${d.id}`, { customerData: d })} className={classes.customerBtns}>
													<p style={{ margin: "0px" }}>View</p>
													<OpenInNewIcon />
												</Button>

												<Button onClick={() => {
													setSelectedCustomer(d)
													setAreYouSureDeleteModal(true)

												}} style={{ backgroundColor: "rgba(188, 70, 70, 1)" }} className={classes.customerBtns}>
													<p style={{ color: "white", margin: "0px" }}>Remove</p>
													<DeleteIcon style={{ color: "white" }} />
												</Button>
											</div>
										</div>
										<div style={{ width: "90%", height: "2px", background: "linear-gradient(89.97deg, rgba(238, 189, 9, 1) 0.99%, rgba(86, 163, 166, 1) 99.97%)", margin: "30px auto" }}>
										</div>
									</div>
								)
							})}
						</tbody>
					</table>
				</Hidden>


			</Card>



			<Dialog
				style={{ width: "100%" }}
				classes={{
					// root: classes.modalRoot,
					// paper: classes.modal + " " + classes.modalSmall
				}}
				fullWidth={true}
				open={areYouSureDeleteModal}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setAreYouSureDeleteModal(false)}
				aria-labelledby="shipment-modal-slide-title"
				aria-describedby="shipment-modal-slide-description"
			>
				<DialogTitle
					className={classes.updateProfileFormHeader}
					id="shipment-modal-slide-title"
					disableTypography
				>
					<Button
						onClick={() => {
							setAreYouSureDeleteModal(false)
						}}
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
					>
						{" "}
						<Close style={{ fontSize: "25px" }} className={classes.modalClose} />
					</Button>

					<h3>Are You Sure You Want To Remove {selectedCustomer.firstName + ' ' + selectedCustomer.lastName} As A Customer?</h3>

				</DialogTitle>
				<DialogContent
					classes={{
						root: classes.modalRoot,
					}}
					style={{ width: "100%" }}
					id="shipment-modal-slide-description"
				// className={classes.modalBody}
				>
					<div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
						<Button onClick={() => {
							props.deleteCustomer(uid, selectedCustomer.id)
							setAreYouSureDeleteModal(false)
						}} style={{ backgroundColor: "rgb(86, 163, 166)" }}>
							Yes
						</Button>
						<Button onClick={() => setAreYouSureDeleteModal(false)}>
							No
						</Button>
					</div>

				</DialogContent>
			</Dialog>

			<Snackbar open={convertedCustomerToLead} onClose={() => setConvertedCustomerToLead(false)}>
				<Alert onClose={() => setConvertedCustomerToLead(false)} severity='success'>
					The customer was converted back to a lead succesfully.
				</Alert>
			</Snackbar>
		</div>
	);
}

function mapStateToProps(state) {
	console.log('statex', state)
	return {
		userType: state.auth.user.userType,
		records: state.recordset.customers.records,
		initDataRetrieved: state.customers.initDataRetrieved,
		customersData: state.customers.customers,
		uid: state.auth.user.uid,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		getCustomers: (uid) => dispatch(getCustomers(uid)),
		deleteCustomer: (uid, id) => dispatch(deleteCustomer(uid, id))
		// nothing yet
	}
}

CustomerExplorerPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerExplorerPage));
