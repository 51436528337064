import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card";
import Button from "components/CustomButtons/Button.js";

import tasksExplorerPageStyle from "assets/jss/material-dashboard-pro-react/views/tasksExplorerPageStyle.js";

export function TasksExplorerPage(props) {

    const { classes } = props;

    return (
        <div>
            <h3 className={classes.title}>To Do</h3>
            <Card className={classes.taskCard}>
                <h4 className={classes.dueDate}>Due Date: 02/25/2021 @ 5 pm MST</h4>
                <h4><b>Title:</b> Contact Customer</h4>
                <h4><b>Description:</b> Simon Bolivar was contacted by us today in regards to transistioning for lead to customer</h4>
            </Card>
            <Button className={classes.newNoteButton}>+ Add Task</Button>
            <h3 className={classes.title}>Completed</h3>
            <Card className={classes.completedCard}>
                <h4 className={classes.dueDate}>Due Date: 02/25/2021 @ 5 pm MST</h4>
                <h4><b>Title:</b> Contact Customer</h4>
                <h4><b>Description:</b> Simon Bolivar was contacted by us today in regards to transistioning for lead to customer</h4>
            </Card>
        </div>
    );
}

TasksExplorerPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(tasksExplorerPageStyle)(TasksExplorerPage);