import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import customTabsStyle from "assets/jss/material-dashboard-pro-react/components/customTabsStyle.js";
import {setTabName} from 'store/actions/customTabsActions'

class CustomTabs extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const {
      classes,
      headerColor,
      plainTabs,
      tabs,
      title,
      rtlActive,
      contacts,
      setTabName
    } = this.props;
    const cardTitle = classNames({
      [classes.cardTitle]: true,
      [classes.cardTitleRTL]: rtlActive
    });
    return (
      <Card className={contacts ? classes.customerCardBody  : classes.cardDiv} plain={plainTabs}>
        <CardHeader className={classes.cardHeader} style={{ backgroundColor: "#274B73", width: "100% !important", padding:"20px !important" }} color={headerColor} plain={plainTabs}>
          {title !== undefined ? (
            <div className={cardTitle}>{title}</div>
          ) : null}
          <Tabs
            variant="fullWidth"
            style={{ width: "50% !important" }}
            value={this.state.value}
            onChange={this.handleChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.displayNone
            }}
          >
            {tabs.map((prop, key) => {
              var icon = {};
              if (prop.tabIcon) {
                icon = {
                  icon: <prop.tabIcon />
                };
              }
              return (
                <Tab
                onClick={(e) => {
                  setTabName(prop.tabName)
                }}
                  style={{ width: "50% !important", background: "rgb(29, 155, 201)", fontSize: "21px !important" }}
                  classes={{
                    root: classes.tabRootButton,
                    labelContainer: classes.tabLabelContainer,
                    label: {fontSize: "21px !important"},
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                    button: { width: "50% !important", fontSize: "21px !important" },
                  }}
                  key={key}
                  label={prop.tabName}
                  {...icon}
                />
              );
            })}
          </Tabs>
        </CardHeader>
        <CardBody style={{padding: "0px"}}>
          {tabs.map((prop, key) => {
            if (key === this.state.value) {
              return <div style={{ width: "50% !important",     fontSize: "21px !important" }} key={key}>{prop.tabContent}</div>;
            }
            return null;
          })}
        </CardBody>
      </Card>
    );
  }
}

CustomTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.func,
      tabContent: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool
};

function mapStateToProps(state) {
  console.log('state.customTab.tabName', state.customTab.tabName)
  return {
    tabName: state.customTab.tabName
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setTabName: (name) => dispatch(setTabName(name))
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(customTabsStyle))(CustomTabs);
