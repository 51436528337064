import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const loginPageStyle = (theme) => ({
  container: {
    ...container,
    backgroundColor: "#fff",
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
      margin: "0 0 0 -200px"
    },
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  img: {
    height: "20vh",
    margin: "20px 0 0 0",
  },
  largeImg: {
    height: "32vh",
    margin: "20px 0 0 0",
  },
  subdescription: {
    color: "#000",
  },
  signUpButton: {
    backgroundColor: "#f3684e",
    width: "100%",
    height: "8vh",
    fontSize: "1.3rem",
    marginBottom: "20px",
    "&:hover": {
      backgroundColor: "#f3684e",
    }
  },
  logInButton: {
    backgroundColor: "#05c6a3",
    width: "100%",
    height: "8vh",
    fontSize: "1.3rem",
    "&:hover": {
      backgroundColor: "#05c6a3",
    }
  },
  continueButton: {
    fontWeight: "600",
    marginTop: "10px",
    width: "48% !important"
  },
  formContainer: {
    display: "block",
    margin: "0 0 0 200px",
  },
  mainContainer: {
    width: "43%",
    maxWidth: "40rem",
    height: "55%",
    maxHeight: "30rem",
    background: "rgba(249, 249, 249, 1)",
    position: "absolute",
    right: "3%",
    bottom: "17%",
    borderRadius: "10px",
    border: "3px solid rgba(15, 55, 87, 0.75)",
    [theme.breakpoints.down('sm')]: {
      right: "6px",
      width: "380px",
      bottom: "135px",
    }
},
mobileMainContainer: {
  width: "375px",
  height: "425px",
  background: "linear-gradient(to bottom, rgba(94, 177, 227, 1) 0.48%, rgba(39, 75, 115, 1) 99.05%) !important",
  margin: "60px auto 0px auto"
},
cardHeader: {
  display: "flex",
  justifyContent: "center",
},
cardBody: {
  padding: "30px 20px"
},
authBtn: {
  margin: "0px 10px",
  position: "absolute",
  top: "-45px",
  height: "80px",
  width: "100px"
},
clickHere: {
  color: "rgba(94, 177, 227, 1)",
  textDecoration: "underline",
  "&:hover": {
    cursor: "pointer"
  }
},
textDanger: {
  paddingLeft: "20px",
  margin: "0px",
  fontSize: "20px",
  color: "black"
},
googleLogo: {
  background: whiteColor,
  margin: "0px 10px",
  position: "absolute",
  top: "-10%",
  height: "80px",
  width: "80px",
  borderRadius: "10px",
  border: "2px solid rgba(15, 55, 87, 0.75)",
},
microsoftLogo: {
  background: whiteColor,
  margin: "0px 10px",
  position: "absolute",
  top: "-10%",
  height: "80px",
  width: "80px",
  borderRadius: "10px",
  border: "2px solid rgba(15, 55, 87, 0.75)",
  left: "20%",
  position: "absolute",
  [theme.breakpoints.down('sm')]: {
    right: "260px",
  }
},
fbLogo: {
  background: whiteColor,
  margin: "0px 10px",
  position: "absolute",
  top: "-10%",
  height: "80px",
  width: "80px",
  borderRadius: "10px",
  border: "2px solid rgba(15, 55, 87, 0.75)",
  right: "20%",
  [theme.breakpoints.down('sm')]: {
    right: "15px",
  }
}
});
export default loginPageStyle;
