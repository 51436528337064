/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";


import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import CardFooter from "components/Card/CardFooter";
import Hidden from "@material-ui/core/Hidden";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import { Checkbox, FormControl, MenuItem, Radio, RadioGroup, Select, SnackbarContent, TextField } from "@material-ui/core";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Input from "@material-ui/core/Input";
import LinearProgress from "@material-ui/core/LinearProgress";
// import CardFields from '../../../components/Card/CardFields';
// import CardField from '../../../components/Card/CardField';
import actionsForNavigation from "store/actions/navigation";
import { connect } from "react-redux";
import { MockUserData } from 'variables/config';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import Close from "@material-ui/icons/Close";
import { getLeads, deleteLead, convertLeadToCustomer, addLead } from "store/actions/leadsActions"
import { deleteCustomer } from "store/actions/customerActions"
import Slide from "@material-ui/core/Slide";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import contactsPageStyle from "assets/jss/material-dashboard-pro-react/views/contactsPageStyle.js";


import AuthActions from "store/actions/auth"
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";
import { Edit, SelectAll } from "@material-ui/icons";
import { createNew } from "typescript";
import Dvr from "@material-ui/icons/Dvr";
import { setTabName } from "store/actions/customTabsActions";
import { set } from "lodash";


// const tableHeaders = ['Name', "Email Address", "Company Name", "Phone Number", "Actions"]
const tableHeaders = ['', 'Name', "Email Address", "Company Name", "Phone Number", "Address", "City", "State", "Zip Code", "Actions"]

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};



export function ContactsPage(props) {
  const { classes, history, tabName, leadsData, leadsIds, uid, user, customersData, initCustomersDataRetrieved, initLeadsDataRetrieved } = props
  const [data, setData] = useState([])
  const [beforeFilter, setBeforeFilter] = useState([])
  const [selectedContact, setSelectedContact] = useState({})
  const [areYouSureConvertContactToCustomer, setAreYouSureConvertContactToCustomer] = useState(false)
  const [convertedLeadSnackbar, setConvertedLeadSnackbar] = useState(false)
  const [areYouSureDeleteLeadModal, setAreYouSureDeleteLeadModal] = useState(false)
  const [areYouSureDeleteCustomerModal, setAreYouSureDeleteCustomerModal] = useState(false)
  const [successSnackbarOnReroute, setSuccessSnackbarOnReroute] = useState(false)
  const [convertedCustomerToLead, setConvertedCustomerToLead] = useState(false)
  const [file, setFile] = useState('');
  const [importModal, setImportModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  // const [multiModal, setMultiModal] = useState(false)
  const location = useLocation();
  const [checkedArr, setCheckedArr] = useState([])
  const [indexArr, setIndexArr] = useState([])
  const [checkbox, setCheckbox] = useState([])
  const [all, setAll] = useState(false)
  const [value, setValue] = useState('')
  const fileReader = new FileReader();
  const [filterError, setFilterError] = useState(false);
  const [nameModal, setNameModal] = useState(false)
  const [listErr, setListErr] = useState(false)
  const [listName, setListName] = useState('')
  const [listArr, setListArr] = useState([])
  const [currListId, setCurrListId] = useState('');
  const [modalListId, setModalListId] = useState('');
  const [modalListName, setModalListName] = useState('')
  const [modalArr, setModalArr] = useState([])
  const [customerModalListName, setCustomerModalListName] = useState(false)
  const [fileLenErr, setFileLenErr] = useState(false)
  const [progress, setProgress] = useState(0);
  const [fileUploaded, setFileUploaded] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [editListName, setEditListName] = useState('')
  const [deleteCheckModal, setDeleteCheckModal] = useState(false)
  const [selectListErr, setSelectListErr] = useState(false)
  useEffect(() => {
    if (location.state && location.state.succesfulConversionBackToLead && !successSnackbarOnReroute) {
      setSuccessSnackbarOnReroute(true)
      setConvertedCustomerToLead(true)
      delete location.state.succesfulConversionBackToLead
    }
  }, [location])



  useEffect(() => {
    if (file) {
      if (file.type.split("/")[1] != "csv") {
        setErrorModal(true)
      }
      else {
        setImportModal(true)
      }
    }
  }, [file])

  useEffect(() => {
    if (all) {
      let items = []
      db.collection('users').doc(uid).collection('lists').doc(uid).collection(tabName).doc(currListId)
        .get()
        .then((result) => {
          for (let i = 0; i < result.data().list.length; i++) {
            items.push(result.data().list[i].id)
            indexArr.push(String(i))
          }
          setCheckedArr(items)
          // setIndexArr(String(indexItems))
        })
    }
  }, [all])

  const inputSearch = (query) => {
    var input = query.toLowerCase()
    const term = input.trim();
    setFilterError(false)

    if (term && value === "name") {
      var result = data.filter((d) => {
        if (d.firstName && d.firstName.toLowerCase().includes(input) || d.lastName && d.lastName.toLowerCase().includes(input)
          || d.info.Name && d.info.Name.toLowerCase().includes(input)
        ) {
          return true
        }
      })
      setData(result)
    }
    else if (term && value === "email") {
      var result = data.filter((d) => {
        if (d.email && d.email.toLowerCase().includes(input) || d.info.Email && d.info.Email.toLowerCase().includes(input)) {
          return true
        }
      })
      setData(result)
    }
    else if (term && value === "company") {
      var result = data.filter((d) => {
        if (d.companyName && d.companyName.toLowerCase().includes(input) || d.info.CompanyName && d.info.CompanyName.toLowerCase().includes(input)) {
          return true
        }
      })
      setData(result)
    }
    else if (term && value === "number") {
      var result = data.filter((d) => {
        if (d.cellPhone && d.cellPhone.toLowerCase().includes(input) || d.info.PhoneNumber && d.info.PhoneNumber.toLowerCase().includes(input)) {
          return true
        }
      })
      setData(result)
    }
    else if (term && value === "address") {
      var result = data.filter((d) => {
        if (d.info.StAddress && d.info.StAddress.toLowerCase().includes(input) || d.info.StAddress2 && d.info.StAddress2.toLowerCase().includes(input)) {
          return true
        }
      })
      setData(result)
    }
    else if (term && value === "city") {
      var result = data.filter((d) => {
        if (d.info.City && d.info.City.toLowerCase().includes(input)) {
          return true
        }
      })
      setData(result)
    }
    else if (term && value === "state") {
      var result = data.filter((d) => {
        if (d.info.State && d.info.State.toLowerCase().includes(input)) {
          return true
        }
      })
      setData(result)
    }
    else if (term && value === "zipcode") {
      var result = data.filter((d) => {
        if (d.info.Zipcode && d.info.Zipcode.toLowerCase().includes(input)) {
          return true
        }
      })
      setData(result)
    }
    else if (term && !value) {
      setFilterError(true)
    }
    else {
      if (tabName === 'Customers') {
        setData(beforeFilter)
      } else {
        setData(beforeFilter)
      }
    }
  };

  const handleChecked = (e) => {
    let newArr = [...checkedArr, e.target.value]
    let delArr = [...indexArr, e.target.id]
    if (checkedArr.includes(e.target.value)) {
      newArr = newArr.filter(id =>
        id !== e.target.value)
    }
    setCheckedArr(newArr)

    if (indexArr.includes(e.target.id)) {
      delArr = delArr.filter(id =>
        id !== e.target.id)
    }
    setIndexArr(delArr)
  }


  const renderTableHeader = () => {
    return tableHeaders.map((key, index) => {
      return <th style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" }} key={index}>{key}  </th>
    })
  }

  const getList = (listId) => {
    const items = []
    db.collection('users').doc(uid).collection('lists').doc(uid).collection(tabName).doc(listId)
      .get()
      .then((result) => {
        if (result.data().list === undefined) {
          setEditListName(result.data().listName)
          setData([])
        }
        else {
          items.push(result.data().list)
          setData(items[0])
          setEditListName(result.data().listName)
          setBeforeFilter(items[0])
        }
      })
  }

  const renderTableData = () => {
    return data.map((d, index) => {
      return (
        <tr style={index % 2 === 1 ? { background: "rgba(242, 242, 242, 1)", marginBottom: "20px", height: "150px", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" }
          : { height: "150px", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px", }}>
          <td className={classes.tableCell}>
            <Checkbox
              style={{ color: "#56a3a6", marginRight: "20px" }}
              className={classes.checkboxes}
              value={d.id}
              id={index}
              onChange={(e) => {
                setCheckbox(!checkbox)
                handleChecked(e)
              }}
              onClick={() => {
                setAll(false)
              }}
              checked={all ? checkedArr : checkedArr.includes(d.id)}
            />
          </td>
          <td className={classes.tableCell}>
            {d.info.Name}
          </td>
          <td className={classes.tableCell}>
            {d.info.Email}
          </td>
          <td className={classes.tableCell}>
            {d.info.CompanyName}
          </td>
          <td className={classes.tableCell}>
            {d.info.PhoneNumber}
          </td>
          <td className={classes.tableCell}>
            {d.info.StAddress2 ?
              d.info.StAddress + ' ' + d.info.StAddress2 : d.info.StAddress}
          </td>
          <td className={classes.tableCell}>
            {d.info.City}
          </td>
          <td className={classes.tableCell}>
            {d.info.State}
          </td>
          <td className={classes.tableCell}>
            {d.info.Zipcode}
          </td>

          {all === false && checkedArr.length < 1 ?
            <td style={{ display: "flex", justifyContent: "space-around", height: "150px", alignItems: "center" }} className={classes.tableCell}>
              <Button
                style={{ width: tabName === 'Customers' ? "50%" : "33%" }}
                onClick={() => {
                  if (tabName === 'Customers') {
                    history.push(`/admin/customer-detail/${d.id}`, { customerData: d.info })
                  } else {
                    history.push(`/admin/lead-detail/${d.id}`, { leadData: d.info })
                  }
                }} className={classes.viewDetailsBtn}>
                <p style={{ margin: "0px", }}>View</p>
                <OpenInNewIcon style={{ color: "black" }} />
              </Button>

              {tabName === 'Customers' ? (
                null
              ) : (
                <Button onClick={() => {
                  setIndexArr(String(index))
                  setSelectedContact(d.info)
                  setAreYouSureConvertContactToCustomer(true)
                }} style={{ backgroundColor: "rgba(39, 75, 115, 1)", width: "33%" }} className={classes.viewDetailsBtn}>
                  <p style={{ color: "white", margin: "0px" }}>Convert</p>
                  <AddIcon style={{ color: "white" }} />
                </Button>
              )}

              <Button
                onClick={() => {
                  setIndexArr(String(index))
                  setSelectedContact(d.info)
                  if (tabName === 'Customers') {
                    setAreYouSureDeleteCustomerModal(true)
                  } else {
                    setAreYouSureDeleteLeadModal(true)
                  }
                }} style={{ backgroundColor: "red", width: tabName === 'Customers' ? "50%" : "33%" }} className={classes.viewDetailsBtn}>
                <p style={{ color: "white", margin: "0px" }}>Remove</p>
                <DeleteIcon style={{ color: "white" }} />
              </Button>

            </td>
            :
            <td></td>}
        </tr>
      )
    })
  }

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Company Name", key: "compName" },
    { label: "Phone Number", key: "number" },
    { label: "St Address", key: "address" },
    { label: "St Address 2", key: "address2" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Zipcode", key: "zipcode" }
  ]

  const csvData = [];

  function generateUniqueFirestoreId() {
    // Alphanumeric characters
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    for (let i = 0; i < 20; i++) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return autoId;
  }


  const csvFileToArray = (string) => {
    let percentage = 0
    const csvHeader = string.slice(0, string.indexOf("\n")).replace(/ /g, "").replace("\r", "").split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).replace("\r", "").split("\n");
    const array = csvRows.map(i => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        if (header === "PhoneNumber") {
          object[header] = values[index].replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
        }
        else {
          object[header] = values[index];
        }
        return object;
      }, {});

      return obj;
    });
    if (array.length > 1000) {
      setFileLenErr(true)
    }
    else {
      const dbList = db.collection('users').doc(uid).collection('lists').doc(uid).collection(tabName).doc(currListId)
      for (let j = 0; j < array.length; j++) {
        let id = generateUniqueFirestoreId()
        let data = {}
        data.info = array[j];
        data.id = id
        dbList.set({
          list: firebase.firestore.FieldValue.arrayUnion(...[(data)])
        }, { merge: true })
          .then(() => {
            percentage = ((j + 1) / array.length) * 100
            setProgress((prev) => (prev >= 100 ? 100 : percentage))
            if (percentage === 100) {
              setFileUploaded(false)
            }
          })
      }
      getList(currListId)
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };
      fileReader.readAsText(file);
    }
  };

  const handleOnChange = (e) => {
    if (!listErr) {
      setFile(e.target.files[0])
    }
    else { return; }
  };

  async function getInfo() {
    await db.collection('users').doc(uid).collection('lists').doc(uid).collection(tabName)
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data())
        });
        setListArr(items)
      });
  }
  const deleteMultiple = async () => {
    if (deleteCheckModal) {
      await db.collection('users').doc(uid).collection('lists').doc(uid).collection(tabName).doc(currListId).delete()
        .then(() => {
          setDeleteCheckModal(false)
        })
    }
    else {
      const dbList = db.collection('users').doc(uid).collection('lists').doc(uid).collection(tabName).doc(currListId)
      dbList
        .get()
        .then(function (doc) {
          if (all) {
            dbList.update({
              list: firebase.firestore.FieldValue.delete()
            })
          }
          else {
            for (let i = 0; i < indexArr.length; i++) {
              dbList.update({
                list: firebase.firestore.FieldValue.arrayRemove(doc.data().list[indexArr[i]])
              })
            }
          }
        })
        .then(() => {
          // setMultiModal(false)
          getList(currListId)

        })
    }

  }



  const convertMultiple = async () => {
    if (!modalListId) {
      setSelectListErr(true)
    }
    else {
      const dbList = db.collection('users').doc(uid).collection('lists').doc(uid).collection(tabName).doc(currListId)
      dbList.get()
        .then(async function (doc) {
          let newData = []
          for (let i = 0; i < indexArr.length; i++) {
            newData.push(doc.data().list[indexArr[i]])
            dbList.update({
              list: firebase.firestore.FieldValue.arrayRemove(doc.data().list[indexArr[i]])
            })
          }
          db.collection('users').doc(uid).collection('lists').doc(uid).collection('Customers').doc(modalListId).set({
            list: firebase.firestore.FieldValue.arrayUnion(...newData)
          }, { merge: true })
            .then(() => {
              getList(currListId);
              setAreYouSureConvertContactToCustomer(false)
              setConvertedLeadSnackbar(true)
              clearArrays()
            })
        })
    }

  }

  const createList = (e) => {
    console.log("checking listName", listName)
    e.preventDefault();
    if (modalListName) {
      db.collection('users').doc(uid).collection('lists').doc(uid).collection('Customers').add({
        listName: modalListName
      })
        .then(function (docRef) {
          db.collection('users').doc(uid).collection('lists').doc(uid).collection('Customers').doc(docRef.id).update({
            listID: docRef.id
          })
        })
        .then(() => {
          setCustomerModalListName(false)
        })
    }
    else if (editListName) {
      db.collection('users').doc(uid).collection('lists').doc(uid).collection(tabName).doc(currListId).update({
        listName: editListName
      })
        .then(() => {
          setEditModal(false)
        })
    }
    else {
      db.collection('users').doc(uid).collection('lists').doc(uid).collection(tabName).add({
        listName: listName
      })
        .then(function (docRef) {
          db.collection('users').doc(uid).collection('lists').doc(uid).collection(tabName).doc(docRef.id).update({
            listID: docRef.id
          })
        })
        .then(() => {
          setNameModal(false)
        })
    }
  }



  const clearArrays = () => {
    setCheckedArr([])
    setIndexArr([])
    setCheckbox([])
    setAll(false)
  }

  useEffect(() => {
    setListArr([]);
    setData([]);
    setCurrListId('');
    clearArrays();
    getInfo();
  }, [tabName])

  useEffect(() => {
    if (areYouSureConvertContactToCustomer) {
      db.collection('users').doc(uid).collection('lists').doc(uid).collection('Customers')
        .onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push(doc.data())
          });
          setModalArr(items)
        });
    }
    else {
      getInfo();
    }
  }, [areYouSureConvertContactToCustomer])

  return (
    <div className={classes.mainContainer}>
      <Hidden className={classes.fullWidth} smDown implementation="css">
        <GridContainer>
          <GridItem xs={6}>
            <p className={classes.subTitle}>
              Review information about your connected users.
            </p>
          </GridItem>
          <GridItem xs={6}
            style={{
              justifyContent: "flex-end",
              display: "flex",
              verticalAlign: "center",
              marginTop: "-50px",
            }}>

            <Button
              className={classes.newButton}
              style={{ marginRight: "20px", height: "60px", backgroundColor: "#13294C" }}
            >
              <CSVLink headers={headers} data={csvData} style={{ color: "white", width: "200px" }}
              >
                Export CSV Sample
              </CSVLink>
            </Button>
          </GridItem>

        </GridContainer>

      </Hidden>

      <Card className={classes.filterCardBody}>
        <SearchBox id="investmentsQuery" onInput={inputSearch} />
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ textColor: "black", padding: "15px" }}
        >
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>Filter by:</p>
          {filterError ? <p style={{ fontSize: "16px", fontWeight: "bold", color: "red" }}>Please choose an option below before searching.</p> : null}
          <div style={{ display: "flex" }}>
            <FormControlLabel style={{ color: "black" }} value="name" control={<Radio style={{ color: "#13294C" }} />} label="Name" />
            <FormControlLabel style={{ color: "black" }} value="email" control={<Radio style={{ color: "#13294C" }} />} label="Email" />
            <FormControlLabel style={{ color: "black" }} value="company" control={<Radio style={{ color: "#13294C" }} />} label="Company" />
            <FormControlLabel style={{ color: "black" }} value="number" control={<Radio style={{ color: "#13294C" }} />} label="Number" />
            <FormControlLabel style={{ color: "black" }} value="address" control={<Radio style={{ color: "#13294C" }} />} label="Address" />
            <FormControlLabel style={{ color: "black" }} value="city" control={<Radio style={{ color: "#13294C" }} />} label="City" />
            <FormControlLabel style={{ color: "black" }} value="state" control={<Radio style={{ color: "#13294C" }} />} label="State" />
            <FormControlLabel style={{ color: "black" }} value="zipcode" control={<Radio style={{ color: "#13294C" }} />} label="Zip Code" />
          </div>
        </RadioGroup>
      </Card>
      <CustomTabs
        contacts={true}

        tabs={[
          {
            id: "Leads",
            tabName: "Leads",
            // tabIcon: () => ,
            tabContent: (
              <GridContainer >
                <GridItem xs={6}
                  style={{
                    display: "flex",
                    verticalAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  <FormControl
                    className={classes.formControl}>
                    <Select
                      style={{ width: "250px", marginLeft: "10px", marginTop: "10px" }}
                      disableUn
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        formControl: classes.addUserInput
                      }}
                      onChange={(e) => {
                        setCurrListId(e.target.value)
                        getList(e.target.value)
                        clearArrays()
                      }}
                      value={currListId}
                    >
                      {listArr.map((d, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={d.listID}
                          >
                            {d.listName}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <div
                    style={{ justifyContent: "flex-end", display: "flex" }}
                  >
                    <Button
                      className={classes.newButton}
                      component="label"
                      style={{ marginLeft: "20px", height: "60px", backgroundColor: "#1D9BC9", height: "50px", }}
                      onClick={() => {
                        setNameModal(true)
                      }}
                    >
                      Create New List

                    </Button>
                    {currListId ?
                      <Button
                        className={classes.newButton}
                        component="label"
                        style={{ marginLeft: "20px", height: "60px", backgroundColor: "#1D9BC9", height: "50px", width: "80px" }}
                        onClick={() => {
                          setEditModal(true)
                        }}
                      >
                        <Edit />
                      </Button>
                      : null}

                  </div>


                </GridItem>
                <GridItem xs={6}
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    verticalAlign: "center",
                    whiteSpace: "pre-wrap",
                    marginTop: "10px"
                  }}
                >
                  {checkedArr.length > 0 ?
                    <>
                      <Button
                        className={classes.newButton}
                        // component="label"
                        style={{ marginRight: "20px", height: "60px", backgroundColor: "#1D9BC9", height: "50px" }}
                        onClick={() => setAreYouSureConvertContactToCustomer(true)}
                      >
                        Convert
                      </Button>

                      <Button
                        className={classes.newButton}
                        // component="label"
                        style={{ marginRight: "20px", height: "60px", backgroundColor: "#1D9BC9", height: "50px" }}
                        onClick={() => setAreYouSureDeleteLeadModal(true)}
                      >
                        Remove
                      </Button>
                      <Button
                        className={classes.newButton}
                        component="label"
                        style={{ marginRight: "20px", height: "60px", backgroundColor: "#1D9BC9", height: "50px" }}
                        onClick={() => clearArrays()}
                      >
                        Clear Selection
                      </Button>
                    </>
                    : null}

                  {currListId && data.length > 0 && !all && data.length != checkedArr.length ?
                    <Button
                      className={classes.newButton}
                      component="label"
                      style={{ marginRight: "20px", height: "60px", backgroundColor: "#1D9BC9", height: "50px" }}
                      onClick={() => {
                        setAll(!all)
                      }}
                    >
                      Select All
                    </Button>

                    :
                    <Button
                      className={classes.newButton}
                      component="label"
                      style={{ marginRight: "20px", height: "60px", backgroundColor: "#1D9BC9", height: "50px" }}
                    // onClick={() => {
                    //   setAll(true)
                    // }}
                    // disabled
                    >
                      Select All
                    </Button>}


                  <Button
                    className={classes.newButton}
                    component="label"
                    style={{ marginRight: "20px", height: "60px", backgroundColor: "#1D9BC9", height: "50px" }}
                    onClick={() => {
                      if (!currListId) {
                        setListErr(true)
                      }
                      else {
                        setListErr(false)
                      }
                    }}
                  >
                    Import CSV <input type="file" hidden accept={".csv"} onChange={handleOnChange} />
                  </Button>
                </GridItem>
                {listErr ?
                  <GridItem xs={12}
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      verticalAlign: "center",
                      whiteSpace: "pre-wrap",
                      marginTop: "10px",
                      marginRight: "20px"
                    }}
                  >
                    <p style={{ fontSize: "16px", fontWeight: "bold", color: "red" }}>Select a List First</p>

                  </GridItem>
                  : null
                }

                {fileLenErr ?
                  <GridItem xs={12}
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      verticalAlign: "center",
                      whiteSpace: "pre-wrap",
                      marginTop: "10px",
                      marginRight: "20px"
                    }}
                  >
                    <p style={{ fontSize: "16px", fontWeight: "bold", color: "red" }}>Make Sure You Have less than 1,000 People in Your File</p>

                  </GridItem>
                  : null
                }

                <GridItem style={{ border: "none !important" }} xs={12}>
                  <table style={{ width: "100%", border: "none !important" }}>
                    <tbody style={{ background: "white", border: "none !important" }}>
                      <tr style={{ height: "50px", width: "100%", border: "none !important" }}>{renderTableHeader()} </tr>
                      {renderTableData()}
                    </tbody>
                  </table>
                </GridItem>
              </GridContainer>
            )
          },
          {
            id: "Customers",
            tabName: "Customers",
            // tabIcon: () => ,
            tabContent: (
              <GridContainer>
                <GridItem xs={6}
                  style={{
                    display: "flex",
                    verticalAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  <FormControl
                    className={classes.formControl}>
                    <Select
                      style={{ width: "250px", marginLeft: "10px", marginTop: "10px" }}
                      disableUn
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        formControl: classes.addUserInput
                      }}
                      onChange={(e) => {
                        setCurrListId(e.target.value)
                        getList(e.target.value)
                        clearArrays()
                      }}
                      value={currListId}

                    >
                      {listArr.map((d, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={d.listID}
                          >
                            {d.listName}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <div
                    style={{ justifyContent: "flex-end", display: "flex" }}
                  >
                    <Button
                      className={classes.newButton}
                      component="label"
                      style={{ marginLeft: "20px", height: "60px", backgroundColor: "#1D9BC9", height: "50px", }}
                      onClick={() => {
                        setNameModal(true)
                      }}
                    >
                      Create New List
                    </Button>
                  </div>


                </GridItem>
                <GridItem xs={6}
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    verticalAlign: "center",
                    whiteSpace: "pre-wrap",
                    marginTop: "10px"
                  }}
                >
                  {checkedArr.length > 0 ?
                    <>

                      <Button
                        className={classes.newButton}
                        // component="label"
                        style={{ marginRight: "20px", height: "60px", backgroundColor: "#1D9BC9", height: "50px" }}
                        onClick={() => setAreYouSureDeleteCustomerModal(true)}
                      >
                        Remove
                      </Button>

                      <Button
                        className={classes.newButton}
                        component="label"
                        style={{ marginRight: "20px", height: "60px", backgroundColor: "#1D9BC9", height: "50px" }}
                        onClick={() => clearArrays()}
                      >
                        Clear Selection
                      </Button>
                    </>
                    : null}


                  {currListId && data.length > 0 && !all && data.length != checkedArr.length ?
                    <Button
                      className={classes.newButton}
                      component="label"
                      style={{ marginRight: "20px", height: "60px", backgroundColor: "#1D9BC9", height: "50px" }}
                      onClick={() => {
                        setAll(true)
                      }}
                    >
                      Select All
                    </Button>

                    :
                    <Button
                      className={classes.newButton}
                      component="label"
                      style={{ marginRight: "20px", height: "60px", backgroundColor: "#1D9BC9", height: "50px" }}
                    // onClick={() => {
                    //   setAll(true)
                    // }}
                    // disabled
                    >
                      Select All
                    </Button>}

                  <Button
                    className={classes.newButton}
                    component="label"
                    style={{ marginRight: "20px", height: "60px", backgroundColor: "#1D9BC9", height: "50px" }}
                  >
                    Import CSV <input type="file" hidden accept={".csv"} onChange={handleOnChange} />
                  </Button>
                </GridItem>
                {listErr ?
                  <GridItem xs={12}
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      verticalAlign: "center",
                      whiteSpace: "pre-wrap",
                      marginTop: "10px",
                      marginRight: "20px"
                    }}
                  >
                    <p style={{ fontSize: "16px", fontWeight: "bold", color: "red" }}>Select a List First</p>

                  </GridItem>
                  : null
                }

                {fileLenErr ?
                  <GridItem xs={12}
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      verticalAlign: "center",
                      whiteSpace: "pre-wrap",
                      marginTop: "10px",
                      marginRight: "20px"
                    }}
                  >
                    <p style={{ fontSize: "16px", fontWeight: "bold", color: "red" }}>Make Sure You Have less than 1,000 People in Your File</p>

                  </GridItem>
                  : null
                }

                <GridItem style={{ border: "none !important" }} xs={12}>
                  <table style={{ width: "100%", border: "none !important" }}>
                    <tbody style={{ background: "white", border: "none !important" }}>
                      <tr style={{ height: "50px", width: "100%", border: "none !important" }}>{renderTableHeader()} </tr>
                      {renderTableData()}
                    </tbody>
                  </table>
                </GridItem>
              </GridContainer>
            )
          },
        ]}
      />

      <Dialog
        open={nameModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setNameModal(false)
              setListName('')
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>
          <h4>Enter Your List Name</h4>
        </DialogTitle>

        <form onSubmit={createList}>
          <DialogContent>
            <TextField
              fullWidth
              id="name"
              label="List Name"
              value={listName}
              InputProps={{
                classes: {
                  root: classes.textFieldRoot,
                },
                inputProps: {
                  onChange: e => setListName(e.target.value)
                }
              }}
              className={classes.textFieldRoot}
              variant="outlined"
            />
          </DialogContent>
          <center>
            <Button
              className={classes.newButton}
              type="submit"
              style={{ backgroundColor: "rgb(86, 163, 166)", fontSize: "20px", height: "50px", marginBottom: "10px" }}
            >
              Submit
            </Button>
          </center>
        </form>
      </Dialog>


      <Dialog
        classes={{
          // root: classes.modalRoot,
          // paper: classes.modal + " " + classes.modalSmall
        }}
        open={importModal}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => setImportModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setImportModal(false)
              setFile('')
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>

          <h4>Import {file.name}? </h4>

        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.modalRoot,
          }}
          style={{ width: "100%" }}
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
            <Button
              className={classes.newButton}
              onClick={(e) => {
                handleOnSubmit(e);
                setImportModal(false)
                setFileUploaded(true)
              }}
              style={{ fontSize: "20px" }}>
              Yes
            </Button>
            <Button
              className={classes.newButton}
              style={{ fontSize: "20px", backgroundColor: "#f5c107" }}
              onClick={() => {
                setImportModal(false)
                setFile('')
              }}>
              No
            </Button>
          </div>

        </DialogContent>
      </Dialog>

      <Dialog
        classes={{
          // root: classes.modalRoot,
          // paper: classes.modal + " " + classes.modalSmall
        }}
        open={errorModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setErrorModal(false)
              setFile('')
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>

          <h4 style={{ color: "red", fontWeight: 500 }}>Error! File Isn't a .CSV File </h4>

        </DialogTitle>
        <center>
          <Button
            className={classes.newButton}
            component="label"
            style={{ marginRight: "20px", height: "60px", backgroundColor: "#1D9BC9" }}
          >
            Choose Different File
            <input type="file" hidden accept={".csv"} onChange={handleOnChange} onClick={() => setErrorModal(false)} />
          </Button>
        </center>
      </Dialog>


      <Dialog
        style={{ width: "100%" }}
        classes={{
          // root: classes.modalRoot,
          // paper: classes.modal + " " + classes.modalSmall
        }}
        fullWidth={true}
        open={areYouSureDeleteLeadModal}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => setAreYouSureDeleteLeadModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              clearArrays()
              setAreYouSureDeleteLeadModal(false)
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>
          {checkedArr.length > 1 ?
            <h4>You Have Selected Multiple Leads, Are You Sure You Want to Delete Selected Leads?</h4>
            :
            <h4>Are You Sure You Want To Remove {selectedContact.Name ? selectedContact.Name : null} As A Lead?</h4>
          }


        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.modalRoot,
          }}
          style={{ width: "100%" }}
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
            <Button
              className={classes.newButton}
              onClick={() => {
                deleteMultiple()
                setAreYouSureDeleteLeadModal(false)
                clearArrays()
              }}
              style={{ fontSize: "20px", }}>
              Yes
            </Button>
            <Button
            className={classes.newButton}
              style={{ fontSize: "20px", backgroundColor: "#f5c107" }}
              onClick={() => setAreYouSureDeleteLeadModal(false)}>
              No
            </Button>
          </div>

        </DialogContent>
      </Dialog>



      <Dialog
        style={{ width: "100%" }}
        classes={{
          // root: classes.modalRoot,
          // paper: classes.modal + " " + classes.modalSmall
        }}
        fullWidth={true}
        open={areYouSureDeleteCustomerModal}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => setAreYouSureDeleteCustomerModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              clearArrays()
              setAreYouSureDeleteCustomerModal(false)
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>
          {checkedArr.length > 1 ?
            <h4>You Have Selected Multiple Customers, Are You Sure You Want to Delete Selected Customers?</h4>
            :
            <h4>Are You Sure You Want To Remove {selectedContact.Name ? selectedContact.Name : selectedContact.firstName + ' ' + selectedContact.lastName} As A Customer?</h4>
          }
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.modalRoot,
          }}
          style={{ width: "100%" }}
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
            <Button 
            className={classes.newButton}
            onClick={() => {
              deleteMultiple()
              setAreYouSureDeleteCustomerModal(false)
              clearArrays()
            }} style={{ fontSize: "20px", }}>
              Yes
            </Button>
            <Button 
            className={classes.newButton}
            onClick={() => setAreYouSureDeleteCustomerModal(false)}
            style={{ fontSize: "20px", backgroundColor: "#f5c107" }}
            >
              No
            </Button>
          </div>

        </DialogContent>
      </Dialog>


      <Dialog
        style={{ width: "100%" }}
        fullWidth={true}
        open={areYouSureConvertContactToCustomer}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              clearArrays()
              setModalListId('')
              setAreYouSureConvertContactToCustomer(false)
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>


          {checkedArr.length > 1 ?
            <h4>You Have Selected Multiple Leads, Are You Sure You Want to Convert Selected Leads?</h4>
            :
            <h4>Are You Sure You Want To Convert {selectedContact.Name ? selectedContact.Name : selectedContact.firstName + ' ' + selectedContact.lastName} To A Customer?</h4>
          }

        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.modalRoot,
          }}
          style={{ width: "100%", }}
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <center>
            <p style={{ fontSize: "16px" }}>Please select a list to add customer to</p>
            <FormControl
              className={classes.formControl}
            >
              <Select
                style={{ width: "250px", marginLeft: "10px", marginTop: "10px", }}
                disableUn
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  formControl: classes.addUserInput
                }}
                onChange={(e) => {
                  setModalListId(e.target.value)
                  // setModalListName(e.target.name)
                  // getList(e.target.value)
                  // clearArrays()
                }}
                value={modalListId}
              >
                {modalArr.map((d, index) => {
                  return (
                    <MenuItem
                      key={index}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple
                      }}
                      value={d.listID}
                    >
                      {d.listName}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <Button
              onClick={() => {
                setCustomerModalListName(true)
              }}
              style={{ marginLeft: "10px", width: "20%" }}>
              Create New List
            </Button>
          </center>
          <div style={{ display: "flex", width: "100%", justifyContent: "space-around", paddingTop: "15px" }}>
            <Button onClick={() => {
              convertMultiple()
            }}
              className={classes.newButton}
              style={{ fontSize: "20px" }}>
              Yes
            </Button>
            <Button 
            className={classes.newButton}
            onClick={() => {
              setModalListId('')
              setAreYouSureConvertContactToCustomer(false)
            }}
            style={{ fontSize: "20px", backgroundColor: "#f5c107" }}
            >
              No
            </Button>
          </div>
          <center>
            {selectListErr ? <p style={{ color: "red", fontWeight: "bold" }}>Please select a list</p> : null}
          </center>
        </DialogContent>
      </Dialog>


      <Dialog
        open={customerModalListName}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setCustomerModalListName(false)
              setModalListName('')
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>
          <h4>Enter Your List Name</h4>
        </DialogTitle>

        <form onSubmit={createList}>
          <DialogContent>
            <TextField
              required
              fullWidth
              id="name"
              label="List Name"
              value={modalListName}
              InputProps={{
                classes: {
                  root: classes.textFieldRoot,
                },
                inputProps: {
                  onChange: e => setModalListName(e.target.value)
                }
              }}
              className={classes.textFieldRoot}
              variant="outlined"
            />
          </DialogContent>
          <center>
            <Button
              className={classes.newButton}
              type="submit"
              style={{ backgroundColor: "rgb(86, 163, 166)", fontSize: "20px", height: "50px", marginBottom: "10px" }}
            >
              Submit
            </Button>
          </center>
        </form>
      </Dialog>

      <Dialog
        open={editModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setEditModal(false)
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>
          <h4>Edit Your List Name or Delete it</h4>
        </DialogTitle>

        <form onSubmit={createList}>
          <DialogContent>
            <TextField
              fullWidth
              id="name"
              label="List Name"
              value={editListName}
              InputProps={{
                classes: {
                  root: classes.textFieldRoot,
                },
                inputProps: {
                  onChange: e => setEditListName(e.target.value)
                }
              }}
              className={classes.textFieldRoot}
              variant="outlined"
            />
          </DialogContent>
          <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
            <Button
              className={classes.newButton}
              type="submit"
              style={{ backgroundColor: "rgb(86, 163, 166)", fontSize: "20px", height: "50px", marginBottom: "10px", marginRight: "25px" }}
            >
              Submit
            </Button>

            <Button
              className={classes.newButton}
              type="submit"
              style={{ backgroundColor: "red", fontSize: "20px", height: "50px", marginBottom: "10px", marginLeft: "25px" }}
              onClick={() => setDeleteCheckModal(true)}
            >
              Delete
            </Button>
          </div>
        </form>
      </Dialog>

      <Dialog
        classes={{
          // root: classes.modalRoot,
          // paper: classes.modal + " " + classes.modalSmall
        }}
        open={deleteCheckModal}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => setImportModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setDeleteCheckModal(false)
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>

          <h4>Are You Sure You Want to Delete This List? </h4>

        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.modalRoot,
          }}
          style={{ width: "100%" }}
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
            <Button
              className={classes.newButton}
              onClick={() => {
                deleteMultiple()
              }}
              style={{ backgroundColor: "rgb(86, 163, 166)", fontSize: "20px" }}>
              Yes
            </Button>
            <Button
              className={classes.newButton}
              style={{ fontSize: "20px", backgroundColor: "#f5c107" }}
              onClick={() => {
                setDeleteCheckModal(false)
              }}>
              No
            </Button>
          </div>

        </DialogContent>
      </Dialog>


      <Snackbar open={convertedLeadSnackbar} onClose={() => setConvertedLeadSnackbar(false)}>
        <Alert onClose={() => setConvertedLeadSnackbar(false)} severity='success'>
          An Invite Link Has Been Sent To The Lead
        </Alert>
      </Snackbar>


      <Snackbar open={convertedCustomerToLead} onClose={() => setConvertedCustomerToLead(false)}>
        <Alert onClose={() => setConvertedCustomerToLead(false)} severity='success'>
          The customer was converted back to a lead succesfully.
        </Alert>
      </Snackbar>

      <Snackbar
        open={fileUploaded}
      >
        <SnackbarContent
          style={{ background: "white", color: "black", fontSize: "18px" }}
          // message="Files are being uploaded"
          action={(
            <div style={{}}>
              <GridContainer>
                <GridItem xs={12}>
                  <h5 style={{ width: "100%" }}>Files are being uploaded</h5>
                </GridItem>
                <GridItem xs={10} style={{ marginTop: "10px" }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    style={{ width: "80%" }}

                  />
                </GridItem>
                <GridItem xs={2} style={{ marginLeft: "-25px" }}>
                  <h6 style={{ color: "black", fontSize: "18px", marginTop: "-3px", }}>
                    {Math.round(progress)}%
                  </h6>
                </GridItem>


              </GridContainer>


              <span>

              </span>
            </div>
          )} >

        </SnackbarContent>
      </Snackbar>

    </div>
  );
}


function mapStateToProps(state) {
  return {
    firstName: state.auth.user.firstName,
    lastName: state.auth.user.lastName,
    records: state.recordset.leads.records,
    uid: state.auth.user.uid,
    initLeadsDataRetrieved: state.leads.initDataRetrieved,
    leadsData: state.leads.leads,
    user: state.auth.user,
    leadsIds: state.leads.leadsIds,
    tabName: state.customTab.tabName,
    customersData: state.customers.customers,
    initCustomersDataRetrieved: state.customers.initDataRetrieved
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLeads: (uid) => dispatch(getLeads(uid)),
    deleteLead: (uid, leadId) => dispatch(deleteLead(uid, leadId)),
    convertLeadToCustomer: (leadData, uid, user) => dispatch(convertLeadToCustomer(leadData, uid, user)),
    addLead: (uid, newLead, allLeads) => dispatch(addLead(uid, newLead, allLeads)),
    deleteCustomer: (uid, id) => dispatch(deleteCustomer(uid, id))
  }
}

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(contactsPageStyle)
)(ContactsPage));
