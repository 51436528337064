import React, { useState, useEffect } from 'react';
import { db } from "firebase/client.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

const CreateTaskForm = props => {
    const [content, setContent] = useState('');

    const createNote = () => {
        db.collection('customers').doc(props.customerId).collection('tasks').add({
            content,
            createdBy: props.user.firstName + " " + props.user.lastName,
            createdByUID: props.user.uid,
            createdAt: new Date()
        })
            .then(res => {
                console.log(res);
                setContent("")
            })
    }

    return (
        <GridContainer>
            <GridItem xs={12}>
                <CustomInput
                    labelText="Type Task Here"
                    id="content"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        type: "text",
                        onChange: (e) => setContent(e.target.value),
                        value: content
                    }}
                />
            </GridItem>
            <GridItem>
                <Button
                    color='info'
                    onClick={createNote}
                >
                    ADD THIS TASK
                </Button>
            </GridItem>
        </GridContainer>
    );
}

export default CreateTaskForm;