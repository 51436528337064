import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer.js';
import CurrentScore from './CurrentScore.jsx'
import ReportResults from './ReportResults.jsx';
import TargetScore from './TargetScore.jsx';
import './CurrentScore.css'
import GridItem from 'components/Grid/GridItem'


const CreditHistory = (props) => {
  const { classes, creditData } = props;
  const [targetScore, setTargetScore] = useState(creditData.targetScore);
  console.log('creditData', creditData)

  const labels = creditData.scoreHistory.map(
    (score) => score.month + ' ' + score.year
  );

  return (
    <GridContainer>
      <GridItem style={{marginBottom: "20px"}} xs={12}
        sm={12}
        md={4}
        lg={4}>
        <CurrentScore classes={classes} creditData={creditData} />
      </GridItem>
      <GridItem style={{marginBottom: "20px"}} xs={12}
        sm={12}
        md={4}
        lg={4} xs={12}
        sm={12}
        md={4}
        lg={4}>
        <ReportResults classes={classes} creditData={creditData} />
      </GridItem>
      <GridItem style={{marginBottom: "20px"}} xs={12}
        sm={12}
        md={4}
        lg={4} xs={12}
        sm={12}
        md={4}
        lg={4}>
        <TargetScore classes={classes} creditData={creditData} />
      </GridItem>
    </GridContainer>

  );
};

CreditHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  creditData: PropTypes.object.isRequired,
};

export default CreditHistory;
