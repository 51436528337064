import { getAccessorsForForm } from "../form";
import { getAccessorsForDialog } from "../dialog";

import { accessorsForRecordset, config } from "store/reducers/recordsets/customers"

export default { 
    recordset: accessorsForRecordset, 
    queryDialog: getAccessorsForDialog("dialog", "customers/query"), 
    queryForm: getAccessorsForForm(config, "form", "customers/query"), 
    editorForm: getAccessorsForForm(config, "form", "customers/editor"),
    newForm: getAccessorsForForm(config, "form", "customers/new") 
};


