/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { db, firebase, storage } from 'firebase/client';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Phone';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Fab from '@material-ui/core/Fab';
import AddIcon from './addIcon.png';
import TrashIcon from './trash.png';
import EditIcon from './edit.png';
import ViewIcon from './view.png';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Table from 'components/Table/Table.js';
import Box from '@material-ui/core/Box';
import Button from 'components/CustomButtons/Button.js';
import Hidden from "@material-ui/core/Hidden";
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import loader from 'assets/img/loader.gif';
import CardBody from 'components/Card/CardBody.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import actionsForNavigation from 'store/actions/navigation';
import Slide from '@material-ui/core/Slide';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';

import documentsStyle from 'assets/jss/material-dashboard-pro-react/views/documentsStyle.js';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { DateTime } from 'luxon';
import { isElementAccessExpression } from 'typescript';

import { updateUserData } from 'store/actions/auth.js';
import { addNewDocument, getDocuments } from 'store/actions/documentActions';
import ClientListDocumentsModal from './ClientListDocumentsModal';

const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

export function DataFurnisherDocuments(props) {
  const { classes, uid, data, initDataRetrieved } = props;

  const documentList = [
    { month: 'January 2022', numberOfDocs: 4, readyToSend: true },
    { month: 'February 2022', numberOfDocs: 2, readyToSend: false },
    { month: 'March 2022', numberOfDocs: 1, readyToSend: true },
  ];

  const sendButton = (status) => {
    if (status) {
      return (
        <Button
          style={{ width: '75px' }}
          color={'success'}
          className={classes.actionButton}
        >
          Send
        </Button>
      );
    } else {
      return null;
    }
  };

  // const tableData = documentList.map((client) => {
  //   const [readyToSend, setReadyToSend] = React.useState(client.readyToSend);
  //   return (
  //     viewButton,
  //     sendButton(readyToSend),
  // );

  return (
    <div>
      <Hidden className={classes.fullWidth} smDown implementation="css">
        <p style={{ fontSize: "20px !important", marginBottom: "40px" }} className={classes.subTitle}>
          Review your documents and send them on if necessary.
        </p>
      </Hidden>
      <GridContainer
        style={{
          display: 'flex',
          backgroundColor: '#fff',
          justifyContent: 'space-between',
          boxShadow: '0px 2px 8px -1px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px',
          width: "100%",
          margin: "0px auto"
        }}
      >


        <div style={{ width: '100%' }}>
          <CardHeader sx={{ width: '100%', height: '100%' }} color='primary'>
            <h3
              style={{
                textAlign: 'center',
                padding: '5px',
                margin: '0',
              }}
              className={classes.cardTitle}
            >
              My Documents
            </h3>
          </CardHeader>
        </div>
        {documentList.map((document) => {
          return (
            <GridItem
              style={{
                direction: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
              xs={12}
              sm={12}
              md={6}
              lg={4}
            >
              <Card
                style={{
                  margin: '25px 0px 15px 0px',
                  border: 'none',
                  boxShadow: 'none',
                }}
              >
                <CardBody style={{ textAlign: 'center' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        margin: '15px 0',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p
                          style={{
                            fontFamily: 'Lato',
                            fontSize: '18px',
                            fontWeight: '600',
                            lineHeight: '19px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            margin: '15px 0',
                          }}
                        >
                          {document.month}
                        </p>
                        <p
                          style={{
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '17px',
                            textAlign: 'left',
                            margin: '0',
                          }}
                        >
                          {document.readyToSend
                            ? 'Ready to Send'
                            : 'Not Ready to Send'}
                        </p>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            margin: '15px 0',
                          }}
                        >
                          <ClientListDocumentsModal image='view' />
                          <ClientListDocumentsModal image='add' />
                          <img src={TrashIcon} style={{ width: '16px' }} />
                        </div>
                        <p
                          style={{
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '17px',
                            textAlign: 'right',
                            margin: '0',
                          }}
                        >
                          # of Documents: {document.numberOfDocs}
                        </p>
                      </div>
                    </div>
                    <Button style={{ height: '40px', width: '100%' }}>
                      Send
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
    </div>

  );
}

DataFurnisherDocuments.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  console.log('stateeeeee', state);
  return {
    uid: state.auth.user.uid,
    data: state.documents.documents,
    initDataRetrieved: state.documents.initDataRetrieved,
  };
}

const mapDispatchToProps = (dispatch) => ({
  addNewDocument: (id, document) => dispatch(addNewDocument(id, document)),
  getDocuments: (id) => dispatch(getDocuments(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(documentsStyle)
)(DataFurnisherDocuments);
