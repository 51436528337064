import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CustomInput from "./CustomInput";
import CustomSelect from "./CustomSelect";
import CustomCheckbox from "./CustomCheckbox";
import { ControlTypes, DataTypes } from "variables/constants";
import { startCase } from "lodash";
import { OpenMode } from "variables/constants";
import { DateTime } from "luxon";

function getControlType(config) {
    if (config.controlType) return config.controlType;
    switch (config.type) {
        case DataTypes.DATETIME:
            return ControlTypes.DATETIME;
        case DataTypes.BOOLEAN:
            return ControlTypes.CHECKBOX;
        default:
            if (config.values)
                return ControlTypes.SELECT;
            else
                return ControlTypes.INPUT;
    }
}

function getFormat(config) {
    if (config.format) return config.format;
    switch (config.type) {
        case DataTypes.DATETIME:
            return value=>value.toISO({includeOffset: false});
        case DataTypes.BOOLEAN:
            return value=>String(value);
        default:
            return value=>value;
    }
}

/** apply a format to a field value.
 *
 * When a field is validated, the data in the underlying store may be converted to the correct type
 * (if it is valid). So for instance, a valid datetime string will be converted to a DateTime object.
 * A format provides a way to convert the value in the store back into something editable. However,
 * if the value in the store is already a string (presumably because it did not validate), or if
 * it is null, we should not apply the format.
 *
 */
function applyFormat(format, value) {
    if (value === '' || value === undefined) return '';
    if (typeof value === 'string') return value;
    return format(value);
}

export function BoundInput(props) {
  let { inputProps, labelProps, config, editField, validateField, ...rest } = props;
  if (!config) {
    console.warn("No config found for BoundInput " + props.id);
    config = {};
  }


  const onBlur = inputProps.readOnly ? undefined : (e) => validateField(e.target.value);
  const value = applyFormat(getFormat(config), inputProps.value);
  const controlType = getControlType(config);
  switch(controlType) {
    case ControlTypes.SELECT:
        inputProps = {
            ...inputProps,
            onChange: (e) => editField(e.target.value),
            onBlur,
            value
        };
        const defaultValue = config.default || null;
        props = { ...rest, inputProps, labelProps, values: config.values, default: defaultValue };
        return <CustomSelect {...props} />;
    case ControlTypes.CHECKBOX:
        inputProps = {
            ...inputProps,
            onChange: (e) => editField(e.target.checked),
            onBlur,
            value,
        };
        props = { ...rest, inputProps, labelProps };
        return <CustomCheckbox {...props} />;
    case ControlTypes.DATETIME:
        inputProps = {
            type: controlType.inputType,
            ...inputProps,
            onChange: (e) => editField(e.target.value),
            onBlur,
            value,
        };
        labelProps = { ...labelProps, shrink: true }
        props = { ...rest, inputProps, labelProps };
        return <CustomInput {...props} />;
    case ControlTypes.DATE:
          let dateString;
          if (value) {
            let date = DateTime.fromISO(value);
            let year = date.c.year;
            let month = date.c.month;
            let day = date.c.day;
            if (month.toString().length === 1) {
              month = month.toString().split();
              month.unshift(0);
              month = month.join('');
            }
            if (day.toString().length === 1) {
              day = day.toString().split();
              day.unshift(0);
              day = day.join('');
            }
            if (year.toString().length < 4) {
              year = year.toString().split('');
              let length = year.length;
              for (let i = 0; i < (4-length); i++) {
                year.unshift(0);
              }
              year = year.join('');
            }
            dateString = `${year}-${month}-${day}`;
          }
            inputProps = {
                type: controlType.inputType,
                ...inputProps,
                onChange: (e) => editField(e.target.value),
                onBlur,
                value: dateString,
            };
          labelProps = { ...labelProps, shrink: true }
          props = { ...rest, inputProps, labelProps };
          return <CustomInput {...props} />;
    default:
        inputProps = {
            type: controlType.inputType,
            ...inputProps,
            onChange: (e) => editField(e.target.value),
            onBlur,
            value
        };
        props = { ...rest, inputProps, labelProps };
        if (props.prepopulatedEmailValue) {
          props.inputProps.value = props.prepopulatedEmailValue
        }

        return <CustomInput {...props} />;
  }
}

BoundInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
  name: PropTypes.string.isRequired,
};

function mapStateToProps(accessors) {
  return (state, ownProps) => {
    const config = accessors.getFieldConfig(ownProps.name);
    const labelText = ownProps.labelText || (config.label === undefined ? startCase(ownProps.name) : config.label);
    const error = accessors.getFieldError(state, ownProps.name);
    const errorText = error && error.message;
    const readOnly = accessors.getMode(state) === OpenMode.READ_ONLY;
    return {
      inputProps: {
        ...ownProps.inputProps,
        name: ownProps.name,
        value: accessors.getField(state, ownProps.name) || "",
        readOnly
      },
      error: !!error,
      helperText: errorText,
      labelText,
      config,
      id: `${accessors.getFormName()}-${accessors.getStepName()}-${ownProps.name}`,
      user: state.auth.user,
    };
  };
}

function mapDispatchToProps(actions) {
  return (dispatch, ownProps) => ({
    editField: (value) => dispatch(actions.editField(ownProps.name, value)),
    validateField: (value) => dispatch(actions.validateField(ownProps.name, value)),
  });
}

export default (actions, accessors) =>
  connect(
    mapStateToProps(accessors),
    mapDispatchToProps(actions)
  )(BoundInput);
