export const OpenMode = Object.freeze({
    READ_ONLY: 'READ_ONLY',
    UPDATE: 'UPDATE'
})

export const Sorts = Object.freeze({
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING'
});

export const ControlTypes = Object.freeze({
    CHECKBOX: Object.freeze({ code: "CHECKBOX" }),
    SELECT: Object.freeze({ code: "SELECT" }),
    INPUT: Object.freeze({ code: "INPUT", inputType: "text" }),
    DATE: Object.freeze({ code: "DATE", inputType: "date" }),
    DATETIME: Object.freeze({ code: "DATETIME", inputType: "datetime-local" })
});


export const CardFieldFormats = Object.freeze({
    SIMPLE_DATE: "simple-date",
    TEXT_BLOCK: "text-block"
});

export const DataTypes = Object.freeze({
    TEXT: Object.freeze({ code: 'TEXT', longForm: 'text value' }),
    NUMBER: Object.freeze({ code: 'NUMBER', longForm: 'numeric value' }),
    DATETIME: Object.freeze({ code: 'DATETIME', longForm: 'date/time value' }),
    BOOLEAN: Object.freeze({ code: 'BOOLEAN', longForm: 'true or false value' }),
    ENUM: Object.freeze({ code: 'ENUM', longForm: 'one of a set of predefined values' })
});

export const CustomerStatus = Object.freeze({
    SERVICE_AGREEMENTS: { code: 'SERVICE_AGREEMENTS', longForm: 'Service Agreements', nextStatus: [  'PERSONALIZED_PLANS' ] },
    PERSONALIZED_PLANS: { code: 'PERSONALIZED_PLANS', longForm: 'Personalized Plans', nextStatus: [ 'NEGATIVE_ITEMS' ] },
    NEGATIVE_ITEMS: { code: 'NEGATIVE_ITEMS', longForm: 'Negative Items', nextStatus: [ 'SEND_LETTERS' ] },
    SEND_LETTERS: { code: 'SEND_LETTERS', longForm: 'Send Letters', nextStatus: [ 'CURRENT_CUSTOMER' ] },
    CURRENT_CUSTOMER: { code: 'CURRENT_CUSTOMER', longForm: 'Current Customer', nextStatus: [ 'COMPLETED', 'DECLINED', 'COLLECTIONS' ] },
    COMPLETED:  { code: 'COMPLETED', longForm: 'Completed', nextStatus: [] },
    DECLINED: { code: 'DECLINED', longForm: 'Declined', nextStatus: [] },
    COLLECTIONS: { code: 'COLLECTIONS', longForm: 'Collections', nextStatus: [] },
})

export const LeadStatus = Object.freeze({
    PENDING: { code: 'PENDING', longForm: 'Pending', nextStatus: [  'ENGAGED' ] },
    ENGAGED: { code: 'ENGAGED', longForm: 'Engaged', nextStatus: [ 'LAPSED', 'CLOSED' ] },
    LAPSED: { code: 'LAPSED', longForm: 'Lapsed', nextStatus: [ ] },
    CLOSED: { code: 'CLOSED', longForm: 'Closed', nextStatus: [ ] }
})

export const Operators = Object.freeze({
    greaterThanOrEqualTo:   Object.freeze({ shortForm: ">=", longForm: "Greater Than Or Equal To" }),
    lessThanOrEqualTo:      Object.freeze({ shortForm: "<=", longForm: "Less Than Or Equal To" }),
    lessThan:               Object.freeze({ shortForm: "<", longForm: "Less Than" }),
    greaterThan:            Object.freeze({ shortForm: ">", longForm: "Greater Than" }),
    equalTo:                Object.freeze({ shortForm: "==", longForm: "Equal To" }),
    notEqualTo:             Object.freeze({ shortForm: "!=", longForm: "Not Equal To" }),
    startsWith:             Object.freeze({ shortForm: "^", longForm: "Starts With" })
});