/*eslint-disable*/
import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { db, firebase, storage } from 'firebase/client';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Phone';
import SendIcon from '@material-ui/icons/Send';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Switch from '@material-ui/core/Switch';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Table from 'components/Table/Table.js';
import Box from '@material-ui/core/Box';
import Button from 'components/CustomButtons/Button.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import loader from 'assets/img/loader.gif';
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import actionsForNavigation from 'store/actions/navigation';
import Slide from '@material-ui/core/Slide';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import LinearProgress from "@material-ui/core/LinearProgress";



import documentsStyle from 'assets/jss/material-dashboard-pro-react/views/documentsStyle.js';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { DateTime } from 'luxon';
import { isElementAccessExpression } from 'typescript';


import { updateUserData } from 'store/actions/auth.js';
import { addNewDocument, getDocuments } from 'store/actions/documentActions';
import { addDocumentsToCustomerCollectionForDF } from "store/actions/customerActions"
import {createLeadsFromCSV} from "store/actions/leadsActions"
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';
import PdfLogo from 'assets/img/market-me/pdfLogo.png'
import UploadArrowLogo from 'assets/img/market-me/uploadArrowLogo.png'
import Dropzone from 'react-dropzone'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';




import csv from "csvtojson"

const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

export function CSVUploadModalContent(props) {
  const { data, classes, uid, selectedCustomer, setClientDocumentModal, setAddNewDocumentModal } = props
  const CSVInputRef = createRef(null)
  const [filesArray, setFilesArray] = useState([])
  console.log('filesArray', filesArray)
  const [fileNames, setFileNames] = useState([])

  const [progress, setProgress] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [totalItems, setTotalItems] = useState(0)

  const [downloadUrls, setDownloadUrls] = useState([])
  const [array, setArray] = useState([])
  console.log('arrayyy', array)

  const fileReader = new FileReader();


  console.log('selectedCustomer', selectedCustomer)

  useEffect(() => {
    if (progress === totalItems && uploading) {
      (async () => {
        await props.addDocumentsToCustomerCollectionForDF(selectedCustomer.uid, downloadUrls, uid, selectedCustomer.id, selectedCustomer)
        setUploading(false)
        setProgress(0)
        setTotalItems(0)
        setClientDocumentModal(false)
        setAddNewDocumentModal(false)
        setFilesArray([])
        setFileNames([])
      })();
    }
  }, [progress])



  const handleCSVUpload = (event) => {
    props.createLeadsFromCSV(uid, array)
    props.setAddLeadModal(false)
    props.setCsvLeadsSuccesful(true)
  }

  const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    var toReturn = []

    const array = csvRows.map((item, index) => {
      const values = item.split(",");
      var obj = {}
      console.log('values', values)
      values.forEach((v, index) => {
        console.log('values foreach', v)
        obj[csvHeader[index].trim()] = v.toString()
      })
      toReturn.push(obj)
    });
    console.log('toReturn', toReturn)
    setArray(toReturn);
  };


  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }} className={classes.container}>
      <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between", paddingTop: "24px", height: "30% !important" }}>
        <TextField
          defaultValue='No File Chosen'
          value={filesArray.map((file) => {
            return file.name + ', '
          })}
          style={{ width: "65%", marginRight: "20px" }} />
        <Button className={classes.yellowBtn} onClick={() => {
          CSVInputRef.current.click();
        }} style={{ width: "30%" }}>
          CHOOSE FILE
          <input onChange={(event) => {
            var file = event.target.files[0]
            fileReader.onload = function (event) {
              const text = event.target.result;
              csvFileToArray(text);
            };
            fileReader.readAsText(file);
          }} type='file' accept=".csv" ref={CSVInputRef} style={{ display: 'none' }} />
        </Button>
      </div>

      {array.length === 0 ? (
       <h4 className={classes.pleaseBeSure}>
         Please Be Sure To Download Our CSV Template before uploading
       </h4>
      ) : (
        <div style={{ height: "70% !important", border: "2px dashed black", margin: "20px 0px", borderRadius: "20px", padding: "60px", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <InsertDriveFileIcon style={{fontSize: "55px"}} />
          <p>.csv</p>
          </div>
        </div>
      )}

      {uploading ? (
        <GridContainer style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
          <GridItem xs={10}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '80%', mr: 1 }}>
                <LinearProgress variant="determinate" value={Math.round(progress / totalItems) * 100} />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <h6 style={{ color: "black", fontSize: "25px" }}>{progress} / {totalItems}</h6>
              </Box>
            </Box>
          </GridItem>

          <GridItem xs={2}>
            <img
              style={{ width: '20%' }}
              src={loader}
              alt="..."
            />
          </GridItem>
        </GridContainer>
      ) : (
        null
      )}
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
        <Button className={classes.halfWidthYellowBtns} onClick={() => {
         props.setAddLeadModal(false)
        }}>
          Back
        </Button>
        <Button style={{ backgroundColor: "#5EB1E3" }} className={classes.halfWidthBtns} onClick={handleCSVUpload}>
          Submit
        </Button>
      </div>
    </div>
  );
}

CSVUploadModalContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    uid: state.auth.user.uid,
    customerData: state.customers.customers
  };
}

const mapDispatchToProps = (dispatch) => ({
  addNewDocument: (id, document) => dispatch(addNewDocument(id, document)),
  getDocuments: (id) => dispatch(getDocuments(id)),
  addDocumentsToCustomerCollectionForDF: (customerUID, downloadUrls, uid, customerId, selectedCustomer) => dispatch(addDocumentsToCustomerCollectionForDF(customerUID, downloadUrls, uid, customerId, selectedCustomer)),
  createLeadsFromCSV: (uid, array) => dispatch(createLeadsFromCSV(uid, array))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(documentsStyle)
)(CSVUploadModalContent);
