import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { connect } from "react-redux";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Explorer } from "./Explorer";

import styles from "assets/jss/material-dashboard-pro-react/components/explorerStyle";

const useStyles = makeStyles(styles);


export function NeedsAttentionExplorer(props) {

    const [attentionSelected, setattentionSelected] = React.useState(true);

    const classes = useStyles();

    const { ExplorerCard, SearchControl, SearchDialog, routeName, searchFields, cardFields, userType } = props;

    let userName = `${props.firstName} ${props.lastName}`
    let filteredRecords = props.records.filter((record) => {
        return record.fields.loanOfficer == userName || record.fields.creditAnalyst
    })

    return (
        <div>
            {attentionSelected == false ? <Explorer {...props}></Explorer> :
                <div>
                    {props.userType == "ANALYST" || props.userType == "ADMIN" ?
                        <div>
                            <SearchControl />
                            <SearchDialog order={searchFields} />
                            {console.log(attentionSelected)}
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={attentionSelected}
                                        onChange={event => setattentionSelected(event.target.checked)}
                                        value="attentionSelected"
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="Show only customers that Need Attention"
                            />
                            {/* Conditional Explorer cards based on status. There is most likely a cleaner way to do this. */}
                            {/* Customer Status Options */}
                            {/* <h1>Leads</h1>
                    {props.records.map((record, index) => (
                        ((record.fields.status === "Lead") && (record.fields.needsAttention == true) || (record.fields.status === undefined)) ?
                            <ExplorerCard
                                needsAttention={record.fields.needsAttention}
                                status={record.fields.status}
                                firstName={record.fields.firstName}
                                lastName={record.fields.lastName}
                                joinDate={record.fields.joinDate}
                                description={record.fields.description}
                                estimatedPullDate={record.fields.estimatedPullDate}
                                key={index}
                                index={index}
                                order={cardFields}
                                editRoute={`${routeName}/editor/${record.key}`}
                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                notesRoute={`${routeName}/notes/${record.key}`}
                            /> : null
                    ))}
                    {props.records.map((record, index) => (
                        ((record.fields.status === "Lead") && (record.fields.needsAttention !== true) || (record.fields.status === undefined)) ?
                            <ExplorerCard
                                needsAttention={record.fields.needsAttention}
                                status={record.fields.status}
                                firstName={record.fields.firstName}
                                lastName={record.fields.lastName}
                                joinDate={record.fields.joinDate}
                                description={record.fields.description}
                                estimatedPullDate={record.fields.estimatedPullDate}
                                key={index}
                                index={index}
                                order={cardFields}
                                editRoute={`${routeName}/editor/${record.key}`}
                                viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                notesRoute={`${routeName}/notes/${record.key}`}
                            /> : null
                    ))} */}
                            <h1>Service Agreements</h1>
                            {props.records.map((record, index) => (
                                ((record.fields.status === "Service Agreements") && (record.fields.needsAttention == true) || (record.fields.status === undefined)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>POP/Neg List</h1>
                            {props.records.map((record, index) => (
                                ((record.fields.status === "POP/Neg List") && (record.fields.needsAttention == true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Send Letters</h1>
                            {props.records.map((record, index) => (
                                ((record.fields.status === "Send Letters") && (record.fields.needsAttention == true)) && (props.userType == "ANALYST") ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}

                                    /> : null
                            ))}
                            <h1>Current Customer</h1>
                            {props.records.map((record, index) => (
                                ((record.fields.status === "Current Customer") && (record.fields.needsAttention == true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Promo</h1>
                            {props.records.map((record, index) => (
                                ((record.fields.status === "Promo") && (record.fields.needsAttention == true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Completed</h1>
                            {props.records.map((record, index) => (
                                ((record.fields.status === "Completed") && (record.fields.needsAttention == true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Declined</h1>
                            {props.records.map((record, index) => (
                                ((record.fields.status === "Declined") && (record.fields.needsAttention == true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Needs to be sent to Collections</h1>
                            {props.records.map((record, index) => (
                                ((record.fields.status === "Needs to be sent to Collections") && (record.fields.needsAttention == true)) && (props.userType == "ANALYST") ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Collections</h1>
                            {props.records.map((record, index) => (
                                ((record.fields.status === "Collections") && (record.fields.needsAttention == true)) && (props.userType == "ANALYST") ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            {props.userType == "ANALYST" || props.userType == "ADMIN" ? <Fab className={classes.newRecord} onClick={e => props.openChild("New Record", `${routeName}/new`)}><AddIcon /></Fab> : null}
                        </div>
                        :

                        <div>
                            {/* Explorer Page view for all user types aside from Admin and Analyst*/}
                            <SearchControl />
                            <SearchDialog order={searchFields} />
                            <h1>Leads</h1>
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "Lead") && (record.fields.needsAttention == true) || (record.fields.status === undefined)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "Service Agreements") && (record.fields.needsAttention == true) || (record.fields.status === undefined)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Awaiting Credit Report</h1>
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "POP/Neg List") && (record.fields.needsAttention == true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Current Customer</h1>
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "Current Customer") && (record.fields.needsAttention == true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Promo</h1>
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "Promo") && (record.fields.needsAttention == true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Completed</h1>
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "Completed") && (record.fields.needsAttention == true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                            <h1>Declined</h1>
                            {filteredRecords.map((record, index) => (
                                ((record.fields.status === "Declined") && (record.fields.needsAttention == true)) ?
                                    <ExplorerCard
                                        needsAttention={record.fields.needsAttention}
                                        status={record.fields.status}
                                        firstName={record.fields.firstName}
                                        lastName={record.fields.lastName}
                                        joinDate={record.fields.joinDate}
                                        description={record.fields.description}
                                        estimatedPullDate={record.fields.estimatedPullDate}
                                        key={index}
                                        index={index}
                                        order={cardFields}
                                        editRoute={`${routeName}/editor/${record.key}`}
                                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                                        notesRoute={`${routeName}/notes/${record.key}`}
                                    /> : null
                            ))}
                        </div>
                    }
                </div>
            }
            <Fab className={classes.newRecord} onClick={e => props.openChild("New Record", `${routeName}/new`)}><AddIcon /></Fab>
        </div>
    );
}

NeedsAttentionExplorer.propTypes = {
    routeName: PropTypes.string
}

function mapStateToProps(state) {
    return {
        firstName: state.auth.user.firstName,
        lastName: state.auth.user.lastName,
        records: state.recordset.customers.records
    }
}

function mapDispatchToProps() {
    return {
        // nothing yet
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NeedsAttentionExplorer);