// /*eslint-disable*/
// import React, { useEffect, useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { db, firebase, storage } from 'firebase/client';
// import { NavLink, withRouter } from 'react-router-dom';
// import { Link } from "react-router-dom";


// import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
// import BoundExplorer from "components/Explorer/BoundExplorer";
// import actionsForExplorer from "store/actions/explorers/leads";
// import withStyles from "@material-ui/core/styles/withStyles";
// import AddAlert from "@material-ui/icons/AddAlert";
// import accessorsForExplorer from "store/reducers/explorers/leads";
// import { LeadStatus } from "variables/constants";
// import compose from "recompose/compose";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Icon from "@material-ui/core/Icon";
// import CustomInput from "components/CustomInput/CustomInput";
// import CardFooter from "components/Card/CardFooter";
// import Hidden from "@material-ui/core/Hidden";
// // @material-ui/icons
// import Email from "@material-ui/icons/Email";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
// import CardHeader from "components/Card/CardHeader.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";
// import Switch from "@material-ui/core/Switch";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
// import Input from "@material-ui/core/Input";

// // import CardFields from '../../../components/Card/CardFields';
// // import CardField from '../../../components/Card/CardField';
// import actionsForNavigation from "store/actions/navigation";
// import { connect } from "react-redux";
// import { MockUserData } from 'variables/config';
// import avatar from 'assets/img/default-avatar.png';
// import SearchBox from 'components/SearchBox/SearchBox.jsx';
// import OpenInNewIcon from '@material-ui/icons/OpenInNew';
// import DeleteIcon from '@material-ui/icons/Delete';
// import Close from "@material-ui/icons/Close";
// import { getLeads, deleteLead, convertLeadToCustomer } from "store/actions/leadsActions"
// import Slide from "@material-ui/core/Slide";
// import Snackbar from '@material-ui/core/Snackbar';
// import { TextField } from "@material-ui/core";
// import Alert from '@material-ui/lab/Alert';
// import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

// import AuthActions from "store/actions/auth"
// import { chain, handleError } from "store/actions/util";
// import { push } from "connected-react-router";




// export function LinkedInPage(props) {
//   const { classes, history } = props
//   const code = "AQW-fnUVpfoUNMyOdJl5lwU9dUCWEVeouOqzuJYNNRgABDkbWOmIv7CaBROtY9UTaXAt2EOIYOagk1PZ4VLDZYyORnKU3AD5ogzWjIdCICQUi6kFHGB9_mj1EkcVVK7WkF_bJNKpnuso5aw3UAEofINey5IUUJM18iRt0UhW8Q4ua83Fel3RLQrkaTF7nZ6X_R9E9_2NJhOq4RQOtiIeZVyEF6JitLK5VH6uRpOtTN1ziFCPj66BTX2HKzyxgYbBM6K6kScV-bni8sZXn-Ev79rTjAdV2HYLao7YB7YPuhHw2XCTmlTZrcT23QIyjNU1-PVQmVJVjD21UNifYPi3tdKF8tf4IQ"
//   const [id, setID] = useState("")
//   const [show, setShow] = useState(false);
//   const [info, setInfo] = useState({
//     post: '',
//     postErr: ''
//   })

//   const onChange = (e) => {
//     setInfo({
//       ...info,
//       [e.target.id]: e.target.value
//     })
//   }
//   const postTest = () => {
//     const data = JSON.stringify(info.post)
//     var request = require('request');
//     var options = {
//       'method': 'POST',
//       'url': `https://protected-shelf-37912.herokuapp.com/https://api.linkedin.com/v2/ugcPosts`,
//       'headers': {
//         'Content-Type': 'application/json',
//         'X-Restli-Protocol-Version': '2.0.0',
//         'Authorization': `Bearer ${code}`
//       },
//       body: `{"author": "urn:li:person:${id}", "lifecycleState": "PUBLISHED", "specificContent": {"com.linkedin.ugc.ShareContent": {"shareCommentary": {"text": ${data}},"shareMediaCategory": "NONE"}}, "visibility": {"com.linkedin.ugc.MemberNetworkVisibility": "CONNECTIONS"}}`
//     };
//     request(options, function (error, response) {
//       if (error) throw new Error(error);
//       console.log(response.body);
//       console.log("status code: ", response.statusCode)
//       if(response.statusCode === 201){
//         alert("Post has been shared")
//         location.reload()
//       }
//     });
//   }

//   const onSubmit = async (e) => {
//     e.preventDefault()
//     if(!info.post){
//       setInfo({
//         ...info,
//         postErr: "Post is empty"
//       })
//       console.log("error")
//     }
//     else{
//       console.log("works")
//       postTest()
//       setInfo({
//         post: '',
//         postErr: ''
//       })
//     }
//   }

//   const loginTest = () => {
//     const link = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86hgia0qus6nr4&scope=r_liteprofile&state=123456&redirect_uri=https://www.linkedin.com/developers/tools/oauth/redirect"
//     window.open(link, "Linkedin", "popup=1")
//     const request = require('request');
//     const options = {
//       'method': 'GET',
//       'url': `https://protected-shelf-37912.herokuapp.com/https://api.linkedin.com/v2/me`,
//       'headers': {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${code}`
//       },
//     };
//     request(options, function (error, response) {
//       if (error) throw new Error(error);
//       if (response.statusCode === 200) {
//         var myJSON = response.body.toString();
//         var data = JSON.parse(myJSON)
//         setID(data.id)
//       }
//     });
//   }

//   return (
//     <div>
//       <h1>LinkedIn Page</h1>
//       <Button
//         onClick={() => loginTest()}
//       >
//         Sign in to LinkedIn
//       </Button>

//       <Button
//         onClick={() => setShow(true)}
//       >
//         Post to LinkedIn
//       </Button>

//           <Dialog
//             open={show}
//           >
//             <form onSubmit={onSubmit}>
//             <DialogTitle id="alert-dialog-title" style={{ fontFamily: "Quattrocento Sans", textAlign: "center", }}>
//               Enter what you want to share on your Linkedin Profile
//             </DialogTitle>
//             <DialogContent>
//               <GridContainer justify="center" style={{ padding: "0 10px" }}>
//                 <GridItem xs={12}>
//                   <Card>
//                     <TextField
//                       id="post"
//                       formControlProps={{
//                         fullWidth: true
//                       }}
//                       multiline={true}
//                       rows={4}
//                       inputProps={{
//                         onChange: onChange,
//                       }}
//                       error={info.postErr}
//                       helperText={info.postErr ? 'Error! Post cannot be empty.' : ''}
//                     />

//                   </Card>

//                 </GridItem>
//                 <Button onClick={() => setShow(false)}>
//                   Cancel
//                 </Button>
//                 <Button type="submit">
//                   Submit
//                 </Button>
//               </GridContainer>
//             </DialogContent>
//             </form>
//           </Dialog>
//     </div>

//   );
// }


// function mapStateToProps(state) {
//   return {
//   }
// }

// function mapDispatchToProps(dispatch) {
//   return {
//   }
// }

// export default withRouter(compose(
//   connect(mapStateToProps, mapDispatchToProps),
//   withStyles(loginPageStyle)
// )(LinkedInPage));

/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";


import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import CardFooter from "components/Card/CardFooter";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Input from "@material-ui/core/Input";

// import CardFields from '../../../components/Card/CardFields';
// import CardField from '../../../components/Card/CardField';
import actionsForNavigation from "store/actions/navigation";
import { connect } from "react-redux";
import { MockUserData } from 'variables/config';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import Close from "@material-ui/icons/Close";
import { getLeads, deleteLead, convertLeadToCustomer } from "store/actions/leadsActions"
import Slide from "@material-ui/core/Slide";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import campaignsStyle from "assets/jss/material-dashboard-pro-react/views/campaignsStyle.js";

import AuthActions from "store/actions/auth"
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Views from "views/Components/Views.js";
import LinkedInCampaignForm from "views/Components/Campaign/LinkedInCampaignForm";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const mockData = [
  {
    name: "Campaign #1",
    remainingTime: "48h",
    actionsCompleted: "98",
    status: "Active",
  },
  {
    name: "Campaign #2",
    remainingTime: "48h",
    actionsCompleted: "98",
    status: "Paused",
  },
  {
    name: "Campaign #3",
    remainingTime: "48h",
    actionsCompleted: "98",
    status: "Draft",
  },
  {
    name: "Campaign #4",
    remainingTime: "48h",
    actionsCompleted: "98",
    status: "Ended",
  },
];

export function InstgramPage(props) {
  const { classes, history } = props;
  const [showCampaign, setShowCampaign] = useState(false);

  return (
    <div className={classes.mainContainer}>
    {showCampaign ? <LinkedInCampaignForm goBack={(() => setShowCampaign(false))} /> :
    <>
      <Hidden className={classes.fullWidth} smDown implementation="css">
        <GridContainer>
          <GridItem xs={6}>
            <p className={classes.subTitle}>
              Manage, create, and see analytics information on your LinkedIn campaigns.
            </p>
          </GridItem>
          <GridItem xs={6} style={{ justifyContent: "flex-end", display: "flex", verticalAlign: "center", marginTop: "-50px", }}>
            <Button
              className={classes.newButton}
              style={{ marginRight: "20px" }}
              onClick={() => setShowCampaign(true)}
            >
              Create Campaign
            </Button>

            <Button
              className={classes.newButton}
              style={{ background: "#13294C" }}
            >
              Import Connections
            </Button>
          </GridItem>
        </GridContainer>

      </Hidden>

      {/* Start Carousel Section */}

      <GridContainer style={{ maxWidth: "100% !important" }}>
        <GridItem xs={12}>
          <Carousel
            autoplay={false}
            infinite={false}
            swipeable={true}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            draggable={false}
            showDots={false}
            responsive={responsive}
            style={{ display: "flex" }}
          >
            {mockData.map((d, index) => {
              return (
                <Card
                  className={classes.campaignCard}
                  style={
                    d.status === "Active"
                      ? { background: "#13294C" }
                      : d.status === "Paused"
                        ? {
                          background:
                            "linear-gradient(180deg, #1D9BC9 0%, #005479 100%)",
                        }
                        : d.status === "Draft"
                          ? { background: "rgb(244 195 0" }
                          : {
                            background:
                              "linear-gradient(180deg, #E60023 0%, #AC001A 100%)"
                          }
                  }
                >
                  <CardHeader>
                    <h5
                      style={{
                        color: "white",
                        fontFamily: "Abril Fatface",
                        fontSize: "24px"
                      }}
                    >
                      {d.name}
                    </h5>
                    <div className={classes.campaignHeader}></div>
                  </CardHeader>
                  <CardBody className={classes.campaignBody}>
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p style={{ color: "white", fontSize: "22px" }}>
                        Remaining Time:
                      </p>
                      <h5
                        style={{
                          display: "flex",
                          color: "white",
                          fontSize: "22px",
                          fontWeight: 500,
                          alignItems: "center",
                          marginTop: "0px",
                        }}
                      >
                        <AccessTimeIcon
                          style={{ color: "white", marginRight: "20px" }}
                        />
                        {d.remainingTime}
                      </h5>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      <p style={{ color: "white", fontSize: "22px" }}>
                        Actions Completed:
                      </p>
                      <h5
                        style={{
                          display: "flex",
                          color: "white",
                          fontSize: "22px",
                          fontWeight: 500,
                          alignItems: "center",
                          marginTop: "0px"
                        }}
                      >
                        <CheckCircleOutlineIcon
                          style={{ color: "white", marginRight: "20px" }}
                        />
                        {d.actionsCompleted}
                      </h5>
                    </div>
                  </CardBody>
                </Card>
              );
            })}
          </Carousel>
        </GridItem>
      </GridContainer>

      {/* Start Campaign Section */}

      <GridContainer
        style={{
          borderRadius: "10px",
          border: "3px solid rgba(15, 55, 87, 0.75)",
          display: "flex",
          flexDirection: "column"
        }}>
        <GridItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
          xs={12}
        >
          <h3 className={classes.subHeader}>All Linkedin Campaigns</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "50%"
            }}
          >
            <GridContainer
              style={{
                width: "100%",
                display: "flex",
                fontSize: "19px",
                height: "60px",
                alignItems: "center"
              }}
            >
              <GridItem xs={6} />
              <GridItem
                xs={3}
              >
                <Button
                  style={{
                    backgroundColor: "rgba(19, 40, 70, 1)",
                    color: "white"
                  }}>
                  Resources
                </Button>
              </GridItem>
              <GridItem
                xs={3}
              >
                <Button
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    border: "3px solid rgba(15, 55, 87, 0.75)",
                    color: "rgba(15, 55, 87)"
                  }}>
                  Templates
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </GridItem>

        {/* Start Campaign Titles Section */}

        <GridContainer
          style={{
            backgroundColor: "rgb(19, 40, 70)",
            height: "100px",
            width: "auto",
            margin: "0 0.1px 0 0.1px"
          }}>
          <GridItem
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
            xs={3}
          >
            <h5
              style={{
                color: "white",
                fontWeight: 600,
                marginLeft: "auto",
                marginRight: "auto"
              }}>

              Campaign #1
            </h5>
          </GridItem>
          <GridItem
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
            xs={3}
          >
            <h5
              style={{
                color: "white",
                fontWeight: 600,
                marginLeft: "auto",
                marginRight: "auto"
              }}>
              Campaign #2
            </h5>
          </GridItem>
          <GridItem
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
            xs={3}
          >
            <h5
              style={{
                color: "white",
                fontWeight: 600,
                marginLeft: "auto",
                marginRight: "auto"
              }}>
              Campaign #3
            </h5>
          </GridItem>
          <GridItem
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
            xs={3}
          >
            <h5
              style={{
                color: "white",
                fontWeight: 600,
                marginLeft: "auto",
                marginRight: "auto"
              }}>
              Campaign #4
            </h5>
          </GridItem>
        </GridContainer>

        {/* Start Campaign Info Section */}

        <GridContainer>
          <GridItem
            xs={3}
          >
            <div
              style={{
                textAlign: "center"
              }}>
              <h4
                style={{
                  fontWeight: 600
                }}
              >
                Direct Messaging
              </h4>
              <GridContainer
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}>
                <GridItem>
                  <GridContainer
                    style={{
                      display: "flex",
                      width: "auto",
                      margin: "0 10px 0 10px",
                      borderBottom: "3px solid rgba(27, 126, 171, 1)"
                    }}>
                    <GridItem
                      xs={8}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start"
                      }}
                    >
                      <h5>
                        &#8226;  Sent:
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end"
                      }}
                    >
                      <h5>
                        100
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem>
                  <GridContainer
                    style={{
                      display: "flex",
                      width: "auto",
                      margin: "0 10px 0 10px",
                      borderBottom: "3px solid rgba(27, 126, 171, 1)"
                    }}>
                    <GridItem
                      xs={8}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start"
                      }}
                    >
                      <h5>
                        &#8226;  Failed:
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end"
                      }}
                    >
                      <h5>
                        5
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem>

                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
          <GridItem
            xs={3}
          >
            <div
              style={{
                textAlign: "center"
              }}>
              <h4
                style={{
                  fontWeight: 600
                }}
              >
                Posting
              </h4>
              <GridContainer
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}>
                <GridItem>
                  <GridContainer
                    style={{
                      display: "flex",
                      width: "auto",
                      margin: "0 10px 0 10px",
                      borderBottom: "3px solid rgba(27, 126, 171, 1)"
                    }}>
                    <GridItem
                      xs={8}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start"
                      }}
                    >
                      <h5
                        style={{
                          width: "auto"
                        }}
                      >
                        &#8226; Complete:
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end"
                      }}
                    >
                      <h5>
                        100
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem>
                  <GridContainer
                    style={{
                      display: "flex",
                      width: "auto",
                      margin: "0 10px 0 10px",
                      borderBottom: "3px solid rgba(27, 126, 171, 1)"
                    }}>
                    <GridItem
                      xs={8}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start"
                      }}
                    >
                      <h5>
                        &#8226;  Failed:
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end"
                      }}
                    >
                      <h5>
                        5
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem>

                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
          <GridItem
            xs={3}
          >
            <div
              style={{
                textAlign: "center"
              }}>
              <h4
                style={{
                  fontWeight: 600
                }}
              >
                Commenting
              </h4>
              <GridContainer
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}>
                <GridItem>
                  <GridContainer
                    style={{
                      display: "flex",
                      width: "auto",
                      margin: "0 10px 0 10px",
                      borderBottom: "3px solid rgba(27, 126, 171, 1)"
                    }}>
                    <GridItem
                      xs={8}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start"
                      }}
                    >
                      <h5>
                        &#8226;  Complete:
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end"
                      }}
                    >
                      <h5>
                        100
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem>
                  <GridContainer
                    style={{
                      display: "flex",
                      width: "auto",
                      margin: "0 10px 0 10px",
                      borderBottom: "3px solid rgba(27, 126, 171, 1)"
                    }}>
                    <GridItem
                      xs={8}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start"
                      }}
                    >
                      <h5>
                        &#8226;  Failed:
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end"
                      }}
                    >
                      <h5>
                        5
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem>

                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
          <GridItem
            xs={3}
          >
            <div
              style={{
                textAlign: "center"
              }}>
              <h4
                style={{
                  fontWeight: 600
                }}
              >
                Followers Targeted
              </h4>
              <GridContainer
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}>
                <GridItem>
                  <GridContainer
                    style={{
                      display: "flex",
                      width: "auto",
                      margin: "0 10px 0 10px",
                      borderBottom: "3px solid rgba(27, 126, 171, 1)"
                    }}>
                    <GridItem
                      xs={8}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start"
                      }}
                    >
                      <h5>
                        &#8226;  Hashtags:
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end"
                      }}
                    >
                      <h5>
                        100
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem>
                  <GridContainer
                    style={{
                      display: "flex",
                      width: "auto",
                      margin: "0 10px 0 10px",
                      borderBottom: "3px solid rgba(27, 126, 171, 1)"
                    }}>
                    <GridItem
                      xs={8}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start"
                      }}
                    >
                      <h5>
                        &#8226;  Lorem:
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end"
                      }}
                    >
                      <h5>
                        5
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem>

                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>

        {/* Start Views Section */}
        <Views />
      </GridContainer>
      </> }
    </div>
  );
}


function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(campaignsStyle)
)(InstgramPage));

