/*eslint-disable*/
import React, { useParams, useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from 'assets/img/market-me/settingsLogo.png'
import SelectedSettingsLogo from 'assets/img/market-me/selectedSettingsLogo.png'



// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "components/CustomButtons/Button.js";
import GlobalSearchBox from "components/SearchBox/GlobalSearchBox";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import AuthActions from 'store/actions/auth';

import { setPathname } from 'store/actions/windowActions'




const useStyles = makeStyles(styles);

export function AdminNavbar(props) {
  const classes = useStyles();
  const { color, rtlActive, brandText, routes, history, user } = props;

  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });

  const [pathName, setPathName] = useState(window.location.pathname)
  const path = window.location.pathname

  useEffect(() => {
    setPathName(path)
  }, [path])


  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                style={{ background: "#13294C" }}
                onClick={props.sidebarMinimize}
              >
                <ViewList style={{ color: "white" }} className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                style={{ background: "#13294C" }}
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp implementation="css">
          {/* <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          > */}
          <Menu
            className={classes.appResponsive}
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          />
          {/* </Button> */}
        </Hidden>


        <Hidden className={classes.brandText} smDown implementation="css">


          {user.userType === 'Data Furnisher' ? (
            <List style={{ display: 'flex', flex: '1', justifyContent: 'flex-start', alignItems: 'center' }}>
              {routes.map(prop => {

                if (prop.name === 'Client Documents' || prop.name === 'Analytics' || prop.name === 'Leads') {
                  if (prop.path === path.slice(6)) {
                    console.log('propppp', prop)
                    return (
                      <NavLink to={`${prop.layout + prop.path}`} className={classes.gradientUnderline}>
                        <ListItemText
                          primary={rtlActive ? prop.rtlName : prop.name}
                          disableTypography={true}
                          style={{ color: 'white', fontFamilt: 'Roboto', fontSize: '18px', fontWeight: '500' }}
                        />
                      </NavLink>
                    )
                  } else {
                    return (
                      <NavLink to={`${prop.layout + prop.path}`} style={{ display: 'flex', marginLeft: '2%', marginRight: '2%' }}>
                        <ListItemText
                          primary={rtlActive ? prop.rtlName : prop.name}
                          disableTypography={true}
                          style={{ color: 'white', fontSize: '18px', fontWeight: '500' }}
                        />
                      </NavLink>
                    )
                  }
                }
              })
              }
            </List>
          ) : (
            <List style={{ display: 'flex', flex: '1', justifyContent: 'flex-start', alignItems: 'center' }}>
              {routes.map(prop => {

                if ( prop.name === 'Campaigns' || prop.name === 'Contacts' || prop.name === 'Demographics') {
                  if (prop.path === path.slice(6)) {
                    console.log('propppp', prop)
                    return (
                      <NavLink to={`${prop.layout + prop.path}`} className={classes.gradientUnderline}>
                        <ListItemText
                          primary={rtlActive ? prop.rtlName : prop.name}
                          disableTypography={true}
                          style={{ color: 'black', fontFamily: 'Roboto', fontSize: '18px', fontWeight: '500' }}
                        />
                      </NavLink>
                    )
                  } else {
                    return (
                      <NavLink to={`${prop.layout + prop.path}`} style={{ display: 'flex', marginLeft: '2%', marginRight: '2%' }}>
                        <ListItemText
                          primary={rtlActive ? prop.rtlName : prop.name}
                          disableTypography={true}
                          style={{ color: 'black', fontSize: '18px', fontWeight: '500' }}
                        />
                      </NavLink>
                    )
                  }
                }
              })
              }
            </List>
          )}
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-evenly', minWidth: '40%', }}>
            <GlobalSearchBox />
            <div style={{ alignItems: 'center', display: 'flex', flex: '1', justifyContent: 'space-evenly', paddingLeft: '1%' }}>
              {user.profilePic ? (
                <img style={{ height: "65px", borderRadius: "100%", margin: "0px 20px" }} src={user.profilePic} />
              ) : (
                <PersonIcon style={{ color: 'white', borderRadius: '50%', fontSize: '40', margin: "0px 20px" }} />
              )}
              <h5 style={{ margin: "0px", padding: "0px", color: "white", fontSize: "16px", fontWeight: 500 }}>
                {user.firstName}
              </h5>
              <CustomDropdown history={history} logout={props.logout} dropdownList={['My Profile', 'Log Out']} />
            </div>
          </div>
        </Hidden>


        <Hidden
          smDown
          className={classes.settingBtn} implementation="css">
          <h1 style={{ fontSize: "20px", margin: "0px 80px", fontWeight: 500, color: "white" }}>
            {typeof props.brandText === 'string' ? props.brandText : props.brandText[0]}
          </h1>
        </Hidden>

        <Hidden
          smDown
          className={classes.settingBtn} implementation="css">
          {pathName === '/admin/settings' ? (
            <img onClick={() => {
              props.setPathname('/admin/settings')
              history.push('/admin/settings')
            }} style={{ height: "27px" }} src={SelectedSettingsLogo} />
          ) : (
            <img onClick={() => {
              props.setPathname('/admin/settings')
              history.push('/admin/settings')
            }} style={{ height: "27px" }} src={SettingsIcon} />
          )}
        </Hidden>

      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};


function mapStateToProps(state) {
  return {
    user: state.auth.user
  };
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.startLogout()),
  setPathname: (path) => dispatch(setPathname(path))
});

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(AdminNavbar));
