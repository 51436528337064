import React from 'react';
import { db, firebase } from "firebase/client.js";
import CustomersActions from "store/actions/recordsets/customers";

class CustomerRefresh extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false
        }
    }

    componentDidMount() {
        CustomersActions.initialize()
        console.log("refresh")
    }

    render() {
        return null
    }
}

export default CustomerRefresh;