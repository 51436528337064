import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/customers";
import accessorsForExplorer from "store/reducers/explorers/customers";
import { CustomerStatus } from "variables/constants";
import BoundSearchControl from "../../../components/Explorer/BoundSearchControl";
import BoundSearchDialog from "../../../components/Explorer/BoundSearchDialog";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CardFields from '../../../components/Card/CardFields';
import CardField from '../../../components/Card/CardField';
import actionsForNavigation from "store/actions/navigation";
import { connect } from "react-redux";


import explorerPageStyle from "assets/jss/material-dashboard-pro-react/views/explorerPageStyle.js";

const Explorer = BoundExplorer(actionsForExplorer, accessorsForExplorer);
const ExplorerCard = BoundExplorerCard(actionsForExplorer.recordset, accessorsForExplorer.recordset);
const SearchControl = BoundSearchControl(actionsForExplorer, accessorsForExplorer);
const SearchDialog = BoundSearchDialog(actionsForExplorer, accessorsForExplorer);

const useStyles = makeStyles(explorerPageStyle);

function CustomExplorerCard(props) {
    const classes = useStyles();

    return (
        <ExplorerCard {...props}></ExplorerCard>
    )
}

export default function CustomerExplorer(props) {
    return (
        <Explorer
            ExplorerCard={CustomExplorerCard}
            searchFields={["firstName", "lastName", "currentScore", "goalScore", "status", "isQualified", "hasCreditReport", "loanOfficer", "creditAnalyst", "emailAddress", "nickName", "socialSecurityNumber"]}
            cardFields={["lastUpdated", "notes", "currentScore", "goalScore", "state", "status", "loanOfficer", "creditAnalyst", "isQualified", "hasCreditReport", "emailAddress", "lastFourSSN"]}
            routeName="/admin/customers"
            userType="ANALYST"
        />
    );
}

// const DISPLAY_ORDER = [
//     CustomerStatus.SERVICE_AGREEMENTS,
//     CustomerStatus.PERSONALIZED_PLANS,
//     CustomerStatus.NEGATIVE_ITEMS,
//     CustomerStatus.SEND_LETTERS,
//     CustomerStatus.CURRENT_CUSTOMER,
//     CustomerStatus.COMPLETED,
//     CustomerStatus.DECLINED,
//     CustomerStatus.COLLECTIONS
// ];


// export function CustomerExplorer(props) {

//     const { routeName, classes } = props;

//     let cardsByStatus = props.records.reduce((groups, record) => {
//         let group = groups[record.fields.status || 'unknown'] || [];
//         group.push(record);
//         groups[record.fields.status] = group;
//         return groups;
//     }, {})

//     return (
//         <div>
//             <SearchControl />
//             <SearchDialog order={["firstName", "lastName", "currentScore", "goalScore", "lastUpdated"]} />
//             {DISPLAY_ORDER.map((group, index) => (
//                 <div>
//                     <h1>{group.longForm}</h1>
//                     {console.log('cardsByStatus:', cardsByStatus)}
//                     {console.log('group.longForm:', group.longForm)}
//                     {/* { cardsByStatus[group.longForm].map((record, index) => (
//                         <ExplorerCard
//                             key={index}
//                             index={index}
//                             editRoute={`${routeName}/editor/${record.key}`}
//                             viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
//                             advance={props.setStatus(record.key, group.nextStatus[0])}
//                         />
//                     ))} */}
//                 </div>
//             ))}
//             <Fab
//                 // className={classes.newRecord}
//                 onClick={e => props.openChild("New Record", `${routeName}/new`)}><AddIcon /></Fab>
//         </div>
//     );
// }

// function mapStateToProps(state, ownProps) {
//     return {
//         records: accessorsForExplorer.recordset.get(state).records,
//         ExplorerCard: ownProps.ExplorerCard || ExplorerCard,
//         CardFields,
//         CardField,
//         SearchControl,
//         SearchDialog
//     };
// }

// function mapDispatchToProps(dispatch) {
//     return {
//         openChild: (title, route) => dispatch(actionsForNavigation.openChild(title, route)),
//         setStatus: (key, status) => dispatch(actionsForExplorer.recordset.updateRecord({ key, fields: { status } }))
//     };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(CustomerExplorer);

