import { addCloseHandlers, addInitializers as addFormInitializers } from '../actions/form';
import { chain, pipeline, getDefaults } from './util';
import { errorCodes } from 'variables/errors';
import { OpenMode } from 'variables/constants';
import actionsForNavigation from '../actions/navigation';

function initialize(collection, values) {
    return {
        type: 'RECORDSET_INITIALIZE',
        collection,
        values
    }
}

function search(collection, query, values) {
    return {
        type: 'RECORDSET_SEARCH',
        collection,
        search: query,
        values
    }
}

function liveFilter(collection, filter) {
    return {
        type: 'RECORDSET_LIVE_FILTER',
        collection,
        filter
    }
}

function setError(collection, error) {
    return {
        type: 'RECORDSET_SET_ERROR',
        collection,
        error
    }
}

function addRecord(collection, record, config) {

    const fields = { ...getDefaults(config), ...record.fields };

    return {
        type: 'RECORDSET_ADD_RECORD',
        collection,
        record: { ...record, fields }
    }
}

function updateRecord(collection, record) {
    return {
        type: 'RECORDSET_UPDATE_RECORD',
        collection,
        record
    }
}

function upsertRecord(collection, record) {
    return {
        type: 'RECORDSET_UPSERT_RECORD',
        collection,
        record
    }
}

function deleteRecord(collection, key) {
    return {
        type: 'RECORDSET_DELETE_RECORD',
        collection,
        key
    }
}

function getRecordset(state, collection) {
    return state.recordset[collection] || { records: [] }
}

function getRecord(recordset, index) {
    return recordset.records[index];
}

function getRecordByKey(recordset, searchKey) {
    return recordset.records.find(({key,value})=>key === searchKey);
}

function getRecordAction(collection, index) {
    return (_dispatch, getState) => Promise.resolve(getRecord(getRecordset(getState(), collection), index));
}

function getRecordByKeyAction(collection, key) {
    return (_dispatch, getState) => Promise.resolve(getRecordByKey(getRecordset(getState(), collection), key));
}

/** Exposes any error dispatching an action as a rejected promise.
 *
 * A recordset's reducer needs to throw errors when it encounters (for example) a duplicate key
 * on insert. To make this consistent with what happens when an error is thrown by a datasource,
 * we need to 'thunkify' the base actions and return errors as a rejected promise.
 *
 * In theory this should just be (dispatch) => new Promise(() => dispatch(action)) but there
 * are issues with the mock store used for testing.
 *
 * @param {*} action
 */
function thunkify(action) {
    return (dispatch, getState) => {
        try {
            dispatch(action);
            getState(); // I think this is only necessary becasue of the test framework.
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

export function actionsForRecordset(collection, config) {
    return {
        initialize: (values) => initialize(collection, values),
        search: (query) => search(collection, query),
        liveFilter: (constraint) => liveFilter(collection, constraint),
        setError: (error) => setError(collection, error),
        addRecord: (record) => thunkify(addRecord(collection, record, config)),
        deleteRecord: (key) => deleteRecord(collection, key),
        updateRecord: (record) => thunkify(updateRecord(collection, record)),
        upsertRecord: (record) => upsertRecord(collection, record),
        getRecordByKey: (key) => getRecordByKeyAction(collection, key),
        getRecord: (index) => getRecordAction(collection, index)
    }
}

function buildSearch(form) {
    const fields = (form.steps && form.steps.DEFAULT && form.steps.DEFAULT.fields) || {}
    const filter = Object.keys(fields).filter(key=>!key.startsWith("_")).map(key=>({ [key] : { operator: fields[`_${key}Operator`], value: fields[key] }}));
    const sort = fields._sort;
    return (dispatch, getState) => Promise.resolve({filter, sort});
}

export function actionsForQueryDialog(_actionsForRecordset, actionsForDialog, actionsForForm) {

    return {
        ...actionsForDialog,
        openDialog: () => chain(actionsForForm.initialize(OpenMode.UPDATE), actionsForDialog.openDialog()),
    }
}

export function actionsForQueryForm(actionsForRecordset, actionsForDialog, actionsForForm) {

    return {
        ...actionsForForm,
        _submit: () => pipeline(actionsForForm.getForm(), buildSearch, actionsForRecordset.search, actionsForDialog.closeDialog),
        cancel: () => chain(actionsForForm.cancel(), actionsForDialog.closeDialog())
    }
}

function buildRecord(form) {
    const { _key, ...fields } = (form.steps && form.steps.DEFAULT && form.steps.DEFAULT.fields) || {}
    return () => Promise.resolve({ key: _key, fields });
}

function buildForm(record) {
    if (record) {
        const { key, fields } = record;
        return () => Promise.resolve({ _key: key, ...fields});
    } else {
        return () => Promise.reject({ code: errorCodes.RECORDSET_INVALID_KEY, message: 'No data to build form' });
    }
}

export function actionsForEditorForm(actionsForRecordset, actionsForForm) {
    actionsForForm = addCloseHandlers(actionsForForm, {
        submit: () => pipeline(actionsForForm.getForm(), buildRecord, actionsForRecordset.updateRecord, actionsForNavigation.close),
        cancel: () => actionsForNavigation.close()
    });
    actionsForForm = addFormInitializers(actionsForForm, {
        initialize: (_openMode, key) =>pipeline(actionsForRecordset.getRecordByKey(key), buildForm)
    });
    return actionsForForm;
}

export function actionsForNewForm(actionsForRecordset, actionsForForm) {
    actionsForForm = addCloseHandlers(actionsForForm, {
        submit: () => pipeline(actionsForForm.getForm(), buildRecord, actionsForRecordset.addRecord, actionsForNavigation.close),
        cancel: () => actionsForNavigation.close()
    });
    return actionsForForm;
}

export function addDatasource(actions, datasource) {
    return {
        ...actions,
        initialize: datasource.initialize ? (records) => pipeline(()=>datasource.initialize(records), actions.initialize) : actions.initialize,
        addRecord: datasource.addRecord ? (record) => pipeline(()=>datasource.addRecord(record), actions.addRecord) : actions.addRecord,
        deleteRecord: datasource.deleteRecord ? (key) => chain(()=>datasource.deleteRecord(key), actions.deleteRecord(key)) : actions.deleteRecord,
        updateRecord: datasource.updateRecord ? (record) => chain(()=>datasource.updateRecord(record), actions.updateRecord(record)) : actions.updateRecord,
        upsertRecord: datasource.upsertRecord ? (record) => chain(()=>datasource.upsertRecord(record), actions.upsertRecord(record)) : actions.upsertRecord,
    }
}


export default {
    initialize,
    search,
    liveFilter,
    addRecord,
    deleteRecord
}