import React from 'react';
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";
import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Style from "assets/jss/material-dashboard-pro-react/components/formStepContainerStyle";

export function FormStepContainer(props) {
    const { classes, steps, step, next, last, continueButtonLabel, previousButtonLabel } = props;
    const Content = step && steps[step];
    return (
        <GridContainer style={{height: "100%"}} justify='center'>
            <GridItem xs={12}>
                {Content}
            </GridItem>
            {/* <div className={classes.buttonContainer}>
                {last &&
                    <GridItem xs={12} sm={6}>
                        <Button className={classes.lastButton} onClick={e => last()}>{previousButtonLabel}</Button>
                    </GridItem>
                }
                {next &&
                    <GridItem xs={12} sm={6}>
                        <Button className={classes.nextButton} onClick={e => next()}>{continueButtonLabel}</Button>
                    </GridItem>
                }
            </div> */}
        </GridContainer>
    );
}

FormStepContainer.propTypes = {
    step: PropTypes.string,
    steps: PropTypes.objectOf(PropTypes.node).isRequired,
    next: PropTypes.func,
    last: PropTypes.func,
    continueButtonLabel: PropTypes.node.isRequired,
    previousButtonLabel: PropTypes.node.isRequired
}

export default withStyles(Style)(FormStepContainer)