/*eslint-disable*/
import React, { useState, createRef, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { db, firebase, storage } from "firebase/client";
import AuthActions from 'store/actions/auth';
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import PinDropIcon from "@material-ui/icons/PinDrop";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
// core components
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import loader from "assets/img/loader.gif";
import LinearProgress from "@material-ui/core/LinearProgress";
import avatar from "assets/img/default-avatar.png";
import Box from "@material-ui/core/Box";

import envelopeLogo from "assets/img/market-me/envelopeLogo.png";
import convertLogo from "assets/img/market-me/convertLogo.png";
import houseLogo from "assets/img/market-me/houseLogo.png";
import emailLogo from "assets/img/market-me/emailLogo.png";
import phoneLogo from "assets/img/market-me/phoneLogo.png";
import userTypeLogo from "assets/img/market-me/userTypeLogo.png";

import userProfileStyle from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { DateTime } from "luxon";
import { isElementAccessExpression } from "typescript";

import { updateUserData, updateUserPhoto, updateUserLogo } from "store/actions/auth.js";

import { updateToken } from 'firebase/client';
import axios from 'axios';
import { getConversationRecipients, getUserPageId, sendMessage, getPageAccessToken } from 'store/actions/instagramActions';

import SendMessage from '../Components/Campaign/SendMessage';

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

export function UserProfile(props) {
  const {
    classes,
    firstName,
    lastName,
    userType,
    email,
    cellPhone,
    officePhone,
    companyName,
    about,
    uid,
    user,
    userInfo,
    authInfo,
    profilePic,
  } = props;
  const [editProfileForm, setEditProfileForm] = useState(false);
  const [firstNameState, setFirstNameState] = useState(
    userInfo.firstName ? userInfo.firstName : firstName ? firstName : "N/A"
  );
  const [lastNameState, setLastNameState] = useState(
    userInfo.lastName ? userInfo.lastName : lastName ? lastName : "N/A"
  );
  const [emailState, setEmailState] = useState(
    authInfo.email ? authInfo.email : email ? email : ""
  );
  const [cellPhoneState, setCellPhoneState] = useState(
    userInfo.cellPhone ? userInfo.cellPhone : cellPhone ? cellPhone : "N/A"
  );
  const [officePhoneState, setOfficePhoneState] = useState(
    userInfo.officePhone
      ? userInfo.officePhone
      : officePhone
        ? officePhone
        : "N/A"
  );
  const [aboutState, setAboutState] = useState(
    userInfo.about ? userInfo.about : about ? about : "N/A"
  );
  const [companyState, setCompanyState] = useState(
    userInfo.companyName
      ? userInfo.companyName
      : companyName
        ? companyName
        : "N/A"
  );
  const profilePicRef = createRef(null);
  const logoRef = createRef(null);
  const [userData, setUserData] = useState({});

  const mobileProfilePicRef = createRef(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  console.log("uploading", uploading);
  const [downloadUrls, setDownloadUrls] = useState([]);

  const [modalUploading, setModalUploading] = useState(false);

  const [unsubModal, setUnsubModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [doneModal, setDoneModal] = useState(false)

  const [message, setMessage] = useState('');


  useEffect(() => {
    if (progress === 100) {
      setUploading(false);
      setModalUploading(false);
    }
  }, [progress]);

  // console.log('userData', userData)

  useEffect(() => {
    if (Object.keys(userInfo).length > 0 && Object.keys(authInfo).length > 0) {
      var tempUser = {
        cellPhone: userInfo.cellPhone,
        companyName: userInfo.companyName,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: authInfo.email,
        profilePic: props.profilePic,
      };
      setUserData(tempUser);
    } else {
      setUserData(user);
    }
  }, [props]);

  // useEffect(() => {

  //    if (!userData.profilePic && props.profilePic) {
  //     console.log('proppsssx', props)
  //     var tempUser = { ...userData, profilePic: props.profilePic }
  //     setUserData(tempUser)
  //    }
  // }, [props])

  function formatPhoneNumber(phoneNumber) {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return "Invalid phone number length";
  }

  function formatDate(date) {
    if (DateTime.isDateTime(date))
      return date.toLocaleString(DateTime.DATE_SHORT);
    else return String(date);
  }

  const updateUserInfo = (e) => {
    e.preventDefault();
    var newInfo = {
      cellPhone: cellPhoneState,
      firstName: firstNameState,
      email: emailState,
      lastName: lastNameState,
      officePhone: officePhoneState,
      companyName: companyState,
      about: aboutState,
    };
    props.updateUserData(uid, newInfo);
    setEditProfileForm(false);
  };

  const unsubButton = () => {
    db.collection('users').doc(uid).update({
      subscription: firebase.firestore.FieldValue.delete()
    })
      .then(() => {
        props.logout();
      })
  }

  const handleImageUpload = (event, str) => {
    var previewUrl = URL.createObjectURL(event.target.files[0]);
    var file = event.target.files[0];
    const storageRef = storage.ref(uid);
    const fileRef = storageRef.child("profilePic");

    var uploadTask = fileRef.put(file);

    // fileRef.put(file).then((snapshot) => {
    console.log("strrrrrr", str);
    if (str === "profile") {
      setUploading(true);
    } else {
      setModalUploading(true);
    }

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      (snapshot) => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            console.log("downloadURL", downloadURL);
            props.updateUserPhoto(uid, { url: downloadURL });
          })
          .catch(() => { });
      }
    );

    // })
    // setPreviewUrl(url)
    // setFile(e.target.files[0])
  };

  const handleLogoUpload = (event, str) => {
    var previewUrl = URL.createObjectURL(event.target.files[0]);
    var file = event.target.files[0];
    const storageRef = storage.ref(uid);
    const fileRef = storageRef.child("logo");

    var uploadTask = fileRef.put(file);

    if (str === "logo") {
      setUploading(true);
    } else {
      setModalUploading(true);
    }

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      (snapshot) => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            props.updateUserLogo(uid, { url: downloadURL });
          })
          .catch(() => { });
      }
    );
  }

  const responseFacebook = (response) => {
    console.log('look fb', response);
    const authData = {
      client_id: response.id,
      access_token: response.accessToken,
      client_secret: 'b89cd2d626577b542614c48f210dc9ca',
    }
    db.collection('users').doc('6YNeZ7P8ZRSsZlDJlF3dwO9pwo02')
      .update(authData)
  }

  const checkLogin = () => {
    // getUserPageId(user.access_token)
    //   .then((pageId) => {
    //     console.log('look pageid', pageId)
    //     getPageAccessToken(pageId, user.access_token)
    //       .then((pageAccessToken) => {
    //         console.log('look pat', pageAccessToken)
    //         console.log('look accestoken', user.access_token)
    //         sendMessage(message, pageId, '5934421779909907', pageAccessToken);
    //       })
    //   })
    axios.get(`https://graph.facebook.com/v9.0/109079831984979/conversations?platform=instagram&access_token=${user.access_token}`)
      .then((res) => {
        console.log('look convo res', res);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const login = () => {
    FB.login((res) => {
      console.log('look res', res)
      if (res.status === 'connected') {
        const authData = {
          client_id: res.authResponse.userID,
          access_token: res.authResponse.accessToken
        }
        db.collection('users').doc('6YNeZ7P8ZRSsZlDJlF3dwO9pwo02')
          .update(authData)
        axios.get(`https://graph.facebook.com/v9.0/me/accounts?access_token=${res.authResponse.accessToken}`)
        .then((response) => {
          console.log('look response', response)
          if (response.data.data.length) {
            const pageId = response.data.data[0].id;
            axios.get(`https://graph.facebook.com/${pageId}?
            fields=access_token&
            access_token=${res.authResponse.accessToken}`)
              .then((res) => {
                const pageAccessToken = res.data.access_token;
                axios.get(`https://graph.facebook.com/v9.0/${pageId}/conversations?platform=instagram&access_token=${pageAccessToken}`)
                  .then((res) => {
                    console.log('look res 4', res)
                    const testId = res.data.data[1].id;
                    console.log('look id', testId)
                    axios.get(`https://graph.facebook.com/v9.0/${testId}?fields=messages&access_token=${pageAccessToken}`)
                      .then((res) => {
                        console.log('look res 5', res)
                        const messageId = res.data.messages.data[0].id;
                        axios.get(`https://graph.facebook.com/v9.0/${messageId}?fields=from,to&access_token=${pageAccessToken}`)
                          .then((res) => {
                            console.log('look res 6', res)
                            const igsid = res.data.to.data[0].id;
                            axios.post(`https://graph.facebook.com/v9.0/${pageId}/messages?recipient={id: ${igsid}}&message={text: 'testing text'}&access_token=${pageAccessToken}`)
                              .then((res) => console.log('look res 7', res))
                              .catch((err) => console.log('look err', err))
                          })
                      })
                  })
              })
          } else {
            throw new Error('No Facebook page connected to Instagram Account or No Instagram Account found')
          }
        })
      }
    }, { scope: 'instagram_basic,instagram_manage_messages,pages_manage_metadata' })
  }

  return (
    <GridContainer
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
    {/* <input onChange={(e)=>setMessage(e.target.value)} />
    <button onClick={checkLogin}>SEND MESSAGE</button>
    <SendMessage message={message} />
    <button onClick={login}>LOGIN</button> */}
      <Hidden className={classes.fullWidth} smDown implementation="css">
        <Card style={{ padding: "10px 0px 20px 15px" }}>
          <GridContainer>
            <GridItem xs={3} sm={3} md={3} style={{ display: "flex" }}>
              <div className={classes.profilePicDiv}>
                <img
                  onClick={() => profilePicRef.current.click()}
                  className={classes.img}
                  src={userData.profilePic ? userData.profilePic : avatar}
                />
                <input
                  onChange={(event) => {
                    handleImageUpload(event, "profile");
                  }}
                  type="file"
                  id="basicNFTArt"
                  ref={profilePicRef}
                  style={{ display: "none" }}
                />
                {/* <div style={{ display: "flex", alignItems: "end" }}>
                  <EditIcon />
                </div> */}
              </div>
            </GridItem>
            <GridItem style={{ display: "flex" }} xs={9} sm={9} md={9}>
              <div className={classes.leadInfo}>
                <div className={classes.leadDetailInfoDiv}>
                  <h5
                    style={{ fontFamily: "Abril Fatface" }}
                    className={classes.leadDetailName}
                  >
                    {userData.firstName + " " + userData.lastName}
                  </h5>
                  <p
                    style={{
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontSize: "16px",
                    }}
                    className={classes.leadDetailInfo}
                  >
                    {" "}
                    <img className={classes.icon} src={houseLogo} />{" "}
                    {userData.companyName}
                  </p>
                  <p
                    style={{
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontSize: "16px",
                    }}
                    className={classes.leadDetailInfo}
                  >
                    {" "}
                    <img className={classes.icon} src={userTypeLogo} />{" "}
                    {userData.userType}
                  </p>
                </div>

                <div className={classes.leadDetailInfoDiv}>
                  {/* <h5 className={classes.placeholder}>
                    {userData.firstName + " " + userData.lastName}
                  </h5> */}
                  <div className={classes.profilePicDiv}>
                    <img
                      onClick={() => logoRef.current.click()}
                      className={classes.img}
                      style={{ width: "85px", height: "85px" }}
                      src={userData.logo ? userData.logo : avatar}
                    />
                    <input
                      onChange={(event) => {
                        handleLogoUpload(event, "logo");
                      }}
                      type="file"
                      id="basicNFTArt"
                      ref={logoRef}
                      style={{ display: "none" }}
                    />
                    <div style={{ display: "flex", alignItems: "end" }}>
                      <EditIcon />
                    </div>
                  </div>
                  <p
                    style={{
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontSize: "16px",
                      whiteSpace: "nowrap"
                    }}
                    className={classes.leadDetailInfo}
                  >
                    {" "}
                    <img className={classes.icon} src={emailLogo} />{" "}
                    {userData.email}
                  </p>

                  <p
                    style={{
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontSize: "16px",
                    }}
                    className={classes.leadDetailInfo}
                  >
                    {" "}
                    <img className={classes.icon} src={phoneLogo} />{" "}
                    {userData.cellPhone}
                  </p>
                </div>
              </div>
            </GridItem>
            <GridItem xs={3} />
            <GridItem xs={9} style={{ marginTop: "-10px" }}>
              <p
                style={{
                  fontSize: "20px",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                }}
                className={classes.leadDetailInfo}
              >
                {userData.about
                  ? userData.about
                  : "Your user description will go here."}
              </p>
            </GridItem>
          </GridContainer>

        </Card>
      </Hidden>

      <Hidden className={classes.fullWidth} mdUp implementation="css">
        <GridItem style={{ display: "flex", marginTop: "50px" }} xs={12}>
          <div style={{ marginBottom: "0px" }} className={classes.leadInfo}>
            <Card
              style={{ marginBottom: "0px" }}
              className={classes.mobileProfilePicDiv}
            >
              <CardHeader className={classes.mobileCardHeader}>
                <img
                  onClick={() => profilePicRef.current.click()}
                  className={classes.mobileImg}
                  src={userData.profilePic ? userData.profilePic : avatar}
                />
                <div
                  onClick={() => setEditProfileForm(true)}
                  style={{
                    position: "absolute",
                    right: "59px",
                    background: "#274B73",
                    color: "white",
                    padding: "5px",
                    borderRadius: "100%",
                    top: "-70px",
                  }}
                >
                  <EditIcon />
                </div>
                <input
                  onChange={(event) => {
                    handleImageUpload(event, "profile");
                  }}
                  type="file"
                  id="basicNFTArt"
                  ref={profilePicRef}
                  style={{ display: "none" }}
                />
                {/* <div style={{ display: "flex" }}>
                                    <EditIcon />
                                </div> */}
              </CardHeader>
              <CardBody style={{ height: "100%" }}>
                <div className={classes.leadDetailInfoDiv}>
                  <h5
                    style={{ textAlign: "center" }}
                    className={classes.leadDetailName}
                  >
                    {userData.firstName + " " + userData.lastName}
                  </h5>
                  {/* <p style={{marginBottom: "15px"}} className={classes.leadDetailInfo}> <img className={classes.icon} src={houseLogo} />  {userData.companyName}</p> */}
                  <p
                    style={{ textAlign: "center" }}
                    className={classes.leadDetailInfo}
                  >
                    {userData.userType}
                    {/* <img className={classes.icon} src={userTypeLogo} />  {userData.userType} */}
                  </p>
                  {/* <p style={{marginBottom: "15px"}} className={classes.leadDetailInfo}> <img className={classes.icon} src={emailLogo} />  {userData.email}</p>

                                    <p style={{marginBottom: "15px"}} className={classes.leadDetailInfo}> <img className={classes.icon} src={phoneLogo} />  {userData.cellPhone}</p> */}
                </div>
              </CardBody>
            </Card>
          </div>
        </GridItem>
      </Hidden>

      <Hidden className={classes.mobileLoadingBar} mdUp implementation="css">
        {uploading ? (
          <GridContainer
            style={{ display: "flex", alignItems: "center", marginTop: "15px" }}
          >
            <GridItem xs={10}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "80%", mr: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    value={Math.round(progress) * 100}
                  />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <h6 style={{ color: "black", fontSize: "25px" }}>
                    {Math.round(progress)}%
                  </h6>
                </Box>
              </Box>
            </GridItem>
            <GridItem xs={2}>
              <img style={{ width: "80%" }} src={loader} alt="..." />
            </GridItem>
          </GridContainer>
        ) : null}
      </Hidden>

      <Hidden smDown implementation="css">
        {uploading ? (
          <GridContainer
            style={{ display: "flex", alignItems: "center", marginTop: "15px" }}
          >
            <GridItem xs={10}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "80%", mr: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    value={Math.round(progress) * 100}
                  />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <h6 style={{ color: "black", fontSize: "25px" }}>
                    {Math.round(progress)}%
                  </h6>
                </Box>
              </Box>
            </GridItem>
            <GridItem xs={2}>
              <img style={{ width: "20%" }} src={loader} alt="..." />
            </GridItem>
          </GridContainer>
        ) : null}
      </Hidden>

      <Hidden className={classes.fullWidth} mdUp implementation="css">
        <GridItem style={{ display: "flex" }} xs={12} sm={12} md={12}>
          <Card
            style={{ marginBottom: "0px" }}
            className={classes.mobileProfileAboutDiv}
          >
            <CardHeader className={classes.aboutHeaderMobile}>
              About
              <div
                onClick={() => setEditProfileForm(true)}
                style={{
                  position: "absolute",
                  right: "9px",
                  background: "#274B73",
                  color: "white",
                  padding: "5px",
                  borderRadius: "100%",
                  top: "-5px",
                }}
              >
                <EditIcon style={{ color: "white" }} />
              </div>
            </CardHeader>
            <div style={{ padding: "0px 25px 25px 25px" }}>
              <p
                style={{
                  fontSize: "15px",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                }}
              >
                {userData.about
                  ? userData.about
                  : "Your user description will go here."}
              </p>
            </div>
          </Card>
        </GridItem>
      </Hidden>

      <Hidden className={classes.fullWidth} mdUp implementation="css">
        <GridItem style={{ display: "flex" }} xs={12} sm={12} md={12}>
          <Card
            style={{ marginBottom: "0px" }}
            className={classes.mobileProfileAboutDiv}
          >
            <CardHeader className={classes.aboutHeaderMobile}>
              Connected Bank Accounts
            </CardHeader>
            <CardBody
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
                padding: "0px",
              }}
            >
              <Button
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#f5c107",
                }}
              >
                Add Bank Account
                <OpenInNewIcon />
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </Hidden>

      <Hidden className={classes.fullWidth} mdUp implementation="css">
        <GridItem style={{ display: "flex" }} xs={12} sm={12} md={12}>
          <Card
            style={{ marginBottom: "0px" }}
            className={classes.mobileProfileAboutDiv}
          >
            <CardHeader className={classes.aboutHeaderMobile}>
              Contact Information
              <div
                onClick={() => setEditProfileForm(true)}
                style={{
                  position: "absolute",
                  right: "9px",
                  background: "#274B73",
                  color: "white",
                  padding: "5px",
                  borderRadius: "100%",
                  top: "-5px",
                }}
              >
                <EditIcon style={{ color: "white" }} />
              </div>
            </CardHeader>
            <div
              style={{
                padding: "0px 25px 25px 25px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontSize: "15px",
              }}
            >
              <div style={{ display: "flex" }}>
                <b>Email:</b>
                <p
                  style={{
                    marginBottom: "15px",
                    marginLeft: "10px",
                    fontSize: "15px",
                  }}
                  className={classes.leadDetailInfo}
                >
                  {" "}
                  {userData.email}
                </p>
              </div>

              <div style={{ display: "flex" }}>
                <b>Cell Phone:</b>
                <p
                  style={{
                    marginBottom: "15px",
                    marginLeft: "10px",
                    fontSize: "15px",
                  }}
                  className={classes.leadDetailInfo}
                >
                  {userData.cellPhone}
                </p>
              </div>

              <div style={{ display: "flex" }}>
                <b>Office Phone:</b>
                <p
                  style={{
                    marginBottom: "15px",
                    marginLeft: "10px",
                    fontSize: "15px",
                  }}
                  className={classes.leadDetailInfo}
                >
                  {userData.officePhone}
                </p>
              </div>

              <div style={{ display: "flex" }}>
                <b>Company Name:</b>
                <p
                  style={{
                    marginBottom: "15px",
                    marginLeft: "10px",
                    fontSize: "15px",
                  }}
                  className={classes.leadDetailInfo}
                >
                  {userData.companyName}
                </p>
              </div>
            </div>
          </Card>
        </GridItem>
      </Hidden>

      <Hidden className={classes.fullWidth} smDown implementation="css">
        {/* <GridItem style={{ display: "flex" }} xs={12} sm={12} md={12}> */}
        <Card
          style={{
            // color: "black",
            // height: "500px",
            // border: "1px solid black",
          }}
        >
          <div
            style={{
              height: "80px",
              background: "#13294C",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: "15px 20px",
            }}
          >
            <h2
              style={{
                margin: "0px",
                color: "white",
                fontFamily: "Abril Fatface",
                fontSize: "30px",
              }}
            >
              {userData.subscription
                ? userData.subscription.planSelected + " "
                : ""}
              Plan
            </h2>
            <Button
              style={{
                background:
                  "linear-gradient(90deg, #E60023 -15.91%, #AE011B 100%)",
                color: "white",
              }}
              onClick={() => setUnsubModal(true)}
            >
              Edit Plan
            </Button>
          </div>
          {/* <CardBody
              style={{
                display: "flex",
                marginBottom: "30px",
                padding: "0px",
                alignItems: "center",
                flexDirection: "column",
              }}
            > */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "30px",
              width: "100%",
            }}
          >
            <p style={{ margin: "0px", fontSize: "20px" }}>
              Features:{" "}
              <span style={{ marginLeft: "20px", fontSize: "17px" }}>
                {userData.subscription
                  ? userData.subscription.features.map(
                    (f, index) => (index ? ", " : ' ') + f
                  )
                  : null}
              </span>
            </p>
            <p style={{ margin: "0px", fontSize: "20px" }}>
              Payment Type:{" "}
              <b style={{ marginLeft: "20px", fontSize: "17px" }}>
                Recurring
              </b>{" "}
            </p>
          </div>
          {/* </CardBody> */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                style={{
                  height: "60px",
                  width: "20%",
                  fontSize: "16px",
                  backgroundColor: "#1D9BC9",
                  color: "white",
                }}
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  setEditProfileForm(true);
                }}
                style={{
                  backgroundColor: "#FEF61B",
                  height: "60px",
                  width: "20%",
                  fontSize: "16px",
                  color: "black",
                }}
              >
                Update Profile
              </Button>
            </div>
          </div>
        </Card>
        {/* </GridItem> */}
      </Hidden>

      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        // maxWidth="md"
        // fullScreen={true}
        open={unsubModal}
        transition={Transition}
        keepMounted
        // onClose={() => setUnsubModal(false)}
        aria-labelledby='modal-slide-title'
        aria-describedby='modal-slide-description'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
          style={{ backgroundColor: "#13294C" }}
        >
          <h3 style={{ fontFamily: "Abril Fatface", color: "white" }}>Unsubscribe From Plan</h3>
        </DialogTitle>
        <DialogContent
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <GridContainer style={{ marginTop: "20px" }}>
            <GridItem xs={2} style={{ marginTop: "-20px" }}>
              <img
                // onClick={() => profilePicRef.current.click()}
                className={classes.img}
                src={userData.profilePic ? userData.profilePic : avatar}
                style={{ width: "100px", height: "100px" }}
              />
            </GridItem>
            <GridItem xs={10} style={{ marginTop: "-40px", justifyContent: "center" }}>
              <h3 style={{ fontFamily: "Abril Fatface" }}>
                {userData.subscription
                  ? userData.subscription.planSelected + " Plan"
                  : ""}
              </h3>

              <p style={{ marginTop: "20px", fontSize: "20px", fontWeight: "bold" }}>
                <span style={{ marginLeft: "50px", fontSize: "17px" }}>
                  {userData.subscription
                    ? userData.subscription.features.map(
                      (f, index) => "•" + f + " " + " "
                    )
                    : null}
                </span>
              </p>

              <p style={{ margin: "20px 0px 0px 50px", fontSize: "20px", textAlign: "left" }}>
                The Gold Plan is perfect for  ipsum dolor sit amet, consectetur adipiscing
                elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </GridItem>

          </GridContainer>

        </DialogContent>
        <div style={{ justifyContent: "space-between", padding: "10px" }}>
          <Button
            onClick={() => setUnsubModal(false)}
            style={{ float: "left", color: "white", backgroundColor: "#1D9BC9", width: "150px" }}>
            Back
          </Button>
          <Button
            onClick={() => {
              setUnsubModal(false)
              setConfirmModal(true)
            }}
            style={{ float: "right", color: "white", backgroundColor: "#E60023", width: "150px" }}>
            Unsubscribe
          </Button>
        </div>
      </Dialog>

      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        // maxWidth="md"
        // fullScreen={true}
        open={confirmModal}
        transition={Transition}
        keepMounted
        // onClose={() => setUnsubModal(false)}
        aria-labelledby='modal-slide-title'
        aria-describedby='modal-slide-description'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
          style={{ backgroundColor: "#13294C" }}
        >
          <h3 style={{ fontFamily: "Abril Fatface", color: "white" }}>Unsubscribe From Plan</h3>
        </DialogTitle>
        <DialogContent
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <p style={{ margin: "0px", fontSize: "20px", textAlign: "left" }}>
            Are you sure you want to subscribe from your Gold Plan?
          </p>
        </DialogContent>
        <div style={{ justifyContent: "space-between", padding: "10px" }}>
          <Button
            onClick={() => setConfirmModal(false)}
            style={{ float: "left", color: "white", backgroundColor: "#1D9BC9", width: "150px" }}>
            Back
          </Button>
          <Button
            style={{ float: "right", color: "black", background: "linear-gradient(180deg, #FEF61B 0%, #EDE51A 100%)", width: "150px" }}
            onClick={() => {
              setConfirmModal(false)
              setDoneModal(true)
            }}
          >
            Submit
          </Button>
        </div>
      </Dialog>

      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        // maxWidth="md"
        // fullScreen={true}
        open={doneModal}
        transition={Transition}
        keepMounted
        // onClose={() => setUnsubModal(false)}
        aria-labelledby='modal-slide-title'
        aria-describedby='modal-slide-description'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
          style={{ backgroundColor: "#13294C" }}
        >
          <h3 style={{ fontFamily: "Abril Fatface", color: "white" }}>Unsubscribe From Plan</h3>
        </DialogTitle>
        <DialogContent
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <p style={{ margin: "0px", fontSize: "20px", textAlign: "left" }}>
            You have successfully unsubscribed from the Gold Plan.
            You will be routed to the Sign Up screen to continue using Market Me and its services.
          </p>
        </DialogContent>
        <Button
          onClick={() => {
            unsubButton()
          }}
          style={{ marginBottom: "10px", marginLeft: "20px", color: "black", background: "linear-gradient(180deg, #FEF61B 0%, #EDE51A 100%)", width: "150px" }}>
          Continue
        </Button>
      </Dialog>

      <Dialog
        // classes={{
        //     root: classes.modalRoot,
        //     paper: classes.modal + " " + classes.modalLarge
        // }}
        fullWidth
        open={editProfileForm}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        // onClose={() => setEditProfileForm(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setEditProfileForm(false);
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close
              style={{ fontSize: "25px" }}
              className={classes.modalClose}
            />
          </Button>

          <h3 style={{ fontFamily: "Abril Fatface" }}>
            Update Your Profile Information
          </h3>
        </DialogTitle>
        <Hidden className={classes.fullWidthModal} smDown implementation="css">
          <DialogContent
            id="shipment-modal-slide-description"
            className={classes.modalBody}
          >
            <div className={classes.leadDetailInfoPicDiv}>
              <img
                onClick={() => mobileProfilePicRef.current.click()}
                className={classes.img}
                src={userData.profilePic ? userData.profilePic : avatar}
              />
              <input
                onChange={(event) => {
                  handleImageUpload(event, "modal");
                }}
                multiple="multiple"
                type="file"
                id="basicNFTArt"
                ref={mobileProfilePicRef}
                style={{ display: "none" }}
              />
              <div style={{ display: "flex", alignItems: "end" }}>
                <EditIcon style={{ color: "white" }} />
              </div>
            </div>
            <form
              className={classes.updateProfileForm}
              onSubmit={() => console.log("hi")}
            >
              <div className={classes.updateProfileFormRow}>
                <FormControl
                  style={{ marginRight: "20px", color: "white !important" }}
                  fullWidth
                >
                  <TextField
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    style={{ color: "white !important" }}
                    InputProps={{
                      className: classes.input,
                      classes: {
                        root: classes.root,
                        focused: classes.root,
                        underline: classes.underline,
                      },
                    }}
                    onChange={(e) => setFirstNameState(e.target.value)}
                    //   {...params}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                      }
                    }}
                    value={firstNameState}
                    id="First Name"
                    label="First Name"
                  />
                </FormControl>
                <FormControl style={{ marginRight: "20px" }} fullWidth>
                  <TextField
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    InputProps={{
                      className: classes.input,
                      classes: {
                        root: classes.root,
                        focused: classes.root,
                        underline: classes.underline,
                      },
                    }}
                    onChange={(e) => setLastNameState(e.target.value)}
                    //   {...params}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                      }
                    }}
                    value={lastNameState}
                    id="Last Name"
                    label="Last Name"
                  />
                </FormControl>
              </div>

              <div className={classes.updateProfileFormRow}>
                <FormControl style={{ marginRight: "20px" }} fullWidth>
                  <TextField
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    InputProps={{
                      className: classes.input,
                      classes: {
                        root: classes.root,
                        focused: classes.root,
                        underline: classes.underline,
                      },
                    }}
                    onChange={(e) => setEmailState(e.target.value)}
                    //   {...params}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                      }
                    }}
                    value={emailState}
                    id="Email"
                    label="Email"
                  />
                </FormControl>
                <FormControl style={{ marginRight: "20px" }} fullWidth>
                  <TextField
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    InputProps={{
                      className: classes.input,
                      classes: {
                        root: classes.root,
                        focused: classes.root,
                        underline: classes.underline,
                      },
                    }}
                    onChange={(e) => setCompanyState(e.target.value)}
                    //   {...params}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                      }
                    }}
                    value={companyState}
                    id="Company"
                    label="Company"
                  />
                </FormControl>
              </div>
              <div className={classes.updateProfileFormRow}>
                <FormControl style={{ marginRight: "20px" }} fullWidth>
                  <TextField
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    InputProps={{
                      className: classes.input,
                      classes: {
                        root: classes.root,
                        focused: classes.root,
                        underline: classes.underline,
                      },
                    }}
                    onChange={(e) => setOfficePhoneState(e.target.value)}
                    //   {...params}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                      }
                    }}
                    value={officePhoneState}
                    id="Office Phone"
                    label="Office Phone"
                  />
                </FormControl>
                <FormControl style={{ marginRight: "20px" }} fullWidth>
                  <TextField
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    InputProps={{
                      className: classes.input,
                      classes: {
                        root: classes.root,
                        focused: classes.root,
                        underline: classes.underline,
                      },
                    }}
                    onChange={(e) => setCellPhoneState(e.target.value)}
                    //   {...params}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                      }
                    }}
                    value={cellPhoneState}
                    id="Cell Phone"
                    label="Cell Phone"
                  />
                </FormControl>
              </div>
            </form>
          </DialogContent>
          {modalUploading ? (
            <GridContainer
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              <GridItem xs={10}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ width: "80%", mr: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={Math.round(progress) * 100}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <h6 style={{ color: "black", fontSize: "25px" }}>
                      {Math.round(progress)}%
                    </h6>
                  </Box>
                </Box>
              </GridItem>
              <GridItem
                style={{ display: "flex", justifyContent: "start" }}
                xs={2}
              >
                <img style={{ width: "20%" }} src={loader} alt="..." />
              </GridItem>
            </GridContainer>
          ) : null}{" "}
          <div style={{ padding: "30px 30px 0px 30px" }}>
            <TextField
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid white",
                borderRadius: "5px",
                height: "170px",
              }}
              multiline
              rows={4}
              InputLabelProps={{
                className: classes.inputLabelAbout,
              }}
              InputProps={{
                className: classes.input,
                disableUnderline: true,
                classes: {
                  root: classes.root,
                  focused: classes.root,
                },
              }}
              onChange={(e) => setAboutState(e.target.value)}
              //   {...params}
              onKeyPress={(e) => {
                if (e.which === 13) {
                }
              }}
              value={aboutState}
              id="About"
              label="About"
            />
          </div>
        </Hidden>

        <Hidden className={classes.fullWidth} mdUp implementation="css">
          <DialogContent
            id="shipment-modal-slide-description"
            className={classes.modalBody}
          >
            <div
              onClick={() => mobileProfilePicRef.current.click()}
              className={classes.mobileLeadDetailInfoPicDiv}
            >
              <img
                className={classes.img}
                src={userData.profilePic ? userData.profilePic : avatar}
              />
              <input
                onChange={(event) => {
                  handleImageUpload(event, "modal");
                }}
                multiple="multiple"
                type="file"
                id="basicNFTArt"
                ref={mobileProfilePicRef}
                style={{ display: "none" }}
              />
              <div
                style={{
                  position: "absolute",
                  right: "59px",
                  background: "#274B73",
                  color: "white",
                  padding: "5px",
                  borderRadius: "100%",
                }}
              >
                <EditIcon style={{ color: "white" }} />
              </div>
              {/* <div style={{ display: "flex", alignItems: "end" }}>
                            <EditIcon />
                        </div> */}
            </div>
            <form
              className={classes.updateProfileFormMobile}
              onSubmit={() => console.log("hi")}
            >
              <div className={classes.updateProfileFormRow}>
                <FormControl
                  style={{ marginRight: "20px", color: "white" }}
                  fullWidth
                >
                  <TextField
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    InputProps={{
                      className: classes.input,
                      classes: {
                        root: classes.root,
                        focused: classes.root,
                        underline: classes.underline,
                      },
                    }}
                    onChange={(e) => setFirstNameState(e.target.value)}
                    //   {...params}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                      }
                    }}
                    value={firstNameState}
                    id="First Name"
                    label="First Name"
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    InputProps={{
                      className: classes.input,
                      classes: {
                        root: classes.root,
                        focused: classes.root,
                        underline: classes.underline,
                      },
                    }}
                    onChange={(e) => setLastNameState(e.target.value)}
                    //   {...params}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                      }
                    }}
                    value={lastNameState}
                    id="Last Name"
                    label="Last Name"
                  />
                </FormControl>
              </div>

              <div className={classes.updateProfileFormRow}>
                <FormControl style={{ marginRight: "20px" }} fullWidth>
                  <TextField
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    InputProps={{
                      className: classes.input,
                      classes: {
                        root: classes.root,
                        focused: classes.root,
                        underline: classes.underline,
                      },
                    }}
                    onChange={(e) => setEmailState(e.target.value)}
                    //   {...params}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                      }
                    }}
                    value={emailState}
                    id="Email"
                    label="Email"
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    InputProps={{
                      className: classes.input,
                      classes: {
                        root: classes.root,
                        focused: classes.root,
                        underline: classes.underline,
                      },
                    }}
                    onChange={(e) => setCompanyState(e.target.value)}
                    //   {...params}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                      }
                    }}
                    value={companyState}
                    id="Company"
                    label="Company"
                  />
                </FormControl>
              </div>
              <div className={classes.updateProfileFormRow}>
                <FormControl style={{ marginRight: "20px" }} fullWidth>
                  <TextField
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    InputProps={{
                      className: classes.input,
                      classes: {
                        root: classes.root,
                        focused: classes.root,
                        underline: classes.underline,
                      },
                    }}
                    onChange={(e) => setOfficePhoneState(e.target.value)}
                    //   {...params}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                      }
                    }}
                    value={officePhoneState}
                    id="Office Phone"
                    label="Office Phone"
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{
                      className: classes.inputLabel,
                      classes: {
                        root: classes.root,
                        focused: classes.root,
                      },
                    }}
                    InputProps={{
                      className: classes.input,
                      classes: {
                        root: classes.root,
                        focused: classes.root,
                        underline: classes.underline,
                      },
                    }}
                    onChange={(e) => setCellPhoneState(e.target.value)}
                    //   {...params}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                      }
                    }}
                    value={cellPhoneState}
                    id="Cell Phone"
                    label="Cell Phone"
                  />
                </FormControl>
              </div>
            </form>
          </DialogContent>
          {modalUploading ? (
            <GridContainer
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              <GridItem xs={10}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ width: "80%", mr: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={Math.round(progress) * 100}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <h6 style={{ color: "black", fontSize: "25px" }}>
                      {Math.round(progress)}%
                    </h6>
                  </Box>
                </Box>
              </GridItem>
              <GridItem
                style={{ display: "flex", justifyContent: "start" }}
                xs={2}
              >
                <img style={{ width: "20%" }} src={loader} alt="..." />
              </GridItem>
            </GridContainer>
          ) : null}
          <div style={{ padding: "20px 30px" }}>
            <TextField
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid white",
                borderRadius: "5px",
                height: "170px",
              }}
              multiline
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{
                className: classes.inputLabelAbout,
              }}
              InputProps={{
                className: classes.input,
                classes: {
                  root: classes.root,
                  focused: classes.root,
                },
              }}
              rows={4}
              onChange={(e) => setAboutState(e.target.value)}
              //   {...params}
              onKeyPress={(e) => {
                if (e.which === 13) {
                }
              }}
              value={aboutState}
              id="About"
              label="About"
            />
          </div>
        </Hidden>

        <div className={classes.cancelSubmitBtnDiv}>
          <Button
            onClick={() => {
              setEditProfileForm(false);
            }}
            style={{
              height: "60px",
              width: "40%",
              fontSize: "16px",
              backgroundColor: "#1D9BC9",
              color: "white",
            }}
          >
            Back
          </Button>
          <Button
            onClick={updateUserInfo}
            style={{
              backgroundColor: "#FEF61B",
              height: "60px",
              width: "40%",
              fontSize: "16px",
              color: "black",
            }}
          >
            Submit
          </Button>
        </div>
      </Dialog>
    </GridContainer>
  );
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  console.log("state.auth.user", state.auth.user);
  return {
    authInfo: state.form.registration.steps.basicDetails.fields,
    userInfo: state.form.registration.steps.commonProfile
      ? state.form.registration.steps.commonProfile.fields
      : {},
    profilePic: state.auth.profilePic
      ? state.auth.profilePic
      : state.auth.user.profilePic
        ? state.auth.user.profilePic
        : false,
    uid: state.auth.user.uid,
    firstName: state.auth.firstName
      ? state.auth.firstName
      : state.auth.user.firstName,
    lastName: state.auth.lastName
      ? state.auth.lastName
      : state.auth.user.lastName,
    userType:
      state.auth.userType !== "N/A"
        ? state.auth.userType
        : state.auth.user.userType,
    email: state.auth.email ? state.auth.email : state.auth.user.email,
    dateOfBirth: state.auth.dateOfBirth
      ? state.auth.dateOfBirth
      : state.auth.user.dateOfBirth,
    phoneNumber: state.auth.user && state.auth.user.phoneNumber,
    cellPhone: state.auth.cellPhone
      ? state.auth.cellPhone
      : state.auth.user.cellPhone,
    officePhone: state.auth.officePhone
      ? state.auth.officePhone
      : state.auth.user.officePhone,
    companyName: state.auth.companyName
      ? state.auth.companyName
      : state.auth.user.companyName,
    about: state.auth.about ? state.auth.about : state.auth.user.about,
    user: state.auth.user,
    userSet: state.auth.user.userSet,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    edit: (key) =>
      dispatch(
        actionsForNavigation.openChild("Profile Editor", `/admin/user/editor`)
      ),
    updateUserData: (uid, newInfo) => dispatch(updateUserData(uid, newInfo)),
    updateUserPhoto: (id, document) => dispatch(updateUserPhoto(id, document)),
    updateUserLogo: (id, document) => dispatch(updateUserLogo(id, document)),
    logout: () => dispatch(AuthActions.startLogout()),
  });
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(userProfileStyle)
)(UserProfile);
