import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/customers";
import accessorsForExplorer from "store/reducers/explorers/customers";

import BoundSearchControl from "../../../components/Explorer/BoundSearchControl";
import BoundSearchDialog from "../../../components/Explorer/BoundSearchDialog";
import Card from "components/Card/Card";
import { Link } from "react-router-dom";
import { connect } from "react-redux";


import FlagIcon from '@material-ui/icons/Flag';

import explorerPageStyle from "assets/jss/material-dashboard-pro-react/views/explorerPageStyle.js";

const Explorer = BoundExplorer(actionsForExplorer, accessorsForExplorer);
const ExplorerCard = BoundExplorerCard(actionsForExplorer.recordset, accessorsForExplorer.recordset);
const SearchControl = BoundSearchControl(actionsForExplorer, accessorsForExplorer);
const SearchDialog = BoundSearchDialog(actionsForExplorer, accessorsForExplorer);

const useStyles = makeStyles(explorerPageStyle);

function CustomExplorerCard(props) {
    const classes = useStyles();

    return (
        <Link to={props.viewRoute}>
            <Card className={classes.loanOffCard}>
                {props.needsAttention == true ?
                    <div className={classes.attentionFlag}>
                        <FlagIcon />
                    </div> : null}
                <h2><b>{props.firstName} {props.lastName}</b></h2>
                <h3 className={classes.dateInfo}>Join Date: {props.joinDate ? `${props.joinDate.c.month}/${props.joinDate.c.day}/${props.joinDate.c.year}` : 'Join Date not available yet'}</h3>
                <h3><b>Description: </b>{props.description ? props.description : 'Description not available yet'}</h3>
                <h3><b>EPD:</b> {props.estimatedPullDate ? `${props.estimatedPullDate.c.month}/${props.estimatedPullDate.c.day}/${props.estimatedPullDate.c.year}` : 'No estimated pull date'}</h3>
            </Card>
        </Link>
    )
}

export function LOCustomerExplorer(props) {
    const classes = useStyles();

    return (
        <div>
            {props.userType == undefined ?
                <div className={classes.textCenter}>
                    <h1>Your account registration needs to be completed</h1>
                    <h3>Please contact your assigned Credit Analyst to finish setting up your account and begin viewing your customers!</h3>
                </div>
                :
                <Explorer
                    ExplorerCard={CustomExplorerCard}
                    searchFields={["firstName", "lastName", "estimatedPullDate"]}
                    cardFields={["lastUpdated", "notes",]}
                    routeName="/admin/leads"
                    userType="LOAN_OFFICER"
                />
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        userType: state.auth.user.userType,
    }
}

function mapDispatchToProps() {
    return {
        // nothing yet
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LOCustomerExplorer);

// const DISPLAY_ORDER = [
//     CustomerStatus.SERVICE_AGREEMENTS,
//     CustomerStatus.PERSONALIZED_PLANS,
//     CustomerStatus.NEGATIVE_ITEMS,
//     CustomerStatus.SEND_LETTERS,
//     CustomerStatus.CURRENT_CUSTOMER,
//     CustomerStatus.COMPLETED,
//     CustomerStatus.DECLINED,
//     CustomerStatus.COLLECTIONS
// ];


// export function CustomerExplorer(props) {

//     const { routeName, classes } = props;

//     let cardsByStatus = props.records.reduce((groups, record) => {
//         let group = groups[record.fields.status || 'unknown'] || [];
//         group.push(record);
//         groups[record.fields.status] = group;
//         return groups;
//     }, {})

//     return (
//         <div>
//             <SearchControl />
//             <SearchDialog order={["firstName", "lastName", "currentScore", "goalScore", "lastUpdated"]} />
//             {DISPLAY_ORDER.map((group, index) => (
//                 <div>
//                     <h1>{group.longForm}</h1>
//                     {console.log('cardsByStatus:', cardsByStatus)}
//                     {console.log('group.longForm:', group.longForm)}
//                     {/* { cardsByStatus[group.longForm].map((record, index) => (
//                         <ExplorerCard
//                             key={index}
//                             index={index}
//                             editRoute={`${routeName}/editor/${record.key}`}
//                             viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
//                             advance={props.setStatus(record.key, group.nextStatus[0])}
//                         />
//                     ))} */}
//                 </div>
//             ))}
//             <Fab
//                 // className={classes.newRecord}
//                 onClick={e => props.openChild("New Record", `${routeName}/new`)}><AddIcon /></Fab>
//         </div>
//     );
// }

// function mapStateToProps(state, ownProps) {
//     return {
//         records: accessorsForExplorer.recordset.get(state).records,
//         ExplorerCard: ownProps.ExplorerCard || ExplorerCard,
//         CardFields,
//         CardField,
//         SearchControl,
//         SearchDialog
//     };
// }

// function mapDispatchToProps(dispatch) {
//     return {
//         openChild: (title, route) => dispatch(actionsForNavigation.openChild(title, route)),
//         setStatus: (key, status) => dispatch(actionsForExplorer.recordset.updateRecord({ key, fields: { status } }))
//     };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(CustomerExplorer);