/*eslint-disable*/
import React from 'react'
import { db, firebase, storage, sendCustomerNotificationEmail } from 'firebase/client';




export const getDemographics = () => {
  return (dispatch) => {
    return db
      .collection('real-demographics').get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch(setDemographics([]))
        } else {
          snapshot = snapshot.docs
          var toReturn = []
          snapshot.forEach((doc) => {
            var docId = doc.id
            doc = doc.data()
            doc.id = docId
            toReturn.push(doc)
          })
          console.log('gettt', toReturn)
          dispatch(setDemographics(toReturn))
        }
      })
  }
}

export const setDemographics = (data) => {
  return {
    type: "SET_DEMOGRAPHICS",
    data
  }
}
