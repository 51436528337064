/*eslint-disable*/
const demographics = []
const initDataRetrieved = false

export const initState = {
  demographics,
  initDataRetrieved
}


export default (state = initState, action) => {
  switch (action.type) {
    case "SET_DEMOGRAPHICS":
      var toReturn = {...state}
      toReturn.demographics = action.data
      toReturn.initDataRetrieved = true
      return toReturn

    default:
      return state
  }
}
