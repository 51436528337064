import {
  primaryColor,
  dangerColor,
  successColor,
  defaultFont,
  whiteColor,
  grayColor,
  //transparentGreyColor
} from "assets/jss/material-dashboard-pro-react.js";

const numberBubbleStyle = {
  value: {
    position: "absolute",
    fontWeight: 800,
    fontFamily: "Abril Fatface",
    color: "black"
  },
  bubble: {
    borderRadius: "50%",
    display: "inline-block",
  },
  container: {
    display: "flex"
  }

};

export default numberBubbleStyle;
