import React from "react";
import { connect } from "react-redux";

import BoundEditorForm from 'components/Explorer/BoundEditorForm';

import actionsForExplorer from 'store/actions/explorers/customers';
import accessorsForExplorer from 'store/reducers/explorers/customers';

const EditorForm = BoundEditorForm(actionsForExplorer.newForm, accessorsForExplorer.newForm);

export function NewCustomer(props) {
    return (
                <EditorForm order={[
                    "firstName",
                    "middleName",
                    "lastName",
                    "suffix",
                    "plan",
                    "marketing",
                    "creditAnalyst",
                    "creditReport",
                    "estimatedPullDate",
                    "joinDate",
                    "loanOfficer",
                    "address",
                    "city",
                    "state",
                    "zipCode",
                    "dateOfBirth",
                    "socialSecurityNumber",
                    "mainPhone",
                    "emailAddress",
                    "altPhone",
                    "currentScore",
                    "goalScore",
                    "importantNotes",
                    "description",
                    "creditReportLogin",
                    "negativeItemsLink"]} />
    );
}

function mapStateToProps(state) {
    return {
        userType: state.auth.user.userType,
    }
}

function mapDispatchToProps() {
    return {
        // nothing yet
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NewCustomer);