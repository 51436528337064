import React, { useState } from "react";
import PropTypes from "prop-types";

import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);

const DeleteDoc = (props) => {

    const classes = useStyles();
    const [alert, setAlert] = useState(null);
    const warningWithConfirmAndCancelMessage = () => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure you want to delete this document?"
                onConfirm={() => successDelete()}
                onCancel={() => cancelDelete()}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancel
            >
      </SweetAlert>
        );
    };
    const successDelete = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Deleted!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                The file has been successfully deleted!
      </SweetAlert>
        );
    };
    const cancelDelete = () => {
        setAlert(
            <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Cancelled"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                You have NOT deleted this document
      </SweetAlert>
        )
    }
    const hideAlert = () => {
        setAlert(null);
    };

    

    return (
        <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure you want to delete this document?"
            onConfirm={() => successDelete()}
            onCancel={() => cancelDelete()}
            confirmBtnCssClass={classes.button + " " + classes.success}
            cancelBtnCssClass={classes.button + " " + classes.danger}
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
        >
        </SweetAlert>
    )
}

export default DeleteDoc;