/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  createWithContent,
  convertToRaw,
  convertFromRaw,
  convertFromHtml,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import InformationModal from "components/InformationModal/InformationModal";
import NumberBubble from "components/NumberBubble/NumberBubble";
import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CardFooter from "components/Card/CardFooter";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Input from "@material-ui/core/Input";
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

// import CardFields from '../../../components/Card/CardFields';
// import CardField from '../../../components/Card/CardField';
import actionsForNavigation from "store/actions/navigation";
import { connect } from "react-redux";
import { MockUserData } from 'variables/config';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import Close from "@material-ui/icons/Close";
import { getLeads, deleteLead, convertLeadToCustomer } from "store/actions/leadsActions"
import Slide from "@material-ui/core/Slide";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import campaignsStyle from "assets/jss/material-dashboard-pro-react/views/campaignsStyle.js";

import InstagramIcon from "assets/img/market-me/instagramIcon.png";
import LinkedInIcon from "assets/img/market-me/linkedInLogo.png";
import EmailIcon from "assets/img/market-me/emailIcon.png";
import TextIcon from "assets/img/market-me/textIcon.png";
import CalendarIcon from "assets/img/market-me/calendarIcon.png";

import AuthActions from "store/actions/auth"
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";

const useStyles = makeStyles(campaignsStyle);

export function EmailForm(props) {
  const classes = useStyles();
  const { setStep, handleChange, goBack, logo } = props;

  const runTimeUnits = ['Days', 'Weeks', 'Months'];
  const [welcome, setWelcome] = useState(false);
  const [welcome2, setWelcome2] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [subject, setSubject] = useState('');
  const [subject2, setSubject2] = useState('');
  const [emailSend, setEmailSend] = useState('');
  const [emailSend2, setEmailSend2] = useState('');
  const [emailNum, setEmailNum] = useState('');
  const [emailNum2, setEmailNum2] = useState('');
  const [editorState1, setEditorState1] = useState(EditorState.createEmpty());
  const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
  const [text, setText] = useState('');
  const [text2, setText2] = useState('');
  const [url, setUrl] = useState('');
  const [url2, setUrl2] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [backgroundColor2, setBackgroundColor2] = useState('');
  const [textColor, setTextColor] = useState('');
  const [textColor2, setTextColor2] = useState('');
  return (
    <>
      <GridContainer className={classes.mainStepContainer}>
        <GridItem className={classes.stepHeader}>
          <NumberBubble value="4." color="yellow" size="2.5rem" />
          <h4 style={{ fontWeight: 600, marginLeft: "1rem" }}>Compose Email Automation</h4>
        </GridItem>
        {/* <GridItem className={classes.stepContainerOuter}> */}
        <div style={{ borderBottom: "2px solid rgb(19,41,76)", margin: "0.625rem" }}>
          <GridContainer style={{ padding: "10px", }}>
            <GridItem xs={3}>
              <h5 style={{ fontWeight: 800 }}>1. Primary Email Template</h5>
              <div>
                <Checkbox
                  className={classes.checkboxes}
                  onChange={() => setWelcome(!welcome)}
                  checked={welcome}
                />
                <span>Welcome</span>

                <Button
                  className={classes.newButton}
                  style={{
                    height: "35px",
                    width: "100px",
                    marginLeft: "20px",
                    background: "linear-gradient(90deg, #E60023 -15.91%, #AE011B 100%)",
                    color: "white",
                  }}>
                  Browse
                </Button>
              </div>
            </GridItem>
            <GridItem xs={3}>
              <h5 style={{ fontWeight: 800 }}>Subject</h5>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  style: {
                    paddingTop: "0px"
                  }
                }}
                inputProps={{
                  placeholder: "Welcome to the family!",
                  required: true,
                  type: "text",
                  disableUnderline: true,
                  onChange: (e) => setSubject(e.target.value),
                  style: {
                    border: "2px solid #E60023",
                    borderRadius: "5px"
                  }
                }}
              />
            </GridItem>
            <GridItem xs={3}>
              <h5 style={{ fontWeight: 800 }}>Feature Image</h5>
              <div style={{ display: "flex" }}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    style: {
                      paddingTop: "0px"
                    }
                  }}
                  inputProps={{
                    placeholder: "File types: PNG,JPG,JPEG",
                    required: true,
                    type: "text",
                    disableUnderline: true,
                    onChange: (e) => setSubject(e.target.value),
                    style: {
                      border: "2px solid #E60023",
                      borderRadius: "5px"
                    }
                  }}
                />
                <Button
                  style={{ height: "45px", width: "100px", background: "linear-gradient(180deg, #E60023 0%, #AC001A 100%)", color: "white", marginTop: "-2px", marginLeft: "-2px" }}
                  component="label"
                  variant="outlined">
                  Choose file
                  <input type="file" hidden />
                </Button>
              </div>
            </GridItem>

            <GridItem xs={3}>
              <h5 style={{ fontWeight: 800 }}>Email Send</h5>
              <div style={{ display: "flex" }}>
                <CustomInput
                  formControlProps={{
                    // fullWidth: true,
                    style: {
                      paddingTop: "0px",
                    }
                  }}
                  inputProps={{
                    placeholder: "5,10",
                    required: true,
                    type: "text",
                    disableUnderline: true,
                    onChange: (e) => setEmailNum(e.target.value),
                    style: {
                      border: "2px solid #E60023",
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      height: "2.5rem",
                      // width:"75px"
                    }
                  }}
                />
                <CustomSelect
                  values={runTimeUnits}
                  className={classes.newInput}
                  formControlProps={{
                    style: {
                      paddingTop: "0px",
                    }
                  }}
                  inputProps={{
                    defaultValue: 0,
                    disableUnderline: true,
                    onChange: (e) => setEmailSend(runTimeUnits[e.target.value]),
                    style: {
                      background: "linear-gradient(180deg, #E60023 0%, #AC001A 100%)",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      width: "75px",
                      color: "white !important",
                      "& .MuiSelect-root": {
                        color: "white",
                      },
                    }
                  }}
                />
              </div>
            </GridItem>

            <GridItem xs={6}>
              <h5 style={{ fontWeight: 800, display: "flex", }}>
                Body
              </h5>
              <Editor
                editorState={editorState1}
                onEditorStateChange={(state) => setEditorState1(state)}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                editorStyle={{ border: "1px solid grey", height: "100px" }}
                toolbarStyle={{ border: "1px solid grey" }}
              />
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem xs={6}>
                  <h5 style={{ fontWeight: 800 }}>Button Text</h5>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      style: {
                        paddingTop: "0px"
                      }
                    }}
                    inputProps={{
                      placeholder: "Browse Gallery",
                      required: true,
                      type: "text",
                      disableUnderline: true,
                      onChange: (e) => setText(e.target.value),
                      style: {
                        border: "2px solid #E60023",
                        borderRadius: "5px"
                      }
                    }}
                  />
                </GridItem>

                <GridItem xs={6}>
                  <h5 style={{ fontWeight: 800 }}>Button Url</h5>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      style: {
                        paddingTop: "0px"
                      }
                    }}
                    inputProps={{
                      placeholder: "www.flickr.com/photos/username/",
                      required: true,
                      type: "text",
                      disableUnderline: true,
                      onChange: (e) => setUrl(e.target.value),
                      style: {
                        border: "2px solid #E60023",
                        borderRadius: "5px"
                      }
                    }}
                  />
                </GridItem>

                <GridItem xs={6}>
                  <h5 style={{ fontWeight: 800 }}>Button Background Color</h5>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      style: {
                        paddingTop: "0px"
                      }
                    }}
                    inputProps={{
                      placeholder: "#FFFFFF",
                      required: true,
                      type: "text",
                      disableUnderline: true,
                      onChange: (e) => setBackgroundColor(e.target.value),
                      style: {
                        border: "2px solid #E60023",
                        borderRadius: "5px"
                      }
                    }}
                  />
                </GridItem>

                <GridItem xs={6}>
                  <h5 style={{ fontWeight: 800 }}>Button Text Color</h5>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      style: {
                        paddingTop: "0px"
                      }
                    }}
                    inputProps={{
                      placeholder: "#FFFFFF",
                      required: true,
                      type: "text",
                      disableUnderline: true,
                      onChange: (e) => setTextColor(e.target.value),
                      style: {
                        border: "2px solid #E60023",
                        borderRadius: "5px"
                      }
                    }}
                  />
                </GridItem>

                <GridItem xs={6} />
                <GridItem xs={6}>
                  <Button
                    className={classes.newButton}
                    style={{ width: "85%", height: "50px", background: "#005479", fontSize: "20px" }}
                  >
                    Preview Email
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
        <GridContainer style={{ padding: " 10px 10px 10px 20px", }}>
          <GridItem xs={3}>
            <h5 style={{ fontWeight: 800 }}>2. Secondary Email Template</h5>
            <div>
              <Checkbox
                className={classes.checkboxes}
                onChange={() => setWelcome2(!welcome2)}
                checked={welcome2}
              />
              <span>Welcome</span>

              <Checkbox
                className={classes.checkboxes}
                onChange={() => setCreateNew(!createNew)}
                checked={createNew}
              />
              <span>Create New</span>

              <Button
                className={classes.newButton}
                style={{
                  height: "35px",
                  width: "100px",
                  marginLeft: "20px",
                  background: "linear-gradient(90deg, #E60023 -15.91%, #AE011B 100%)",
                  color: "white",
                }}>
                Browse
              </Button>
            </div>
          </GridItem>
          <GridItem xs={3}>
            <h5 style={{ fontWeight: 800 }}>Subject</h5>
            <CustomInput
              formControlProps={{
                fullWidth: true,
                style: {
                  paddingTop: "0px"
                }
              }}
              inputProps={{
                placeholder: "20% Off, Valentine’s Day Special, etc..",
                required: true,
                type: "text",
                disableUnderline: true,
                onChange: (e) => setSubject2(e.target.value),
                style: {
                  border: "2px solid #E60023",
                  borderRadius: "5px"
                }
              }}
            />
          </GridItem>
          <GridItem xs={3}>
            <h5 style={{ fontWeight: 800 }}>Feature Image</h5>
            <div style={{ display: "flex" }}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  style: {
                    paddingTop: "0px"
                  }
                }}
                inputProps={{
                  placeholder: "File types: PNG,JPG,JPEG",
                  required: true,
                  type: "text",
                  disableUnderline: true,
                  onChange: (e) => setSubject(e.target.value),
                  style: {
                    border: "2px solid #E60023",
                    borderRadius: "5px"
                  }
                }}
              />
              <Button
                style={{ height: "45px", width: "100px", background: "linear-gradient(180deg, #E60023 0%, #AC001A 100%)", color: "white", marginTop: "-2px", marginLeft: "-2px" }}
                component="label"
                variant="outlined">
                Choose file
                <input type="file" hidden />
              </Button>
            </div>
          </GridItem>

          <GridItem xs={3}>
            <h5 style={{ fontWeight: 800 }}>Email Send</h5>
            <div style={{ display: "flex" }}>
              <CustomInput
                formControlProps={{
                  // fullWidth: true,
                  style: {
                    paddingTop: "0px",
                  }
                }}
                inputProps={{
                  placeholder: "5,10",
                  required: true,
                  type: "text",
                  disableUnderline: true,
                  onChange: (e) => setEmailNum2(e.target.value),
                  style: {
                    border: "2px solid #E60023",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    height: "2.5rem",
                    // width:"75px"
                  }
                }}
              />
              <CustomSelect
                values={runTimeUnits}
                className={classes.newInput}
                formControlProps={{
                  style: {
                    paddingTop: "0px",
                  }
                }}
                inputProps={{
                  defaultValue: 0,
                  disableUnderline: true,
                  onChange: (e) => setEmailSend2(runTimeUnits[e.target.value]),
                  style: {
                    background: "linear-gradient(180deg, #E60023 0%, #AC001A 100%)",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                    width: "75px",
                    color: "white !important",
                    "& .MuiSelect-root": {
                      color: "white",
                    },
                  }
                }}
              />
            </div>
          </GridItem>

          <GridItem xs={6}>
            <h5 style={{ fontWeight: 800, display: "flex", }}>
              Body
            </h5>
            <Editor
              editorState={editorState2}
              onEditorStateChange={(state) => setEditorState2(state)}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              editorStyle={{ border: "1px solid grey", height: "100px" }}
              toolbarStyle={{ border: "1px solid grey" }}
            />
          </GridItem>

          <GridItem xs={6}>
            <GridContainer>
              <GridItem xs={6}>
                <h5 style={{ fontWeight: 800 }}>Button Text</h5>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    style: {
                      paddingTop: "0px"
                    }
                  }}
                  inputProps={{
                    placeholder: "Browse Gallery",
                    required: true,
                    type: "text",
                    disableUnderline: true,
                    onChange: (e) => setText2(e.target.value),
                    style: {
                      border: "2px solid #E60023",
                      borderRadius: "5px"
                    }
                  }}
                />
              </GridItem>

              <GridItem xs={6}>
                <h5 style={{ fontWeight: 800 }}>Button Url</h5>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    style: {
                      paddingTop: "0px"
                    }
                  }}
                  inputProps={{
                    placeholder: "www.flickr.com/photos/username/",
                    required: true,
                    type: "text",
                    disableUnderline: true,
                    onChange: (e) => setUrl2(e.target.value),
                    style: {
                      border: "2px solid #E60023",
                      borderRadius: "5px"
                    }
                  }}
                />
              </GridItem>

              <GridItem xs={6}>
                <h5 style={{ fontWeight: 800 }}>Button Background Color</h5>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    style: {
                      paddingTop: "0px"
                    }
                  }}
                  inputProps={{
                    placeholder: "#FFFFFF",
                    required: true,
                    type: "text",
                    disableUnderline: true,
                    onChange: (e) => setBackgroundColor2(e.target.value),
                    style: {
                      border: "2px solid #E60023",
                      borderRadius: "5px"
                    }
                  }}
                />
              </GridItem>

              <GridItem xs={6}>
                <h5 style={{ fontWeight: 800 }}>Button Text Color</h5>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    style: {
                      paddingTop: "0px"
                    }
                  }}
                  inputProps={{
                    placeholder: "#FFFFFF",
                    required: true,
                    type: "text",
                    disableUnderline: true,
                    onChange: (e) => setTextColor2(e.target.value),
                    style: {
                      border: "2px solid #E60023",
                      borderRadius: "5px"
                    }
                  }}
                />
              </GridItem>

              <GridItem xs={6} />
              <GridItem xs={6}>
                <Button
                  className={classes.newButton}
                  style={{ width: "85%", height: "50px", background: "#005479", fontSize: "20px" }}
                >
                  Preview Email
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridContainer>
      <GridContainer className={classes.nextButtonsContainer}>
        {/* <Button onClick={() => {
          setStep(0);
          goBack();
        }} className={classes.backButton}>Back</Button> */}
        <Button onClick={() => setStep(2)} className={classes.nextButton}>Next</Button>
      </GridContainer>
    </>
  )
}

function mapStateToProps(state) {
  return {
    logo: state.auth.user.logo
  };
}

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(campaignsStyle)
)(EmailForm));