import { connect } from 'react-redux';
import FormStepContainer from './FormStepContainer';

function mapStateToProps(accessors) {
    return (state, ownProps) => { 
        return {
            step: accessors.get(state).step
        }
    }
}

function mapDispatchToProps(actions) {
    return (dispatch, ownProps) => ({
        next: () => dispatch(actions.nextStep()),
        last: () => dispatch(actions.lastStep())
    });
}

export default (actions, accessors) => connect(mapStateToProps(accessors), mapDispatchToProps(actions))(FormStepContainer);
