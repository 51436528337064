import { connect } from 'react-redux';
import Form from './NewLeadForm';

function mapStateToProps(accessors) {
    return (state, ownProps) => {
        return {
            user: state.auth.user
        }
    }
}

function mapDispatchToProps(actions) {
    return (dispatch, _ownProps) => {
        return {
        submit: () => dispatch(actions.submit()),
        cancel: () => dispatch(actions.cancel())
    }};
}

export default (actions, accessors) => connect(mapStateToProps(accessors), mapDispatchToProps(actions))(Form);
