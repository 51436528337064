import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Remove from '@material-ui/icons/Remove';
import LaunchIcon from '@material-ui/icons/Launch';
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';

const useStyles = makeStyles(styles);

const BillsList = (props) => {
  const classes = useStyles();
  const { billsData } = props;

  const actionButtons = [
    { color: 'info', icon: LaunchIcon },
    { color: 'danger', icon: Remove },
  ].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        style={{ color: '#fff' }}
        className={classes.actionButton}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  return (
    // <Box
    //   sx={{
    //     display: 'flex',
    //     backgroundColor: 'lightGray',
    //     borderRadius: '5px',
    //     padding: '10px',
    //     margin: '10px 5px',
    //   }}
    // >
    <GridContainer>
      <GridItem
        sx={{
          direction: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          maxHeight: '350px',
        }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Card style={{ height: '350px', margin: '25px 0px 15px 0px' }}>
          <CardHeader color='primary' style={{ padding: '10px' }}>
            <h5
              style={{
                textAlign: 'center',
              }}
              className={classes.cardTitle}
            >
              Current Monthly Bills
            </h5>
          </CardHeader>
          <CardBody style={{ padding: '5px 10px', overflow: 'scroll' }}>
            <Table
              tableHead={['Name', 'Amount', 'Action']}
              tableData={[
                [
                  billsData.bills[0].name,
                  billsData.bills[0].amount,
                  actionButtons,
                ],
                [
                  billsData.bills[1].name,
                  billsData.bills[1].amount,
                  actionButtons,
                ],
                [
                  billsData.bills[2].name,
                  billsData.bills[2].amount,
                  actionButtons,
                ],
                [
                  billsData.bills[3].name,
                  billsData.bills[3].amount,
                  actionButtons,
                ],
              ]}
              customCellClasses={[classes.center, classes.right, classes.right]}
              customClassesForCells={[0, 4, 5]}
              customHeadCellClasses={[
                classes.center,
                classes.right,
                classes.right,
              ]}
              customHeadClassesForCells={[0, 4, 5]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    // </Box>
  );
};

BillsList.propTypes = {
  classes: PropTypes.object.isRequired,
  billsData: PropTypes.object.isRequired,
};

export default BillsList;
