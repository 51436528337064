import { 
    actionsForRecordset, 
    addDatasource 
} from 'store/actions/recordset';

import { getDatasourceFor } from 'firebase/datasource';
import { config } from 'store/reducers/recordsets/leads';

export default addDatasource(actionsForRecordset('leads', config), getDatasourceFor(config));


