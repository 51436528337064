import { getAccessorsForCollection, addCalculatedFields } from "../recordset";
import { CardFieldFormats } from "variables/constants";
import { DataTypes, ControlTypes } from "variables/constants";
import UsersActions from "store/actions/recordsets/users";

import { db } from "../../../firebase/client.js";

const getAnalysts = (fields) => {
    const getData = async () => {
        const usersRef = db.collection('users');
        const isAnalyst = usersRef.where('userType', '==', 'ANALYST').get();
        const isCreditAnalyst = usersRef.where('userType', '==', 'CREDIT_ANALYST').get();

        const [ analystQuerySnapshot, creditAnalystQuerySnapshot ] = await Promise.all([
            isAnalyst,
            isCreditAnalyst
          ]);

        const analystArray = analystQuerySnapshot.docs;
        const creditAnalystArray = creditAnalystQuerySnapshot.docs;

        const totalArray = analystArray.concat(creditAnalystArray);
        return totalArray;
    }
    const data = getData();
    const returnData = [];
    data.then((array) => {
        array.forEach((snapshot) => {
            let snapshotData = snapshot.data();
            snapshotData.uid = snapshot.id;
            if (fields) {
                let returnString = '';
                fields.forEach((field) => {
                    returnString += `${snapshotData[field]} `;
                })
                returnData.push(returnString);
            } else {
                returnData.push(snapshotData);
            }
        })
    });
    return returnData;
};

const getOfficers = (fields) => {
    const getData = async () => {
        const usersRef = db.collection('users');
        const isOfficer = usersRef.where('userType', '==', 'LOAN_OFFICER').get();
        let returnArr = [];
        await isOfficer.then((snapshot) => {
            for (let i = 0; i < snapshot.docs.length; i++) {
                returnArr.push(snapshot.docs[i]);
            }
        });
        // const totalArray = analystArray.concat(creditAnalystArray);
        return returnArr;
    }
    const data = getData();
    const returnData = [];
    data.then((array) => {
        array.forEach((snapshot) => {
            let snapshotData = snapshot.data();
            snapshotData.uid = snapshot.id;
            if (fields) {
                let returnString = '';
                fields.forEach((field) => {
                    returnString += `${snapshotData[field]} `;
                })
                returnData.push(returnString);
            } else {
                returnData.push(snapshotData);
            }
        })
    });
    return returnData;
}

export const config = {
    firestoreCollection: 'leads',
    fields: {
        lastUpdated: { type: DataTypes.DATETIME, cardFormat: CardFieldFormats.SIMPLE_DATE },
        notes: { maxLength: 120 },
        currentScore: { type: DataTypes.NUMBER },
        goalScore: { type: DataTypes.NUMBER },
        status: { type: DataTypes.TEXT, controlType: ControlTypes.SELECT, default: "Pending", values: ["Pending"] },
        mainPhone: { type: DataTypes.NUMBER, maxLength: 10 },
        joinDate: { type: DataTypes.DATETIME, cardFormat: CardFieldFormats.SIMPLE_DATE },
        analyst: {
            controlType: ControlTypes.SELECT,
            values: getAnalysts(['firstName', 'lastName']),
            users: getAnalysts()
        },
        loanOfficer: {
            controlType: ControlTypes.SELECT,
            values: getOfficers(['firstName', 'lastName']),
            users: getOfficers()
        }
    },
    textSearchFields: [ "firstName", "lastName", "notes", "status" ]
}

function calculator(column, accessor) {
    switch(column) {
        case "fullName":
            return accessor("firstName") + " " + accessor("lastName");
        default:
            return accessor(column);
    }
}

export const accessorsForRecordset = addCalculatedFields(getAccessorsForCollection(config, "recordset","leads"), calculator);

export default accessorsForRecordset;


