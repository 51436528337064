/*eslint-disable*/
const documents = []
const initDataRetrieved = false

export const initState = {
  documents,
  initDataRetrieved
}


export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_NEW_DOC_ADDED':
    var toReturn = {...state}
    toReturn.documents = action.data
    return toReturn

    case 'SET_DOCS':
      var toReturn = {...state}
      toReturn.documents = action.data
      toReturn.initDataRetrieved = true
      return toReturn

    default:
      return state
  }
}
