import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { connect } from "react-redux";


import styles from "assets/jss/material-dashboard-pro-react/components/explorerStyle";
import { NeedsAttentionExplorer } from "./NeedsAttentionExplorer";

const useStyles = makeStyles(styles);

export function LeadsExplorer(props) {

    const [records, setRecords] = useState([]);
    const classes = useStyles();

    const { ExplorerCard, SearchControl, SearchDialog, routeName, searchFields, cardFields } = props;

    useEffect(() => {
        if (props.userType === 'ANALYST' || props.userType === 'CREDIT_ANALYST') {
            let toReturn = props.records.map((record) => {
                if (!!record.fields.analyst) {
                    if (record.fields.analyst.uid === props.uid) {
                        return record;
                    }
                }
            })
            toReturn = toReturn.filter((record) => {
                return record !== undefined;
            })
            setRecords(toReturn);
        } else {
            setRecords(props.records);
        }
    }, [props])

    return (
        <div>
            <SearchControl />
            <SearchDialog order={searchFields} />
            {!records.length ? (
                <h1 className={classes.statusTitle}>No Records Found for Analyst</h1>
            ) : null}
            <h1 className={classes.statusTitle}>Pending</h1>
            {records.map((record, index) => (
                ((record.fields.status == "Pending") || (record.fields.status == undefined))  && (!record.fields.convertedToCustomer) ?
                <ExplorerCard
                    firstName={record.fields.firstName}
                    lastName={record.fields.lastName}
                    currentScore={record.fields.currentScore}
                    goalScore={record.fields.goalScore}
                    notes={record.fields.notes}
                    joinDate={record.fields.joinDate}
                    status={record.fields.status}
                    routeName={`${routeName}`}
                    key={index}
                    index={index}
                    order={cardFields}
                    editRoute={`${routeName}/editor/${record.key}`}
                    viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                /> : null
            ))}
            <h1 className={classes.statusTitle}>Engaged</h1>
            {records.map((record, index) => (
                ((record.fields.status == "Engaged")) && (!record.fields.convertedToCustomer) ?
                    <ExplorerCard
                        firstName={record.fields.firstName}
                        lastName={record.fields.lastName}
                        currentScore={record.fields.currentScore}
                        goalScore={record.fields.goalScore}
                        notes={record.fields.notes}
                        joinDate={record.fields.joinDate}
                        status={record.fields.status}
                        routeName={`${routeName}`}
                        key={index}
                        index={index}
                        order={cardFields}
                        editRoute={`${routeName}/editor/${record.key}`}
                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                    /> : null
            ))}
            <h1 className={classes.statusTitle}>Lapsed</h1>
            {records.map((record, index) => (
                ((record.fields.status == "Lapsed")) && (!record.fields.convertedToCustomer) ?
                    <ExplorerCard
                        firstName={record.fields.firstName}
                        lastName={record.fields.lastName}
                        currentScore={record.fields.currentScore}
                        goalScore={record.fields.goalScore}
                        notes={record.fields.notes}
                        joinDate={record.fields.joinDate}
                        status={record.fields.status}
                        routeName={`${routeName}`}
                        key={index}
                        index={index}
                        order={cardFields}
                        editRoute={`${routeName}/editor/${record.key}`}
                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                    /> : null
            ))}
            <h1 className={classes.statusTitle}>Closed</h1>
            {records.map((record, index) => (
                ((record.fields.status == "Closed")) && (!record.fields.convertedToCustomer) ?
                    <ExplorerCard
                        firstName={record.fields.firstName}
                        lastName={record.fields.lastName}
                        currentScore={record.fields.currentScore}
                        goalScore={record.fields.goalScore}
                        notes={record.fields.notes}
                        joinDate={record.fields.joinDate}
                        status={record.fields.status}
                        routeName={`${routeName}`}
                        key={index}
                        index={index}
                        order={cardFields}
                        editRoute={`${routeName}/editor/${record.key}`}
                        viewRoute={`${routeName}/${record.key}?mode=READ_ONLY`}
                    /> : null
            ))}
            <Fab className={classes.newRecord} onClick={e => props.openChild("New Record", `${routeName}/new`)}><AddIcon /></Fab>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        firstName: state.auth.user.firstName,
        lastName: state.auth.user.lastName,
        uid: state.auth.user.uid,
        records: state.recordset.leads.records,
    }
}

function mapDispatchToProps() {
    return {
        // nothing yet
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LeadsExplorer);
