/*eslint-disable*/
import { CardElement, useElements, useStripe, PaymentElement } from "@stripe/react-stripe-js"
import { NavLink, withRouter, useParams } from 'react-router-dom';
import { db, firebase, storage } from 'firebase/client';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { startLoadProfile } from 'store/actions/auth.js'
import loader from 'assets/img/loader.gif';
import axios from "axios"
import React, { useState, useEffect } from 'react'
import "./App.css";
import { chain } from 'store/actions/util';
import { push } from 'connected-react-router';

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#87bbfd" }
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee"
    }
  }
}

const PaymentForm = (props) => {
  const { user, history } = props;
  const [success, setSuccess] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const [unsuccessfulPaymentSnackbar, setUnsuccessfulPaymentSnackbar] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [email, setEmail] = useState('')


  useEffect(() => {
    if (user && Object.keys(user).length === 0) {
      async function fetchSecret() {
        return db.collection('users').doc(window.location.pathname.split('/')[3]).get()
        .then((snapshot) => {
          snapshot = snapshot.data()
          const userEmail = snapshot.email
          setEmail(userEmail)
        })
        .catch((err) => {
          console.log('errrr', err)
        })
      }
      fetchSecret()
    }
    if (user && Object.keys(user).length !== 0) {
      if (user.user) {
        setEmail(user.user.email)
      } else {
        setEmail(user.email)
      }
    }
  }, [props])

  // const handleSubmitPay = async (e) => {
  //   e.preventDefault()
  //   const { error, paymentMethod } = await stripe.createPaymentMethod({
  //     type: "card",
  //     card: elements.getElement(CardElement)
  //   })


  //   if (!error) {
  //     try {
  //       console.log('proppsss', elements.getElement(CardElement))
  //       const { id } = paymentMethod

  //       //single payment
  //       const response = await axios.post("http://localhost:4000/payment", {
  //         amount: props.amount,
  //         id
  //       })


  //       if (response.data) {
  //         console.log("Successful payment", paymentMethod)
  //         setSuccess(true)

  //         // turn this into a cloud function
  //         // return db.collection('users').doc(user.uid).
  //       }
  //     } catch (error) {
  //       console.log("Error", error)
  //     }
  //   } else {
  //     console.log(error.message)
  //   }
  // }


  const handleSubmitSub = async (e) => {
    e.preventDefault()
    setLoading(true)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email: email
      }
    })
    if (!error) {
      try {
        const { id } = paymentMethod
        //subscription payment
        const response = await axios.post("http://localhost:4000/subscribe", {
          amount: props.amount,
          // id,
          payment_method: id,
          email: email
        })

        // const response = await axios.post("https://us-central1-market-me-346916.cloudfunctions.net/app/subscribe", {
        //   amount: props.amount,
        //   // id,
        //   payment_method: id,
        //   email: email
        // })

        const { client_secret, status } = response.data;
        if (status !== 'paid') {
          console.log('Succesful Error:', response.data.decline_code)
          setUnsuccessfulPaymentSnackbar(true)
          setErrorMessage(response.data.decline_code)
          setLoading(false)
        } else {
          var data = response.data

          var uid
          if (window.location.pathname.split('/')[3]) {
            uid = window.location.pathname.split('/')[3]
          } else if (!user.uid) {
            uid = user.user.uid
          } else {
            uid = user.uid
          }
          return db.collection('users').doc(uid).update({
            subscription: {
              planSelected: props.planSelected,
              createdAt: new Date(),
              customerId: data.customer.id,
              features: [...props.features],
              subscriptionId: data.subscription.id,
              plan: data.subscription.plan,
              subscriptionStartDate: data.subscription.current_period_start,
              subscriptionRenewalDate: data.subscription.current_period_end,
              status: "Active"
            }
          }).then(() => {
            console.log('sucessful subscription!')
            setSuccess(true)
            props.startLoad({ uid }, history)
          })
        }
      } catch (error) {
        console.log("Error", error)
      }
    } else {
      console.log(error.message)
      setUnsuccessfulPaymentSnackbar(true)
      setErrorMessage(error.message)
    }
  }







  return (
    <>
      {!success ?
        <form onSubmit={handleSubmitSub}>
          <fieldset className="FormGroup">
            <div className="FormRow">
              {/* <PaymentElement /> */}
              <CardElement options={CARD_OPTIONS} />
            </div>
          </fieldset>



          {loading ? (
            <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
              <button onClick={() => props.setOpenStripeModal(false)} className='closeButton'>Close</button>
              <button className='payButton'>Pay
                <img
                  style={{ width: '10%', marginLeft: "20px" }}
                  src={loader}
                  alt="..."
                />
              </button>
            </div>
          ) : (
            <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
              <button onClick={() => props.setOpenStripeModal(false)} className='closeButton'>Close</button>
              <button className='payButton'>Pay</button>
            </div>
          )}
        </form>
        :
        <div>
          <h2>Payment Successful</h2>
        </div>
      }

      <Snackbar open={unsuccessfulPaymentSnackbar} onClose={() => setUnsuccessfulPaymentSnackbar(false)}>
        <Alert onClose={() => setUnsuccessfulPaymentSnackbar(false)} severity='error'>
          There was a problem with your payment: "{errorMessage}".
        </Alert>
      </Snackbar>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startLoad: (user, history) => chain(dispatch(startLoadProfile(user)), history.push('/admin/user-page')),
  }
}

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentForm));
