import React from "react";

import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from 'components/Grid/GridItem';

import classNames from "classnames";

import { startCase } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function NewFieldInput(props) {
  const { field, handleChange } = props;
  const classes = useStyles();
  let type = 'string';
  let labelProps = {};

  if (field === 'joinDate') {
    type = 'datetime-local';
    labelProps = { ...labelProps, shrink: true }
  }

  const inputClasses = classNames({
    [classes.input]: true
  });

  const underlineClasses = classNames({
    [classes.underline]: true,
    [classes.underlineError]: undefined,
    [classes.underlineSuccess]: undefined,
    [classes.whiteUnderline]: undefined
  });

  return (
    <GridItem xs={6} s={6}>
      <FormControl
        className={classes.formControl}
      >
        <InputLabel
          className={classes.labelRoot}
          {...labelProps}
        >
          {startCase(field)}
        </InputLabel>
        <Input
          classes={{
            input: inputClasses,
            disabled: classes.disabled
          }}
          type={type}
          onChange={(e) => {
            handleChange(field, e.target.value);
            }}
        />
      </FormControl>
    </GridItem>
  )
};