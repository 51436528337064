/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';

import withStyles from "@material-ui/core/styles/withStyles";

import compose from "recompose/compose";

import { connect } from "react-redux";
import Style from "assets/jss/material-dashboard-pro-react/views/campaignsStyle.js";

import Step3 from "./Step3";
import InstagramDM from "./InstagramDM";
import AutoCommenting from "./AutoCommenting";

const useStyles = makeStyles(Style);

const InstagramCampaignForm = (props) => {
  const { goBack } = props;
  const classes = useStyles();
  const [campaignStep, setCampaignStep] = useState(1);
  const [campaignForm, setCampaignForm] = useState({ runLengthUnit: 'Days', linkedIn: {}, instagram: {}, sms: {}, email: {} });
  const [platforms, setPlatforms] = useState({});

  const handleChange = (field, value) => {
    setCampaignForm((prevState) => {
      let newState = { ...prevState };
      if (typeof field === 'object') {
        newState[field[0]][field[1]] = value;
      } else {
        newState[field] = value;
      }
      return newState;
    });
  }

  const handlePlatformSelect = (platform) => {
    if(Object.keys(platforms).length === 0){
      setPlatforms(() => {
        let newState = { };
        newState[platform] = !newState[platform];
        return newState;
      })
    }
    else{
      setPlatforms({})
      let newState = { };
      newState[platform] = !newState[platform];
      return newState;
    }

  }

  return (
    <>
      {campaignStep === 1 ?
      <Step3 goBack={goBack} setStep={setCampaignStep} campaignForm={campaignForm} handleChange={handleChange} setPlatforms={handlePlatformSelect} platforms={platforms}/> :
        campaignStep === 2 ?
      <InstagramDM setStep={setCampaignStep} campaignForm={campaignForm} handleChange={handleChange} setPlatforms={handlePlatformSelect} platforms={platforms}/> :
        campaignStep === 3 ?
      <AutoCommenting setStep={setCampaignStep} campaignForm={campaignForm} handleChange={handleChange} setPlatforms={handlePlatformSelect} platforms={platforms}/> : null
      }
    </>
  )
}

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(InstagramCampaignForm));
