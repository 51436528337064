import React, { useState, useEffect } from 'react';
import { db } from "firebase/client.js";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/leadDetailStyle";

// core components
import Button from "components/CustomButtons/Button.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";

export function LeadAdvanceButton(props) {

    const [alert, setAlert] = React.useState(null);

    const hideAlert = () => {
        setAlert(null);
    };

    const { classes, customerId } = props;

    const startLeadCoversion = () => {
        let validate = window.confirm("Are you sure you want to convert this lead to a customer? Please make sure all fields are filled in!")
        if (validate == true) {
            loadLeadData()
        } else {
            console.log('selected cancel')
        }
    }

    const loadLeadData = () => {
        db.collection("leads").doc(props.customerId)
            .get().then((snapshot) => {
                var docData = snapshot.data()
                if (docData) {
                    var docObj = {
                        id: snapshot.id,
                        convertedToCustomer: true,
                        ...docData
                    }
                    return (docObj)
                } else {
                    return (null)
                }
            }, (error) => {
                console.log('error fetching data: ', error)
            })
            // .then(docObj => {
            //     checkRequiredFields(docObj)
            // })        
            .then(docObj => {
                addNewCustomerFromLead(docObj)
            })
            .then(() => {
                updateLeadToConverted()
            })
    }

    const checkRequiredFields = (docObj) => {
        // Set docs
        if (docObj.docs) {
            return docObj.docs
        } else {
            docObj = {
                docs: []
            }
        }
        //    Should I do this for all required fields or just rely that a lead will  have all info
    }

    const addNewCustomerFromLead = (leadInfo) => {
        db.collection("customers").doc(props.customerId).set({
            firstName: leadInfo.firstName,
            lastName: leadInfo.lastName,
            currentScore: leadInfo.currentScore,
            goalScore: leadInfo.goalScore,
            joinDate: leadInfo.joinDate,
            state: leadInfo.state,
            notes: leadInfo.notes,
            emailAddress: leadInfo.emailAddress,
            mainPhone: leadInfo.mainPhone,
            docs: leadInfo.docs,
            id: leadInfo.id,
            convertedToCustomer: leadInfo.convertedToCustomer,
            status: "Service Agreements",
            needsAttention: true
        })
            .then(() => {
                renderConfirmation()
            })
            .catch(error => {
                console.log(error)
            })
    }

    const updateLeadToConverted = () => {
        db.collection("leads").doc(props.customerId).set({
            convertedToCustomer: true
        }, { merge: true })
    }

    const renderConfirmation = () => {
        window.alert("This Lead has successfully been converted to a Customer! You may have to reload in order to see this customer")
        // {return (
        //     <SweetAlert
        //         success
        //         style={{ display: "block", marginTop: "-100px" }}
        //         title="This Lead is now a Customer!"
        //         onConfirm={() => hideAlert()}
        //         confirmBtnCssClass={classes.button + " " + classes.success}
        //     >
        //         You may have to reload the page to see this update
        //         <Link to="/admin/leads">
        //             <Button><b>Back to Leads</b></Button>
        //         </Link>
        //     </SweetAlert>
        // )}
    }

    return (
        <div>
            <Button color="success" onClick={startLeadCoversion}>Click here to advance this lead to a customer</Button>
        </div>);
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(actions) {
    return () => ({

    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles))
    (LeadAdvanceButton);