/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";

import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CardFooter from "components/Card/CardFooter";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Input from "@material-ui/core/Input";
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

// import CardFields from '../../../components/Card/CardFields';
// import CardField from '../../../components/Card/CardField';
import PlusIcon from "assets/img/market-me/plusIcon.png";
import TextEditor from "components/TextEditor/TextEditor";
import actionsForNavigation from "store/actions/navigation";
import { connect } from "react-redux";
import { MockUserData } from 'variables/config';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import Close from "@material-ui/icons/Close";
import { getLeads, deleteLead, convertLeadToCustomer } from "store/actions/leadsActions"
import Slide from "@material-ui/core/Slide";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import campaignsStyle from "assets/jss/material-dashboard-pro-react/views/campaignsStyle.js";

import NumberBubble from "components/NumberBubble/NumberBubble";
import InformationModal from "components/InformationModal/InformationModal";
import InstagramIcon from "assets/img/market-me/instagramIcon.png";
import LinkedInIcon from "assets/img/market-me/linkedInLogo.png";
import EmailIcon from "assets/img/market-me/emailIcon.png";
import TextIcon from "assets/img/market-me/textIcon.png";
import CalendarIcon from "assets/img/market-me/calendarIcon.png";

import AuthActions from "store/actions/auth"
import { chain, handleError } from "store/actions/util";

import { getConversationRecipients, getUserPageId, sendMessage, getPageAccessToken } from 'store/actions/instagramActions';
import { push } from "connected-react-router";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const useStyles = makeStyles(campaignsStyle);

const InstagramDM = (props) => {
  const classes = useStyles();
  const { user, setStep, campaignForm, handleChange, setPlatforms, platforms, goBack } = props;
  const [ editorText, setEditorText ] = useState('');

  const placeholder = "Hey! I'm on the promoter team at @kordspace_agency and we think you’d be perfect to benefit from our digital marketing services! ⭐️ If you’re interested we can send you three FREE moodboards based on your brand identity. Please direct message @kordspace_agency and we will get back to you ASAP! 💛 X";

  const modules = {
    toolbar: false
  }

  const sendInstaMessage = () => {
    getUserPageId(user.access_token)
      .then((pageId) => {
        getPageAccessToken(pageId, user.access_token)
          .then((pageAccessToken) => {
            sendMessage(editorText, pageId, ['5934421779909907', "5511658475628433", "5349977368445045"], pageAccessToken);
          })
      })
  }

  return (
    <>
      <GridContainer className={classes.mainStepContainer}>
        <GridItem
          className={classes.stepHeader}
          style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}
        >
          <NumberBubble color="yellow" value="4." size="2.5rem" />
          <h4 style={{ fontWeight: 600, marginLeft: "1rem" }}>Compose Instagram Direct Messaging Ad</h4>
        </GridItem>
        <GridItem style={{ display: "flex", marginTop: "-1rem", alignItems: "end" }}>
          <GridContainer style={{ display: "flex" }}>
            <GridItem style={{ display: "flex", flexDirection: "column" }} xs={6}>
              <GridItem>
                <GridContainer className={classes.step3Container} style={{ marginTop: "2rem" }}>
                  <GridItem xs={6}>
                    <h4 style={{ fontWeight: 800, width: "max-content" }}>Embed Your Instagram Account</h4>
                    <Button
                      style={{
                        background: "linear-gradient(180deg, #005479 0%, #13294C 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
                        color: "white",
                        width: "-webkit-fill-available"
                      }}
                    >Add Profile</Button>
                  </GridItem>
                  <GridItem xs={6} style={{ minWidth: "50rem", marginLeft: "-1rem" }}>

                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem className={classes.step3Container} style={{ }}>
                <h4 style={{ fontWeight: 800, marginBottom: 0 }}>Add Images or Videos</h4>
                <p>Choose an image or video. JPEG is the only image format supported. To convert images, go <a href="https://convertio.co/png-jpeg/" target="_blank">here.</a></p>
                <div style={{ borderRadius: "4px", border: "2px solid #E60023", height: "5rem", width: "5rem", textAlign: "center", paddingTop: "1rem" }}>
                  <img src={PlusIcon} style={{ width: "1.75rem" }} />
                  <h6>Add Media</h6>
                </div>
              </GridItem>
              <GridItem xs={8} style={{ display: "flex", flexDirection: "column" }}>
                <h4 style={{ fontWeight: 800 }}>Introductory Message</h4>
                <ReactQuill
                    style={{ minHeight: "10rem", height: "1rem", minWidth: "29rem", marginBottom: "1rem" }}
                    placeholder={placeholder}
                    modules={modules}
                    theme="snow"
                    onChange={(e) => {
                      const text = e.replace(/<[^>]+>/g, '');
                      setEditorText(text);
                    }}
                  />
              </GridItem>
            </GridItem>
            <GridItem style={{ display: "flex", flexDirection: "column" }} xs={6}>
              <GridContainer style={{ marginTop: "1rem" }}>
                <GridItem xs={7} style={{ width: "30rem", minHeight: "15rem" }}>
                  <h4 style={{ fontWeight: 800, marginLeft: "1rem" }}>Direct Messaging Preview</h4>
                  <div className={classes.directMessageHeader} style={{ display: "flex" }}>
                    {user.profilePic ? (
                      <img src={user.profilePic} className={classes.profilePictureCircle} style={{ width: "12%", height: "12%", marginRight: "1rem", marginLeft: "0.5rem" }}/>
                    ) : null}
                    <div>
                      <h5 style={{ margin: "0", fontWeight: 800 }}>{user.firstName} {user.lastName}</h5>
                      <h8>Typically responds instantly</h8>
                    </div>
                  </div>
                  <div className={classes.instagramExample}>
                    <p style={{ textAlign: "center", margin: 0 }}>Monday 4:26pm</p>
                    <div style={{ display: "flex", alignItems: "flex-end", marginTop: "1rem" }}>
                      {user.profilePic ? (
                        <img src={user.profilePic} className={classes.profilePictureCircle} style={{ width: "10%", height: "10%" }}/>
                      ) : null}
                      <div className={classes.instagramBlurb}>
                        <p style={{ margin: 0 }}>{!editorText || editorText === "<p><br></p>" ? placeholder : editorText}</p>
                      </div>
                    </div>
                  </div>
                </GridItem>
                <GridItem>
                  <Button onClick={sendInstaMessage}>Send Message</Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem>
        </GridItem>
      </GridContainer>
      <GridContainer
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Button onClick={()=>setStep((prev) => (prev-1))} className={classes.backButton}>Back</Button>
        <Button onClick={()=>setStep((prev) => (prev+1))} className={classes.nextButton}>Next</Button>
      </GridContainer>
    </>
  )
}

function mapStateToProps(state) {
  return {
    user: state.auth.user
  };
}

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(campaignsStyle)
)(InstagramDM));