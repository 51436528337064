import {
  whiteColor,
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";

const pagesStyle = theme => ({
  wrapper: {
    height: "auto",
    minHeight: "100vh",
    position: "relative",
    top: "0"
  },
  fullPage: {
    display: "flex",
    justifyContent: "center",
    // padding: "120px 0",
    position: "relative",
    minHeight: "100vh",
    display: "flex!important",
    margin: "0",
    border: "0",
    zIndex: 1,
    backgroundColor: "#fff",
    color: whiteColor,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    height: "100%",
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important"
    },
    "&:before": {
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)"
    },
    "&:before,&:after": {
      // display: "block",
      // // content: '""',
      // position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: "2",
      backgroundColor: "#fff"
    }
  },
  logoAndText: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    right: "3%",
    top: "2%",
    [theme.breakpoints.down('sm')]: {
      right: "15px",
      top: "80px",
    }
  }
});

export default pagesStyle;
