/*eslint-disable*/
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from 'react-router-dom';

import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";

import Style from 'assets/jss/kordspace-template/views/pages/registration/stepStyle';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import AuthActions from "store/actions/auth"
import { push } from "connected-react-router";
import { chain, handleError } from "store/actions/util";

export function RegistrationConfirmation(props) {

    const { classes, authInfo, startEmailLogin, userInfo, history, profilePic } = props;

    console.log('stateeeeeppppppp', authInfo, userInfo)

    const [credentials, setCredentials] = useState({})
    const [basicUserInfo, setBasicUserInfo] = useState({})

    useEffect(() => {
        if (authInfo) {
            setCredentials(authInfo)
        }
    }, [authInfo])

    useEffect(() => {
        if (userInfo) {
            if (profilePic) {
                var updateInfo = {profilePic, ...userInfo}
                setBasicUserInfo(updateInfo)
            } else {
                setBasicUserInfo(userInfo)
            }
        }
    }, [userInfo])

    console.log('userinfoooo', userInfo, authInfo)




    const [alert, setAlert] = React.useState(null);

    const hideAlert = () => {
        setAlert(null);
    };

    return (
        <div>
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Thank you for creating an account with Market Me!"
                // onConfirm={() => hideAlert()}
                // confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Please wait while we log you in.
                <div style={{display: "none"}}>
                {/* {authInfo.email && userInfo.firstName ? setTimeout(
                    function () {
                        props.emailSignIn(authInfo, userInfo)
                    }, 6000
                ) : null} */}
                </div>


            </SweetAlert>
        </div>
    );
}

RegistrationConfirmation.propTypes = {
    classes: PropTypes.object.isRequired,
};



const mapStateToProps = (state) => {
    console.log('stateeeeeeeeeeks', state)
    return {
        authInfo: state.form.registration.steps.basicDetails.fields,
        userInfo: state.form.registration.steps.commonProfile.fields,
        profilePic: state.form.profilePic
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        emailSignIn: (credentials, basicUserInfo) => dispatch(handleError(chain(AuthActions.startEmailLogin(credentials, basicUserInfo), push('/admin/user-page')), AuthActions.setError)),
    }
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(RegistrationConfirmation));

