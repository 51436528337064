import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Remove from '@material-ui/icons/Remove';
import LaunchIcon from '@material-ui/icons/Launch';
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import EquifaxLogo from './equifax.png';
import ExperianLogo from './experian.png';
import TransUnionLogo from './transunion.png';
import TrashIcon from './trash.png';
import EditIcon from './edit.png';

const useStyles = makeStyles(styles);

const AgenciesList = (props) => {
  const classes = useStyles();
  const { agenciesData } = props;

  const actionButtons = [
    { color: 'info', icon: LaunchIcon },
    { color: 'danger', icon: Remove },
  ].map((prop, key) => {
    return (
      <Button color={prop.color} className={classes.actionButton} key={key}>
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  return (
    <GridContainer
      style={{
        display: 'flex',
        backgroundColor: '#fff',
        justifyContent: 'space-between',
        width: '100%',
        margin: "0px auto",
        // maxWidth: '1300px',
        boxShadow: '0px 2px 8px -1px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
      }}
    >
      <div style={{ width: '100%' }}>
        <CardHeader sx={{ width: '100%', height: '100%' }} color='primary'>
          <h3
            style={{
              textAlign: 'center',
              padding: '5px',
              margin: '0',
            }}
            className={classes.cardTitle}
          >
            Connected Agencies
          </h3>
        </CardHeader>
      </div>
      <GridItem
        style={{
          direction: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
        xs={12}
        sm={12}
        md={6}
        lg={4}
      >
        <Card
          style={{
            // height: '450px',
            margin: '25px 0px 15px 0px',
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <CardBody style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <div>
                <img
                  src={EquifaxLogo}
                  style={{
                    width: '65px',
                    margin: '10px',
                  }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p>Equifax</p>
                  <div>
                    <img
                      src={EditIcon}
                      style={{ width: '16px', marginRight: '26px' }}
                    />
                    <img src={TrashIcon} style={{ width: '16px' }} />
                  </div>
                </div>
                <Button style={{ height: '40px', width: '236px' }}>
                  Connected
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem
        sx={{
          direction: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
        xs={12}
        sm={12}
        md={6}
        lg={4}
      >
        <Card
          style={{
            // height: '450px',
            margin: '25px 0px 15px 0px',
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <CardBody style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <div>
                <img
                  src={ExperianLogo}
                  style={{
                    width: '65px',
                    margin: '10px',
                  }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p>Experian</p>
                  <div>
                    <img
                      src={EditIcon}
                      style={{ width: '16px', marginRight: '26px' }}
                    />
                    <img src={TrashIcon} style={{ width: '16px' }} />
                  </div>
                </div>
                <Button style={{ height: '40px', width: '236px' }}>
                  Connected
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem
        sx={{
          direction: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
        xs={12}
        sm={12}
        md={6}
        lg={4}
      >
        <Card
          style={{
            // height: '450px',
            margin: '25px 0px 15px 0px',
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <CardBody style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <div>
                <img
                  src={TransUnionLogo}
                  style={{
                    width: '65px',
                    margin: '10px',
                  }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p>TransUnion</p>
                  <div>
                    <img
                      src={EditIcon}
                      style={{ width: '16px', marginRight: '26px' }}
                    />
                    <img src={TrashIcon} style={{ width: '16px' }} />
                  </div>
                </div>
                <Button style={{ height: '40px', width: '236px' }}>
                  Connected
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

AgenciesList.propTypes = {
  classes: PropTypes.object.isRequired,
  cryptoData: PropTypes.object.isRequired,
};

export default AgenciesList;
