/*eslint-disable*/
import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { db, firebase, storage } from 'firebase/client';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Phone';
import SendIcon from '@material-ui/icons/Send';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Switch from '@material-ui/core/Switch';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Table from 'components/Table/Table.js';
import Box from '@material-ui/core/Box';
import Button from 'components/CustomButtons/Button.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import loader from 'assets/img/loader.gif';
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import actionsForNavigation from 'store/actions/navigation';
import Slide from '@material-ui/core/Slide';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import LinearProgress from "@material-ui/core/LinearProgress";



import documentsStyle from 'assets/jss/material-dashboard-pro-react/views/documentsStyle.js';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { DateTime } from 'luxon';
import { isElementAccessExpression } from 'typescript';


import { updateUserData } from 'store/actions/auth.js';
import { addNewDocument, getDocuments } from 'store/actions/documentActions';
import { addDocumentsToCustomerCollectionForDF } from "store/actions/customerActions"
import ClientListDocumentsModal from './ClientListDocumentsModal';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';


import PdfLogo from 'assets/img/market-me/pdfLogo.png'
import UploadArrowLogo from 'assets/img/market-me/uploadArrowLogo.png'
import Dropzone from 'react-dropzone'

const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

export function AddNewDocumentsModalContent(props) {
  const { data, classes, uid, selectedCustomer, setClientDocumentModal, setAddNewDocumentModal } = props
  const documentsInputRef = createRef(null)
  const [filesArray, setFilesArray] = useState([])
  console.log('filesArray', filesArray)
  const [fileNames, setFileNames] = useState([])

  const [progress, setProgress] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [totalItems, setTotalItems] = useState(0)

  const [downloadUrls, setDownloadUrls] = useState([])


  console.log('selectedCustomer', selectedCustomer)

  useEffect(() => {
    if (progress === totalItems && uploading) {
      (async () => {
        await props.addDocumentsToCustomerCollectionForDF(selectedCustomer.uid, downloadUrls, uid, selectedCustomer.id, selectedCustomer)
        setUploading(false)
        setProgress(0)
        setTotalItems(0)
        setClientDocumentModal(false)
        setAddNewDocumentModal(false)
        setFilesArray([])
        setFileNames([])
      })();
    }
  }, [progress])


  console.log('selectedCustomer', selectedCustomer)
  const handleImageUpload = (event) => {
    // setUploading(true)
    // var url = URL.createObjectURL(event.target.files[0])
    // setFilesPreviewUrl(url)

    // var array = []
    // Array.from(event.target.files).forEach(file => {
    //   array = [...array, file]
    // });
    setUploading(true)
    console.log('arrayarrayarrayarray', filesArray)
    setTotalItems(filesArray.length)
    var promises = []
    filesArray.map((file, i) => {
      var random = Math.random()
      storage.ref().child(`${uid}/customers/${selectedCustomer.uid}/documents/${file.name + random}`).put(file)
        .then(async () => {
          await storage.ref().child(`${uid}/customers/${selectedCustomer.uid}/documents/${file.name + random}`).getDownloadURL().then((url) => {
            console.log('downloadurl', url)
            setDownloadUrls((prevState) => [...prevState, { url, name: file.name }])
            setFileNames((prevState) => [...prevState, file.name])
            setProgress((prevState) => prevState + 1)
          })
        }
        )
    })
  }


  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }} className={classes.container}>
      <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between", paddingTop: "24px", height: "30% !important" }}>
        <TextField
          defaultValue='No File Chosen'
          value={filesArray.map((file) => {
            return file.name + ', '
          })}
          style={{ width: "65%", marginRight: "20px" }} />
        <Button className={classes.yellowBtn} onClick={() => {
          documentsInputRef.current.click();
        }} style={{ width: "30%" }}>
          CHOOSE FILE(s)
          <input onChange={(event) => {
            var array = []
            Array.from(event.target.files).forEach(file => {
              array = [...array, file]
            });
            setFilesArray(array)
            // handleImageUpload(event)
          }} multiple="multiple" type='file' id='vipNFTArt' ref={documentsInputRef} style={{ display: 'none' }} />
        </Button>
      </div>

      {filesArray.length === 0 ? (
        <Dropzone onDrop={acceptedFiles => {
          var array = []
          Array.from(acceptedFiles).forEach(file => {
            array = [...array, file]
          });
          setFilesArray(array)
        }}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} style={{ height: "70% !important", border: "2px dashed black", margin: "20px 0px", borderRadius: "20px", padding: "60px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <input {...getInputProps()} />
              <img style={{ width: "70px" }} src={UploadArrowLogo} />
              <p style={{ marginTop: "30px", fontSize: "18px" }}>
                Drop files here or use the “Choose File(s)” button
              </p>
            </div>
          )}
        </Dropzone>
      ) : (
        <div style={{ height: "70% !important", border: "2px dashed black", margin: "20px 0px", borderRadius: "20px", padding: "60px", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          {filesArray.map((f, i) => {
            return (
              <img style={{ width: '100px', marginLeft: "30px", marginBottom: "20px" }} src={URL.createObjectURL(f)} />
            )
          })}
        </div>
      )}

      {uploading ? (
        <GridContainer style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
          <GridItem xs={10}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '80%', mr: 1 }}>
                <LinearProgress variant="determinate" value={Math.round(progress / totalItems) * 100} />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <h6 style={{ color: "black", fontSize: "25px" }}>{progress} / {totalItems}</h6>
              </Box>
            </Box>
          </GridItem>

          <GridItem xs={2}>
            <img
              style={{ width: '20%' }}
              src={loader}
              alt="..."
            />
          </GridItem>
        </GridContainer>
      ) : (
        null
      )}
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
        <Button className={classes.halfWidthYellowBtns} onClick={() => {
          setAddNewDocumentModal(false)
          setClientDocumentModal(true)
        }}>
          Back
        </Button>
        <Button style={{ backgroundColor: "#5EB1E3" }} className={classes.halfWidthBtns} onClick={handleImageUpload}>
          Submit
        </Button>
      </div>
    </div>
  );
}

AddNewDocumentsModalContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    uid: state.auth.user.uid,
    customerData: state.customers.customers
  };
}

const mapDispatchToProps = (dispatch) => ({
  addNewDocument: (id, document) => dispatch(addNewDocument(id, document)),
  getDocuments: (id) => dispatch(getDocuments(id)),
  addDocumentsToCustomerCollectionForDF: (customerUID, downloadUrls, uid, customerId, selectedCustomer) => dispatch(addDocumentsToCustomerCollectionForDF(customerUID, downloadUrls, uid, customerId, selectedCustomer))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(documentsStyle)
)(AddNewDocumentsModalContent);
