/*eslint-disable*/
const customers = []
const initDataRetrieved = false

export const initState = {
  customers,
  initDataRetrieved
}


export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_CUSTOMERS':
      var toReturn = {...state}
      toReturn.customers = action.data
      toReturn.initDataRetrieved = true
      return toReturn

    default:
      return state
  }
}
