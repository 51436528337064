import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import NegativeItemsForm from "./NegativeItemsForm";
import NegativeItemsTable from "./NegativeItemsTable";

import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import withStyles from "@material-ui/core/styles/withStyles";

import styles from "assets/jss/material-dashboard-pro-react/views/customerDetailStyle";
import { db } from "firebase/client";

class NegativeItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    loadNegativeItems = (customerId) => {
        console.log("loading negative items")
        db.collection("customers")
            .doc(customerId)
            .collection("negativeItems").orderBy("createdAt")
            .onSnapshot(querySnapshot => {
                const snapshot = querySnapshot.docs;
                const negativeItems = [];

                for (let i = 0; i < snapshot.length; i++) {
                    const docData = {
                        ...snapshot[i].data(),
                        id: snapshot[i].id
                    }
                    negativeItems.push(docData)
                }
                this.setState({
                    negativeItemsData: negativeItems,
                    negativeItemsDataLoaded: true
                })
            })
    }

    render() {

        const { classes } = this.props;

        if (!this.state.negativeItemsDataLoaded) {
            this.loadNegativeItems(this.props.customerId)
        }

        return this.state.negativeItemsDataLoaded ? (
            <GridContainer>
                {console.log(this.state)}
                <h1>Negative Items</h1>
                {this.state.negativeItemsData.map((item) => (
                    <GridItem xs={12}>
                        <Card style={{ marginBottom: "0" }} >{console.log(item.createdAt.toDate())}
                            <div className={classes.whiteCard}>
                                <p style={{ fontWeight: "300" }}>{item.content}</p>
                                <p>{item.createdBy} <span style={{ color: "#999", fontSize: "10px" }}>{item.createdAt.toDate().toString()}</span></p>
                            </div>
                        </Card>
                    </GridItem>
                ))}

                <NegativeItemsForm
                    customerId={this.props.customerId} user={this.props.authUser}
                />
                <NegativeItemsTable
                    data={this.state.data}
                    header={[
                        {
                            name: "Account Name",
                            prop: "accountName"
                        },
                        {
                            name: "Account Number",
                            prop: "accountNumber"
                        },
                        {
                            name: "Code",
                            prop: "code"
                        },
                        {
                            name: "Transunion",
                            prop: "transunion"
                        },
                        {
                            name: "Experian",
                            prop: "experian"
                        },
                        {
                            name: "Equifax",
                            prop: "equifax"
                        },
                        {
                            name: "Notes",
                            prop: "notes"
                        },
                    ]}
                />
            </GridContainer>
        ) : (
            <p className={classes.body}>Loading...</p>
        );
    }
}

const mapStateToProps = (state) => {
    // console.log(state);
    return {
        authUser: state.auth.user,
        // user: state.profile.user,
    };
};

const mapDispatchToProps = (dispatch) => {

};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(NegativeItems);