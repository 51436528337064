/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import ReactPaginate from 'react-paginate';
import "./pagination.css"


import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import BoundSearchControl from "../../../components/Explorer/BoundSearchControl";
import BoundSearchDialog from "../../../components/Explorer/BoundSearchDialog";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from 'components/CustomInput/CustomInput.js';
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import Hidden from "@material-ui/core/Hidden";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Link } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CardFields from '../../../components/Card/CardFields';
import CardField from '../../../components/Card/CardField';
import actionsForNavigation from "store/actions/navigation";
import { connect } from "react-redux";
import { MockUserData } from 'variables/config';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import Close from "@material-ui/icons/Close";
import { getLeads, deleteLead, convertLeadToCustomer, addLead } from "store/actions/leadsActions"
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import explorerPageStyle from "assets/jss/material-dashboard-pro-react/views/explorerPageStyle.js";
import loader from 'assets/img/loader.gif';

import AddLogo from 'assets/img/market-me/addLogo.png'
import EditLogo from 'assets/img/market-me/editLogo.png'
import TrashLogo from 'assets/img/market-me/trashLogo.png'
import { Checkbox, Radio, RadioGroup, TextField } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";


const Explorer = BoundExplorer(actionsForExplorer, accessorsForExplorer);
const ExplorerCard = BoundExplorerCard(actionsForExplorer.recordset, accessorsForExplorer.recordset);
const SearchControl = BoundSearchControl(actionsForExplorer, accessorsForExplorer);
const SearchDialog = BoundSearchDialog(actionsForExplorer, accessorsForExplorer);

const useStyles = makeStyles(explorerPageStyle)

function CustomExplorerCard(props) {

  const classes = useStyles();

  return (
    <ExplorerCard {...props}></ExplorerCard>
  )
}


const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};



export function DemographicsPage(props) {
  const { classes, uid, history, initDataRetrieved, user, demographicsData, initLeadsDataRetrieved, leadsIds } = props
  const [data, setData] = useState([])
  const [leadsData, setLeadsData] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)
  const [areYouSureDeleteModal, setAreYouSureDeleteModal] = useState(false)
  const [selectedLead, setSelectedLead] = useState({})
  const [areYouSureConvertCustomer, setAreYouSureConvertCustomer] = useState(false)
  const [convertedLeadSnackbar, setConvertedLeadSnackbar] = useState(false)
  const [successSnackbarOnReroute, setSuccessSnackbarOnReroute] = useState(false)
  const [areYouSureAddLead, setAreYouSureAddLead] = useState(false)
  const [addedLeadSnackbar, setAddedLeadSnackbar] = useState(false)
  const [file, setFile] = useState('')
  const [importModal, setImportModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [value, setValue] = useState('')
  const [filterError, setFilterError] = useState(false)
  const location = useLocation();
  const [pageNumber, setPageNumber] = useState(0);
  const [storage, setStorage] = useState([])
  const itemsPerPage = 50;
  const [index, setIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(itemsPerPage)
  const [searchValue, setSearchValue] = useState('')
  const [searchData, setSearchData] = useState([])
  const [searching, setSearching] = useState(false)
  const [listId, setListId] = useState('')
  const [listArr, setListArr] = useState([])
  const [modalName, setModalName] = useState(false)
  const [listName, setListName] = useState('')
  const [addToListErr, setAddToListErr] = useState(false)
  useEffect(() => {
    if (props.dataLoaded && !dataLoaded) {
      setStorage(props.demographicsData)
      setData(props.demographicsData.slice(0, itemsPerPage))
      setDataLoaded(true)
    }

  }, [props])


  useEffect(() => {
    if (location.state && location.state.succesfulConversion && !successSnackbarOnReroute) {
      setConvertedLeadSnackbar(true)
    }
  }, [location])

  useEffect(() => {
    if (file) {
      if (file.type.split("/")[1] != "csv") {
        setErrorModal(true)
      }
      else {
        setImportModal(true)
      }
    }
  }, [file])


  const onSearchInput = (query) => {
    var input = query.toLowerCase()
    const term = input.trim();
    setFilterError(false)
    if (term && value === "name") {
      var result = storage.filter((d) => {
        if (d.firstName && d.firstName.toLowerCase().includes(input) || d.lastName && d.lastName.toLowerCase().includes(input)
          || d.Name && d.Name.toLowerCase().includes(input)) {
          return true
        }
      })
      setSearchData(result)
    }
    else if (term && value === "email") {
      var result = storage.filter((d) => {
        if (d.email && d.email.toLowerCase().includes(input) || d.Email && d.Email.toLowerCase().includes(input)) {
          return true
        }
      })
      setSearchData(result)
    }
    else if (term && value === "company") {
      var result = storage.filter((d) => {
        if (d.companyName && d.companyName.toLowerCase().includes(input) || d.CompanyName && d.CompanyName.toLowerCase().includes(input)) {
          return true
        }
      })
      setSearchData(result)
    }
    else if (term && value === "number") {
      var result = storage.filter((d) => {
        if (d.cellPhone && d.cellPhone.toLowerCase().includes(input)) {
          return true
        }
      })
      setSearchData(result)
    }
    else if (term && !value) {
      setFilterError(true)
    }
    else {
      setData(storage.slice(index, endIndex))
    }
  };

  const tableHeaders = ['Name', "Email Address", "Phone Number", "Address", "City", "State", "Zip Code", "Actions"]



  const renderTableHeader = () => {
    return tableHeaders.map((key, index) => {
      return <th style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" }} className={classes.tableHeader} key={index}>{key}  </th>
    })
  }

  const renderTableData = () => {
    return data
      .map((d, index) => {
        return (
          <tr style={index % 2 === 1 ? { background: "rgba(242, 242, 242, 1)", marginBottom: "20px", height: "150px", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" } : { height: "150px", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" }}>
            <td className={classes.tableCell}>
              {d.Name ?
                d.Name : d.firstName + ' ' + d.lastName}
            </td>
            <td className={classes.tableCell}>
              {d.Email ?
                d.Email : d.email}
            </td>
            <td className={classes.tableCell}>
              {d.PhoneNumber ?
                d.PhoneNumber : d.cellPhone}
            </td>
            <td className={classes.tableCell}>
              {d.StAddress2 ?
                d.StAddress + ' ' + d.StAddress2 : d.StAddress}
            </td>
            <td className={classes.tableCell}>
              {d.City}
            </td>
            <td className={classes.tableCell}>
              {d.State}
            </td>
            <td className={classes.tableCell}>
              {d.Zipcode}
            </td>
            <td style={{ display: "flex", width: "100%", justifyContent: "space-around", height: "150px", alignItems: "center" }} className={classes.tableCell}>
              <Button onClick={() => history.push(`/admin/lead-detail/${d.id}`, { leadData: d })} className={classes.viewDetailsBtn}>
                <p style={{ margin: "0px" }}>View</p>
                <OpenInNewIcon style={{ color: "black" }} />
              </Button>

              {leadsIds.includes(d.id) ? (
                <Button onClick={() => {
                  setSelectedLead(d)
                  setAreYouSureDeleteModal(true)
                  // setAreYouSureAddLead(true)
                  // setAreYouSureConvertCustomer(true)
                }} style={{ backgroundColor: "red" }} className={classes.viewDetailsBtn}>
                  <p style={{ color: "white", margin: "0px" }}>Remove Lead</p>
                  <AddIcon style={{ color: "white" }} />
                </Button>
              ) : (
                <Button onClick={() => {
                  setSelectedLead(d)
                  setAreYouSureAddLead(true)
                  // setAreYouSureConvertCustomer(true)
                }} style={{ backgroundColor: "rgba(39, 75, 115, 1)" }} className={classes.viewDetailsBtn}>
                  <p style={{ color: "white", margin: "0px" }}>Add As Lead</p>
                  <AddIcon style={{ color: "white" }} />
                </Button>
              )}
              {/* <Button onClick={() => {
              setSelectedLead(d)
              setAreYouSureConvertCustomer(true)
            }} style={{ backgroundColor: "rgba(39, 75, 115, 1)" }} className={classes.viewDetailsBtn}>
              <p style={{ color: "white", margin: "0px" }}>Convert</p>
              <AddIcon style={{ color: "white" }} />
            </Button> */}
              {/* <Button onClick={() => {
              setSelectedLead(d)
              setAreYouSureDeleteModal(true)
            }} style={{ backgroundColor: "rgba(188, 70, 70, 1)" }} className={classes.viewDetailsBtn}>
              <p style={{ color: "white", margin: "0px" }}>Remove</p>
              <DeleteIcon style={{ color: "white" }} />
            </Button> */}
            </td>
          </tr>
        )
      })
  }

  const renderSearchData = () => {
    return searchData
      .slice(pageNumber * itemsPerPage, (pageNumber * itemsPerPage) + itemsPerPage)
      .map((d, index) => {
        return (
          <tr style={index % 2 === 1 ? { background: "rgba(242, 242, 242, 1)", marginBottom: "20px", height: "150px", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" } : { height: "150px", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" }}>
            <td className={classes.tableCell}>
              {d.Name ?
                d.Name : d.firstName + ' ' + d.lastName}
            </td>
            <td className={classes.tableCell}>
              {d.Email ?
                d.Email : d.email}
            </td>
            <td className={classes.tableCell}>
              {d.PhoneNumber ?
                d.PhoneNumber : d.cellPhone}
            </td>
            <td className={classes.tableCell}>
              {d.StAddress2 ?
                d.StAddress + ' ' + d.StAddress2 : d.StAddress}
            </td>
            <td className={classes.tableCell}>
              {d.City}
            </td>
            <td className={classes.tableCell}>
              {d.State}
            </td>
            <td className={classes.tableCell}>
              {d.Zipcode}
            </td>
            <td style={{ display: "flex", width: "100%", justifyContent: "space-around", height: "150px", alignItems: "center" }} className={classes.tableCell}>
              <Button onClick={() => history.push(`/admin/demographics/${d.id}`, { leadData: d })} className={classes.viewDetailsBtn}>
                <p style={{ margin: "0px" }}>View</p>
                <OpenInNewIcon style={{ color: "black" }} />
              </Button>



              {leadsIds.includes(d.id) ? (
                <Button onClick={() => {
                  setSelectedLead(d)
                  setAreYouSureDeleteModal(true)
                  // setAreYouSureAddLead(true)
                  // setAreYouSureConvertCustomer(true)
                }} style={{ backgroundColor: "red" }} className={classes.viewDetailsBtn}>
                  <p style={{ color: "white", margin: "0px" }}>Remove Lead</p>
                  <AddIcon style={{ color: "white" }} />
                </Button>
              ) : (
                <Button onClick={() => {
                  setSelectedLead(d)
                  setAreYouSureAddLead(true)
                  // setAreYouSureConvertCustomer(true)
                }} style={{ backgroundColor: "rgba(39, 75, 115, 1)" }} className={classes.viewDetailsBtn}>
                  <p style={{ color: "white", margin: "0px" }}>Add As Lead</p>
                  <AddIcon style={{ color: "white" }} />
                </Button>
              )}
            </td>
          </tr>
        )
      })
  }
  const handleOnChange = (e) => {
    setFile(e.target.files[0])
  };

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Company Name", key: "compName" },
    { label: "Phone Number", key: "number" }
  ]

  const csvData = [];

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).replace(/ /g, "").replace("\r", "").split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).replace("\r", "").split("\n");

    const array = csvRows.map(i => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        if (header === "PhoneNumber") {
          object[header] = values[index].replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
        }
        else {
          object[header] = values[index];
        }
        return object;
      }, {});
      db.collection('users').doc(uid).collection('leads').add(obj)
        .then(function (docRef) {
          db.collection('users').doc(uid).collection('leads').doc(docRef.id).update({
            id: docRef.id
          })
            .then(() => {
              window.location.reload()
            })
        })
      return obj;
    });

    const handleOnSubmit = (e) => {
      e.preventDefault();
      if (file) {
        fileReader.onload = function (event) {
          const text = event.target.result;
          csvFileToArray(text);
        };

        fileReader.readAsText(file);
      }
    };
  };

  // Invoke when user click to request another page.
  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage)
    setEndIndex((selected * itemsPerPage) + itemsPerPage)

  };

  useEffect(() => {
    setData(storage.slice(index, endIndex))
  }, [index])

  async function getInfo() {
    await db.collection('users').doc(uid).collection('lists').doc(uid).collection('Leads')
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data())
        });
        setListArr(items)
      });
  }

  useEffect(() => {
    getInfo()
  })

  const createList = async (e) => {
    e.preventDefault();
    await db.collection('users').doc(uid).collection('lists').doc(uid).collection('Leads').add({
      listName: listName
    })
      .then(function (docRef) {
        db.collection('users').doc(uid).collection('lists').doc(uid).collection('Leads').doc(docRef.id).update({
          listID: docRef.id
        })
      })
      .then(() => {
        setNameModal(false)
      })
  }

  const addLeadstoList = () => {
    const data = {
      info: selectedLead,
      id: selectedLead.id
    }
    if (!listId) {
      setAddToListErr(true)
    }
    else {
      db.collection('users').doc(uid).collection('lists').doc(uid).collection('Leads').doc(listId).set({
        list: firebase.firestore.FieldValue.arrayUnion(data),
      }, { merge: true })
        .then(() => {
          setAreYouSureAddLead(false)
          setAddedLeadSnackbar(true)
        })
    }

  }
  return (
    <div className={classes.mainContainer}>


      <Hidden className={classes.fullWidth} smDown implementation="css">
        <GridContainer>
          <GridItem xs={6}>
            <p className={classes.subTitle}>
              Review information about potential clients and reach out to them
            </p>
          </GridItem>
          <GridItem xs={6}
            style={{
              justifyContent: "flex-end",
              display: "flex",
              verticalAlign: "center",
              marginTop: "-50px",
            }}>
            <Button
              className={classes.newButton}
              component="label"
              style={{ marginRight: "20px", height: "60px", width:"150px", backgroundColor: "#13294C" }}
            >
              Import CSV <input type="file" hidden accept={".csv"} onChange={handleOnChange} />
            </Button>
            <Button
              className={classes.newButton}
              style={{ marginRight: "20px", height: "60px", width:"150px", backgroundColor: "#13294C" }}
            >
              <CSVLink headers={headers} data={csvData} style={{ color: "white", width: "200px" }}
              >
                Export CSV Sample
              </CSVLink>
            </Button>
          </GridItem>
        </GridContainer>
      </Hidden>


      <Card className={classes.filterCardBody}>
        <Card
          style={{
            background: "linear-gradient(108.56deg, #274B73 39.14%, #005479 64.5%)",
            padding: "10px",
            marginTop: "-20px"
          }}
        >
          <div style={{ display: "flex" }}>
            <TextField
              variant="outlined"
              fullWidth
              InputProps={{
                placeholder: "Search",
                inputProps: {
                  onChange: (e) => setSearchValue(e.target.value),
                },
                style: { color: "white" }
              }}
            />
            <Button style={{ width: "10%", marginLeft: "50px", background: "#f5c107" }}
              onClick={() => {
                setSearching(true)
                onSearchInput(searchValue)
              }
              }>
              Search
            </Button>
          </div>
        </Card>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ textColor: "black", padding: "15px", marginTop: "-20px" }}
        >
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>Filter by:</p>
          {filterError ? <p style={{ fontSize: "16px", fontWeight: "bold", color: "red" }}>Please choose an option below before searching.</p> : null}
          <div style={{ display: "flex" }}>
            <FormControlLabel style={{ color: "black" }} value="name" control={<Radio style={{ color: "#13294C" }} />} label="Name" />
            <FormControlLabel style={{ color: "black" }} value="email" control={<Radio style={{ color: "#13294C" }} />} label="Email" />
            <FormControlLabel style={{ color: "black" }} value="number" control={<Radio style={{ color: "#13294C" }} />} label="Number" />
            <FormControlLabel style={{ color: "black" }} value="address" control={<Radio style={{ color: "#13294C" }} />} label="Address" />
            <FormControlLabel style={{ color: "black" }} value="city" control={<Radio style={{ color: "#13294C" }} />} label="City" />
            <FormControlLabel style={{ color: "black" }} value="state" control={<Radio style={{ color: "#13294C" }} />} label="State" />
            <FormControlLabel style={{ color: "black" }} value="zipcode" control={<Radio style={{ color: "#13294C" }} />} label="Zip Code" />
            <Button
              style={{ background: "#f5c107" }}
              onClick={() => {
                setSearching(false)
                setValue('')
                setPageNumber(0)
                setIndex(0)
              }}
            >
              <Clear />
              Clear Filter
            </Button>
          </div>
        </RadioGroup>
      </Card>
      <Card className={classes.customerCardBody}>
        <CardHeader className={classes.cardHeader}>
          Global Leads Database
        </CardHeader>

        <Hidden mdUp implementation="css">
          <table style={{ width: "100%" }}>
            <tbody>
              {data.map((d, index) => {
                return (
                  <div style={{ width: "90%", margin: "10px auto" }}>
                    <div style={{ display: "flex", justifyContent: 'space-between', marginBottom: "50px", height: "150px", margin: "10px auto", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" }}>
                      <div style={{ display: "flex", height: "50px", width: "100%", padding: "5px", height: "150px" }}>
                        {/* <div style={{ width: "30%", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "20px" }}>
                                                <img style={{ width: "auto", borderRadius: "100%", marginBottom: "10px", maxHeight: '115px' }} src={d.profilePic ? d.profilePic : avatar} />
                                                </div> */}
                        <div style={{ display: "flex", flexDirection: "column", width: "70%", justifyContent: "space-evenly" }}>
                          <p>
                            <b style={{ fontSize: "16px", marginRight: "10px" }}>
                              Name:
                            </b>
                            {d.firstName + ' ' + d.lastName}
                          </p>
                          <p>
                            <b style={{ fontSize: "16px", marginRight: "10px" }}>
                              Email:
                            </b>
                            {' ' + d.email}
                          </p>
                          <p>
                            <b style={{ fontSize: "16px", marginRight: "10px" }}>
                              Cell Phone:
                            </b>
                            {d.cellPhone ? d.cellPhone : "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex" }}>
                      <Button onClick={() => history.push(`/admin/demographics/${d.id}`, { leadData: d })} className={classes.viewDetailsBtn}>
                        <p style={{ margin: "0px" }}>View</p>
                        <OpenInNewIcon style={{ color: "black" }} />
                      </Button>
                      {/* <Button onClick={() => {
                        setSelectedLead(d)
                        setAreYouSureConvertCustomer(true)
                      }} style={{ backgroundColor: "rgba(39, 75, 115, 1)" }} className={classes.viewDetailsBtn}>
                        <p style={{ color: "white", margin: "0px" }}>Convert</p>
                        <AddIcon style={{ color: "white" }} />
                      </Button> */}


                      {leadsIds.includes(d.id) ? (
                        <Button onClick={() => {
                          setSelectedLead(d)
                          // setAreYouSureAddLead(true)
                          // setAreYouSureConvertCustomer(true)
                        }} style={{ backgroundColor: "red" }} className={classes.viewDetailsBtn}>
                          <p style={{ color: "white", margin: "0px" }}>Remove Lead</p>
                          <AddIcon style={{ color: "white" }} />
                        </Button>
                      ) : (
                        <Button onClick={() => {
                          setSelectedLead(d)
                          setAreYouSureAddLead(true)
                          // setAreYouSureConvertCustomer(true)
                        }} style={{ backgroundColor: "rgba(39, 75, 115, 1)" }} className={classes.viewDetailsBtn}>
                          <p style={{ color: "white", margin: "0px" }}>Add As Lead</p>
                          <AddIcon style={{ color: "white" }} />
                        </Button>
                      )}

                    </div>
                    <div style={{ width: "90%", height: "2px", background: "linear-gradient(89.97deg, rgba(238, 189, 9, 1) 0.99%, rgba(86, 163, 166, 1) 99.97%)", margin: "30px auto" }}>
                    </div>
                  </div>
                )
              })}
            </tbody>
          </table>
        </Hidden>




        <Hidden smDown implementation="css">
          <table style={{ width: "100%" }}>
            {dataLoaded ?
              <tbody>
                <tr style={{ height: "50px", width: "100%" }}>{renderTableHeader()} </tr>

                {searching ? renderSearchData()
                  : renderTableData()}

              </tbody>
              :
              <img
                style={{
                  margin: "10% 0% 0% 40%",
                  position: "center",
                  width: "20%"
                }}
                src={loader}
                alt="..."
              />
            }
          </table>
        </Hidden>
      </Card>
      <div>
        <ReactPaginate
          onPageChange={handlePageClick}
          pageCount={searching ? Math.ceil(searchData.length / itemsPerPage)
            : Math.ceil(storage.length / itemsPerPage)}
          previousLabel={"Previous"}
          nextLabel={"Next"}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>


      <Dialog
        classes={{
          // root: classes.modalRoot,
          // paper: classes.modal + " " + classes.modalSmall
        }}
        open={importModal}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => setImportModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setImportModal(false)
              setFile('')
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>

          <h4>Import {file.name}? </h4>

        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.modalRoot,
          }}
          style={{ width: "100%" }}
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
            <Button
              className={classes.newButton}
              onClick={(e) => {
                handleOnSubmit(e);
                setImportModal(false)
              }}
              style={{ backgroundColor: "rgb(86, 163, 166)", fontSize: "20px" }}>
              Yes
            </Button>
            <Button
              className={classes.newButton}
              style={{ fontSize: "20px", backgroundColor: "#f5c107" }}
              onClick={() => {
                setImportModal(false)
                setFile('')
              }}>
              No
            </Button>
          </div>

        </DialogContent>
      </Dialog>

      <Dialog
        classes={{
          // root: classes.modalRoot,
          // paper: classes.modal + " " + classes.modalSmall
        }}
        open={errorModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setErrorModal(false)
              setFile('')
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>

          <h4 style={{ color: "red", fontWeight: 500 }}>Error! File Isn't a .CSV File </h4>

        </DialogTitle>
        <center>
          <Button
            className={classes.newButton}
            component="label"
            style={{ marginRight: "20px", height: "60px", backgroundColor: "#1D9BC9" }}
          >
            Choose Different File
            <input type="file" hidden accept={".csv"} onChange={handleOnChange} onClick={() => setErrorModal(false)} />
          </Button>
        </center>
      </Dialog>



      <Dialog
        style={{ width: "100%" }}
        classes={{
          // root: classes.modalRoot,
          // paper: classes.modal + " " + classes.modalSmall
        }}
        fullWidth={true}
        open={areYouSureAddLead}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setAreYouSureAddLead(false)
              setListId()
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>

          <h3>Are You Sure You Want To Add {selectedLead.Name ? selectedLead.Name : null} As A Lead?</h3>

        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.modalRoot,
          }}
          style={{ width: "100%" }}
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <center>
            <p style={{ fontSize: "16px" }}>Please select a list to add lead to</p>
            <FormControl
              className={classes.formControl}
            >
              <Select
                style={{ width: "250px", marginLeft: "10px", marginTop: "10px", }}
                disableUn
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  formControl: classes.addUserInput
                }}
                onChange={(e) => {
                  setListId(e.target.value)
                  // setModalListName(e.target.name)
                  // getList(e.target.value)
                  // clearArrays()
                }}
                value={listId}
              >
                {listArr.map((d, index) => {
                  return (
                    <MenuItem
                      key={index}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple
                      }}
                      value={d.listID}
                    >
                      {d.listName}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <Button
              onClick={() => {
                setModalName(true)
              }}
              style={{ marginLeft: "10px", width: "20%" }}>
              Create New List
            </Button>
          </center>
          <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
            <Button
              className={classes.newButton}
              onClick={() => {
                addLeadstoList()
              }}
              style={{ fontSize: "20px" }}
            >
              Yes
            </Button>
            <Button
              className={classes.newButton}
              onClick={() => {
                setAreYouSureAddLead(false)
                setListId('')
              }}
              style={{ fontSize: "20px", backgroundColor: "#f5c107" }}
            >
              No
            </Button>
          </div>
          <center>
            {addToListErr ? <p style={{ color: "red", fontWeight: "bold" }}>Please select a list</p> : null}
          </center>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalName}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setModalName(false)
              setListName('')
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>
          <h4>Enter Your List Name</h4>
        </DialogTitle>

        <form onSubmit={createList}>
          <DialogContent>
            <TextField
              required
              fullWidth
              id="name"
              label="List Name"
              value={listName}
              InputProps={{
                classes: {
                  root: classes.textFieldRoot,
                },
                inputProps: {
                  onChange: e => setListName(e.target.value)
                }
              }}
              className={classes.textFieldRoot}
              variant="outlined"
            />
          </DialogContent>
          <center>
            <Button
              className={classes.newButton}
              type="submit"
              style={{ backgroundColor: "rgb(86, 163, 166)", fontSize: "20px", height: "50px", marginBottom: "10px" }}
            >
              Submit
            </Button>
          </center>
        </form>
      </Dialog>



      <Dialog
        style={{ width: "100%" }}
        classes={{
          // root: classes.modalRoot,
          // paper: classes.modal + " " + classes.modalSmall
        }}
        fullWidth={true}
        open={areYouSureConvertCustomer}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAreYouSureConvertCustomer(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setAreYouSureConvertCustomer(false)
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>

          <h3>Are You Sure You Want To Convert {selectedLead.firstName + ' ' + selectedLead.lastName} To A Customer?</h3>

        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.modalRoot,
          }}
          style={{ width: "100%" }}
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
            <Button onClick={() => {
              props.convertLeadToCustomer(selectedLead, uid, user)
              setAreYouSureConvertCustomer(false)
              setConvertedLeadSnackbar(true)
            }} style={{ backgroundColor: "rgb(86, 163, 166)" }}>
              Yes
            </Button>
            <Button onClick={() => setAreYouSureConvertCustomer(false)}>
              No
            </Button>
          </div>

        </DialogContent>
      </Dialog>


      <Dialog
        style={{ width: "100%" }}
        classes={{
          // root: classes.modalRoot,
          // paper: classes.modal + " " + classes.modalSmall
        }}
        fullWidth={true}
        open={areYouSureDeleteModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAreYouSureDeleteModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setAreYouSureDeleteModal(false)
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>

          <h3>Are You Sure You Want To Remove {selectedLead.Name ? selectedLead.Name : null} As A Lead?</h3>

        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.modalRoot,
          }}
          style={{ width: "100%" }}
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
            <Button
              className={classes.newButton}
              onClick={() => {
                props.deleteLead(uid, selectedLead.id)
                setAreYouSureDeleteModal(false)
              }} style={{ fontSize: "20px", }}>
              Yes
            </Button>
            <Button
              className={classes.newButton}
              onClick={() => setAreYouSureDeleteModal(false)}
              style={{ fontSize: "20px", backgroundColor: "#f5c107" }}
            >
              No
            </Button>
          </div>

        </DialogContent>
      </Dialog>



      <Snackbar open={addedLeadSnackbar} onClose={() => setAddedLeadSnackbar(false)}>
        <Alert onClose={() => setAddedLeadSnackbar(false)} severity='success'>
          Lead Added To Your Contacts
        </Alert>
      </Snackbar>

      <Snackbar open={convertedLeadSnackbar} onClose={() => setConvertedLeadSnackbar(false)}>
        <Alert onClose={() => setConvertedLeadSnackbar(false)} severity='success'>
          An Invite Link Has Been Sent To The Lead
        </Alert>
      </Snackbar>
    </div >
  );
}

function mapStateToProps(state) {
  console.log('checking state.leads.leads', state)
  return {
    firstName: state.auth.user.firstName,
    lastName: state.auth.user.lastName,
    records: state.recordset.leads.records,
    uid: state.auth.user.uid,
    initLeadsDataRetrieved: state.leads.initDataRetrieved,
    leadsData: state.leads.leads,
    user: state.auth.user,
    leadsIds: state.leads.leadsIds
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLeads: (uid) => dispatch(getLeads(uid)),
    deleteLead: (uid, leadId) => dispatch(deleteLead(uid, leadId)),
    convertLeadToCustomer: (leadData, uid, user) => dispatch(convertLeadToCustomer(leadData, uid, user)),
    addLead: (uid, newLead, allLeads) => dispatch(addLead(uid, newLead, allLeads))
  }
}

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(explorerPageStyle)
)(DemographicsPage));
