import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Remove from '@material-ui/icons/Remove';
import LaunchIcon from '@material-ui/icons/Launch';
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';

const useStyles = makeStyles(styles);

const CoinList = (props) => {
  const classes = useStyles();
  const { coins } = props;
  const tableData = coins.map((coin) => {
    return [coin.name, coin.coinAmount, coin.usdAmount];
  });

  const actionButtons = [
    { color: 'info', icon: LaunchIcon },
    { color: 'danger', icon: Remove },
  ].map((prop, key) => {
    return (
      <Button color={prop.color} className={classes.actionButton} key={key}>
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  return (
    <GridContainer>
      <GridItem
        sx={{
          direction: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          maxHeight: '250px',
        }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Card style={{ margin: '25px 0px 15px 0px' }}>
          <CardBody style={{ padding: '5px 5px' }}>
            <Table
              // tableHead={['Name', 'Coins', 'USD']}
              tableData={tableData}
              customCellClasses={[classes.center, classes.right, classes.right]}
              customClassesForCells={[0, 4, 5]}
              customHeadCellClasses={[
                classes.center,
                classes.right,
                classes.right,
              ]}
              customHeadClassesForCells={[0, 4, 5]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

CoinList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default CoinList;
