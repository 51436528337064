import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { db, firebase, sendSMS } from "firebase/client.js";

import moment from 'moment';
import { Link } from 'react-router-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import Table from "components/Table/Table";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";

import Style from "assets/jss/material-dashboard-pro-react/views/textBlastStyle";
import { Grid } from "@material-ui/core";

// TODO:
// import { startSetUsersList } from "store/actions/adminActions"
import CreateDocumentForm from "components/Form/CreateDocumentForm";

const Transition = (props) => {
    return <Slide direction="down" {...props} />
}

class TextBlast extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            assignActionModal: false,
            volUID: '',
            username: '',
            driverSelect: '',
            driverPhone: '',
            driverGroup: [],
            dataRefreshed: false,
            showDriverList: false,
            phones: [],
            phoneNumbers: '',
            selectedList: null,
        };
    }

    // Loads data from users from admin.users (redux)
    // What data do we need for MarketMe?
    loadData = () => {
        let usersData = this.props.users.dataRows.map((prop, key) => {
            let colObj = { ...prop }
            const volUID = colObj.volUID
            const username = colObj.username
            // Load Data for Summary Cards and add buttons
            return {
                ...colObj,
                // status: (<span style={{ color: "red" }}>assigned</span>),
                id: key,
                actions: (
                    <Button
                        color="primary"
                        fullWidth
                        onClick={
                            () => this.handleClickOpen("assignActionModal",
                                volUID
                            )}
                    >ASSIGN ACTION</Button>)
            }
        })

        this.setState({
            usersData,
            dataLoaded: true
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleNewChange = (field, value) => {
        this.setState({
            [field]: value
        })
        this.setState({
            phoneNumbers: this.props.listsData[value].list.map((customer) => {
                return customer.phoneNumber;
            }).join()
        })
    }

    // Adds new phone number to local state phone list
    addNewPhone = () => {
        let newPhoneList = this.state.phones
        newPhoneList.push(this.state.newPhone)
        console.log(newPhoneList)
        this.setState({
            phones: newPhoneList,
            newPhone: ""
        })
    }

    // Gets driver info from state, adds to local state
    addDrivers = e => {

        const driverGroup = this.state.driverGroup;
        const driverObj = this.state.driverPhoneData[e.target.value + driverGroup.length];
        const driverPhone = `${this.state.driverPhone}${this.state.driverPhone && ','}${driverObj.phone}`;
        console.log(e.target, driverObj, driverPhone);
        driverGroup.push(driverObj);

        this.setState({
            driverSelect: -1,
            driverGroup: driverGroup,
            driverPhone: driverPhone,
        }, () => { console.log(this.state) });

    }

    // Removes group member from local state
    removeGroupMember = member => {
        const driverGroup = this.state.driverGroup;
        const phoneNumbers = this.state.driverPhone.split(",");
        const index = driverGroup.findIndex(driver => driver.driverName === member.driverName);
        phoneNumbers.splice(index, 1);
        driverGroup.splice(index, 1);

        this.setState({
            driverGroup: driverGroup,
            driverPhone: phoneNumbers.join(',')
        });

    }


    // filterData = (query, casesData) => {
    //     if (casesData) {
    //       var newArray = casesData.filter(function (el) {
    //         return el.clientFirstName.includes(query)
    //       });
    //       console.log(newArray);
    //           this.setState({
    //             filteredCasesData: newArray,
    //             dataFiltered:
    //           })

    //     }

    // }

    // Gets phone data from Firestore collection 'driverPhone', parses and sets to state
    // Which collection to pull numbers from?
    loadDriverPhoneData = () => {
        let data = db.collection("driverPhone")
            .get().then((snapshot) => {
                //console.log(snapshot)
                const data = []
                // Parse data into array like in firebase.js
                snapshot.forEach((doc) => {
                    var docData = doc.data()
                    var docObj = {
                        phone: String(doc.driverPhone).replace(/[^+\d]+/g, ""),
                        id: doc.id,
                        ...docData
                    }
                    data.push(docObj);
                })
                return (data)
            }, (error) => {
                console.log('error fetching data: ', error)
            }).then(data => {
                this.setState({
                    driverPhoneData: data,
                    driverPhoneDataLoaded: true,
                })
            })
    }

    editPhoneNumbers = (phoneString) => {
        this.setState({ phoneNumbers: phoneString });
    }

    sendText = async () => {
        const phoneArray = this.state.phoneNumbers.split(',');
        const body = this.state.txtMessage;
        const from = '+19148773057';
        for (let i = 0; i < phoneArray.length; i++) {
            let to = `+1${phoneArray[i]}`;
            const returnSMS = await sendSMS({
                body, from, to
            })
        }
        this.setState({
            textBlastSent: true,
            txtMessage: '',
            driverPhone: ''
        })
    }

    render() {
        const { AuthBool, classes, query } = this.props; // Removed users prop
        const searchButton =
        classes.top +
        " " +
        classes.searchButton;
        if (!this.state.driverPhoneDataLoaded) {
            this.loadDriverPhoneData()
        }

        let filteredCollectionData = []
        if (this.state[this.state.pathLoadedField]) {
            var newArray = this.state[this.state.pathField].filter(function (el) {
                return (el.id && el.id.includes(query)) || (el.FIRST_NAME && el.FIRST_NAME.includes(query)) || (el.FIRST_NAME && el.FIRST_NAME.toLowerCase().includes(query)) || (el.LAST_NAME && el.LAST_NAME.includes(query)) || (el.LAST_NAME && el.LAST_NAME.toLowerCase().includes(query)) || (el.city && el.city.includes(query)) || (el.city && el.city.toLowerCase().includes(query)) || (el.state && el.state.includes(query))
            });
            // console.log(newArray);
            filteredCollectionData = newArray
        }
        // if(cases) {this.filterData(query, cases.dataRows)}
        let type = ""
        if (this.props.path == "drivers") {
            type = "driver"
        } else {
            type = "vendor"
        }

        var driversToSelect = [];

        if (this.state.driverPhoneData) {

            driversToSelect = this.state.driverPhoneData.filter(driver => this.state.driverGroup.findIndex(drvr => drvr.driverName === driver.driverName) === -1);

        }
        return (
            <div>
                {
                        <div>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <GridContainer>

                                        <GridItem>
                                            <CustomInput
                                                labelText="Phone Numbers"
                                                id="phoneNumbers"
                                                labelProps={{
                                                    shrink: true,
                                                    color: 'black'
                                                }}
                                                inputProps={{
                                                    type: "text",
                                                    onChange: (e) => this.editPhoneNumbers(e.target.value),
                                                    value: this.state.phoneNumbers
                                                }}
                                            />

                                        </GridItem>

                                        <GridItem xs={12}>
                                            <CustomInput
                                                labelText="Message"
                                                id="txtMessage"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                labelProps={{
                                                    shrink: true,
                                                    color: 'black'
                                                }}
                                                inputProps={{
                                                    type: "text",
                                                    onChange: (event) => this.handleChange(event),
                                                    value: this.state.txtMessage
                                                    //defaultValue: this.props.vendor.vendorType ? this.props.vendor.vendorType : ''
                                                }}
                                            />
                                            <Button
                                                color="linear-gradient(180deg, rgba(253,245,52,1) 0%, rgba(235,226,49,1) 63%)"
                                                onClick={

                                                    () => this.sendText()}
                                            >TEXT CUSTOMER(S)</Button>
                                            {this.state.showDriverList && this.state.driverPhoneData.map(driver => {
                                                return (
                                                    <GridContainer>
                                                        <GridItem xs={6}>
                                                            <p>{String(driver.driverName)}

                                                            </p>
                                                        </GridItem>

                                                    </GridContainer>

                                                )
                                            })}
                                        </GridItem>

                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={6}>
                                    <CustomSelect
                                        labelText="Customer List"
                                        values={this.props.listsData.map((list)=>list.listName)}
                                        // formControlProps={{
                                        //     style: {
                                        //         border: "2px solid #E60023",
                                        //         paddingTop: "0px"
                                        //     }
                                        // }}
                                        inputProps={{
                                            onChange: (e) => this.handleNewChange('selectedList', e.target.value),
                                            value: this.props.listsData[this.state.selectedList],
                                            style: {
                                                height: "2rem"
                                            }
                                        }}
                                    />
                                    {this.state.selectedList ? this.props.listsData[this.state.selectedList].list.map((customer) => {
                                        return (
                                            <div>
                                                <h4>{customer.name}</h4>
                                                <p>{customer.phoneNumber}</p>
                                            </div>
                                        )
                                    }) : null}
                                </GridItem>

                            </GridContainer>



                            {this.state.textBlastSent && "Text(s) sent"}

                        </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        AuthBool: state.auth.authenticated,
        listsData: state.lists.lists,
        // users: state.admin.users,
        // id: ownProps.path
        // lawyers: state.users.lawyers
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    //console.log(ownProps.uid)
    return {
        // startSetUsersList: dispatch(startSetUsersList()),
        // startSetLawyersList: dispatch(startSetLawyersList(ownProps.uid))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(TextBlast);
