import {
  cardTitle,
  grayColor,
  title
} from "assets/jss/material-dashboard-pro-react.js";
import 'assets/css/material-dashboard-pro-react.css';


const userProfileStyles = (theme) => ({
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  updateProfileFormHeader: {
    textAlign: "center",
    fontWeight: 500,
    background: "#FAFAFA",
    fontFamily: 'Abril Fatface, cursive',
    margin: "0px",
    padding: "20px",
    color: "black",
    fontSize: "23px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "16px",
    }
  },
  cardTitle: {
    fontWeight: "600"
  },
  updateProfileForm: {
    display: "flex",
    flexDirection: "column",
    height: "200px",
    justifyContent: "space-around",
    width: '60%'
  },
  updateProfileFormMobile: {
    display: "flex",
    flexDirection: "column",
    height: "200px",
    justifyContent: "space-around",
    width: '100%',
    color: "white"
  },
  updateProfileFormRow: {
    display: "flex",
    color: "white"
  },
  cardCategory: {
    marginTop: "10px",
    color: grayColor[1] + " !important",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around"
  },
  updateProfileButton: {
    float: "right",
    backgroundColor: "#f3684e"
  },
  profilePhoto: {
    width: "42%",
    height: "21vh",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  profileHeader: {
    textAlign: "center"
  },
  userType: {
    color: "#ffb163",
    fontWeight: "400",
  },
  companyInfo: {
    backgroundColor: "#f3684e",
    color: "#fff",
    padding: "12px",
    display: "flex",
    borderRadius: "5px",
    boxShadow: "0px 0px 17px -11px #000000"
  },
  profileInfo: {
    padding: "12px",
    display: "flex",
    boxShadow: "0px 0px 17px -11px #000000"
  },
  phoneDisplay: {
    display: "flex",
    justifyContent: "space-betweeen"
  },
  modalCloseButton: {
    "&, &:hover": {
      color: "white"
    },
    "&:hover": {
      opacity: "1"
    },
    cursor: "pointer",
    padding: "1rem",
    margin: "-1rem -1rem -1rem auto",
    backgroundColor: "transparent",
    border: "0",
    WebkitAppearance: "none",
    float: "right",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 #ffffff",
    opacity: ".5"
  },
  leadInfo: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: "50px",
    [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column"
    }
},
fullWidth: {
width: "100%",
},
fullWidthModal: {
  width: "100%",
  background: "linear-gradient(180deg, #13294C 0%, #005479 100%)",
},
mobileProfilePicDiv: {
  [theme.breakpoints.down('sm')]: {
    display: "flex",
    flexDirection: "column",
    fontSize: "16px",
    padding: "20px"
  }
},
mobileProfileAboutDiv: {
  [theme.breakpoints.down('sm')]: {
    display: "flex",
    flexDirection: "column",
    fontSize: "16px",
    background: "linear-gradient(180deg, #13294C 0%, #005479 100%)"
    // padding: "25px 40px 25px 40px"
  }
},
mobileCardHeader: {
  [theme.breakpoints.down('sm')]: {
    display: "flex",
    justifyContent: "center"
  }
},
mobileImg: {
  [theme.breakpoints.down('sm')]: {
    height: "200px",
    width: "200px",
    borderRadius: "50%",
    marginTop: "-115px"
  }
},
aboutHeaderMobile: {
  [theme.breakpoints.down('sm')]: {
   fontSize: "26px",
    padding: "0px",
    textAlign: "center",
    fontWeight: 500,
    marginBottom: "25px",
    background: "linear-gradient(90.01deg, #274B73 0.48%, #3AC0C4 99.05%) !important",
    color: "white",
    height: "40px",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center"
  }
},
img: {
    height: "200px",
    width: "200px",
    // borderRadius: "50%",
    [theme.breakpoints.down("md")]: {
        width: "200px",
    },
    "&:hover": {
      cursor: "pointer"
    }
},
  leadDetailName: {
    fontSize: "25px",
    fontWeight: 500,
    margin: "0px",
    fontFamily: 'Abril Fatface, cursive'
},
profilePicDiv: {
  display: "flex",
    width: "20%",
    marginRight: "30px",
    fontSize: "16px",
},
leadDetailInfoPicDiv: {
    display: "flex",
    width: "25%",
    marginRight: "30px",
    fontSize: "16px",
    justifyContent: "center",
},
mobileLeadDetailInfoPicDiv: {
  display: "flex",
  width: "100%",
  fontSize: "16px",
  justifyContent: "center",
  marginBottom: "15px"
},
inputLabel: {
  color: "white"
},
inputLabelAbout: {
  padding: "10px", color: "white"
},
underline: {
  borderBottom: "1px solid white",
  "&:after": {
    borderBottom: "1px solid white",
  }
},
leadDetailInfoDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "25%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      height: "70px"
    }
},
leadDetailName: {
  fontSize: "31px",
  fontWeight: 500,
  margin: "0px",
},
leadDetailInfo: {
  margin: "0px",
  fontSize: "20px"
},
icon: {
  width: "20px",
  marginRight: "20px"
},
modalBody: {
  display: 'flex',
  padding: "30px 30px 0px 30px",
  justifyContent: "space-around",
  [theme.breakpoints.down('sm')]: {
    flexDirection: "column",
    alignItems: "center"
  }
},
inputRoot: {
  width: "50%",
  marginRight: "30px"
},
input: {
  width: "100%",
},
placeholder: {
  visibility: "hidden",
  [theme.breakpoints.down('sm')]: {
    display: "none"
  }
},
mobileLoadingBar: {
  width: "90%"
},
cancelSubmitBtnDiv: {
  display: "flex",
  justifyContent: "space-between",
  padding: "25px",
  background: "#005479",
  [theme.breakpoints.down('sm')]: {
    margin: '0px',
    paddingTop: "0px",
    paddingBottom: "30px"
  }
},
root: {
  color: "white",
  "&:after": {
    color: "white",
  }
}
});
export default userProfileStyles;
