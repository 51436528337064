/*eslint-disable*/
import React, {useState} from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';

// @material-ui/icons
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import Phone from '@material-ui/icons/Phone';
import Timeline from '@material-ui/icons/Timeline';
import wizard from "assets/img/mortgage-ready/wizard-3.png";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import Card from 'components/Card/Card.js';

import RegistrationActions from 'store/actions/forms/registration';
import RegistrationAccessors from 'store/reducers/forms/registration';

import BoundInput from 'components/CustomInput/BoundInput';

import Style from 'assets/jss/kordspace-template/views/pages/registration/stepStyle';

import { userTypes } from "variables/config";
import {updateuserType} from 'store/actions/auth.js'


const CustomInput = BoundInput(RegistrationActions.commonProfile, RegistrationAccessors.commonProfile);

export function CommonProfile(props) {

    const { classes, error, userType } = props;

    return (
        <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            <GridItem xs={12} sm={10} md={8} lg={6} className={classes.inputContainer}>
                <h2
                    className={classes.cardTitle}
                >
                    Personal Information
                </h2>
                <img src={wizard} alt="wizard" className={classes.largeImg} />
                {error &&
                    <InfoArea icon={Timeline} title='Error' description={error.message} />
                }
                <GridContainer>
                    <GridItem xs={8}>
                        <Card className={classes.inputCard}>
                            <CustomInput
                                labelText='First Name'
                                name='firstName'
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disableUnderline: true,
                                }}
                            />
                        </Card>
                    </GridItem>
                    <GridItem xs={4}>
                        <Card className={classes.inputCard}>
                            <CustomInput
                                labelText='M.I.'
                                name='middleInitial'
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disableUnderline: true,
                                }}
                            />
                        </Card>
                    </GridItem>
                </GridContainer>
                <Card className={classes.inputCard}>
                    <CustomInput
                        labelText='Last Name'
                        name='lastName'
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            disableUnderline: true,
                        }}
                    />
                </Card>
                <Card className={classes.inputCard}>
                    <CustomInput
                        labelText='Company Name'
                        name='companyName'
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <BusinessCenterIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Card>
                <Card className={classes.inputCard}>
                    <CustomInput
                        labelText='Office Phone'
                        name='officePhone'
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Phone />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Card>
                <Card className={classes.inputCard}>
                    <CustomInput
                        labelText='Cell Phone'
                        name='cellPhone'
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Phone />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Card>
                <Card className={classes.inputCard}>
                    <CustomInput
                        labelText='Date of Birth'
                        name='dateOfBirth'
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            type: 'date',
                            disableUnderline: true
                        }}
                    />
                </Card>
            </GridItem>
        </GridContainer>
    );
}

const mapDispatchToProps = (dispatch) => ({
    updateuserType: (userType) => dispatch(updateuserType(userType))
});

const mapStateToProps = (state, ownProps) => {
    return {
        error: RegistrationAccessors.commonProfile.getError(state),
        userType: state.auth.userType
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(CommonProfile);