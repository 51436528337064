import {
  containerFluid,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
} from 'assets/jss/material-dashboard-pro-react.js';

const headerStyle = (theme) => ({
  appBar: {
    //backgroundColor: "#2a3456",
    background: 'white',
    // backgroundColor: '#03225c',
    // backgroundImage: 'linear-gradient(to right, #03225c 50%, #48b4f7 100%)',
    // opacity: '0.9',
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    position: 'absolute',
    width: '100%',
    paddingTop: '10px',
    zIndex: '1029',
    color: grayColor[6],
    border: '0',
    padding: '10px 0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block',
  },
  container: {
    ...containerFluid,
    minHeight: '50px',
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    }
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    margin: '0 !important',
    letterSpacing: 'unset',
    '&:hover,&:focus': {
      background: 'transparent',
    },
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  sidebarMinimize: {
    float: 'left',
    padding: '0 0 0 15px',
    display: 'block',
    color: grayColor[6],
  },
  sidebarMinimizeRTL: {
    padding: '0 15px 0 0 !important',
  },
  sidebarMiniIcon: {
    width: '32px',
    height: '24px',
    color: 'white',
  },
  img: {
    height: '10vh',
    margin: '0 0 0 60px',
  },
  brandText: {
    width: '100%',
    fontSize: '22px',
    color: 'white',
    marginLeft: '40px',
    display: 'flex',
    flex: '1',
    [theme.breakpoints.down('sm')]: {
      width: '79%',
      textAlign: 'center',
      marginLeft: '0px',
      display: "none"
    },
  },
  gradientUnderline: {
    display: 'flex',
    marginLeft: '2%',
    marginRight: '2%',
    // background: "linear-gradient(90deg, #EEBD09 0%, #56A3A6 100%)",
    borderRadius: '4px',
    // boxSizing: "content-box",
    borderWidth: '0px 0px 2px 0px',
    borderStyle: 'solid',
    borderImage: 'linear-gradient(90deg, #EEBD09 0%, #56A3A6 100%)',
    borderImageSlice: 1,
    border: '0px 0px 1px 0px',
  },
  appResponsive: {
    color: "white",
    fontSize: "30px"
  },
  settingBtn: {
    // width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up('md')]: {
      display: "none"
    }
  }
});

export default headerStyle;
