/*eslint-disable*/
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import ChartistGraph from 'react-chartist';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Phone';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Hidden from "@material-ui/core/Hidden";

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import actionsForNavigation from 'store/actions/navigation';
import Slide from '@material-ui/core/Slide';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';

import CreditHistory from './CreditHistory/CreditHistory';
import CreditReport from './CreditReport/CreditReport';
import CreditGrowth from './CreditGrowth';

import creditScoreStyle from 'assets/jss/material-dashboard-pro-react/views/creditScoreStyle.js';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { DateTime } from 'luxon';
import { isElementAccessExpression } from 'typescript';

import { updateUserData } from 'store/actions/auth.js';

const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

export function CreditScore(props) {
  const date = moment();
  console.log('creditDataxxxxx', props.creditData);

  const creditData = props.creditData;

  const { classes } = props;
  return (
    <div>
      <Hidden className={classes.fullWidth} smDown implementation="css">
        <p className={classes.subTitle} >
        Browse analytical data associated with your credit score.
        </p>
      </Hidden>

      <CreditHistory classes={classes} creditData={creditData} />
      <CreditReport classes={classes} creditData={creditData} />
    </div>
  );
}

CreditScore.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  console.log('stateeeeee', state);
  return {
    creditData: {
      currentScore: state.auth.user.currentScore
        ? state.auth.user.currentScore
        : [],
      targetScore: state.auth.user.targetScore
        ? state.auth.user.targetScore
        : [],
      scoreHistory: state.auth.user.scoreHistory
        ? state.auth.user.scoreHistory
        : [],
      creditChecks: state.auth.user.creditChecks
        ? state.auth.user.creditChecks
        : [],
      negativeItems: state.auth.user.negativeItems
        ? state.auth.user.negativeItems
        : [],
      creditLines: state.auth.user.creditLines
        ? state.auth.user.creditLines
        : [],
    },
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    edit: (key) =>
      dispatch(
        actionsForNavigation.openChild('Profile Editor', `/admin/user/editor`)
      ),
    updateUserData: (uid, newInfo) => dispatch(updateUserData(uid, newInfo)),
  });
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(creditScoreStyle)
)(CreditScore);
