/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";

import InformationModal from "components/InformationModal/InformationModal";
import NumberBubble from "components/NumberBubble/NumberBubble";
import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CardFooter from "components/Card/CardFooter";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Input from "@material-ui/core/Input";
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

// import CardFields from '../../../components/Card/CardFields';
// import CardField from '../../../components/Card/CardField';
import actionsForNavigation from "store/actions/navigation";
import { connect } from "react-redux";
import { MockUserData } from 'variables/config';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import Close from "@material-ui/icons/Close";
import { getLeads, deleteLead, convertLeadToCustomer } from "store/actions/leadsActions"
import Slide from "@material-ui/core/Slide";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import campaignsStyle from "assets/jss/material-dashboard-pro-react/views/campaignsStyle.js";

import InstagramIcon from "assets/img/market-me/instagramIcon.png";
import LinkedInIcon from "assets/img/market-me/linkedInLogo.png";
import EmailIcon from "assets/img/market-me/emailIcon.png";
import TextIcon from "assets/img/market-me/textIcon.png";
import CalendarIcon from "assets/img/market-me/calendarIcon.png";

import AuthActions from "store/actions/auth"
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";

const useStyles = makeStyles(campaignsStyle);

const Step1 = (props) => {
  const classes = useStyles();
  const { setStep, campaignForm, handleChange, setPlatforms, platforms, goBack, listsData } = props;
  const [ listNames, setListNames ] = useState([]);

  const runTimeUnits = ['Days', 'Weeks', 'Months'];

  useEffect(() => {
    setListNames(listsData.map((list) => list.listName))
  }, [listsData])

  return (
    <>
      <GridContainer className={classes.mainStepContainer}>
        <GridItem className={classes.stepHeader}>
          <NumberBubble value="1." color="yellow" size="2.5rem" />
          <h4 style={{ fontWeight: 600, marginLeft: "1rem" }}>Campaign Information</h4>
        </GridItem>
        <GridItem className={classes.stepContainerOuter}>
          <GridContainer className={classes.stepContainerInner}>
            <GridItem>
              <h5 style={{ fontWeight: 800 }}>Campaign Name</h5>
            </GridItem>
            <GridItem>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  style: {
                    paddingTop: "0px"
                  }
                }}
                inputProps={{
                  placeholder: "My new campaign",
                  required: true,
                  type: "text",
                  disableUnderline: true,
                  onChange: (e) => handleChange('campaignName', e.target.value),
                  style: {
                    border: "2px solid #E60023",
                    borderRadius: "5px"
                  }
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.stepContainerInner}>
            <GridItem style={{ display: "flex", alignItems: "baseline" }}>
              <h5 style={{ fontWeight: 800 }}>Platforms</h5>
              <h5 style={{ fontSize: "0.8rem", marginLeft: "2rem" }}>(Select one)</h5>
            </GridItem>
            <GridItem>
              <GridContainer className={classes.buttonContainer}>
                  <Button
                    className={platforms.instagram ? classes.stepButtonPressed : classes.stepButton}
                    onClick={() => setPlatforms('instagram')}
                  >
                    <img src={InstagramIcon} width="20%" className={platforms.instagram ? classes.stepIconPressed : classes.stepIcon} />
                    <div>Instagram</div>
                  </Button>

                  <Button
                    className={platforms.linkedin ? classes.stepButtonPressed : classes.stepButton}
                    onClick={() => setPlatforms('linkedin')}
                  >
                    <img src={LinkedInIcon} className={platforms.linkedin ? classes.stepIconPressed : classes.stepIcon} width="25%" />
                    <div>LinkedIn</div>
                  </Button>


                <Button
                  className={platforms.email ? classes.stepButtonPressed : classes.stepButton}
                  onClick={() => setPlatforms('email')}
                >
                  <img src={EmailIcon} className={platforms.email ? classes.stepIconPressed : classes.stepIcon} width="30%" />
                  <div>Email</div>
                </Button>
                <Button
                  className={platforms.sms ? classes.stepButtonPressed : classes.stepButton}
                  onClick={() => setPlatforms('sms')}
                >
                  <img src={TextIcon} className={platforms.sms ? classes.stepIconPressed : classes.stepIcon} width="25%" />
                  <div>SMS Text</div>
                </Button>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem className={classes.stepContainerOuter}>
          <GridContainer className={classes.stepContainerInner}>
            <GridItem style={{ display: "flex", alignItems: "center" }}>
              <h5 style={{ fontWeight: 800 }}>Target Customers</h5>
              <InformationModal infoText="Enter the total amount of customers you want to reach. Leave empty to reach all in specified Customer List." />
            </GridItem>
            <GridItem>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  style: {
                    paddingTop: "0px",
                  }
                }}
                inputProps={{
                  placeholder: "1000, 2000, 3000 Customers",
                  required: true,
                  type: "text",
                  disableUnderline: true,
                  onChange: (e) => handleChange('targetCustomers', e.target.value),
                  style: {
                    border: "2px solid #E60023",
                    borderRadius: "5px",
                    height: "2.5rem"
                  }
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.stepContainerInner}>
            <GridItem>
              <h5 style={{ fontWeight: 800 }}>Customer List</h5>
            </GridItem>
            <GridItem>
              <CustomSelect
                values={listNames}
                formControlProps={{
                  style: {
                    border: "2px solid #E60023",
                    paddingTop: "0px"
                  }
                }}
                inputProps={{
                  disableUnderline: true,
                  onChange: (e) => handleChange('customerList', e.target.value),
                  style: {
                    height: "2rem"
                  }
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem className={classes.stepContainerOuter} style={{ borderBottom: "0px" }}>
          <GridContainer className={classes.stepContainerInner}>
            <GridItem >
              <h5 style={{ fontWeight: 800 }}>Run Length</h5>
            </GridItem>
            <GridItem style={{ display: "flex" }}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  style: {
                    paddingTop: "0px",
                  }
                }}
                inputProps={{
                  placeholder: "20, 40, 60",
                  required: true,
                  type: "text",
                  disableUnderline: true,
                  onChange: (e) => handleChange('runLength', e.target.value),
                  style: {
                    border: "2px solid #E60023",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    height: "2.5rem",

                  }
                }}
              />
              <CustomSelect
                values={runTimeUnits}
                className={classes.newInput}
                formControlProps={{
                  style: {
                    paddingTop: "0px",
                  }
                }}
                inputProps={{
                  defaultValue: 0,
                  disableUnderline: true,
                  onChange: (e) => handleChange('runLengthUnit', runTimeUnits[e.target.value]),
                  style: {
                    background: "linear-gradient(180deg, #E60023 0%, #AC001A 100%)",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                    color: "white !important",
                    "& .MuiSelect-root": {
                      color: "white",
                    },
                  }
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.stepContainerInner}>
            <GridItem>
              <h5 style={{ fontWeight: 800 }}>Date Range</h5>
            </GridItem>
            <GridItem style={{ display: "flex", alignItems: "baseline" }}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  style: {
                    paddingTop: "0px"
                  }
                }}
                inputProps={{
                  placeholder: "01/05/2021",
                  required: true,
                  type: "text",
                  disableUnderline: true,
                  onChange: (e) => handleChange('startDate', e.target.value),
                  style: {
                    border: "2px solid #E60023",
                    borderRadius: "5px",
                    height: "2.5rem",
                    marginRight: "0.5rem"
                  }
                }}
              /> to
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  style: {
                    paddingTop: "0px"
                  }
                }}
                inputProps={{
                  placeholder: "04/05/2021",
                  required: true,
                  type: "text",
                  disableUnderline: true,
                  onChange: (e) => handleChange('endDate', e.target.value),
                  style: {
                    border: "2px solid #E60023",
                    borderRadius: "5px",
                    height: "2.5rem",
                    marginLeft: "0.5rem"
                  }
                }}
              />
              <img src={CalendarIcon} width="7%" className={classes.calendarIcon} />
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.stepContainerInner}>
            <GridItem>
              <h5 style={{ fontWeight: 800, marginLeft: "10px", marginTop: "15px" }}>Automatic Start</h5>
            </GridItem>
            <GridItem style={{ display: "flex", alignItems: "flex-end" }}>
              <Checkbox
                onChange={(e) => {
                  handleChange('continuously', e.target.checked);
                }}
              />
              <p>Run continuously starting today</p>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.nextButtonsContainer}>
        <Button onClick={() => {
          setStep(0);
          goBack();
        }} className={classes.backButton}>Back</Button>
        <Button onClick={() => setStep(2)} className={classes.nextButton}>Next</Button>
      </GridContainer>
    </>
  )
}

function mapStateToProps(state) {
  return {
    listsData: state.lists.lists
  };
}

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(campaignsStyle)
)(Step1));