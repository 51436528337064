import React, { useState } from "react";

import BoundNewLead from 'components/Form/BoundNewLead';
import BoundEditorForm from 'components/Explorer/BoundEditorForm'

import actionsForExplorer from 'store/actions/explorers/leads';
import accessorsForExplorer from 'store/reducers/explorers/leads';

const NewLeadForm = BoundNewLead(actionsForExplorer.newForm, accessorsForExplorer.newForm);

const EditorForm = BoundEditorForm(actionsForExplorer.newForm, accessorsForExplorer.newForm);

export default function NewLead(props) {
    // return (
    //     <EditorForm order={["firstName", "lastName", "currentScore", "goalScore", "state", "notes", "joinDate", "emailAddress", "mainPhone", "status", "loanOfficer", "analyst"]} />
    // );
    return (
        <NewLeadForm order={["firstName", "lastName", "currentScore", "goalScore", "state", "notes", "joinDate", "emailAddress", "mainPhone", "status", "loanOfficer", "analyst"]} />
    );
}


