import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const documentsStyle = (theme) => ({
  container: {
    // ...container,
    zIndex: "4",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "20px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  img: {
    height: "20vh",
    margin: "20px 0 0 0",
  },
  largeImg: {
    height: "32vh",
    margin: "20px 0 0 0",
  },
  subdescription: {
    color: "#000",
  },
  signUpButton: {
    backgroundColor: "#f3684e",
    width: "100%",
    height: "8vh",
    fontSize: "1.3rem",
    marginBottom: "20px",
    "&:hover": {
      backgroundColor: "#f3684e",
    }
  },
  logInButton: {
    backgroundColor: "#05c6a3",
    width: "100%",
    height: "8vh",
    fontSize: "1.3rem",
    "&:hover": {
      backgroundColor: "#05c6a3",
    }
  },
  continueButton: {
    fontWeight: "600",
    color: "#FFFFFF",
    backgroundColor: "#ffb063",
    marginTop: "10px",
  },
  formContainer: {
    display: "block",
    margin: "0 0 0 200px",
  },
  updateProfileFormHeader: {
    textAlign: "center",
    fontWeight: 500,
    background: "#274B73",
    margin: "0px",
    padding: "20px",
    color: "white",
    fontSize: "22px"
  },
  modalCloseButton: {
    "&, &:hover": {
      color: "white"
    },
    "&:hover": {
      opacity: "1"
    },
    cursor: "pointer",
    padding: "1rem",
    margin: "-1rem -1rem -1rem auto",
    backgroundColor: "transparent",
    border: "0",
    WebkitAppearance: "none",
    float: "right",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 #ffffff",
    opacity: ".5"
  },
  updateProfileForm: {
    display: "flex",
    flexDirection: "column",
    height: "500px",
    justifyContent: "space-around"
  },

  subTitle: {
    fontSize: "20px"
  },
  modalBody: {
    padding: "30px"
  },
  cardBody: {
    minHeight: "600px",
    overflow: "scroll",
    background: "rgba(255, 255, 255, 1)",
  },
  cardBodyChoosePlan: {
    paddingTop: "30px"
  },
  cardHeader: {
    background: "linear-gradient(90.01deg, #274B73 0.48%, #3AC0C4 99.05%) !important",
    fontWeight: 400,
    fontSize: "30px",
    lineHeight: "32px",
    textAlign: "center",
    color: "white",
  },
  tableHeader: {
    width: "20%"
  },
  tableCell: {
    width: "20%",
    fontSize: "18px",
    textAlign: "center"
  },
  filterCardBody: {
    background: "rgba(255, 255, 255, 1)",
    [theme.breakpoints.down('sm')]: {
      height: "120px"
    }
  },
  documentCardBody: {
    minHeight: "600px",
    overflow: "scroll",
    background: "rgba(255, 255, 255, 1)",
    marginTop: "0px",
    boxShadow: "0px 1px 6px grey",
  },
  newRecord: {
    "&:hover": {
      background: "linear-gradient(180deg, #5EB1E3 26.3%, #274B73 210.42%)",
    },
    background: "linear-gradient(180deg, #5EB1E3 26.3%, #274B73 210.42%)",
    borderRadius: "50%",
    color: "#fff",
    position: "fixed",
    bottom: 20,
    right: 40,
    zIndex: 3,
    height: "86px",
    fontSize: "50px",
    width: "86px",
    [theme.breakpoints.down("sm")]: {
      height: "85px",
      width: "85px",
      bottom: 80,
      right: 17,
    },
  },
  tableRow: {
    "&:hover": {
     cursor: "pointer"
    },
  },
  addBtn: {
    fontSize: "50px",
  },
  track: {
    backgroundColor: "rgba(238, 189, 9, 1) !important"
  },
  thumb: {
    color: "rgba(238, 189, 9, 1)"
  },
  modalMedium: {
    maxWidth: "1000px",
    minHeight: "600px"
  },
  addDocBtn: {
    width: "200px",
    height: "300px",
    border: "1px solid rgba(58, 192, 196, 1)",
    margin: "30px 15px 30px 15px",
    "&:hover": {
      boxShadow: "0px 1px 6px grey",
      cursor: "pointer"
    },
    "&:active": {
      border: "3px solid rgba(58, 192, 196, 1)",
    }
  },
  yellowBtn: {
    backgroundColor: "#f5c107",
    "&:hover": {
      backgroundColor: "#f5c107",
    }
  },
  documentCard: {
    width: "200px",
    height: "300px",
    border: "1px solid rgba(58, 192, 196, 1)",
    margin: "30px 15px 30px 15px",
    "&:hover": {
      boxShadow: "0px 1px 6px grey",
      cursor: "pointer"
    },
  },
  sendBtn: {
    display: "flex",
    width: "35%",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#f5c107",
    "&:hover": {
      backgroundColor: "#f5c107",
    },
    [theme.breakpoints.down('sm')]: {
      width: "40%",
      justifyContent: "center",
      alignItems: "center",

    }
  },
  markUnreadyBtn: {
    [theme.breakpoints.down('sm')]: {
      width: "40%",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgb(188, 70, 70)",
      color: "white"
    }
  },
  readyToSendBtn: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "white",
    border: "2px solid #f5c107",
    color: "black",
    "&:hover": {
      backgroundColor: "#f5c107",
      color: "white",
    }
  },
  fullWidthBackBtn: {
    height: "60px",
    margin: "24px",
    backgroundColor: "#f5c107",
    "&:hover": {
      backgroundColor: "#f5c107",
    }
  },
  halfWidthYellowBtns: {
    height: "60px",
    margin: "24px",
    width: "40%",
    backgroundColor: "#f5c107",
    "&:hover": {
      backgroundColor: "#f5c107",
    }
  },
  halfWidthBtns: {
    height: "60px",
    margin: "24px",
    width: "40%"
  },
  editTrashBtns: {
    height: "25px",
    "&:hover": {
      cursor: "pointer",
    }
  },
  uploadedDocumentsDiv: {
    display: "flex",
     flexDirection: "row",
      flexWrap: "wrap",
    [theme.breakpoints.down('sm')]: {
      alignItems: "center",
      justifyContent: "center",
      padding: "30px"
    }
  },
  AddEditDeleteBtns: {
    width: "17%",
    display: "flex",
    justifyContent: 'space-between',
    padding: "10px",
    [theme.breakpoints.down('sm')]: {
      width: "90%",
      zIndex: 9999,
      background: "white",
      position: "absolute"
    }
  },
  modalRoot: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: "0px"
    }
  },
  addNewDocModalContentDiv: {
    width: "98%",
     display: "flex",
     justifyContent: "flex-end",
     [theme.breakpoints.down('sm')]: {
      width: "100%",
      justifyContent: "center",
    }
  },
  choosePlanGridContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "100px"
  },
  gradientDivider: {
    width: "2px",
     height: "80%",
     background: "linear-gradient(89.97deg, rgba(238, 189, 9, 1) 0.99%, rgba(86, 163, 166, 1) 99.97%)",
  },
  choosePlanCard: {
    width: "950px",
    height: "650px",
    background: "linear-gradient(180deg, #5EB1E3 0%, #274B73 119.04%)",
    padding: "30px"

  },
  choosePlanCardHeader: {
    fontSize: "30px",
    color: "white",
    textAlign: "center",
    fontWeight: 500
  },
  planText: {
    fontSize: "24px",
    color: "white",
    textAlign: "center",
    fontWeight: 500,
    marginBottom: "20px"
  },
  featureText: {
    fontSize: "20px",
    color: "white",
    textAlign: "center",
    margin: "0px 0px 20px 0px"
  },
  choosePlanButton: {
    background: "transparent",
    border: "2px solid #5EB1E3",
    color: "white"
  },
  selectedPlanButton: {
    background: "#EEBD09",
    color: "white"
  }

});

export default documentsStyle;
