/*eslint-disable*/
import React from 'react';
import { db, firebase, storage, sendCustomerNotificationEmail, tokenExchange } from 'firebase/client';
import axios from 'axios';

export const login = (userId) => {
  FB.login((res) => {
    if (res.status === 'connected') {
      const authData = {
        user_id: res.authResponse.userID,
        client_id: '808420453694706',
        access_token: res.authResponse.accessToken,
        client_secret: '8bf9053c995d7f7e069f64a1a35e9784',
      }
      // tokenExchange({ shortToken: res.authResponse.accessToken, userId })
      db.collection('users').doc(userId)
        .update(authData)
    } else {
      // User did not login
    }
  }, { scope: 'instagram_basic,instagram_manage_messages,pages_manage_metadata' })
}

export const getUserPageId = async (accessToken) => {
  return await axios.get(`https://graph.facebook.com/v9.0/me/accounts?access_token=${accessToken}`)
    .then((res) => {
      if (res.data.data.length) {
        return res.data.data[0].id;
      } else {
        throw 'No User Page Found';
      }
    })
    .catch((err) => {
      console.error(err);
    })
}

export const getPageAccessToken = async (pageId, accessToken) => {
  return await axios.get(`https://graph.facebook.com/${pageId}?fields=access_token&access_token=${accessToken}`)
    .then((res) => {
      return res.data.access_token;
    })
    .catch((err) => {
      console.error(err);
    })
}

export const getConversationId = async (pageId, pageAccessToken) => {
  return await axios.get(`https://graph.facebook.com/v9.0/${pageId}/conversations?platform=instagram&access_token=${pageAccessToken}`)
    .then((res) => {
      return res.data.data[0].id;
    })
    .catch((err) => {
      console.error(err);
    })
}

export const getMessages = async (conversationId, pageAccessToken) => {
  return await axios.get(`https://graph.facebook.com/v9.0/${conversationId}?fields=messages&access_token=${pageAccessToken}`)
    .then((res) => {
      return res.data.messages.data[0].id;
    })
    .catch((err) => {
      console.error(err);
    })
}

export const getIgsid = async (messageId, pageAccessToken) => {
  return await axios.get(`https://graph.facebook.com/v9.0/${messageId}?fields=from,to&access_token=${pageAccessToken}`)
    .then((res) => {
      // Need to expand on this, only works when first message in convo is sent by us
      // Can use 'from' field to get other user's igsid
      return res.data.to.data[0].id;
    })
    .catch((err) => {
      console.error(err);
    })
}

export const sendMessage = (message, pageId, igsid, pageAccessToken) => {
  const msg = (id) => {
    axios.post(`https://graph.facebook.com/v9.0/${pageId}/messages?recipient={id: ${id}}&message={text: '${message}'}&access_token=${pageAccessToken}`)
      .then((res) => {
        console.log('Message sent successfully.');
      })
      .catch((err) => {
        console.error(err);
        throw err;
      })
  }
  if (typeof igsid === 'object') {
    igsid.forEach((id) => {
      msg(id);
    })
  } else {
    msg(igsid);
  }
}

export const getConversations = async (pageId, pageAccessToken) => {
  return await axios.get(`https://graph.facebook.com/v9.0/${pageId}/conversations?platform=instagram&access_token=${pageAccessToken}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
    })
}

export const getConversationRecipients = async (accessToken) => {
  await getUserPageId(accessToken)
    .then((pageId) => {
      getPageAccessToken(pageId, accessToken)
      .then((pageAccessToken) => {
        getConversations(pageId, pageAccessToken)
          .then((convs) => {
            console.log('look convo res', res)
            convs.map((convo) => {
              return getMessages(convo.id, pageAccessToken)
                .then((messages) => {
                  // Iterate over messages
                    // For each, check from and to to find the igsid that doesn't match current user, return
                })
            })
          })
      })
    })
}