import { getAccessorsForCollection, addCalculatedFields } from "../recordset";
import { CardFieldFormats } from "variables/constants";
import { DataTypes, ControlTypes } from "variables/constants";

export const config = {
    firestoreCollection: 'customers',
    fields: {
        lastUpdated: { type: DataTypes.DATETIME, cardFormat: CardFieldFormats.SIMPLE_DATE, recordset: true },
        dateOfBirth: { type: DataTypes.DATETIME, cardFormat: CardFieldFormats.SIMPLE_DATE, controlType: ControlTypes.DATE, recordset: true },
        estimatedPullDate: { type: DataTypes.DATETIME, cardFormat: CardFieldFormats.SIMPLE_DATE, controlType: ControlTypes.DATE, recordset: true },
        joinDate: { type: DataTypes.DATETIME, cardFormat: CardFieldFormats.SIMPLE_DATE, recordset: true },
        notes: { maxLength: 120, recordset: true },
        currentScore: { type: DataTypes.TEXT, recordset: true },
        goalScore: { type: DataTypes.TEXT, recordset: true },
        mainPhone: { type: DataTypes.NUMBER, maxLength: 10, recordset: true },
        zipCode: { type: DataTypes.TEXT, recordset: true },
        lastFourSSN: { type: DataTypes.NUMBER, recordset: true },
        importantNotes: { type: DataTypes.TEXT, recordset: true },
        socialSecurityNumber: { type: DataTypes.TEXT, maxLength: 9, default: 555555555, recordset: true },
        emailAddress: { type: DataTypes.TEXT, recordset: true },
        loanOfficer: { type: DataTypes.TEXT, recordset: true },
        status: { type: ControlTypes.SELECT, default: "Service Agreements", recordset: true },
        isQualified: { type: ControlTypes.SELECT, cardFormat: CardFieldFormats.BOOLEAN, recordset: true },
        hasCreditReport: { type: ControlTypes.SELECT, cardFormat: CardFieldFormats.BOOLEAN, recordset: true },
        needsAttentiont: { type: ControlTypes.SELECT, cardFormat: CardFieldFormats.BOOLEAN, recordset: true },
        // Below are fields needed for card:
        state: { recordset: true },
        creditAnalyst: { recordset: true },
        isQualified: { recordset: true },
        description: { recordset: true },
    },
    textSearchFields: [ "firstName", "lastName", "emailAddress", "status", "socialSecurityNumber", "creditAnalyst", "loanOfficer", ]
}

function calculator(column, accessor) {
    switch(column) {
        case "fullName":
            if (accessor("middleName")) {
                return accessor("firstName") + " " + accessor("middleName") + " " + accessor("lastName");
            } else {
                return accessor("firstName") + " " + accessor("lastName");
            }
            // + " " + accessor(!"suffix" ? "" : "suffix")

        //     }
        // case "lastFourSSN":
        //     return accessor("socialSecurityNumber").toString().split("").splice(5).join("");
        default:
            return accessor(column);
    }
}

export const accessorsForRecordset = addCalculatedFields(getAccessorsForCollection(config, "recordset","customers"), calculator);

export default accessorsForRecordset;


