import React, { useState } from "react";
import Button from "components/CustomButtons/Button.js";
import firebase, { db, storage } from "firebase/client.js"
import { v1 as uuidv1 } from 'uuid';
import Close from "@material-ui/icons/Close";
import ButtonBase from "@material-ui/core/ButtonBase";
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from "@material-ui/core/IconButton";
import SweetAlert from "react-bootstrap-sweetalert";
import DeleteDoc from './DeleteDoc';

const api = {
    uploadFile({ timeout = 550 }) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve()
            }, timeout)
        })
    },
}

class Docupload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            files: [],
            uploaded: [],
            status: 'idle',
            confirmDelete: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.loadDocs = this.loadDocs.bind(this);
        this.exploreFiles = this.exploreFiles.bind(this);

    };

    loadCustomerData = () => {

        let dispatcherData = db.collection("leads").doc(this.props.customerId)
            .get().then((snapshot) => {
                var docData = snapshot.data()
                if (docData) {
                    var docObj = {
                        id: snapshot.id,
                        ...docData
                    }
                    return (docObj)
                } else {
                    return (null)
                }
            }, (error) => {
                console.log('error fetching data: ', error)
            }).then(docObj => {
                this.setState({
                    orderData: docObj,
                    customerDataLoaded: true
                })
            })
    }

    componentDidUpdate(prevProps, prevState) {
        const { status, files } = this.state;

        if (prevProps.del !== this.props.del) {
            const fileObj = files.find(file => file.fileURL === this.props.del);
            if (files.indexOf(fileObj) !== -1) {
                files.splice(files.indexOf(fileObj), 1);
                this.setState({
                    files: files
                });
            };

        }

        if (status === 'idle') return;

        if (status === 'loading') {
            console.log(`Total files: ${files.length}`);
            this.setState({ status: 'idle' })
            this.loadDocs();
        }

        if (status === 'ready') {
            // this.props.onAssignPic(this.state.files);
            const { orderData } = this.state;
            orderData.newDocs ? orderData.newDocs = [...orderData.newDocs, ...this.state.files] : orderData.newDocs = this.state.files;
            console.log(orderData.newDocs);
            this.setState({ status: 'idle', orderData: orderData, files: [] });
        }
    }

    handleChange(e) {
        this.setState({ inputValue: e.target.value })
        e.preventDefault();
        if (e.target.files.length) {
            const arrFiles = Array.from(e.target.files);
            console.log(e.target.files, arrFiles);

            const files = arrFiles.map((file, index) => {
                const fileURL = window.URL.createObjectURL(file);
                console.log({ file, id: index + this.state.files.length, fileURL });
                return { file, id: index + this.state.files.length, fileURL };
            });

            this.setState({
                files: [...this.state.files, ...files],
                status: 'loading'
            });
        }
        this.setState({
            inputValue: ''
        })
    }

    loadDocs() {
        const { files } = this.state;
        console.log(files);
        for (let i = 0; i < files.length; i++) {
            api.uploadFile(files[i])
                .then(() => {
                    const uploaded = this.state.uploaded;
                    uploaded.push(files[i]);
                    this.setState({
                        uploaded: uploaded,
                        status: i === (files.length - 1) ? 'ready' : this.state.status
                    })
                }).catch(err => console.error(err));
        }
    }

    removeDoc = (i) => {
        this.setState(prevState => {
            const { orderData } = prevState;
            orderData.newDocs.splice(i, 1);
            return ({
                orderData: orderData
            });
        });
    }


    uploadImage = (refString, pic, name) => {
        console.log("Uploading image");
        return new Promise((resolve, reject) => {
            // create storage ref
            var storageRef = storage.ref(refString);
            var nameRef = storageRef.child(name)
            // upload pic
            var uploadTask = nameRef.put(pic);
            // update progress bar
            uploadTask.on('state_changed', (snapshot) => {
                // Observe state change events such as progress, pause, and 
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var Progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.setState({ uploadProgress: 'Progress is ' + Progress + '% done' });

                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        this.setState({ uploadProgress: ' Upload is paused' });
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        this.setState({ uploadProgress: 'Upload is running' });
                        break;
                }

            }, (error) => {
                // Handle unsuccessful uploads
                console.log(error)
            }, () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    resolve(downloadURL)
                });
            })
        })
    }

    confirmDelete = (document, e) => {
        e.preventDefault();
        let validate = window.confirm("Are you sure you want to delete this document?")
        if (validate == true) {
            this.deleteDocument(document, e)
        }
    }

    deleteDocument = async (document, e) => {
        e.preventDefault();
        const { orderData, additionalDocuments } = this.state;
        const { docs, documents, previouslySentDocuments } = orderData;
        console.log(docs, documents, this.props.orderID);
        const orderRef = db.collection('leads').doc(this.props.customerId);

        const docsEntries = Object.entries(docs);

        const documentsIndex = documents ? documents.findIndex(obj => obj.id === document.id) : -1;
        const docsIndex = docsEntries.findIndex(entry => entry[1].id === document.id);

        const psdIndex = previouslySentDocuments ? previouslySentDocuments.findIndex(obj => obj.id === document.id) : -1;
        const addIndex = additionalDocuments ? additionalDocuments.findIndex(obj => obj.id === document.id) : -1;

        console.log(documentsIndex, docsIndex);

        if (psdIndex > -1) {
            previouslySentDocuments.splice(psdIndex, 1);
        }

        if (addIndex > -1) {
            additionalDocuments.splice(addIndex, 1);
        }

        if (documentsIndex > -1) {

            documents.splice(documentsIndex, 1);
            console.log(documents);

            orderRef.set({
                documents: documents,
                previouslySentDocuments: previouslySentDocuments ? previouslySentDocuments : null
            }, { merge: true });

            orderData.documents = documents;
            orderData.previouslySentDocuments = previouslySentDocuments ? previouslySentDocuments : null;
            this.setState({
                orderData,
                additionalDocuments
            });

        } else if (docsIndex > -1) {

            console.log(docsEntries);

            await orderRef.set({
                docs: {
                    [docsEntries[docsIndex][0]]: firebase.firestore.FieldValue.delete()
                },
                previouslySentDocuments: previouslySentDocuments ? previouslySentDocuments : null
            }, { merge: true });

            docsEntries.splice(docsIndex, 1);

            orderData.docs = Object.fromEntries(docsEntries);
            orderData.previouslySentDocuments = previouslySentDocuments ? previouslySentDocuments : null;
            this.setState({
                orderData,
                additionalDocuments: additionalDocuments
            });

        } else {
            console.log("Can't find document anywhere");
        }
    }



    saveDoc = async (document) => {
        const id = uuidv1();
        const docValToType = {
            airLabel: 'Carrier Labels',
            alternateBOL: 'Accufrate BOL',
            alternateAirWaybill: 'Carrier Air Waybill'
        }
        const oldDocs = this.state.orderData.docs ? this.state.orderData.docs : {};
        const newDocs = this.state.orderData.newDocs.filter(doc => doc !== document);
        const orderData = this.state.orderData;

        const docName = this.state.document ? docValToType[this.state.document] : document.name ? document.name : document.file.name;
        const fileURL = await this.uploadImage(`/leads/${this.props.customerId}/documents/${id}`, document.file, docName);
        const docID = `${this.state.document ? this.state.document : document.name ? document.name : document.file.name}${this.state.document ? '' : `_${id}`}`;

        const changeDoc = {
            name: docName,
            fileURL,
            id,
            createdAt: new Date()
        };

        console.log(changeDoc);

        oldDocs[docID] = changeDoc;
        orderData.newDocs = newDocs;
        orderData.docs = oldDocs;

        db.collection("leads").doc(this.props.customerId).set({
            docs: oldDocs
        }, { merge: true })
            .then(() => {
                this.setState({ orderData: orderData });
            })
            .catch(err => {
                console.log("Document link was not uploaded to firestore")
            });
    }

    exploreFiles() {
        this.refs.docUploader.click();
    }

    render() {
        if (!this.state.customerDataLoaded) {
            this.loadCustomerData()
        }
        console.log(this.state)

        const uploadedDocuments = [];
        this.state.orderData && this.state.orderData.documents && this.state.orderData.documents.map(doc => uploadedDocuments.push(doc));
        if (this.state.orderData && this.state.orderData.docs) {
            console.log("loading docs data")

            const docs = this.state.orderData.docs;
            for (let key in docs) {
                if ('createdAt' in docs[key]) {
                    uploadedDocuments.push(docs[key]);
                }
            }
        }


        if (this.state.customerDataLoaded) {
            return (
                <div>
                    {uploadedDocuments.map((doc, i) => (
                        <a href={doc.fileURL} download={doc.name}>
                            <Button
                                color='primary'
                            >
                                {doc.name.includes('.') ? doc.name.split('.')[0] : doc.name}
                                <IconButton size='small' style={{ marginLeft: '16px', marginRight: '-16px' }} onClick={(e) => this.confirmDelete(doc, e)}>
                                    <Close />
                                </IconButton>
                            </Button>
                        </a>
                    ))}
                    <Button
                        color='info'
                        component='div'
                        onClick={this.exploreFiles}
                        style={{ float: "right" }}
                    >
                        UPLOAD DOCUMENT
                        <input type="file" accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pub, .pubx,image/jpeg,image/gif,image/png,application/pdf,image/x-eps" ref={'docUploader'} onChange={this.handleChange} style={{ display: 'none' }} multiple />
                    </Button>
                    {(this.state.orderData.newDocs) && this.state.orderData.newDocs.map((doc, i) => (
                        <div style={{ display: 'flex' }}>
                            <Close style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.removeDoc(i)} />
                            {this.state.editDocName === i ?
                                <input
                                    type='text'
                                    onChange={(e) => {
                                        const orderData = this.state.orderData;
                                        orderData.newDocs[i].name = e.target.value;
                                        this.setState({ orderData: orderData });
                                    }}
                                    value={doc.name ? doc.name : doc.file.name}
                                />
                                : <p>
                                    <a href={doc.fileURL} target='_blank'>
                                        {doc.name ? doc.name : doc.file.name}
                                    </a>
                                </p>
                            }
                            <ButtonBase
                                onClick={() => {
                                    this.setState({ editDocName: i === this.state.editDocName ? -1 : i });
                                }}
                            >
                                <EditIcon />
                            </ButtonBase>
                            <ButtonBase
                                onClick={() => this.saveDoc(doc)}
                            >
                                <SaveIcon />
                            </ButtonBase>
                        </div>
                    ))}
                </div>
            );
        } else {
            return "Loading..."
        }
    }
}

export default Docupload;