import React from 'react';
import GridContainer from 'components/Grid/GridContainer';
import NegativeCircles from './NegativeCircles';
import NegativeDetails from './NegativeDetails';
import Suggestions from './Suggestions';
import './NegativeFactors.css';

const NegativeFactors = () => {
  
  return (
    <GridContainer >
      <div id={'topSection'}>
        <NegativeCircles/>
        <NegativeDetails/>
      </div>
      <div id={'bottomSection'}>
        <Suggestions/>
      </div>
    </GridContainer>
  );
};

export default NegativeFactors;
