import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db, firebase } from "firebase/client.js";
import { DateTime } from 'luxon';


// @material-ui/core components
import Card from "components/Card/Card.js";
import Slide from "@material-ui/core/Slide";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/views/customerDetailStyle";

import CreateTaskForm from "./CreateTaskForm"


const Transition = (props) => {
    return <Slide direction="down" {...props} />;
};

class CustomerTasks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            updateProfileModal: false,
            updateDispatchModal: false,
            updatePhotoModal: false,
            learnDashboardModal: false,
        };
    }

    handleClickOpen = (modal, ID, shipperID, status) => {
        var x = [];
        x[modal] = true;
        this.setState({
            ...x,
            ID,
            shipperID,
            status,
        });
    };

    handleClose = (modal) => {
        var x = [];
        x[modal] = false;
        this.setState(x);
        window.location.reload();
    };


    loadTasks = (customerId) => {
        console.log("loading groups");
        db.collection("customers")
            .doc(customerId)
            .collection("tasks").orderBy("createdAt")
            .onSnapshot(querySnapshot => {
                const snapshot = querySnapshot.docs;
                const tasks = [];

                for (let i = 0; i < snapshot.length; i++) {
                    const docData = {
                        ...snapshot[i].data(),
                        id: snapshot[i].id
                    }
                    tasks.push(docData);
                }
                this.setState({
                    tasksData: tasks,
                    tasksDataLoaded: true,
                });
            })
    };


    render() {
        const { classes} = this.props;

        if (!this.state.tasksDataLoaded) {
            this.loadTasks(this.props.customerId);
        }

        // console.log(this.state);
        console.log(this.props);
        if (this.props.authUser) {
            return this.state.tasksDataLoaded ? (
                <GridContainer>
                    {this.state.tasksData.map((task) => (
                        <GridItem xs={12}>
                            <Card style={{ marginBottom: "0" }} >{console.log(task.createdAt.toDate())}
                                <div className={classes.whiteCard}>
                                    <p style={{ fontWeight: "300" }}>{task.content}</p>
                                    <p>{task.createdBy} <span style={{ color: "#999", fontSize: "10px" }}>{task.createdAt.toDate().toString()}</span></p>
                                </div>
                            </Card>
                        </GridItem>
                    ))}
                    <GridItem xs={12}>
                        <CreateTaskForm customerId={this.props.customerId} user={this.props.authUser} />
                    </GridItem>
                </GridContainer>
            ) : (
                <p className={classes.body}>Loading...</p>
            );
        } else { return null }
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        authUser: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    //   console.log(this.props)
    return {
        // startSetAllOrdersList: dispatch(startSetAllOrdersList(groups))
    };
};


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(CustomerTasks);