import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import RegistrationActions from "store/actions/forms/registration";
import RegistrationAccessors from "store/reducers/forms/registration";
import BoundFormStepContainer from "components/Form/BoundFormStepContainer";
import Style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import BasicDetails from "./registration/BasicDetails";
import CommonProfile from "./registration/CommonProfile";
import AlreadyRegistered from "./registration/AlreadyRegistered";
import RegistrationConfirmation from "./registration/RegistrationConfirmation";

const FormStepContainer = BoundFormStepContainer(RegistrationActions, RegistrationAccessors);

export function RegisterPage(props) {
  const { classes } = props;

  console.log('localstorage', window.localStorage)
  return (
    <div style={{width: "100%"}} className={classes.container}>
      <FormStepContainer
        classes={classes}
        steps={{
          basicDetails: <BasicDetails />,
          alreadyRegistered: <AlreadyRegistered />,
          commonProfile: <CommonProfile />,
          registrationConfirmation: <RegistrationConfirmation />
        }}
        continueButtonLabel="Continue"
        previousButtonLabel="Previous"
      />
    </div>
  );
}

export default withStyles(Style)(RegisterPage);
