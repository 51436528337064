import React from "react";
import CustomChipInput from "components/CustomInput/CustomChipInput";
import Search from "@material-ui/icons/Search";
import { debounce } from "lodash";
import InputAdornment from "@material-ui/core/InputAdornment";
import TuneIcon from '@material-ui/icons/Tune';
import { withStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/searchControlStyle";

/**
 *
 *  Control will have two icons to right, one which triggers search, another which
 *  pops up the SearchDialog.
 *
 */
export function SearchControl(props) {
    const { classes, onSearch, onOpenDialog, onLiveSearch, ...rest } = props;
    const updateInput = (e) => {
        return onLiveSearch && onLiveSearch(e.target.value);
    };
    return (
            <CustomChipInput
                classes={classes}
                onUpdateInput={(e) => setTimeout(() => {
                    if (e.target) {
                        updateInput(e);
                    }
                    }, 1000)}
                inputProps={{
                    placeholder: "Search...",
                    endAdornment: (
                        <InputAdornment position="end">
                        <Search onClick={onSearch} />
                        <TuneIcon onClick={onOpenDialog}/>
                        </InputAdornment>
                    )
                }}
                blurBehavior="add"
                {...rest}
            />
    );
}

export default withStyles(styles)(SearchControl);
