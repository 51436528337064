/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { db, firebase, storage } from 'firebase/client';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Phone';
import SendIcon from '@material-ui/icons/Send';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Switch from '@material-ui/core/Switch';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Table from 'components/Table/Table.js';
import Box from '@material-ui/core/Box';
import Button from 'components/CustomButtons/Button.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import loader from 'assets/img/loader.gif';
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import actionsForNavigation from 'store/actions/navigation';
import Slide from '@material-ui/core/Slide';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';



import documentsStyle from 'assets/jss/material-dashboard-pro-react/views/documentsStyle.js';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { DateTime } from 'luxon';
import { isElementAccessExpression } from 'typescript';

import { updateUserData } from 'store/actions/auth.js';
import { addNewDocument, getDocuments } from 'store/actions/documentActions';
import ClientListDocumentsModal from './ClientListDocumentsModal';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';


import PdfLogo from 'assets/img/market-me/pdfLogo.png'

const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

export function DocumentsModalContent(props) {
  const { data, classes, setAddNewDocumentModal, setClientDocumentModal, setSelectedDocument, selectedDocument } = props

  console.log('selectedDocument', selectedDocument)

  const [borderSize, setBorderSize] = useState(1)


  return (

    <div className={classes.uploadedDocumentsDiv} style={ data.length > 0 && window.innerWidth > 574 ? { justifyContent: "center"} : { }}>



      <Card onClick={() => {
        setAddNewDocumentModal(true)
        setClientDocumentModal(false)
      }} className={classes.addDocBtn}>
        <CardBody style={{
          backgroundColor: "#C4C4C4", display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%"
        }}>
          <AddIcon style={{ fontSize: "65px" }} />
        </CardBody>
        <CardFooter style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "25%"
        }}>
          <p style={{
            fontSize: "18px", textAlign: "center", fontWeight: 400
          }}>Add New Document</p>
        </CardFooter>
      </Card>
      {data.documents ? data.documents.map((d, index) => {
        return (
          <Card style={selectedDocument && selectedDocument.documentId === d.documentId ? {  borderImage: "linear-gradient(0deg, rgba(86, 163, 166, 1), rgba(39, 75, 115, 1)) 1", borderStyle: "solid", borderWidth: "4px"  } : { border: "1px solid rgba(58, 192, 196, 1)",
          }} key={index} onClick={() => {
            if (selectedDocument.documentId && selectedDocument.documentId === d.documentId) {
              setSelectedDocument({})
            } else {
              setSelectedDocument(d)
            }
          }} className={classes.documentCard}>
            <CardBody style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              padding: "0px"
            }}>
              <img src={d.fileUrl} style={{ width: "100%" }} />
            </CardBody>
            <CardFooter style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "25%"
            }}>
              <p style={{
                fontSize: "18px", textAlign: "center", fontWeight: 400
              }}>{d.fileName}</p>
            </CardFooter>
          </Card>
        )
      }) : (
        null
      )}
    </div>
  );
}

DocumentsModalContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    uid: state.auth.user.uid,
    customerData: state.customers.customers
  };
}

const mapDispatchToProps = (dispatch) => ({
  addNewDocument: (id, document) => dispatch(addNewDocument(id, document)),
  getDocuments: (id) => dispatch(getDocuments(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(documentsStyle)
)(DocumentsModalContent);
