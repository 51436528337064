/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";

import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CardFooter from "components/Card/CardFooter";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Input from "@material-ui/core/Input";
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

// import CardFields from '../../../components/Card/CardFields';
// import CardField from '../../../components/Card/CardField';
import TextEditor from "components/TextEditor/TextEditor";
import actionsForNavigation from "store/actions/navigation";
import { connect } from "react-redux";
import { MockUserData } from 'variables/config';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import Close from "@material-ui/icons/Close";
import { getLeads, deleteLead, convertLeadToCustomer } from "store/actions/leadsActions"
import Slide from "@material-ui/core/Slide";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import campaignsStyle from "assets/jss/material-dashboard-pro-react/views/campaignsStyle.js";

import NumberBubble from "components/NumberBubble/NumberBubble";
import InformationModal from "components/InformationModal/InformationModal";
import InstagramIcon from "assets/img/market-me/instagramIcon.png";
import LinkedInIcon from "assets/img/market-me/linkedInLogo.png";
import EmailIcon from "assets/img/market-me/emailIcon.png";
import TextIcon from "assets/img/market-me/textIcon.png";
import CalendarIcon from "assets/img/market-me/calendarIcon.png";

import AuthActions from "store/actions/auth"
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles(campaignsStyle);

const LinkedInDM = (props) => {
  const classes = useStyles();
  const { user, setStep, campaignForm, handleChange, setPlatforms, platforms, goBack } = props;
  const [ editorText, setEditorText ] = useState('');
  const [ subjectText, setSubjectText ] = useState('');

  const placeholder = "Hey %FIRSTNAME%,\nI’d like to invite you as a VIP to our exclusive influencer dinner on March 1st before Social Media Marketing World ‘20.\nAs a marketing exec at %COMPANYNAME%, you’ll feel right at home with company like the CMOs of Google, Facebook, and LinkedIn, as well as the top minds in social media.\nRSVP here, and we’d love to see you there!";
  const modules = {
    toolbar: false
  }

  console.log('look user', user)

  const displayText = () => {
    const subject = (
      `<p><b>${subjectText}</b></p>`
    );
    return subject + '<br/>' + editorText;
  }

  return (
    <>
      <GridContainer className={classes.mainStepContainer}>
        <GridItem
          className={classes.stepHeader}
          style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}
        >
          <NumberBubble color="yellow" value="4." size="2.5rem" />
          <h4 style={{ fontWeight: 600, marginLeft: "1rem" }}>Compose LinkedIn Direct Messaging Ad</h4>
        </GridItem>
        <GridItem style={{ display: "flex", marginTop: "-1rem", alignItems: "end" }}>
          <GridContainer style={{ display: "flex" }}>
            <GridItem style={{ display: "flex", flexDirection: "column" }} xs={6}>
              <GridItem>
                <GridContainer className={classes.step3Container} style={{ marginTop: "2rem" }}>
                  <GridItem xs={6}>
                    <h4 style={{ fontWeight: 800 }}>Add Subject Line</h4>
                  </GridItem>
                  <GridItem xs={6} style={{ minWidth: "50rem", marginLeft: "-1rem" }}>
                    <CustomInput
                      formControlProps={{
                        style: {
                          width: "60%",
                          paddingTop: "0px",
                          marginLeft: "1rem"
                        }
                      }}
                      inputProps={{
                        placeholder: "VIP Invite to Influencer Dinner?",
                        required: true,
                        type: "text",
                        disableUnderline: true,
                        onChange: (e) => {
                          handleChange('linkedInDMSubject', e.target.value);
                          setSubjectText(e.target.value);
                          },
                        style: {
                          border: "2px solid #E60023",
                          borderRadius: "5px"
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={8} style={{ display: "flex", flexDirection: "column" }}>
                <h4 style={{ fontWeight: 800 }}>Message Text</h4>
                <ReactQuill
                    style={{ minHeight: "10rem", height: "1rem", minWidth: "29rem", marginTop: "1rem", marginBottom: "-2rem" }}
                    placeholder={placeholder}
                    modules={modules}
                    theme="snow"
                    onChange={setEditorText}
                  />
              </GridItem>
              <GridItem className={classes.step3Container} style={{ marginTop: "-5rem" }}>
                <h4 style={{ fontWeight: 800 }}>Call to Action Button</h4>
                <div style={{ display: "flex" }}>
                  <h4>Text</h4>
                  <CustomInput
                    formControlProps={{
                          style: {
                            width: "60%",
                            paddingTop: "0px",
                            marginLeft: "1rem"
                          }
                        }}
                        inputProps={{
                          placeholder: "RSVP Now, Learn More",
                          required: true,
                          type: "text",
                          disableUnderline: true,
                          onChange: (e) => handleChange('linkedInCallButton', e.target.value),
                          style: {
                            border: "2px solid #E60023",
                            borderRadius: "5px"
                          }
                        }}
                  />
                  <h4 style={{ marginLeft: "1rem" }}>URL</h4>
                  <CustomInput
                    formControlProps={{
                          style: {
                            width: "60%",
                            paddingTop: "0px",
                            marginLeft: "1rem",
                            marginRight: "-2rem"
                          }
                        }}
                        inputProps={{
                          placeholder: "www.landingpage.com",
                          required: true,
                          type: "text",
                          disableUnderline: true,
                          onChange: (e) => handleChange('linkedInCallURL', e.target.value),
                          style: {
                            border: "2px solid #E60023",
                            borderRadius: "5px"
                          }
                        }}
                  />
                </div>
              </GridItem>
            </GridItem>
            <GridItem style={{ display: "flex", flexDirection: "column" }} xs={6}>
              <GridContainer style={{ marginTop: "1rem" }}>
                <GridItem xs={7} style={{ width: "30rem", minHeight: "15rem" }}>
                  <h4 style={{ fontWeight: 800 }}>Direct Messaging Preview</h4>
                  <div className={classes.directMessageHeader}>
                    <h5 style={{ margin: "0", fontWeight: 800 }}>{user.firstName} {user.lastName}</h5>
                    <h8>{user.about ? user.about : null}</h8>
                  </div>
                  <div className={classes.directMessageProfile}>
                        {user.profilePic ? (
                          <img src={user.profilePic} className={classes.profilePictureCircle}/>
                        ) : null}
                        <h5 style={{ fontWeight: 800 }}>{user.firstName} {user.lastName}</h5>
                        <h8>{user.about ? user.about : null}</h8>
                  </div>
                  <ReactQuill
                    className={classes.directMessageQuill}
                    value={displayText()}
                    placeholder={placeholder}
                    modules={modules}
                    style={{
                      minHeight: "14rem"
                    }}
                    theme="snow"
                    readOnly
                  />
                </GridItem>
              </GridContainer>

            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem>
        </GridItem>
      </GridContainer>
      <GridContainer
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Button onClick={()=>setStep((prev) => {
          if (prev === 1) {
            goBack();
          } else {
            return (prev-1);
          }
          })} className={classes.backButton}>Back</Button>
        <Button onClick={()=>setStep((prev) => (prev+1))} className={classes.nextButton}>Next</Button>
      </GridContainer>
    </>
  )
}

function mapStateToProps(state) {
  return {
    user: state.auth.user
  };
}

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(campaignsStyle)
)(LinkedInDM));