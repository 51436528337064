import React from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import 'assets/css/material-dashboard-pro-react.css';


const CurrentScore = (props) => {
  const {classes, creditData} = props

  return (
      // <GridItem
      // id={'grid-item'}
      // sx={{
      //   direction: 'row',
      //   display: 'flex',
      //   justifyContent: 'space-around',
      //   alignItems: 'center',
      //   flex: '1 275',
      // }}
      // xs={12}
      // sm={12}
      // md={4}
      // lg={4}
      //   >
        <Card
          style={{
            height: '100%',
            margin: '0',
            //margin: '25px 0px 15px 0px',
            flex: '1' }}
        >
          <CardHeader color='primary'>
            <h3
              style={{
                textAlign: 'center',
                margin: '5px',
                fontFamily: "Metal Core",
                fontSize: "30px"
              }}
              className={classes.cardTitle}
            >
              Current Score
            </h3>
          </CardHeader>
          <CardBody style={{ textAlign: 'center' }}>
            <h1
              style={{
                fontSize: '3.5rem',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              {creditData.currentScore}
            </h1>
            <Button>Check Credit</Button>
          </CardBody>
        </Card>
      // </GridItem>
  )
}

export default CurrentScore