import React from 'react'
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Remove from '@material-ui/icons/Remove';
import LaunchIcon from '@material-ui/icons/Launch';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import 'assets/css/material-dashboard-pro-react.css';


const CreditChecks = (props) => {
    const { classes, creditData }=props

    const actionButtons = [
        { color: 'info', icon: LaunchIcon },
        { color: 'danger', icon: Remove },
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            className={classes.actionButton}
            key={key}
            style={{ color: 'white', alignItems: 'center' }}
          >
            <p style={{ margin: '0' }}>
              {prop.color === 'info' ? 'Details' : 'Remove'}
            </p>
            <prop.icon className={classes.icon} />
          </Button>
        );
      });

      console.log('creditDatacreditData', creditData)

      const tableData = creditData.creditChecks && creditData.creditChecks.map((check) => [
        check.date,
        check.score,
        actionButtons,
      ]);

  return (
    <GridItem
          sx={{
            direction: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            maxHeight: '250px',
            width: '100%',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={6}
          id={'report-grid-item'}
        >
          <Card style={{ height: '250px', margin: '25px 0px 15px 0px' }}>
            <CardHeader color='primary' style={{ padding: '10px' }}>
              <h5
                style={{
                  textAlign: 'center',
                  margin: '10px 0',
                  fontFamily: "Metal Core",
                  fontSize: "30px"
                }}
                className={classes.cardTitle}
              >
                Credit Checks
              </h5>
            </CardHeader>
            <CardBody style={{ padding: '5px 10px', overflow: 'scroll' }}>
              <Table
                tableHead={['Date', 'Score', 'Action']}
                tableData={tableData}
                customCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right,
                ]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right,
                ]}
                customHeadClassesForCells={[0, 4, 5]}
              />
            </CardBody>
          </Card>
        </GridItem>
  )
}

export default CreditChecks