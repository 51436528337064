/*eslint-disable*/
import { db, firebase, storage } from 'firebase/client';
import {
  getTransformIn,
  getTransformOut,
  applyTransform,
} from 'firebase/datasource';
import '@firebase/auth';

import { errorCodes } from 'variables/errors';
import { DataTypes } from 'variables/constants';
import { logger } from './util';
import { push } from 'connected-react-router';
import { getCustomers } from './customerActions'
import { getDemographics } from './demographicsActions'
import { setUpdateRegistrationProfilePic } from './form'
import { chain, handleError } from "./util";



const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const microSoftProvider = new firebase.auth.OAuthProvider('microsoft.com');
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
// const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// const users = db.collection("users");


const config = {
  fields: {
    dateOfBirth: { type: DataTypes.DATETIME },
  },
};

const transformIn = getTransformIn(config);
const transformOut = getTransformOut(config);


export const updateUserPhoto = (id, document) => {
  return (dispatch) => {
    return db.collection('users').doc(id).update({ profilePic: document.url })
      .then(() => {
        return db
          .collection('users')
          .doc(id)
          .get()
          .then((snapshot) => {
            snapshot = snapshot.data();
            dispatch(updateUser(snapshot));
          });
      })
  }
}

export const updateUserLogo = (id, document) => {
  return (dispatch) => {
    return db.collection('users').doc(id).update({ logo: document.url })
      .then(() => {
        return db.collection('users').doc(id).get()
          .then((snapshot) => {
            dispatch(updateUser(snapshot.data()));
          })
      })
  }
}

function startMicrosoftLogin() {
  return (dispatch) => {
    return firebase
      .auth()
      .signInWithPopup(microSoftProvider)
      .then((user) => {
        dispatch(setUser(user));
      });
  };
}


function startFacebookLogin() {
  return (dispatch) => {
    return firebase
      .auth()
      .signInWithPopup(facebookAuthProvider)
      .then((user) => {
        dispatch(setUser(user));
      });
  };
}


function startGoogleLogin() {
  return (dispatch) => {
    return firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then((user) => {
        dispatch(setUser(user));
      });
  };
}

export function startEmailLogin(credentials, userInfo) {
  return logger('startEmailLogin', (dispatch) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((user) => {
        console.log('userInfouserInfouserInfo', user)
        if (userInfo) {
          dispatch(setUserFromSignUp(user, userInfo));
          // push('/admin/user-page')
        } else {
          dispatch(setUser(user));
        }
      });
  });
}
export function startLoadProfile(user, history) {
  return logger('startLoadProfile', (dispatch) => {
    return db
      .collection('users')
      .doc(user.uid)
      .get()
      .then((snapshot) => {
        const userData = snapshot.data()
        console.log('startLoadProfile', userData, snapshot)
        if (!userData.subscription) {
          history.push(`/auth/choose-a-plan/${user.uid}`)
        } else {
          dispatch(
            setUser({
              ...applyTransform(transformIn, userData),
              uid: user.uid,
            })
          );
          // if (userData.userType && userData.userType === 'Data Furnisher') {
          //   dispatch(getCustomers(user.uid))
          //   dispatch(getLeads(user.uid))
          // }
        }
      });
  });
}


function startUpdateProfile(user) {
  return logger('startUpdateProfile', (dispatch) => {
    const { uid, ...docData } = user;
    return db
      .collection('users')
      .doc(uid)
      .set(applyTransform(transformOut, docData))
      .then(() => dispatch(updateUser(user)));
  });
}

function startUserAdvance(credentials) {
  // Check with Jonathan how he wants to handle the firebase call
  // return (dispatch) => {
  //     return firebase.auth()
  //         .signInWithEmailAndPassword(credentials.email, credentials.password)
  //         .then(user => {
  //             dispatch(setUser(user));
  //         }).catch((error) => {
  //             dispatch(setError(error));
  //         });
  // };
}

function startDelete(credentials) {
  return logger('startDelete', (dispatch) => {
    console.warn('deleting', credentials && credentials.email);

    const userCredential = credentials
      ? firebase
        .auth()
        .signInWithEmailAndPassword(credentials.email, credentials.password)
        .then((credential) => credential.user)
      : Promise.resolve(firebase.auth().currentUser);

    return userCredential
      .then((user) => {
        if (user) {
          return Promise.all([
            db.collection('users').doc(user.uid).delete(),
            user.delete(),
          ]).then(() => dispatch(logout()));
        } else {
          return Promise.reject({
            code: errorCodes.APP_AUTH_NOT_LOGGED_IN,
            message: 'user not logged in',
          });
        }
      })
      .catch((error) => {
        // firebase seems to generate an uncaught auth/wrong-password if the user does not exist. We get an error
        // saying the user does not exist here, but the auth/wrong-password seems totally uncatchable.
        console.warn(
          "Note, if there is an auth/wrong-password error just before/after this, don't worry about it"
        );
        return Promise.reject(error);
      });
  });
}

function startEmailSignup(credentials, profile) {
  const prepopInviteId = window.localStorage.getItem('inviteID')
  var userDoc = { credentials: credentials.email, ...profile };
  if (prepopInviteId) {
    userDoc.inviteId = prepopInviteId
  }
  if (window.localStorage.getItem('invitedByUID')) {
    userDoc.invitedByUID = window.localStorage.getItem('invitedByUID')
  }


  return (dispatch) => {
    if (credentials.file) {
      const random = Math.random()
      const storageRef = storage.ref(`temporaryProfilePicCache/${random}`)
      const fileRef = storageRef.child('profilePic')
      fileRef.put(credentials.file).then((snapshot) => {
        // need to store picture in a cached place in storage because we are creating the user and then the onauthstatechanged is happening before we set user with updated file placement.... a mess i know.

        snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('hereeeee', downloadURL)
          var temporaryDLURL = downloadURL
          userDoc.profilePic = temporaryDLURL
          return firebase
              .auth()
              .createUserWithEmailAndPassword(credentials.email, credentials.password)
              .then((user) => {
                return db
                  .collection('users')
                  .doc(user.user.uid)
                  .set(applyTransform(transformOut, userDoc))
                  .then(() => {
                    const storageRef = storage.ref(user.user.uid)
                    const fileRef = storageRef.child('profilePic')
                    fileRef.put(credentials.file).then((snapshot) => {
                      snapshot.ref.getDownloadURL().then((downloadURL) => {
                        userDoc.profilePic = downloadURL
                        return db.collection('users').doc(user.user.uid).update({
                          profilePic: downloadURL
                        }).then(() => {
                          // possibly delete it from cache?/?
                          if (window.localStorage.getItem('invitedByUID')) {
                            return db.collection('users').doc(window.localStorage.getItem('invitedByUID')).collection('customers').doc(window.localStorage.getItem('inviteID')).update({
                              accountCreation: "Created",
                              uid: user.user.uid
                            }).then(() => {
                              window.localStorage.removeItem('invitedByUID')
                              if (window.localStorage.getItem('emailForSignIn')) {
                                window.localStorage.removeItem('emailForSignIn')
                              }
                              if (window.localStorage.getItem('inviteID')) {
                                window.localStorage.removeItem('inviteID')
                              }
                              dispatch(setUser(userDoc));
                              dispatch(handleError(chain(startEmailLogin(credentials, userDoc), push('/auth/choose-a-plan'))))
                            })
                          } else {
                            dispatch(setUser(userDoc));
                            dispatch(handleError(chain(startEmailLogin(credentials, userDoc), push('/auth/choose-a-plan'))))
                          }
                        })
                      })
                    })

                  })
              }
              )

            })
        })
    } else {
      return firebase
      .auth()
      .createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then((user) => {
        return db
          .collection('users')
          .doc(user.user.uid)
          .set(applyTransform(transformOut, userDoc))
          .then(() => {
              if (window.localStorage.getItem('invitedByUID')) {
                return db.collection('users').doc(window.localStorage.getItem('invitedByUID')).collection('customers').doc(window.localStorage.getItem('inviteID')).update({
                  accountCreation: "Created",
                  uid: user.user.uid
                }).then(() => {
                  window.localStorage.removeItem('invitedByUID')
                  if (window.localStorage.getItem('emailForSignIn')) {
                    window.localStorage.removeItem('emailForSignIn')
                  }
                  if (window.localStorage.getItem('inviteID')) {
                    window.localStorage.removeItem('inviteID')
                  }
                  dispatch(handleError(chain(startEmailLogin(credentials, userDoc), push('/auth/choose-a-plan'))))
                })
              } else {
                dispatch(handleError(chain(startEmailLogin(credentials, userDoc), push('/auth/choose-a-plan'))))
              }

          })
      }
      )
    }
   }
}

export const updateuserType = (userType) => {
  return {
    type: 'UPDATE_USER_TYPE',
    userType,
  };
};

function startPasswordReset(emailAddress) {
  return (dispatch) =>
    firebase
      .auth()
      .sendPasswordResetEmail(emailAddress)
      .catch((error) => {
        dispatch(setError(error));
      });
}

function startLogout() {
  return (dispatch) => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(logout());
      })
      .catch((error) => {
        dispatch(setError(error));
      });
  };
}

function setUserFromSignUp(user, userInfo) {
  console.log('action.user action', userInfo)
  return {
    type: 'AUTH_SET_USER_SIGNUP',
    user,
    userInfo,
  };
}

function setUser(user) {
  return {
    type: 'AUTH_SET_USER',
    user,
  };
}

export const updateUserData = (uid, newInfo) => {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(uid)
      .update(newInfo)
      .then(() => {
        return db
          .collection('users')
          .doc(uid)
          .get()
          .then((snapshot) => {
            snapshot = snapshot.data();
            dispatch(updateUser(snapshot));
          });
      });
  };
};

function updateUser(user) {
  return {
    type: 'AUTH_UPDATE_USER',
    user,
  };
}

function setError(error) {
  return {
    type: 'AUTH_SET_ERROR',
    error,
  };
}

function logout() {
  return {
    type: 'AUTH_LOGOUT',
  };
}

// function thunkify(action) {
//   return (dispatch, getState) => {
//     try {
//       dispatch(action);
//       getState(); // I think this is only necessary becasue of the test framework.
//       return Promise.resolve();
//     } catch (error) {
//       return Promise.reject(error);
//     }
//   };
// }

// export function actionsForAuth() {
//   return {
//     updateUser: (user) => thunkify(updateUser(user)),
//   };
// }

export default {
  startGoogleLogin,
  startMicrosoftLogin,
  startEmailLogin,
  startUserAdvance,
  startEmailSignup,
  startPasswordReset,
  startLogout,
  startDelete,
  startLoadProfile,
  startUpdateProfile,
  setUser,
  updateUser,
  setError,
  logout,
  startFacebookLogin
};
