/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";

import NumberBubble from "components/NumberBubble/NumberBubble";
import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CardFooter from "components/Card/CardFooter";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Input from "@material-ui/core/Input";
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

// import CardFields from '../../../components/Card/CardFields';
// import CardField from '../../../components/Card/CardField';
import actionsForNavigation from "store/actions/navigation";
import { connect } from "react-redux";
import { MockUserData } from 'variables/config';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import Close from "@material-ui/icons/Close";
import { getLeads, deleteLead, convertLeadToCustomer } from "store/actions/leadsActions"
import Slide from "@material-ui/core/Slide";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import campaignsStyle from "assets/jss/material-dashboard-pro-react/views/campaignsStyle.js";

import InstagramIcon from "assets/img/market-me/instagramIcon.png";
import LinkedInIcon from "assets/img/market-me/linkedInLogo.png";
import EmailIcon from "assets/img/market-me/emailIcon.png";
import TextIcon from "assets/img/market-me/textIcon.png";
import CalendarIcon from "assets/img/market-me/calendarIcon.png";

import AuthActions from "store/actions/auth"
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";

import SmsStep from "./SmsStep";
import InstagramStep from "./InstagramStep";
import LinkedInStep from "./LinkedInStep";
import EmailStep from "./EmailStep";

const useStyles = makeStyles(campaignsStyle);

const Step2 = (props) => {
  const classes = useStyles();
  const { setStep, campaignForm, handleChange, setPlatforms, platforms } = props;

  const getSteps = () => {
    let steps = Object.entries(platforms).map((platform) => {
      console.log("checking check:", platforms)
      console.log("checking platform", platform[0])
      switch(platform[0]) {
        case 'instagram':
          if (platform[1]) {
            return <InstagramStep key="instagram" handleChange={handleChange} campaignForm={campaignForm} />;
          }
          break;
        case 'linkedin':
          if (platform[1]) {
            return <LinkedInStep key="linkedin" handleChange={handleChange} campaignForm={campaignForm} />;
          }
          break;
        case 'email':
          if (platform[1]) {
            return <EmailStep key="email" handleChange={handleChange} campaignForm={campaignForm} />;
          }
          break;
        case 'sms':
          if (platform[1]) {
            return <SmsStep key="sms" handleChange={handleChange} campaignForm={campaignForm} />;
          }
          break;
      }
    })
    steps = steps.filter((step) => step !== undefined);
    if (steps.length === 0) {
      return (<h5 style={{ fontWeight: 800, textAlign: "center" }}> Please select at least one Platform. </h5>)
    }
    return steps;
  }

  return (
    <>
      <GridContainer className={classes.mainStepContainer}>
        <GridItem
          className={classes.stepHeader}
          style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px", width: "-webkit-fill-available", display: "flex", alignItems: "center" }}
        >
          <NumberBubble value="2." color="yellow" size="2.5rem" />
          <h4 style={{ fontWeight: 600, marginLeft: "1rem" }}>Platform Specifics</h4>
        </GridItem>
        {getSteps()}
      </GridContainer>
      <GridContainer className={classes.nextButtonsContainer}>
        <Button onClick={()=>setStep(1)} className={classes.backButton}>Back</Button>
        <Button onClick={()=>setStep(3)} className={classes.nextButton}>Next</Button>
      </GridContainer>
    </>
  )
}

function mapStateToProps(state) {
  return {
  };
}

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(campaignsStyle)
)(Step2));