// ##############################
// // // Dashboard View styles
// #############################

const dashboardStyle = theme => ({
  primary: {
    backgroundColor: "#FFFFFF",
    color: "#FFFFFF",
  },
  fab: {
    position: "fixed",
    right: "25px",
    bottom: "20px",
    backgroundColor: "#CE8A59",
    [theme.breakpoints.down("sm")]: {
      right: "25px",
      bottom: "90px"
    }
  },
  investmentCards: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  dangerIcon: {
    color: 'red'
  },
  successIcon: {
    color: 'green'
  },
  cardIconTitle: {
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardProductTitle: {
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center"
  },
  cardCategory: {
    color: "#999999",
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: "#999999"
  },
  stats: {
    color: "#999999",
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0"
  },
  successText: {
    color: 'green'
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: "17px",
    height: "17px"
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px"
    }
  },
  searchButton: {
    marginTop: "1rem"
  },
  searchIcon: {
    color: "#aaa",
  },
  searchWrapper: {
    padding: "10px"
  },
  matchesPageCard: {
    margin: '0',
    boxShadow: "0px 1px 6px grey",
    alignItems: 'center',
    flex: '2',
    height: '3em',
    justifyContent: 'flex-start',
    background: "linear-gradient(108.56deg, #274B73 39.14%, #005479 64.5%) !important",
    [theme.breakpoints.down("sm")]: {
      // display: "none"
    }
  },
  searchBoxDiv: {
    width: '100%',
    margin: '0 auto',
    marginTop: '0px',
    marginBottom: '0px',
    padding: "0px",
    height: '100%',
    background: "rgba(236, 236, 236, 1)",
    [theme.breakpoints.up("md")]: {
      margin: '0 auto',
      marginTop: '0px',
      marginBottom: '0px',
      padding: "0px",
    },
    [theme.breakpoints.down("sm")]: {
     display: "flex",
     alignItems: "center",
    },
  }

});

export default dashboardStyle;
