/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { db, firebase, storage } from 'firebase/client';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Phone';
import SendIcon from '@material-ui/icons/Send';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Table from 'components/Table/Table.js';
import Box from '@material-ui/core/Box';
import Button from 'components/CustomButtons/Button.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import loader from 'assets/img/loader.gif';
import CardBody from 'components/Card/CardBody.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import actionsForNavigation from 'store/actions/navigation';
import Slide from '@material-ui/core/Slide';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Hidden from "@material-ui/core/Hidden";
import documentsStyle from 'assets/jss/material-dashboard-pro-react/views/documentsStyle.js';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { DateTime } from 'luxon';
import { isElementAccessExpression } from 'typescript';

import { updateUserData } from 'store/actions/auth.js';
import { setDocumentsReadyToSend, deleteDocument } from 'store/actions/documentActions';
import { sendDocumentsToCustomer } from 'store/actions/customerActions';
import ClientListDocumentsModal from './ClientListDocumentsModal';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';

import DocumentsModalContent from './DocumentsModalContent.jsx'
import AddNewDocumentsModalContent from './AddNewDocumentsModalContent.jsx'


import AddLogo from 'assets/img/market-me/addLogo.png'
import EditLogo from 'assets/img/market-me/editLogo.png'
import TrashLogo from 'assets/img/market-me/trashLogo.png'

import GradientAddLogo from 'assets/img/market-me/gradientAddLogo.png'
import GradientEditLogo from 'assets/img/market-me/gradientEditLogo.png'
import GradientTrashLogo from 'assets/img/market-me/gradientTrashLogo.png'

import 'assets/css/material-dashboard-pro-react.css';



const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

export function DataFurnisherDocuments(props) {
  const { classes, uid, customerData, initDataRetrieved } = props;
  const [docData, setDocData] = useState([])
  const [clientDocumentModal, setClientDocumentModal] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [addNewDocumentModal, setAddNewDocumentModal] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState({})
  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false)
  const [customerReceivedDocs, setCustomerReceivedDocs] = useState(false)
  const [needsToBeReadyToSendSnackbar, setNeedsToBeReadyToSendSnackbar] = useState(false)
  const [mobile, setMobile] = useState(false)

  const tableHeaders = ['Name', "Amount", "Last Date Sent", "Ready To Send", "Actions"]

  useEffect(() => {
    setDocData(customerData)
  }, [props])


  useEffect(() => {
    console.log('docDataxxxx', window.innerWidth)
    if (window.innerWidth < 574) {
      setMobile(true)
    }
  }, [window.innerWidth])


  const renderTableHeader = () => {
    return tableHeaders.map((key, index) => {
      return <th style={{fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px"}} className={classes.tableHeader} key={index}>{key}  </th>
    })
  }

  const renderTableData = () => {
    return docData.map((d, index) => {
      return (
        <tr className={classes.tableRow} onClick={() => {
          setSelectedCustomer(d)
        }} style={selectedCustomer.id && selectedCustomer.id === d.id ? { background: "rgba(94, 177, 227, 0.45)", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" } : index % 2 === 1 ? { background: "rgba(242, 242, 242, 1)", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" } : {fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px"}}>
          <td className={classes.tableCell}>
            {d.firstName + ' ' + d.lastName}
          </td>
          <td className={classes.tableCell}>
            {d.documents ? d.documents.length : 0}
          </td>
          <td className={classes.tableCell}>
            {d.lastDateSent ? new Date(d.lastDateSent.toMillis()).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" }) : 'N/A'}
          </td>
          <td className={classes.tableCell}>
            {d.readyToSend === true ? (
              <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                <p style={{ margin: "0px" }}>
                  Ready
                </p>
                <Switch
                  onChange={() => props.setDocumentsReadyToSend(uid, d.id, false)}
                  classes={{
                    track: classes.track,
                    thumb: classes.thumb,
                  }}
                  checked={d.readyToSend} />
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                <p style={{ margin: "0px" }}>
                  Not Ready
                </p>
                <Switch onChange={() => props.setDocumentsReadyToSend(uid, d.id, true)} checked={d.readyToSend} />
              </div>
            )}
          </td>
          <td style={{ display: "flex", width: "100%", justifyContent: "space-around" }} className={classes.tableCell}>
            <Button className={classes.sendBtn} onClick={() => {
              if (d.readyToSend) {
                props.sendDocumentsToCustomer(d.uid, d.documents, uid, d.id)
                setCustomerReceivedDocs(true)
              } else {
                setNeedsToBeReadyToSendSnackbar(true)
              }
            }}>
              <p style={{ margin: "0px" }}>Send</p>
              <SendIcon />
            </Button>
            <Button onClick={() => {
              setSelectedCustomer(d)
              setClientDocumentModal(true)
            }} style={{ display: "flex", width: "60%", justifyContent: "space-around", alignItems: "center", background: "rgba(39, 75, 115, 1)", color: "white" }}>
              <p style={{ margin: "0px" }}>View/Add</p>
              <OpenInNewIcon />
            </Button>
          </td>
        </tr>
      )
    })
  }

  const onSearchInput = (query) => {
    var input = query.toLowerCase()
    const term = input.trim();
    if (term) {
      var result = docData.filter((d) => {
        if (d.firstName.toLowerCase().includes(input) || d.lastName.toLowerCase().includes(input)) {
          return true
        }
      })
      setDocData(result)
    } else {
      setDocData(customerData)
    }
  };


  return (
    <div className={classes.container}>

      <Hidden className={classes.fullWidth} smDown implementation="css">
        <p style={{ fontSiz: "20px !important" }} className={classes.subTitle}>
          Review client documents and send them on if necessary.
        </p>
      </Hidden>

      <Card className={classes.filterCardBody}>
        <SearchBox id="investmentsQuery" onInput={onSearchInput} />
      </Card>
      <Card className={classes.documentCardBody}>
        <CardHeader style={{ fontSize: "30px" }} className={classes.cardHeader}>
          Client Documents
        </CardHeader>


        <Hidden mdUp implementation="css">
          <table style={{ width: "100%" }}>
            <tbody>
              {docData.map((d, index) => {
                return (
                  <div style={selectedCustomer.id && selectedCustomer.id === d.id ? { background: "rgba(94, 177, 227, 0.45)",  width: "90%", margin: "10px auto"  } : { width: "90%", margin: "10px auto" }} onClick={() => {
                    setSelectedCustomer(d)
                  }}>
                    <div style={{ display: "flex", justifyContent: 'space-between', height: "150px" }}>
                      <div style={{ display: "flex", height: "50px", width: "100%", padding: "5px", height: "150px", paddingLeft: "20px" }}>
                        <div style={{ width: "30%", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "20px" }}>
                          <img style={{ width: "auto", borderRadius: "100%", marginBottom: "10px", maxHeight: '115px' }} src={d.profilePic ? d.profilePic : avatar} />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: "70%", justifyContent: "space-evenly", fontSize: "20px", paddingLeft: "5px", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" }}>
                          <p>
                            <b>Name: </b>
                            {d.firstName + ' ' + d.lastName}
                          </p>
                          <p>
                          <b>Status: </b>
                            {d.readyToSend ? 'Ready To Send' : 'Not Ready'}
                          </p>

                          <p style={{ margin: "0px" }}>
                          <b># of documents: </b>
                            {d.documents ? d.documents.length : 0}
                          </p>
                        </div>
                      </div>
                    </div>


                    {d.readyToSend ? (
                      <div style={{
                        display: "flex", margin: "0px auto",
                        justifyContent: "space-around"
                      }}>
                        <Button className={classes.sendBtn} onClick={() => {
                          props.sendDocumentsToCustomer(d.uid, d.documents, uid, d.id)
                          setCustomerReceivedDocs(true)
                        }}>
                          <p style={{ marginRight: "10px", marginBottom: "0px" }}>Send</p>
                          <SendIcon />
                        </Button>


                        <Button className={classes.markUnreadyBtn} onClick={() => {
                          props.setDocumentsReadyToSend(uid, d.id, false)
                        }}>
                          <p style={{ marginRight: "10px", marginBottom: "0px" }}>Not Ready</p>
                          <Close style={{ fontSize: "12px" }} />
                        </Button>
                      </div>
                    ) : (
                      <div style={{ display: "flex", flexDirection: "column", padding: "0px 20px" }}>
                        <Button className={classes.readyToSendBtn} onClick={() => props.setDocumentsReadyToSend(uid, d.id, true)}>
                          <p style={{ margin: "0px" }}>Ready To Send</p>
                        </Button>
                      </div>
                    )}
                    {/*
                      <Button onClick={() => {
                        setSelectedCustomer(d)
                        setClientDocumentModal(true)
                      }} style={{ display: "flex", width: "60%", justifyContent: "space-around", alignItems: "center", background: "rgba(39, 75, 115, 1)", color: "white" }}>
                        <p style={{ margin: "0px" }}>View/Add</p>
                        <OpenInNewIcon />
                      </Button> */}
                    <div style={{ width: "90%", height: "2px", background: "linear-gradient(89.97deg, rgba(238, 189, 9, 1) 0.99%, rgba(86, 163, 166, 1) 99.97%)", margin: "30px auto 0px auto" }}>
                    </div>
                  </div>
                )
              })}
            </tbody>
          </table>
        </Hidden>

        <Hidden smDown implementation="css">
          <table style={{ width: "100%" }}>
            <tbody>
              <tr style={{ height: "50px", width: "100%" }}>{renderTableHeader()} </tr>
              {renderTableData()}
            </tbody>
          </table>
        </Hidden>
      </Card>
      {selectedCustomer.id ? (
        <Fab onClick={() => {
          setClientDocumentModal(true)
        }} className={classes.newRecord}><AddIcon className={classes.addBtn} /></Fab>
      ) : (
        null
      )}








      <Dialog
        style={{ width: "100%" }}
        classes={{
          // root: classes.modalRoot,
          // paper: classes.modal + " " + classes.modalSmall
        }}
        fullWidth={true}
        open={deleteDocumentModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDeleteDocumentModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setDeleteDocumentModal(false)
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>

          <h3>Are You Sure You Want To Delete {selectedDocument.fileName}?</h3>

        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.modalRoot,
          }}
          style={{ width: "100%" }}
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
            <Button onClick={() => {
              props.deleteDocument(uid, selectedDocument, selectedCustomer)
              setDeleteDocumentModal(false)
              setClientDocumentModal(true)
              var newDocs = selectedCustomer.documents.filter((d) => {
                if (d.documentId !== selectedDocument.documentId) {
                  return true
                } else {
                  return false
                }
              })
              var newCustomerData = { ...selectedCustomer }
              newCustomerData.documents = newDocs
              setSelectedCustomer(newCustomerData)
              setSelectedDocument({})
            }} style={{ backgroundColor: "rgb(86, 163, 166)" }}>
              Yes
            </Button>
            <Button onClick={() => setDeleteDocumentModal(false)}>
              No
            </Button>
          </div>

        </DialogContent>
      </Dialog>


      <Dialog
        style={{ width: "100%" }}
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalMedium
        }}
        fullWidth={true}
        open={clientDocumentModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setClientDocumentModal(false)
          setSelectedCustomer({})
        }}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setClientDocumentModal(false)
              setSelectedCustomer({})
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>

          <h3>{selectedCustomer.firstName + ' ' + selectedCustomer.lastName} Documents</h3>

        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.modalRoot,
          }}
          style={{ width: "100%" }}
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <div className={classes.addNewDocModalContentDiv}>
            {selectedDocument.documentId ? (
              <div className={classes.AddEditDeleteBtns}>
                <img onClick={() => {
                  setAddNewDocumentModal(true)
                  setClientDocumentModal(false)
                }} className={classes.editTrashBtns} src={GradientAddLogo} />
                <img className={classes.editTrashBtns} src={GradientEditLogo} />
                <img onClick={() => {
                  setDeleteDocumentModal(true)
                  setAddNewDocumentModal(false)
                  setClientDocumentModal(false)
                }} className={classes.editTrashBtns} src={GradientTrashLogo} />
              </div>
            ) : (
              <div className={classes.AddEditDeleteBtns}>
                <img className={classes.editTrashBtns} src={AddLogo} />
                <img className={classes.editTrashBtns} src={EditLogo} />
                <img className={classes.editTrashBtns} src={TrashLogo} />
              </div>
            )}
          </div>
          <DocumentsModalContent
            selectedDocument={selectedDocument} setSelectedDocument={setSelectedDocument} setClientDocumentModal={setClientDocumentModal} setAddNewDocumentModal={setAddNewDocumentModal} data={selectedCustomer} />
        </DialogContent>
        <Button className={classes.fullWidthBackBtn} onClick={() => {
          setClientDocumentModal(false)
          setSelectedCustomer({})
        }}>
          Back
        </Button>
      </Dialog>


      <Dialog
        style={{ width: "100%" }}
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalMedium
        }}
        fullWidth={true}
        open={addNewDocumentModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setAddNewDocumentModal(false)
          setClientDocumentModal(true)
        }}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setAddNewDocumentModal(false)
              setClientDocumentModal(true)
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>

          <h3>Add New Documents For {selectedCustomer.firstName + ' ' + selectedCustomer.lastName}</h3>

        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.modalRoot,
          }}
          style={{ width: "100%" }}
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <AddNewDocumentsModalContent setAddNewDocumentModal={setAddNewDocumentModal} setClientDocumentModal={setClientDocumentModal} selectedCustomer={selectedCustomer} />
        </DialogContent>

      </Dialog>


      <Snackbar open={needsToBeReadyToSendSnackbar} onClose={() => setNeedsToBeReadyToSendSnackbar(false)}>
        <Alert onClose={() => setNeedsToBeReadyToSendSnackbar(false)} severity='error'>
          Please check ready to send and try again.
        </Alert>
      </Snackbar>
      <Snackbar open={customerReceivedDocs} onClose={() => setCustomerReceivedDocs(false)}>
        <Alert onClose={() => setCustomerReceivedDocs(false)} severity='success'>
          The customer received the documents succesfully
        </Alert>
      </Snackbar>
    </div>
  );
}

DataFurnisherDocuments.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  console.log('stateeeeee', state.customers);
  return {
    uid: state.auth.user.uid,
    customerData: state.customers.customers
  };
}

const mapDispatchToProps = (dispatch) => ({
  addNewDocument: (id, document) => dispatch(addNewDocument(id, document)),
  getDocuments: (id) => dispatch(getDocuments(id)),
  setDocumentsReadyToSend: (uid, customerId, bool) => dispatch(setDocumentsReadyToSend(uid, customerId, bool)),
  deleteDocument: (uid, selectedDocument, selectedCustomer) => dispatch(deleteDocument(uid, selectedDocument, selectedCustomer)),
  sendDocumentsToCustomer: (customerUID, documents, userUID, customerId) => dispatch(sendDocumentsToCustomer(customerUID, documents, userUID, customerId))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(documentsStyle)
)(DataFurnisherDocuments);
