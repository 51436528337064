export const userTypes = {
    FURNISHER: "Data Furnisher",
    CREDIT_APPLICANT: "Credit Applicant",
    ADMIN: "Admin",
}

export const MockUserData = [{
  "id": 1,
  "firstName": "Gwyneth",
  "lastName": "Di Iorio",
  "email": "gdiiorio0@ycombinator.com",
  "companyName": "Tazzy",
  "cellPhone": "106-734-8637",
  "address": "5722 Forest Park"
}, {
  "id": 2,
  "firstName": "Rhys",
  "lastName": "Antonijevic",
  "email": "rantonijevic1@ucsd.edu",
  "companyName": "Realcube",
  "cellPhone": "482-748-4877",
  "address": "37 Macpherson Park"
}, {
  "id": 3,
  "firstName": "Juditha",
  "lastName": "Ruckledge",
  "email": "jruckledge2@japanpost.jp",
  "companyName": "Mynte",
  "cellPhone": "159-844-7581",
  "address": "1532 Hauk Hill"
}, {
  "id": 4,
  "firstName": "Eugenius",
  "lastName": "Edgett",
  "email": "eedgett3@digg.com",
  "companyName": "Jaxspan",
  "cellPhone": "533-221-8869",
  "address": "49 Blue Bill Park Trail"
}, {
  "id": 5,
  "firstName": "Bogey",
  "lastName": "Huebner",
  "email": "bhuebner4@creativecommons.org",
  "companyName": "Zooveo",
  "cellPhone": "586-553-9881",
  "address": "47175 Eliot Parkway"
}, {
  "id": 6,
  "firstName": "Alyosha",
  "lastName": "Dreossi",
  "email": "adreossi5@columbia.edu",
  "companyName": "Miboo",
  "cellPhone": "513-545-1592",
  "address": "3613 Mayer Circle"
}, {
  "id": 7,
  "firstName": "Halie",
  "lastName": "Pimlett",
  "email": "hpimlett6@live.com",
  "companyName": "Devcast",
  "cellPhone": "333-374-5117",
  "address": "19949 Erie Point"
}, {
  "id": 8,
  "firstName": "Neale",
  "lastName": "Lutty",
  "email": "nlutty7@imageshack.us",
  "companyName": "Mynte",
  "cellPhone": "894-624-1975",
  "address": "8 Schmedeman Court"
}, {
  "id": 9,
  "firstName": "Suzie",
  "lastName": "Crichley",
  "email": "scrichley8@uiuc.edu",
  "companyName": "Flipbug",
  "cellPhone": "215-335-8381",
  "address": "589 Kings Avenue"
}, {
  "id": 10,
  "firstName": "Brittan",
  "lastName": "Trueman",
  "email": "btrueman9@buzzfeed.com",
  "companyName": "Devpoint",
  "cellPhone": "720-949-0085",
  "address": "0 Merrick Point"
}, {
  "id": 11,
  "firstName": "Sharla",
  "lastName": "Ickovici",
  "email": "sickovicia@plala.or.jp",
  "companyName": "Dynava",
  "cellPhone": "344-595-8924",
  "address": "7 Dovetail Crossing"
}, {
  "id": 12,
  "firstName": "Linet",
  "lastName": "Donovan",
  "email": "ldonovanb@rakuten.co.jp",
  "companyName": "InnoZ",
  "cellPhone": "580-822-0229",
  "address": "15 Farmco Parkway"
}, {
  "id": 13,
  "firstName": "Crissy",
  "lastName": "Slessar",
  "email": "cslessarc@bigcartel.com",
  "companyName": "Twitternation",
  "cellPhone": "707-625-8104",
  "address": "99368 Basil Parkway"
}, {
  "id": 14,
  "firstName": "Gabrielle",
  "lastName": "Master",
  "email": "gmasterd@free.fr",
  "companyName": "Skyba",
  "cellPhone": "260-200-4325",
  "address": "929 Loftsgordon Junction"
}, {
  "id": 15,
  "firstName": "Erasmus",
  "lastName": "Main",
  "email": "emaine@nbcnews.com",
  "companyName": "Roodel",
  "cellPhone": "664-114-1250",
  "address": "2 Clarendon Drive"
}, {
  "id": 16,
  "firstName": "Livvy",
  "lastName": "McCafferky",
  "email": "lmccafferkyf@flavors.me",
  "companyName": "Aimbo",
  "cellPhone": "855-252-2743",
  "address": "113 Moose Hill"
}, {
  "id": 17,
  "firstName": "Shel",
  "lastName": "Schwand",
  "email": "sschwandg@is.gd",
  "companyName": "Devcast",
  "cellPhone": "596-723-2428",
  "address": "5 Quincy Avenue"
}, {
  "id": 18,
  "firstName": "Brandy",
  "lastName": "Giacubbo",
  "email": "bgiacubboh@naver.com",
  "companyName": "Skinix",
  "cellPhone": "335-569-7043",
  "address": "74404 Fieldstone Hill"
}, {
  "id": 19,
  "firstName": "Marcia",
  "lastName": "MacCarlich",
  "email": "mmaccarlichi@acquirethisname.com",
  "companyName": "Ozu",
  "cellPhone": "313-965-6826",
  "address": "366 Schurz Hill"
}, {
  "id": 20,
  "firstName": "Neala",
  "lastName": "Sandcroft",
  "email": "nsandcroftj@123-reg.co.uk",
  "companyName": "Yamia",
  "cellPhone": "100-392-7540",
  "address": "1 Lien Point"
}, {
  "id": 21,
  "firstName": "Kincaid",
  "lastName": "Crickett",
  "email": "kcrickettk@amazonaws.com",
  "companyName": "Fadeo",
  "cellPhone": "712-835-6177",
  "address": "59 Summer Ridge Trail"
}, {
  "id": 22,
  "firstName": "Damaris",
  "lastName": "Ferrettino",
  "email": "dferrettinol@house.gov",
  "companyName": "Plambee",
  "cellPhone": "360-736-8846",
  "address": "29458 Eastlawn Drive"
}, {
  "id": 23,
  "firstName": "Erina",
  "lastName": "Boynton",
  "email": "eboyntonm@blogtalkradio.com",
  "companyName": "Thoughtblab",
  "cellPhone": "743-312-5759",
  "address": "1 West Street"
}, {
  "id": 24,
  "firstName": "Cynde",
  "lastName": "Fincken",
  "email": "cfinckenn@usa.gov",
  "companyName": "Blogtags",
  "cellPhone": "996-445-8263",
  "address": "9 Dakota Alley"
}, {
  "id": 25,
  "firstName": "Jamaal",
  "lastName": "Dogerty",
  "email": "jdogertyo@cam.ac.uk",
  "companyName": "Kwimbee",
  "cellPhone": "611-951-5303",
  "address": "752 Anzinger Trail"
}, {
  "id": 26,
  "firstName": "Trudie",
  "lastName": "Gouldeby",
  "email": "tgouldebyp@sciencedaily.com",
  "companyName": "Trudeo",
  "cellPhone": "615-927-4733",
  "address": "158 Garrison Avenue"
}, {
  "id": 27,
  "firstName": "Parry",
  "lastName": "Michallat",
  "email": "pmichallatq@salon.com",
  "companyName": "Voonte",
  "cellPhone": "310-492-2263",
  "address": "03484 6th Trail"
}, {
  "id": 28,
  "firstName": "Yanaton",
  "lastName": "Barbier",
  "email": "ybarbierr@cpanel.net",
  "companyName": "Tanoodle",
  "cellPhone": "632-334-8506",
  "address": "6 Graedel Way"
}, {
  "id": 29,
  "firstName": "Marmaduke",
  "lastName": "Morrice",
  "email": "mmorrices@shareasale.com",
  "companyName": "Bluezoom",
  "cellPhone": "889-542-3343",
  "address": "0148 Graedel Crossing"
}, {
  "id": 30,
  "firstName": "Stanford",
  "lastName": "Verick",
  "email": "sverickt@timesonline.co.uk",
  "companyName": "Mynte",
  "cellPhone": "100-909-7503",
  "address": "5076 Glendale Hill"
}, {
  "id": 31,
  "firstName": "Blisse",
  "lastName": "Giovanni",
  "email": "bgiovanniu@bloglines.com",
  "companyName": "Minyx",
  "cellPhone": "662-970-1767",
  "address": "4 Raven Lane"
}, {
  "id": 32,
  "firstName": "Marie-ann",
  "lastName": "Barrowclough",
  "email": "mbarrowcloughv@gov.uk",
  "companyName": "Devbug",
  "cellPhone": "390-459-8784",
  "address": "11 Center Road"
}, {
  "id": 33,
  "firstName": "Tish",
  "lastName": "Kowalik",
  "email": "tkowalikw@thetimes.co.uk",
  "companyName": "JumpXS",
  "cellPhone": "758-184-3860",
  "address": "9 Red Cloud Junction"
}, {
  "id": 34,
  "firstName": "Gwenora",
  "lastName": "Michieli",
  "email": "gmichielix@google.co.jp",
  "companyName": "Zoonoodle",
  "cellPhone": "912-884-6118",
  "address": "13091 American Ash Hill"
}, {
  "id": 35,
  "firstName": "Darin",
  "lastName": "Antonopoulos",
  "email": "dantonopoulosy@sitemeter.com",
  "companyName": "Eare",
  "cellPhone": "227-660-8545",
  "address": "708 Mcbride Parkway"
}, {
  "id": 36,
  "firstName": "Morie",
  "lastName": "Sibson",
  "email": "msibsonz@skyrock.com",
  "companyName": "Zooveo",
  "cellPhone": "581-786-6437",
  "address": "6 Arrowood Crossing"
}, {
  "id": 37,
  "firstName": "Lindsey",
  "lastName": "MacGettigen",
  "email": "lmacgettigen10@multiply.com",
  "companyName": "Zooxo",
  "cellPhone": "121-637-9742",
  "address": "617 Loftsgordon Terrace"
}, {
  "id": 38,
  "firstName": "Odetta",
  "lastName": "Unsworth",
  "email": "ounsworth11@wisc.edu",
  "companyName": "Gevee",
  "cellPhone": "115-870-6599",
  "address": "804 1st Court"
}, {
  "id": 39,
  "firstName": "Hy",
  "lastName": "Farnon",
  "email": "hfarnon12@sfgate.com",
  "companyName": "Shufflester",
  "cellPhone": "209-717-6822",
  "address": "6507 Melrose Crossing"
}, {
  "id": 40,
  "firstName": "Duncan",
  "lastName": "Whitehorne",
  "email": "dwhitehorne13@so-net.ne.jp",
  "companyName": "Kwinu",
  "cellPhone": "161-718-7291",
  "address": "9 West Parkway"
}, {
  "id": 41,
  "firstName": "Rosanna",
  "lastName": "Izkovitch",
  "email": "rizkovitch14@typepad.com",
  "companyName": "Dabtype",
  "cellPhone": "719-772-0211",
  "address": "04 Bellgrove Avenue"
}, {
  "id": 42,
  "firstName": "Rip",
  "lastName": "Goodbarne",
  "email": "rgoodbarne15@hc360.com",
  "companyName": "Yakidoo",
  "cellPhone": "886-830-4393",
  "address": "7781 Shasta Road"
}, {
  "id": 43,
  "firstName": "Caril",
  "lastName": "Redbourn",
  "email": "credbourn16@globo.com",
  "companyName": "Yakijo",
  "cellPhone": "596-938-1952",
  "address": "9 Butternut Center"
}, {
  "id": 44,
  "firstName": "Land",
  "lastName": "Branchflower",
  "email": "lbranchflower17@google.co.jp",
  "companyName": "Divanoodle",
  "cellPhone": "161-343-7328",
  "address": "08808 Clove Road"
}, {
  "id": 45,
  "firstName": "Joleen",
  "lastName": "O'Sherin",
  "email": "josherin18@prlog.org",
  "companyName": "Skidoo",
  "cellPhone": "593-670-6086",
  "address": "31 Bartillon Crossing"
}, {
  "id": 46,
  "firstName": "Quinlan",
  "lastName": "Willshire",
  "email": "qwillshire19@sfgate.com",
  "companyName": "Pixoboo",
  "cellPhone": "102-140-2607",
  "address": "8 Talmadge Junction"
}, {
  "id": 47,
  "firstName": "Sherilyn",
  "lastName": "Say",
  "email": "ssay1a@gov.uk",
  "companyName": "Leenti",
  "cellPhone": "472-990-7362",
  "address": "80922 New Castle Way"
}, {
  "id": 48,
  "firstName": "Carolus",
  "lastName": "Fawcus",
  "email": "cfawcus1b@mapquest.com",
  "companyName": "Oyondu",
  "cellPhone": "621-719-3694",
  "address": "275 Ruskin Junction"
}, {
  "id": 49,
  "firstName": "Trace",
  "lastName": "Sans",
  "email": "tsans1c@chicagotribune.com",
  "companyName": "InnoZ",
  "cellPhone": "515-404-6601",
  "address": "7224 Cordelia Place"
}, {
  "id": 50,
  "firstName": "Bebe",
  "lastName": "Becraft",
  "email": "bbecraft1d@arizona.edu",
  "companyName": "Jabberstorm",
  "cellPhone": "757-635-6924",
  "address": "0490 Holy Cross Point"
}]



export const uris = Object.freeze({
    HOME: "/admin/customers/explorer"
});

export const logging = Object.freeze({
    LOG_ASYNC_ACTIONS : true
});