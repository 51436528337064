/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";


import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import CardFooter from "components/Card/CardFooter";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import BoundSearchControl from "../../../components/Explorer/BoundSearchControl";
// import BoundSearchDialog from "../../../components/Explorer/BoundSearchDialog";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Input from "@material-ui/core/Input";

// import CardFields from '../../../components/Card/CardFields';
// import CardField from '../../../components/Card/CardField';
import actionsForNavigation from "store/actions/navigation";
import { connect } from "react-redux";
import { MockUserData } from 'variables/config';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import Close from "@material-ui/icons/Close";
import { getLeads, deleteLead, convertLeadToCustomer } from "store/actions/leadsActions"
import Slide from "@material-ui/core/Slide";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import AuthActions from "store/actions/auth"
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";


import GoogleLogo from 'assets/img/market-me/googleLogo.png'
import EmailLogo from 'assets/img/market-me/Login-Sign Up/email-logo-grey 1.png'
import FacebookLogo from 'assets/img/market-me/Login-Sign Up/Facebook.png'





export function LoginBox(props) {
  const { classes, authError, history } = props
  const [emailInput, setEmailInput] = useState('')
  const [passwordInput, setPasswordInput] = useState('')
  const [passwordNotice, setPasswordNotice] = useState(null)

  const handlePasswordReset = (e) => {
    e.preventDefault()
    if (!emailInput) {
      setPasswordNotice("Please add your email above")
    } else {
      props.passwordReset(emailInput)
      setPasswordNotice("Password reset link sent. Please check your email.")
    }
  }

  return (
    <div>
      <Hidden mdUp implementation='css'>
        <Card className={classes.mobileMainContainer}>
          <CardHeader className={classes.cardHeader}>
            <Button onClick={() => {
              props.startGoogleLogin()
            }} className={classes.googleLogo}>  <img style={{ height: "45px" }} src={GoogleLogo} /></Button>
            <Button onClick={() => props.startMicrosoftLogin()} className={classes.microsoftLogo}><img style={{ height: "50px" }} src={EmailLogo} /></Button>
            <Button onClick={() => props.startFacebookLogin()} className={classes.fbLogo}><img style={{ height: "65px" }} src={FacebookLogo} /></Button>
          </CardHeader>
          <CardBody className={classes.cardBody}>
            <CustomInput
              needWhite
              labelText="Email"
              id="email"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => setEmailInput(e.target.value),
                disableUnderline: true,
                form: {
                  autocomplete: 'off',
                },
                style: {
                  border: "2px solid rgba(15, 55, 87, 0.6)",
                  borderRadius: "5px",
                  height: "50px",
                }
                // endAdornment: (
                //   <InputAdornment position="end">
                //     <Email />
                //   </InputAdornment>
                // )
              }}
            />
            <CustomInput
              needWhite
              labelText="Password"
              id="password"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => setPasswordInput(e.target.value),
                type: "password",
                disableUnderline: true,
                form: {
                  autocomplete: 'off',
                },
                style: {
                  border: "2px solid rgba(15, 55, 87, 0.6)",
                  borderRadius: "5px",
                  height: "50px",
                }
                // endAdornment: (
                //   <InputAdornment position="end">
                //     <Icon>
                //       lock_outline
                //     </Icon>
                //   </InputAdornment>
                // )
              }}
            />
            <p style={{ color: "white", fontWeight: 500, fontSize: "16px" }}>Forgot Password? Click <b onClick={handlePasswordReset} className={classes.clickHere} style={{ color: "rgba(94, 177, 227, 1)", textDecoration: "underline" }}>here</b> to send a password reset email...</p>
          </CardBody>
          {authError ? (
            <div className={classes.justifyContentCenter}>
              <p className={classes.textDanger}><br /><b>Error:</b> {authError.message}<br /><br /><b>Did you forget your password?</b></p>
              <Button
                style={{ backgroundColor: "rgba(94, 177, 227, 1)", fontWeight: "600", color: "#FFFFFF" }}
                type="button"
                onClick={handlePasswordReset}
                color="danger"
                simple size="lg"
                block>
                Reset Password
              </Button>
            </div>
          ) : null}
          {passwordNotice ? <p className={classes.textDanger}>{passwordNotice}</p> : null}
          <CardFooter style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={() => history.push("/auth/register-page")}
              className={classes.continueButton}
              style={{ backgroundColor: "rgba(33, 144, 192, 1)", color: "white" }}
              type="submit"
              color="primary"
              simple size="lg"
              block>
              Sign Up
            </Button>
            <Button
              onClick={() => {
                props.emailSignIn({ email: emailInput, password: passwordInput })
              }}
              className={classes.continueButton}
              style={{ backgroundColor: "rgba(94, 177, 227, 1)", color: "white" }}
              type="submit"
              color="primary"
              simple size="lg"
              block>
              Login
            </Button>
          </CardFooter>
        </Card>
      </Hidden>


      <Hidden smDown implementation='css'>
        <Card className={classes.mainContainer}>
          <CardHeader className={classes.cardHeader}>
            <Button onClick={() => props.startGoogleLogin()} className={classes.googleLogo}>  <img style={{ height: "45px" }} src={GoogleLogo} /></Button>
            <Button onClick={() => props.startMicrosoftLogin()}  className={classes.microsoftLogo}><img style={{ height: "50px" }} src={EmailLogo} /></Button>
            <Button onClick={() => props.startFacebookLogin()}  className={classes.fbLogo}><img style={{ height: "45px" }} src={FacebookLogo} /></Button>
          </CardHeader>
          <CardBody className={classes.cardBody}>
            <CustomInput
              needWhite
              labelText="Email"
              id="email"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => setEmailInput(e.target.value),
                disableUnderline: true,
                form: {
                  autocomplete: 'off',
                },
                style: {
                  border: "2px solid rgba(15, 55, 87, 0.6)",
                  borderRadius: "5px",
                  height: "50px",
                }
                // endAdornment: (
                //   <InputAdornment position="end">
                //     <Email />
                //   </InputAdornment>
                // )
              }}
              labelProps={{
                color: "black"
              }}
            />
            <CustomInput
              needWhite
              labelText="Password"
              id="password"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => setPasswordInput(e.target.value),
                type: "password",
                disableUnderline: true,
                form: {
                  autocomplete: 'off',
                },
                style: {
                  border: "2px solid rgba(15, 55, 87, 0.6)",
                  borderRadius: "5px",
                  height: "50px",
                }
                // endAdornment: (
                //   <InputAdornment position="end">
                //     <Icon>
                //       lock_outline
                //     </Icon>
                //   </InputAdornment>
                // )
              }}
              labelProps={{
                color: "black"
              }}
            />
            <p style={{ color: "black", fontWeight: 500, fontSize: "16px" }}>Forgot Password? <b onClick={handlePasswordReset} className={classes.clickHere} style={{ color: "rgba(166, 24, 40, 1)", textDecoration: "underline" }}>Click here</b> to send a password reset email...</p>
          </CardBody>
          {authError ? (
            <div className={classes.justifyContentCenter}>
              <p className={classes.textDanger}><br /><b>Error:</b> {authError.message}<br /><br /><b>Did you forget your password?</b></p>
              <Button
                style={{ backgroundColor: "rgba(94, 177, 227, 1)", fontWeight: "600", color: "#FFFFFF" }}
                type="button"
                onClick={handlePasswordReset}
                color="danger"
                simple size="lg"
                block>
                Reset Password
              </Button>
            </div>
          ) : null}
          {passwordNotice ? <p className={classes.textDanger}>{passwordNotice}</p> : null}
          <CardFooter style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={() => history.push("/auth/register-page")}
              className={classes.continueButton}
              style={{ backgroundColor: "rgba(33, 144, 192, 1)", color: "white" }}
              type="submit"
              color="primary"
              simple size="lg"
              block>
              Sign Up
            </Button>
            <Button
              onClick={() => {
                props.emailSignIn({ email: emailInput, password: passwordInput })
              }}
              className={classes.continueButton}
              style={{ backgroundColor: "rgba(252, 243, 52, 1)", color: "black" }}
              type="submit"
              color="primary"
              simple size="lg"
              block>
              Login
            </Button>
          </CardFooter>
        </Card>
      </Hidden>
    </div>
  );
}


function mapStateToProps(state) {
  return {
    authError: state.auth.error
  }
}

function mapDispatchToProps(dispatch) {
  return {
    startGoogleLogin: () => dispatch(AuthActions.startGoogleLogin()),
    emailSignIn: (credentials) => dispatch(handleError(chain(AuthActions.startEmailLogin(credentials), push('/admin/user-page')), AuthActions.setError)),
    passwordReset: (email) => dispatch(AuthActions.startPasswordReset(email)),
    startMicrosoftLogin: () => dispatch(AuthActions.startMicrosoftLogin()),
    startFacebookLogin: () => dispatch(AuthActions.startFacebookLogin)
  }
}

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(loginPageStyle)
)(LoginBox));
