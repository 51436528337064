/*eslint-disable*/
import React from 'react'
import { db, firebase, storage } from 'firebase/client';



export const deleteDocument = (uid, selectedDocument, selectedCustomer) => {
  console.log('uid, selectedDocument, selectedCustomer', uid, selectedDocument, selectedCustomer)

  var newDocs = selectedCustomer.documents.filter((d) => {
    if (d.documentId !== selectedDocument.documentId) {
      return true
    } else {
      return false
    }
  } )
  console.log('newDocs', newDocs)


  return (dispatch) => {
    return db.collection('users').doc(uid).collection('customers').doc(selectedCustomer.id).update({
      documents: newDocs
    }).then(() => {
    })
  }
}

export const setDocumentsReadyToSend = (uid, customerId, bool) => {
  return (dispatch) => {
    return db.collection('users').doc(uid).collection('customers').doc(customerId).update({
      readyToSend: bool
    }).then(() => {

    })
  }
}

export const addNewDocument = (id, document) => {
  return (dispatch) => {
    return db.collection('users').doc(id).collection('documents').doc().set(document)
    .then(() => {
      console.log('doc settttt')
      dispatch(getDocuments(id))
    })
  }
}

export const getDocuments = (id) => {
  return (dispatch) => {
    return db.collection('users').doc(id).collection('documents').get()
    .then((snapshot) => {
      console.log('snapppshot', snapshot)
      if (snapshot.empty) {
        dispatch(setDocs([]))
      } else {
        snapshot = snapshot.docs
        var toReturn = []
        snapshot.forEach((doc) => {
          var docId = doc.id
          doc = doc.data()
          doc.id = docId
          toReturn.push(doc)
        })
        dispatch(setDocs(toReturn))
      }
    })
  }
}


export const setDocs = (documents) => {
  return {
    type: 'SET_DOCS',
    data: documents
  }
}

export const setNewDocAdded = (documents) => {
  return {
    type: 'SET_NEW_DOC_ADDED',
    data: documents
  }
}
