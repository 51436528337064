import React, { useState, useEffect, useRef } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InformationLogo from "assets/img/market-me/informationLogo.png";

import styles from "assets/jss/material-dashboard-pro-react/components/informationModalStyle.js";

const useStyles = makeStyles(styles);

export default function InformationModal(props) {
    const classes = useStyles();
    const { infoText } = props;
    let [ hovered, setHovered ] = useState(false);
    let ref = useRef(null);

    return (
      <div className={classes.container}>
        <img
          onMouseOver={()=>setHovered(true)}
          onMouseOut={()=>setHovered(false)}
          src={InformationLogo}
          className={classes.logo}
        />
        <div className={hovered ? classes.textBox : classes.hidden}>
          <h4>{infoText}</h4>
        </div>
      </div>
    );
}

InformationModal.propTypes = {
    infoText: PropTypes.string
};
