import React from 'react'
import GridItem from 'components/Grid/GridItem';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import ChartistGraph from 'react-chartist';
import 'assets/css/material-dashboard-pro-react.css';



const CreditHistory = (props) => {
    const { classes, creditData } = props

    const labels = creditData.scoreHistory.map(
        (score) => score.month + ' ' + score.year
      );

    const series = creditData.scoreHistory.map((score) => score.score);

    const creditGraph = {
        data: {
          labels: labels,
          series: [series],
        },
        options: {
          stackBars: true,
          axisX: {
            showGrid: false,
          },
          low: 550,
          high: 850,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
          height: '185px',
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      };
  return (
    <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={8}
          sx={{ flex: '1', maxWidth: '100%' }}
          id={'report-grid-item'}
        >
          <Card style={{ height: '250px', margin: '25px 0px 15px 0px' }}>
            <CardHeader color='primary'>
              <h5
                style={{
                  textAlign: 'center',
                  margin: '10px 0',
                  fontFamily: "Metal Core",
                  fontSize: "30px"
                }}
                className={classes.cardTitle}
              >
                Credit History
              </h5>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={creditGraph.data}
                type='Bar'
                options={creditGraph.options}
                responsiveOptions={creditGraph.responsiveOptions}
              />
            </CardBody>
          </Card>
        </GridItem>
  )
}

export default CreditHistory