import { connect } from 'react-redux';
import CardField from './CardField';

function getValue(state, accessors, ownProps) {
    if (ownProps.index !== undefined) return accessors.getRowField(state, ownProps.index, ownProps.name);
    if (ownProps.key) return accessors.getRowByKeyField(state, ownProps.key, ownProps.name);
    return accessors.getField(state, ownProps.name);
}

function mapStateToProps(accessors) {
    return (state, ownProps) => {
        const { label, cardFormat } = accessors.getFieldConfig(ownProps.name) || {};
        return {
            value: ownProps.value || getValue(state, accessors, ownProps),
            label: ownProps.label === undefined ? label : ownProps.label,
            format: cardFormat
        }
    }
}

function mapDispatchToProps(actions) {
    return (dispatch, ownProps) => ({
        // nothing yet
    });
}

export default (actions, accessors) => connect(mapStateToProps(accessors), mapDispatchToProps(actions))(CardField);
