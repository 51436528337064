import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card";
import Button from "components/CustomButtons/Button.js";

import notesExplorerPageStyle from "assets/jss/material-dashboard-pro-react/views/notesExplorerPageStyle.js";

export function NotesExplorerPage(props) {

    const { classes } = props;

    return (
        <div>
            <h3 className={classes.title}>Global Notes</h3>
            <Card className={classes.noteCard}>
                <p>01/20/2020 @ 3:45 pm MST</p>
                <h4><b>Loan Officer:</b> Mary Hopkins</h4>
                <h4><b>Note:</b> Simon Bolivar was contacted by us today in regards to transistioning for lead to customer</h4>
            </Card>
            <Card className={classes.noteCard}>
                <p>01/17/2020 @ 10:15 am MST</p>
                <h4><b>Loan Officer:</b> Justin Oberg</h4>
                <h4><b>Note:</b> Marco Lopes was contacted by us today in regards to signing Service Agreements</h4>
            </Card>
            <h3 className={classes.title}>Processing Notes</h3>
            <Card className={classes.noteCard}>
                <p>01/17/2020 @ 10:15 am MST</p>
                <h4><b>Loan Officer:</b> Alex Eickelman</h4>
                <h4><b>Note:</b> Lauren MacDonald was contacted by us today in regards to signing Service Agreements</h4>
            </Card>
            <Button className={classes.newNoteButton}>+ Add Note</Button>
        </div>
    );
}

NotesExplorerPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(notesExplorerPageStyle)(NotesExplorerPage);
