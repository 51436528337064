/*eslint-disable*/
import React from 'react'
import { db, firebase, storage, sendCustomerNotificationEmail } from 'firebase/client';
import {setTabName} from 'store/actions/customTabsActions'
import {getCustomers} from './customerActions'

// export const deleteLead = (uid, leadId) => {
//   return (dispatch) => {
//     return db.collection('users').doc(uid).collection('leads').doc(leadId).delete()
//       .then(() => {
//         dispatch(getLeads(uid))
//       })
//   }
// }

export const getLists = (uid) => {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(uid)
      .collection('lists').get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch(setLists([], []))
        } else {
          snapshot = snapshot.docs
          var toReturn = []
          var ids = []
          snapshot.forEach((doc) => {
            var docId = doc.id
            ids.push(docId)
            doc = doc.data()
            doc.id = docId
            toReturn.push(doc)
          })
          dispatch(setLists(toReturn, ids))
        }
      })
  }
}

export const addList = (uid, newList, allLists) => {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(uid)
      .collection('lists').doc(newList.id).set(newList)
      .then(() => {
        dispatch(getLists(uid))
      })
  }
}

export const setLists = (data, ids) => {
  return {
    type: "SET_LISTS",
    data,
    ids
  }
}