import { getAccessorsForForm } from "../form";
import { getAccessorsForDialog } from "../dialog";

import { accessorsForRecordset, config } from "store/reducers/recordsets/leads"

export default { 
    recordset: accessorsForRecordset, 
    queryDialog: getAccessorsForDialog("dialog", "leads/query"), 
    queryForm: getAccessorsForForm(config, "form", "leads/query"), 
    editorForm: getAccessorsForForm(config, "form", "leads/editor"),
    newForm: getAccessorsForForm(config, "form", "leads/new"),
};
