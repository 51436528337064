import React from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Timeline from '@material-ui/icons/Timeline';
import { makeStyles } from '@material-ui/core/styles';
//import { pieChart } from "variables/charts.js";
import styles from 'assets/jss/material-dashboard-pro-react/views/chartsStyle.js';
import ChartistGraph from 'react-chartist';
import './Bills.css';

const BillsPie = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const bills = props.bills;
  let billTotal = 0;
  bills.forEach((bill) => {
    billTotal = billTotal + bill.amount;
  });
  const pieChartData = {
    labels: [],
    series: [],
  };
  bills.map((bill) => {
    pieChartData.labels.push(Math.round((bill.amount / billTotal) * 100) + '%');
    pieChartData.series.push(Math.round((bill.amount / billTotal) * 100));
  });
  const pieChart = {
    data: {
      labels: pieChartData.labels,
      series: pieChartData.series,
    },
    options: {
      height: '230px',
    },
  };
  const legendData = bills.map(
    (bill) =>
      `<i className={'fas fa-circle ' + classes.info} /> ${bill.name}{' '}`
  );
  const gradients = [
    '#56A3A6, #EEBD09',
    '#56A3A6, #274B73',
    '#BC4646, #3AC0C4',
    '#56A3A6, #3AC0C4',
  ];

  return (
    <GridContainer
      xs={12}
      sm={12}
      md={12}
      style={{ margin: '0', width: '100%' }}
    >
      <Card style={{ maxWidth: '425px' }}>
        <CardHeader color='danger' icon>
          <CardIcon color='danger'>
            <Timeline />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Monthly Bills</h4>
        </CardHeader>
        <CardBody
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0 20px 20px 20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <h6 className={classes.legendTitle}>Legend</h6>
            {bills.map((bill, index) => (
              <div
                key={index}
                style={{ margin: 0, display: 'flex', alignItems: 'center' }}
              >
                <span
                  style={{
                    display: 'inline-block',
                    height: '18px',
                    width: '18px',
                    marginRight: '10px',
                    background: `linear-gradient(to right, ${gradients[index]})`,
                  }}
                ></span>
                {bill.name}
              </div>
            ))}
          </div>
          <ChartistGraph
            style={{ marginLeft: '20px' }}
            data={pieChart.data}
            type='Pie'
            options={pieChart.options}
          />
        </CardBody>
      </Card>
    </GridContainer>
  );
};

export default BillsPie;
