import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import CreditHistory from './CreditHistory'
import CreditChecks from './CreditChecks'
import EstTime from './EstTime'
import './CreditReport.css'
const useStyles = makeStyles(styles);

const CreditReport = (props) => {
  const classes = useStyles();
  const { creditData } = props;

  return (
    <GridContainer id={'credit-report-container'}>
      <div 
        style={{display: 'flex', flexDirection: 'column', flex: '1'}}>
        <CreditHistory creditData={creditData} classes={classes} />
        <CreditChecks classes={classes} creditData={creditData} />
      </div>
      <div 
        style={{display: 'flex', flex: '1'}}>
        <EstTime classes={classes} creditData={creditData} />
      </div>
    </GridContainer>
  );
};

CreditReport.propTypes = {
  classes: PropTypes.object.isRequired,
  creditData: PropTypes.object.isRequired,
};

export default CreditReport;
