/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";

import InformationModal from "components/InformationModal/InformationModal";
import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CardFooter from "components/Card/CardFooter";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Input from "@material-ui/core/Input";
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

// import CardFields from '../../../components/Card/CardFields';
// import CardField from '../../../components/Card/CardField';
import actionsForNavigation from "store/actions/navigation";
import { connect } from "react-redux";
import { MockUserData } from 'variables/config';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import Close from "@material-ui/icons/Close";
import { getLeads, deleteLead, convertLeadToCustomer } from "store/actions/leadsActions"
import Slide from "@material-ui/core/Slide";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import campaignsStyle from "assets/jss/material-dashboard-pro-react/views/campaignsStyle.js";
import { tokenExchange } from 'firebase/client';

import InstagramIcon from "assets/img/market-me/instagramIcon.png";
import LinkedInIcon from "assets/img/market-me/linkedInLogo.png";
import EmailIcon from "assets/img/market-me/emailIcon.png";
import TextIcon from "assets/img/market-me/textIcon.png";
import CalendarIcon from "assets/img/market-me/calendarIcon.png";
import MessagingLogo from "assets/img/market-me/directMessagingLogo.png";
import CommentingLogo from "assets/img/market-me/commentingLogo.png";

import AuthActions from "store/actions/auth"
import { login } from 'store/actions/instagramActions';
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";

const useStyles = makeStyles(campaignsStyle);

const InstagramStep = (props) => {
  const { uid, handleChange, campaignForm } = props;
  const classes = useStyles();

  return (
    <GridContainer className={classes.stepContainerInner}>
      <GridItem>
        <GridContainer>
          <GridItem>
            <Button style={{
              background: "#0068A0",
              color: "#fff",
              fontSize: "14px",
              fontWeight: 600,
              textDecoration: "none",
              borderRadius: "3px",
              width: "min-content",
              height: "3rem",
              padding: "20px, 30px"
            }}
            onClick={()=>login(uid)}>
              <img src={InstagramIcon} width="20%" className={classes.stepIconPressed} style={{ marginRight: "1rem" }}/>
              Login with Instagram
            </Button>
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", height: "3rem" }}>
          <GridItem style={{ display: "flex", alignItems: "center" }} xs={4}>
            <h4 style={{ fontWeight: 800 }}>Instagram Target Customers</h4>
            <InformationModal infoText="Select the number of Customers you want to reach from your total Target Customers." />
          </GridItem>
          <GridItem style={{ display: "flex", alignItems: "baseline" }}>
            <h5 style={{ fontWeight: 800 }}>Services</h5>
            <h5 style={{ fontSize: "0.8rem", marginLeft: "2rem"}}>(Select one or many)</h5>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem style={{ height: "4rem" }}>
        <GridContainer style={{ display: "flex", alignItems: "baseline" }}>
          <GridItem xs={4} >
            <CustomInput
              formControlProps={{
                fullWidth: true,
                style: {
                  paddingTop: "0px"
                }
              }}
              inputProps={{
                placeholder: "500, 1000, 2000",
                required: true,
                type: "text",
                disableUnderline: true,
                onChange: (e) => handleChange(['instagram', 'targetCustomers'], e.target.value),
                style: {
                  border: "2px solid #E60023",
                  borderRadius: "5px"
                }
              }}
            />
          </GridItem>
          <GridItem xs={6} >
            <Button
              className={campaignForm.instagram.directMessaging ? classes.stepButtonPressed : classes.stepButton}
              onClick={() => {
                  handleChange(['instagram', 'directMessaging'], !campaignForm.instagram.directMessaging);
              }}
            >
              <img src={MessagingLogo} className={campaignForm.instagram.directMessaging ? classes.stepIconPressed : classes.stepIcon} width="18%"/>
              Direct Messaging
            </Button>
            <Button
              className={campaignForm.instagram.commenting ? classes.stepButtonPressed : classes.stepButton}
              onClick={() => {
                  handleChange(['instagram', 'commenting'], !campaignForm.instagram.commenting);
              }}
              style={{ marginLeft: "1rem" }}
            >
              <img src={CommentingLogo} width="20%" className={campaignForm.instagram.commenting ? classes.stepIconPressed : classes.stepIcon} />
              Auto-Commenting
            </Button>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridContainer>
        <GridItem xs={4}/>
        <GridItem xs={3} >
          <h5 style={{ fontWeight: 800 }}>Direct Messaging:</h5>
          <p>	&#8226; When a person clicks on your ad, a message thread with your business will open in LinkedIn Direct Messages.</p>
        </GridItem>
        <GridItem xs={3} >
          <h5 style={{ fontWeight: 800 }}>Auto-Commenting:</h5>
          <p>	&#8226; Stay original by sending out up to 10 comment variations while targeting followers or certain hashtags on Instagram.</p>
        </GridItem>
      </GridContainer>
    </GridContainer>
  )
}

function mapStateToProps(state) {
  return {
    uid: state.auth.user.uid
  };
}

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(campaignsStyle)
)(InstagramStep));