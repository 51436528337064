import React from 'react'
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import GridItem from 'components/Grid/GridItem.js';
import ChartistGraph from 'react-chartist';
import 'assets/css/material-dashboard-pro-react.css';


const EstTime = (props) => {
    const { classes, creditData }=props
    console.log(creditData.scoreHistory)
    const labels = creditData.scoreHistory.map(
        (score) => score.month + ' ' + score.year
      );
      const series = creditData.scoreHistory.map((score) => score.score);
      const seriesTab = creditData.scoreHistory.map(
        (score) => creditData.targetScore
      );

    const estimatedCreditGraph = {
        data: {
          labels: labels,
          series: [series, seriesTab],
        },
        options: {
          stackBars: true,
          axisX: {
            showGrid: false,
          },
          low: 650,
          high: 850,
          chartPadding: {
            top: 15,
            right: 5,
            bottom: 0,
            left: 0,
          },
          height: '480px',
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      };

  return (
    <GridItem
        xs={12}
        sm={12}
        md={12}
        lg={8}
        style={{ display: 'flex', flex: '1', }}
        id={'report-grid-item'}
      >
        <Card style={{ margin: '25px 0px 15px 0px' }}>
          <CardHeader color='primary'>
            <h5
              style={{
                textAlign: 'center',
                margin: '10px 0',
                fontFamily: "Metal Core",
                fontSize: "30px"
              }}
              className={classes.cardTitle}
            >
              Estimated Time to Target score
            </h5>
          </CardHeader>
          <CardBody>
            <ChartistGraph
              data={estimatedCreditGraph.data}
              type='Bar'
              options={estimatedCreditGraph.options}
              responsiveOptions={estimatedCreditGraph.responsiveOptions}
            />
          </CardBody>
        </Card>
      </GridItem>
  )
}

export default EstTime