/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage, sendSMS } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";

import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CardFooter from "components/Card/CardFooter";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Input from "@material-ui/core/Input";
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

// import CardFields from '../../../components/Card/CardFields';
// import CardField from '../../../components/Card/CardField';
import TextEditor from "components/TextEditor/TextEditor";
import actionsForNavigation from "store/actions/navigation";
import { connect } from "react-redux";
import { MockUserData } from 'variables/config';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import Close from "@material-ui/icons/Close";
import { getLeads, deleteLead, convertLeadToCustomer } from "store/actions/leadsActions"
import Slide from "@material-ui/core/Slide";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import campaignsStyle from "assets/jss/material-dashboard-pro-react/views/campaignsStyle.js";

import NumberBubble from "components/NumberBubble/NumberBubble";
import InformationModal from "components/InformationModal/InformationModal";
import InstagramIcon from "assets/img/market-me/instagramIcon.png";
import LinkedInIcon from "assets/img/market-me/linkedInLogo.png";
import EmailIcon from "assets/img/market-me/emailIcon.png";
import TextIcon from "assets/img/market-me/textIcon.png";
import CalendarIcon from "assets/img/market-me/calendarIcon.png";

import AuthActions from "store/actions/auth"
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles(campaignsStyle);

const SmsTextBlast = (props) => {
  const classes = useStyles();
  const { setStep, campaignForm, handleChange, setPlatforms, platforms, goBack, listsData } = props;
  const [ editorText, setEditorText ] = useState('');
  const [ listNames, setListNames ] = useState([]);
  const [numbers, setNumbers] = useState('');
  const [now, setNow] = useState(false);

  const placeholder = "You’ve found a community of travelers that are just like you.\nWe don’t want to be stuck in tourist traps that isoluate us from vibrant, local experiences. We want to discover the hidden gem and less-traveled  roads of our next destination.\nReady for your next authentic travel experience?";
  const modules = {
    toolbar: false
  }

  const sendMessage = async () => {
    const phoneArray = numbers.split(',');
    const body = editorText;
    const from = '+19148773057';
    for (let i = 0; i < phoneArray.length; i++) {
        let to = `+1${phoneArray[i]}`;
        const returnSMS = await sendSMS({
            body, from, to
        })
    }
    setNumbers('');
    setEditorText('');
    setNow(false);
  }

  const handleListSelect = (value) => {
    setNumbers(listsData[value].list.map((customer) => {
      return customer.phoneNumber;
    }).join());
  }

  useEffect(() => {
    setListNames(listsData.map((list) => list.listName))
  }, [listsData])

  return (
    <>
      <GridContainer className={classes.mainStepContainer}>
        <GridItem
          className={classes.stepHeader}
          style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}
        >
          <NumberBubble color="yellow" value="4." size="2.5rem" />
          <h4 style={{ fontWeight: 600, marginLeft: "1rem" }}>Compose SMS Text Blast</h4>
        </GridItem>
        <GridItem style={{ display: "flex", marginTop: "-1rem", alignItems: "end" }}>
          <GridItem xs={3}>
            <GridContainer className={classes.step3Container}>
              <GridItem>
                <h4 style={{ fontWeight: 800 }}>Customer List</h4>
              </GridItem>
              <GridItem>
                <CustomSelect
                  values={listNames}
                  formControlProps={{
                    style: {
                      paddingTop: "0px",
                      marginRight: "1rem"
                    }
                  }}
                  inputProps={{
                    disableUnderline: true,
                    onChange: (e)=>handleListSelect(e.target.value),
                    style: {
                      borderRadius: "5px",
                      "& .MuiSelect-root": {
                          color: "white",
                      },
                      border: "2px solid rgb(230, 0, 35"
                    },

                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={9} style={{ display: "flex", alignItems: "baseline" }}>
            <h4 style={{ fontWeight: 800 }}>Phone Numbers</h4>
            <InformationModal infoText="Add specific phone numbers here or keep empty to utilize all in specified Customer List" />
            <CustomInput
              formControlProps={{
                style: {
                  width: "60%",
                  paddingTop: "0px",
                  marginLeft: "1rem"
                }
              }}
              inputProps={{
                placeholder: "All Numbers: 5559832313, ...",
                required: true,
                type: "text",
                disableUnderline: true,
                value: numbers,
                onChange: (e) => {
                  setNumbers(e.target.value);
                },
                style: {
                  border: "2px solid #E60023",
                  borderRadius: "5px"
                }
              }}
            />
          </GridItem>
        </GridItem>
        <GridItem>
          <GridContainer>
            <GridItem xs={7} style={{ width: "30rem", minHeight: "15rem" }}>
              {/* <ReactQuill
                style={{ minHeight: "12rem", height: "1rem", marginTop: "1rem", marginLeft: "1rem" }}
                placeholder={placeholder}
                modules={modules}
                theme="snow"
                onChange={setEditorText}
                value={editorText}
              /> */}
              <textarea
                placeholder={placeholder}
                onChange={(e)=>{setEditorText(e.target.value)}}
                value={editorText}
                style={{
                  height: "12rem",
                  width: "100%",
                  marginLeft: "1rem",
                  backgroundColor: "white",
                  padding: "10px",
                  fontFamily: "Roboto",
                  fontSize: "larger",
                  resize: "none",
                  marginTop: "1rem"
                }} />
            </GridItem>
            <GridItem xs={5} style={{ marginTop: "1rem" }}>
              <h4 style={{ fontWeight: 800 }}>Select Sending Time</h4>
              <div style={{ display: "flex", alignItems: "center", height: "5rem" }}>
                <Checkbox
                  onChange={(e) => {
                    setNow((prev) => !prev);
                  }}
                  checked={now}
                />
                <p style={{ fontWeight: 800, marginTop: "0.6rem", marginRight: "1rem" }}>Now</p>
                <Checkbox
                // onChange={(e) => {
                //   handleChange('continuously', e.target.checked);
                //   }}
                />
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    style: {
                      paddingTop: "0px"
                    }
                  }}
                  inputProps={{
                    placeholder: "04/05/2022",
                    required: true,
                    type: "text",
                    disableUnderline: true,
                    // onChange: (e) => handleChange('campaignName', e.target.value),
                    style: {
                      border: "2px solid #E60023",
                      borderRadius: "5px"
                    }
                  }}
                />
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    style: {
                      paddingTop: "0px"
                    }
                  }}
                  inputProps={{
                    placeholder: "3:34PM",
                    required: true,
                    type: "text",
                    disableUnderline: true,
                    // onChange: (e) => handleChange('campaignName', e.target.value),
                    style: {
                      border: "2px solid #E60023",
                      borderRadius: "5px"
                    }
                  }}
                />
              </div>
            </GridItem>
          </GridContainer>
          <div>
            {now ?
              <Button style={{ float: 'right', marginBottom: "1rem" }} onClick={sendMessage}>Send Message</Button> : null
            }
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Button onClick={()=>{
          if (!setStep) {
            goBack();
          } else {
          setStep((prev) => {
            if (prev === 1) {
              goBack();
            } else {
              return (prev-1);
            }
            })}
          }
        } className={classes.backButton}>Back</Button>
        <Button onClick={()=>setStep((prev)=>(prev+1))} className={classes.nextButton}>Next</Button>
      </GridContainer>
    </>
  )
}

function mapStateToProps(state) {
  return {
    listsData: state.lists.lists
  };
}

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(campaignsStyle)
)(SmsTextBlast));