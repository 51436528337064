import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { firebase } from 'firebase/client';
import { DateTime } from 'luxon';

import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import NewLeadInput from "../CustomInput/NewLeadInput";
import NewLeadSelect from "../CustomInput/NewLeadSelect";

import { addRecordCustom } from 'firebase/datasource';

import withStyles from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/components/formStyle';

export function NewLead(props) {
  const { classes, submit, cancel, submitButtonLabel, cancelButtonLabel, user } = props;

  const fields = ['firstName', 'lastName', 'currentScore', 'goalScore', 'state', 'notes', 'joinDate', 'email', 'mainPhone', 'loanOfficer', 'analyst'];

  const [ form, setForm ] = useState({});
  const [ prevLink, setPrevLink ] = useState('');
  const [ error, setError ] = useState('');

  const handleChange = (field, value) => {
    const checkValidate = (field, value) => {
      switch (field) {
        case 'email':
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(value).toLowerCase());
        default:
          return true;
      }
    }
    if (field === 'joinDate') {
      value = firebase.firestore.Timestamp.fromMillis(DateTime.fromISO(value).toMillis());
    }
    if (checkValidate(field, value)) {
      setForm({
        ...form,
        [field]: value
      });
      setError('')
    } else {
      setError(`Please check ${field}.`);
    }
  }

  useEffect(() => {
    if (user.userType === 'LOAN_OFFICER') {
      setPrevLink("/admin/leads/officer");
      handleChange('loanOfficer', user);
    } else {
      setPrevLink("/admin/leads/explorer");
    }
    handleChange('status', 'Pending');
  }, [user])

  return (
    <GridContainer className={classes.formContainer} justify='center'>
        <GridItem xs={12}>
          <GridContainer>
            {fields.map((field, index) => {
              if ((field === 'loanOfficer')|| field === 'analyst') {
                if (user.userType === 'LOAN_OFFICER' && field === 'loanOfficer') {
                  return;
                }
                return <NewLeadSelect key={index} label={field} handleChange={handleChange}/>
              } else {
                return <NewLeadInput key={index} field={field} handleChange={handleChange} />
              }
            })}
          </GridContainer>
        </GridItem>
        <div>
            {/* <GridItem xs={12}>
              {error ? <div style={{color: 'red'}}>{error}</div> : null}
            </GridItem> */}
            <GridItem xs={12} sm={6}>
              <Link to={prevLink}>
                <Button className={classes.cancelButton} >{cancelButtonLabel || "Go Back"}</Button>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Link to={prevLink}>
                <Button className={classes.submitButton} onClick={e => addRecordCustom('leads', form)}>{submitButtonLabel || "Submit"}</Button>
              </Link>
            </GridItem>
        </div>
    </GridContainer>
  )
}

export default withStyles(Style)(NewLead)