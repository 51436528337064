import { db, firebase } from 'firebase/client';
import { InvalidKeyError } from 'variables/errors';
import { DataTypes } from 'variables/constants';
import { DateTime } from 'luxon';
import { isEmpty, mapValues } from 'lodash';

function getFieldTransformOut(config, field) {
    const fieldConfig = config.fields && config.fields[field];
    if (fieldConfig) {
        switch (fieldConfig.type) {
            case DataTypes.DATETIME:
                return storeValue=>storeValue && firebase.firestore.Timestamp.fromMillis(storeValue.toMillis());
            default:
                return undefined;
        }
    } else {
        return undefined;
    }
}

function getFieldTransformIn(config, field) {
    const fieldConfig = config.fields && config.fields[field];
    if (fieldConfig) {
        switch (fieldConfig.type) {
            case DataTypes.DATETIME:
                return firebaseValue=>firebaseValue && DateTime.fromMillis(firebaseValue.toMillis());
            default:
                return undefined;
        }
    } else {
        return undefined;
    }
}

export function getTransformOut(config) {
    let transform = {};
    if (config.fields)
    for (let field in config.fields) {
        const fieldTransform = getFieldTransformOut(config, field);
        if (fieldTransform) transform[field] = fieldTransform;
    }
    return transform;
}

export function getTransformIn(config) {
    let transform = {};
    if (config.fields)
    for (let field in config.fields) {
        const fieldTransform = getFieldTransformIn(config, field);
        if (fieldTransform) transform[field] = fieldTransform;
    }
    return transform;
}

function applyFieldTransform(transform, field, value) {
    return transform[field] ? transform[field](value) : value;
}


export function applyTransform(transform, fields) {
    if (isEmpty(transform)) return fields;
    return mapValues(fields, (value,field)=>applyFieldTransform(transform, field, value));
}

function checkFields(data, config) {
    if (config.firestoreCollection !== 'customers') {
        return data;
    }
    let returnData = {};
    for (const field in data) {
        if ((config.fields[field] && config.fields[field].recordset) || field.includes('Name')) {
            returnData[field] = data[field];
        }
    }
    return returnData;
}

function initialize(config) {
    console.log("init")
    let collectionRef = db.collection(config.firestoreCollection);
    let transformIn = getTransformIn(config);
    if (config.defaultQuery) {
        let transformOut = getTransformOut(config);
        collectionRef = collectionRef.where(config.defaultQuery.field, config.defaultQuery.operation, applyFieldTransform(transformOut, config.defaultQuery.operand));
    }
    // Iterate through fields
        // If field has recordset = true
            // Add to recordset?
    return collectionRef.get().then(snapshot=>snapshot.docs.map(doc=>({ key: doc.id, fields: applyTransform(transformIn, doc.data())})));
}

// const userObserver = db
//     .collection("TEST-users")
//     .doc(this.props.authUser.uid)
//     .onSnapshot(async (doc) => {
//         const docData = {
//             ...doc.data(),
//             id: doc.id,
//         };
//         this.setState({
//             new: docData.newNotifications ? true : false,
//         });
//     });
// this.unsubUser = userObserver;

function addRecord(config, {fields, key}) {
    let collectionRef = db.collection(config.firestoreCollection);
    let transform = getTransformOut(config);
    let firebaseFields = applyTransform(transform, fields);
    if (config.key) key = firebaseFields[config.key];
    if (key) {
        const docRef = collectionRef.doc(key);
        return db.runTransaction(transaction => {
            return transaction.get(docRef).then(doc => {
                if (doc.exists) {
                    throw new InvalidKeyError(key);
                } else {
                    transaction.set(docRef, firebaseFields);
                    return { fields, key: fields[config.key] };
                }
            })
        });
    } else {
        return collectionRef.add(firebaseFields).then((docRef)=>({ key: docRef.id, fields }));
    }
}

function updateRecord(config, { fields, key }) {
    let collectionRef = db.collection(config.firestoreCollection);
    let transform = getTransformOut(config);
    let firebaseFields = applyTransform(transform, fields);
    if (config.key) key = firebaseFields[config.key];
    if (key) {
        return collectionRef.doc(key).update(firebaseFields).catch(error => Promise.reject(error.code === 'not-found' ? new InvalidKeyError(key) : error));
    } else {
        throw new InvalidKeyError(key);
    }
}

function upsertRecord(config, { fields, key }) {
    let collectionRef = db.collection(config.firestoreCollection);
    let transform = getTransformOut(config);
    let firebaseFields = applyTransform(transform, fields);
    if (config.key) key = firebaseFields[config.key];
    return collectionRef.doc(key).set(firebaseFields, { merge: true }).then(()=>fields);
}

function deleteRecord(config, key) {
    let collectionRef = db.collection(config.firestoreCollection);
    return collectionRef.doc(key).delete();
}

export async function getRecords(collection) {
    return db.collection(collection)
        .get()
        .then((snapshot) => {
            return snapshot.docs.map((doc) => {
                return doc.data();
            })
        });
}

export function addRecordCustom(collection, document) {
    db.collection(collection)
        .add(document);
    console.log(`Added document to ${collection}.`);
}

export async function getRecord(collection, id) {
    const doc = await db.collection(collection).doc(id).get();
    return doc.data();
}

export default {
    initialize,
    addRecord,
    updateRecord,
    deleteRecord
}

export function getDatasourceFor(config) {
    return {
        initialize: ()=>initialize(config),
        addRecord: (record) => addRecord(config, record),
        updateRecord: (record) => updateRecord(config, record),
        upsertRecord: (record) => upsertRecord(config, record),
        deleteRecord: (key) => deleteRecord(config, key)
    }
}
