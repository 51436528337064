import React, { useState, useEffect, useRef } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/components/numberBubbleStyle.js";

const useStyles = makeStyles(styles);

export default function NumberBubble(props) {
    const classes = useStyles();
    const {
        color,
        value,
        size
    } = props;
    let [ marginTop, setMarginTop ] = useState('');
    let [ marginLeft, setMarginLeft ] = useState('');
    let ref = useRef(null);

    let backgroundColor = "#bbb";
    let textColor = "black";
    let sizeNum = Number(size.match(/[\d\.]+/)[0]);

    if (color) {
      switch(color) {
        case 'yellow':
          backgroundColor = "linear-gradient(180deg, #FEF61B 0%, #EDE51A 100%)";
          break;
        case 'blue':
          backgroundColor = "#1D9BC9";
          textColor = "white";
          break;
        default:
          break;
      }
    }

    useEffect(() => {
      setMarginTop((((sizeNum*16) - ref.current.offsetHeight) / 2) + 'px');
      setMarginLeft((((sizeNum*16) - ref.current.offsetWidth) / 2) + 'px');
    }, []);

    return (
      <div className={classes.container}>
        <span style={{ background: backgroundColor, height: size, width: size }} className={classes.bubble} />
        <h4 ref={ref} style={{ marginTop, marginLeft, color: textColor }} className={classes.value}>{value}</h4>
      </div>
    );
}

NumberBubble.propTypes = {
    value: PropTypes.string,
    size: PropTypes.string,
};
