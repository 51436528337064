import React from 'react';

// core components
import Box from '@material-ui/core/Box';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import actionsForNavigation from 'store/actions/navigation';
import AuthActions from 'store/actions/auth';
import { actionsForDialog } from 'store/actions/dialog';
import TermsAndConditionsDialog from 'views/Components/TermsAndConditionsDialog';
import Slide from '@material-ui/core/Slide';
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import accountSettings from 'assets/img/market-me/settings/account-settings.png';
import editProfile from 'assets/img/market-me/settings/edit-profile.png';
import customerDatabse from 'assets/img/market-me/settings/customer-database.png';
import helpCenter from 'assets/img/market-me/settings/help-center.png';
import mergeUpdate from 'assets/img/market-me/settings/merge-update.png';
import privacy from 'assets/img/market-me/settings/privacy.png';
import reportProblem from 'assets/img/market-me/settings/report-a-problem.png';
import termsOfUse from 'assets/img/market-me/settings/terms-of-use.png';

import Style from 'assets/jss/material-dashboard-pro-react/views/settingsPageStyle';

import withStyles from '@material-ui/core/styles/withStyles';

const TermsAndConditionsActions = actionsForDialog('termsAndConditions');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export function SettingsPage(props) {
  const { classes, openTermsAndConditions, history } = props;

  return (
    <div className={classes.mainContainer}>
      <Hidden smDown implementation="css">
        <p className={classes.subTitle}>
          Report problems, load databases, and modify or view account information
        </p>
      </Hidden>


      <Hidden mdUp implementation="css">
        <p
          className={classes.cardHeader}
          style={{
            margin: '0',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '32px',
            display: 'flex',
            alignItems: 'center',
            textIndent: '5px',
            justifyContent: "center",
            fontSize: "33px",
          }}
        >
          Account
        </p>
        <GridContainer
          style={{
            display: 'flex',
            background: "linear-gradient(180deg, #13294C 0%, #005479 100%)",
            justifyContent: 'space-between',
            padding: '20px',
            width: '100%',
            boxShadow: 'rgb(0 0 0 / 25%) 0px 2px 8px -1px',
            borderRadius: '10px',
            margin: "20px 0px",
          }}
        >
          <GridItem xs={12}>
            <Card style={{ border: 'none', boxShadow: 'none', margin: "0px", background: "inherit", color: "white" }}>
              <div className={classes.cardLink}>
                <img src={editProfile} style={{ width: '34px', marginRight: "25px" }} />
                <p
                  onClick={(e) => history.push('/admin/user-page')}
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "26px",
                    lineHeight: '31px',
                  }}
                >
                  Edit Profile
                </p>
              </div>
              <div className={classes.cardLink}>
                <img src={accountSettings} style={{ width: '34px', marginRight: "25px" }} />
                <p
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "26px",
                    lineHeight: '31px',
                  }}
                >
                  Account Settings
                </p>
              </div>
              <div className={classes.cardLink}>
                <img src={privacy} style={{ width: '34px', marginRight: "25px" }} />
                <p
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "26px",
                    lineHeight: '31px',
                  }}
                >
                  Privacy
                </p>
              </div>
              <div className={classes.cardLink}>
                <img src={termsOfUse} style={{ width: '34px', marginRight: "25px" }} />
                <p
                  onClick={() => openTermsAndConditions()}
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "26px",
                    lineHeight: '31px',
                  }}
                >
                  Terms of Use
                </p>
              </div>
              <TermsAndConditionsDialog settingsMobile TransitionComponent={Transition} />
            </Card>
          </GridItem>
        </GridContainer>
        <p
          className={classes.cardHeader}
          style={{
            margin: '0',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '32px',
            display: 'flex',
            alignItems: 'center',
            textIndent: '5px',
            textTransform: 'uppercase',
            justifyContent: "center",
            fontSize: "33px"
          }}
        >
          Support
        </p>
        <GridContainer style={{
          display: 'flex',
          backgroundColor: '#fff',
          justifyContent: 'space-between',
          padding: '20px',
          width: '100%',
          boxShadow: 'rgb(0 0 0 / 25%) 0px 2px 8px -1px',
          borderRadius: '10px',
          margin: "20px 0px",
          background: "linear-gradient(180deg, #13294C 0%, #005479 100%)",
        }} >
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Card style={{ border: 'none', boxShadow: 'none', margin: "0px",background: "inherit", color: "white" }}>
              <div className={classes.cardLink}>
                <img src={reportProblem} style={{ width: '34px', marginRight: "25px" }} />
                <p
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "26px",
                    lineHeight: '31px',
                  }}
                >
                  Report A Problem
                </p>
              </div>
              <div className={classes.cardLink}>
                <img src={helpCenter} style={{ width: '34px', marginRight: "25px" }} />
                <p
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "26px",
                    lineHeight: '31px',
                  }}
                >
                  Help Center
                </p>
              </div>
            </Card>
          </GridItem>
        </GridContainer>
        <p
          className={classes.cardHeader}
          style={{
            margin: '0',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '32px',
            display: 'flex',
            alignItems: 'center',
            textIndent: '5px',
            textTransform: 'uppercase',
            justifyContent: "center",
            fontSize: "33px"
          }}
        >
          Databases
        </p>
        <GridContainer style={{
          display: 'flex',
          backgroundColor: '#fff',
          justifyContent: 'space-between',
          padding: '20px',
          width: '100%',
          boxShadow: 'rgb(0 0 0 / 25%) 0px 2px 8px -1px',
          borderRadius: '10px',
          margin: "20px 0px",
          background: "linear-gradient(180deg, #13294C 0%, #005479 100%)",
        }}>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Card style={{ border: 'none', boxShadow: 'none', margin: "0px", background: "inherit", color: "white"  }}>
              <div className={classes.cardLink}>
                <img
                  src={customerDatabse}
                  style={{ marginRight: '7px', width: '27px', height: '34px', marginRight: "25px" }}
                />
                <p
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "26px",
                    lineHeight: '31px',
                  }}
                >
                  Load New Customer Database
                </p>
              </div>
              <div className={classes.cardLink}>
                <img src={mergeUpdate} style={{ width: '34px', marginRight: "17px" }} />
                <p
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "26px",
                    lineHeight: '31px',
                  }}
                >
                  Merge/Update Customer Database
                </p>
              </div>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Button
              onClick={(e) => {
                props.logout();
              }}
              className={classes.logOutButton}
              style={{ backgroundColor: '#FEF61B', margin: '20px 0' }}
            >
              Log Out
            </Button>
          </GridItem>
        </GridContainer>
      </Hidden>



      <Hidden smDown implementation="css">
        <GridContainer
          style={{
            display: 'flex',
            background: "linear-gradient(180deg, #13294C 0%, #005479 100%)",
            justifyContent: 'space-between',
            padding: '20px',
            width: '100%',
            boxShadow: 'rgb(0 0 0 / 25%) 0px 2px 8px -1px',
            borderRadius: '10px',
            margin: "30px 0px",
          }}
        >
          <GridItem xs={12}>
            <Card style={{ border: 'none', boxShadow: 'none',background: "inherit", color: "white", marginTop: "0px" }}>
              <p
                className={classes.cardHeader}
                style={{
                  margin: '15px 0px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '29px',
                  lineHeight: '32px',
                  display: 'flex',
                  alignItems: 'center',
                  textIndent: '5px',
                  fontFamily: "Abril Fatface",
                }}
              >
                Account
              </p>
              <div className={classes.cardLink}>
                <img src={editProfile} style={{ width: '34px' }} />
                <p
                  onClick={(e) => history.push('/admin/user-page')}
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "20px",
                    lineHeight: '31px',
                  }}
                >
                  Edit Profile
                </p>
              </div>
              <div className={classes.cardLink}>
                <img src={accountSettings} style={{ width: '34px' }} />
                <p
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "20px",
                    lineHeight: '31px',
                  }}
                >
                  Account Settings
                </p>
              </div>
              <div className={classes.cardLink}>
                <img src={privacy} style={{ width: '34px' }} />
                <p
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "20px",
                    lineHeight: '31px',
                  }}
                >
                  Privacy
                </p>
              </div>
              <div className={classes.cardLink}>
                <img src={termsOfUse} style={{ width: '34px' }} />
                <p
                  onClick={() => openTermsAndConditions()}
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "20px",
                    lineHeight: '31px',
                  }}
                >
                  Terms of Use
                </p>
              </div>
              <TermsAndConditionsDialog TransitionComponent={Transition} />
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Card style={{ border: 'none', boxShadow: 'none', background: "inherit", color: "white" }}>
              <p
                className={classes.cardHeader}
                style={{
                  margin: '15px 0px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '29px',
                  lineHeight: '32px',
                  display: 'flex',
                  alignItems: 'center',
                  textIndent: '5px',
                  fontFamily: "Abril Fatface",
                }}
              >
                Support
              </p>
              <div className={classes.cardLink}>
                <img src={reportProblem} style={{ width: '34px' }} />
                <p
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "20px",
                    lineHeight: '31px',
                  }}
                >
                  Report A Problem
                </p>
              </div>
              <div className={classes.cardLink}>
                <img src={helpCenter} style={{ width: '34px' }} />
                <p
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "20px",
                    lineHeight: '31px',
                  }}
                >
                  Help Center
                </p>
              </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Card style={{ border: 'none', boxShadow: 'none', background: "inherit", color: "white" }}>
              <p
                className={classes.cardHeader}
                style={{
                  margin: '15px 0px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '29px',
                  lineHeight: '32px',
                  display: 'flex',
                  alignItems: 'center',
                  textIndent: '5px',
                  fontFamily: "Abril Fatface",
                }}
              >
                Databases
              </p>
              <div className={classes.cardLink}>
                <img
                  src={customerDatabse}
                  style={{ marginRight: '7px', width: '27px', height: '34px' }}
                />
                <p
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "20px",
                    lineHeight: '31px',
                  }}
                >
                  Load New Customer Database
                </p>
              </div>
              <div className={classes.cardLink}>
                <img src={mergeUpdate} style={{ width: '34px' }} />
                <p
                  className={classes.linkName}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "20px",
                    lineHeight: '31px',
                  }}
                >
                  Merge/Update Customer Database
                </p>
              </div>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <Button
              onClick={(e) => {
                props.logout();
              }}
              className={classes.logOutButton}
              style={{ backgroundColor: '#FEF61B', margin: '20px 0' }}
            >
              Log Out
            </Button>
          </GridItem>
        </GridContainer>
      </Hidden>

    </div>
  );
}

function mapStateToProps(state) {
  return {
    // nothing yet
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    edit: (key) =>
      dispatch(
        actionsForNavigation.openChild('Profile Editor', '/admin/user/editor')
      ),
    logout: () => dispatch(AuthActions.startLogout()),
    openTermsAndConditions: () =>
      dispatch(TermsAndConditionsActions.openDialog()),
  });
}

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(SettingsPage));
