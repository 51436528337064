/*eslint-disable*/
import LoginPage from 'views/Pages/LoginPage.js';
import CustomerExplorerPage from 'views/Pages/CustomerExplorerPage.js';
import NotesExplorerPage from 'views/Pages/NotesExplorerPage.js';
import TasksExplorerPage from 'views/Pages/TasksExplorerPage.js';
import RegisterPage from 'views/Pages/RegisterPage.js';
import RegistrationConfirmation from 'views/Pages/registration/RegistrationConfirmation.js';
import UserProfile from 'views/Pages/UserProfile.js';
import SettingsPage from 'views/Pages/SettingsPage.js';
import LeadsEditor from 'views/Pages/leads/LeadsEditor';
import NewLead from 'views/Pages/leads/NewLead';
import CustomersEditor from 'views/Pages/customers/CustomersEditor';
import NewCustomer from 'views/Pages/customers/NewCustomer';
import NegativeItems from 'views/Pages/customers/NegativeItems/NegativeItems';
import NegativeFactors from 'views/Pages/customers/Credit/CreditHistory/NegativeFactors/NegativeFactors';
import CustomerTasks from 'views/Pages/customers/CustomerTasks';
import ProfileEditor from 'views/Pages/profile/ProfileEditor';
import LeadDetail from 'views/Pages/leads/LeadDetail';
import CustomerDetail from 'views/Pages/customers/CustomerDetail';
import Agencies from 'views/Pages//customers/Agencies/Agencies';
import CreditScore from 'views/Pages/customers/Credit/CreditScore';
import Crypto from 'views/Pages//customers/Crypto/Crypto';
import DataFurnisherDocuments from 'views/Pages/documents/DataFurnisherDocuments';
import CreditApplicantDocuments from 'views/Pages/documents/CreditApplicantDocuments';
import Analytics from 'views/Pages/analytics/Analytics';
import Subscriptions from 'views/Pages/customers/Subscriptions/Subscriptions.jsx';
import Bills from 'views/Pages/customers/Bills/Bills.jsx';
import InstagramPage from 'views/Pages/instagram/InstagramPage'
import LinkedInPage from 'views/Pages/linkedIn/LinkedInPage'
import EmailPage from 'views/Pages/email/EmailPage'
import SmsTextPage from 'views/Pages/smsText/SmsTextPage'
import CampaignsPage from 'views/Pages/campaigns/CampaignsPage'
import DemographicsExplorer from 'views/Pages/demographics/DemographicsExplorer'
import ContactsPage from 'views/Pages/contacts/ContactsPage'

// @material-ui/icons
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Person from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import LeadsCustom from 'views/Pages/leads/LeadsCustom';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PostAddIcon from '@material-ui/icons/PostAdd';
import BarChartIcon from '@material-ui/icons/BarChart';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';
import TextsmsIcon from '@material-ui/icons/Textsms';
import ChoosePlan from 'views/Pages/registration/ChoosePlan.jsx';
// import Leads from "assets/img/mortgage-ready/leads.png";

var dashRoutes = [
  {
    path: '/choose-a-plan/:id',
    rtlName: 'تسجيل',
    rtlMini: 'صع',
    component: ChoosePlan,
    layout: '/auth',
  },
  {
    path: '/choose-a-plan',
    rtlName: 'تسجيل',
    rtlMini: 'صع',
    component: ChoosePlan,
    layout: '/auth',
  },
  {
    path: '/lead-detail/:id',
    component: LeadDetail,
    layout: '/admin',
    // name: "Lead Detail",
    // rtlName: "ملف تعريفي للمستخدم",
    // rtlMini: "شع",
  },
  {
    path: '/customer-detail/:id',
    component: CustomerDetail,
    layout: '/admin',
  },
  {
    path: '/user-page',
    name: 'Profile',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: UserProfile,
    layout: '/admin',
  },
  {
    path: '/instagram',
    name: 'Instagram',
    icon: InstagramIcon,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: InstagramPage,
    layout: '/admin',
  },
  // {
  //   path: '/linkedIn',
  //   name: 'LinkedIn',
  //   icon: LinkedInIcon,
  //   rtlName: 'ملف تعريفي للمستخدم',
  //   rtlMini: 'شع',
  //   component: LinkedInPage,
  //   layout: '/admin',
  // },
  {
    path: '/email',
    name: 'Email',
    icon: EmailIcon,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: EmailPage,
    layout: '/admin',
  },
  {
    path: '/sms-text',
    name: 'SMS Text',
    icon: TextsmsIcon,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: SmsTextPage,
    layout: '/admin',
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    icon: TextsmsIcon,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: CampaignsPage,
    layout: '/admin',
  },
  {
    path: '/demographics',
    name: 'Demographics',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: DemographicsExplorer,
    layout: '/admin',
  },
  {
    path: '/contacts',
    name: 'Contacts',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: ContactsPage,
    layout: '/admin',
  },
  // {
  //   path: '/credit-agencies',
  //   name: 'Credit Agencies',
  //   icon: AttachMoneyIcon,
  //   rtlName: 'ملف تعريفي للمستخدم',
  //   rtlMini: 'شع',
  //   component: Agencies,
  //   layout: '/admin',
  // },
  // {
  //   path: '/credit-score',
  //   name: 'Credit Score',
  //   icon: AttachMoneyIcon,
  //   rtlName: 'ملف تعريفي للمستخدم',
  //   rtlMini: 'شع',
  //   component: CreditScore,
  //   layout: '/admin',
  //   // category: 'Browse analytical data associated with your credit score.',
  // },
  // {
  //   path: '/digital-assets',
  //   name: 'Digital Assets',
  //   icon: AttachMoneyIcon,
  //   rtlName: 'ملف تعريفي للمستخدم',
  //   rtlMini: 'شع',
  //   component: Crypto,
  //   layout: '/admin',
  // },
  // {
  //   path: '/client-documents',
  //   name: 'Client Documents',
  //   icon: PostAddIcon,
  //   rtlName: 'ملف تعريفي للمستخدم',
  //   rtlMini: 'شع',
  //   component: DataFurnisherDocuments,
  //   layout: '/admin',
  // },
  // {
  //   path: '/documents',
  //   name: 'Documents',
  //   icon: PostAddIcon,
  //   rtlName: 'ملف تعريفي للمستخدم',
  //   rtlMini: 'شع',
  //   component: CreditApplicantDocuments,
  //   layout: '/admin',
  //   // category: 'Review client documents and send them on if necessary.',
  // },
  // {
  //   path: '/subscriptions',
  //   name: 'Subscriptions',
  //   icon: PostAddIcon,
  //   rtlName: 'ملف تعريفي للمستخدم',
  //   rtlMini: 'شع',
  //   component: Subscriptions,
  //   layout: '/admin',
  // },
  // {
  //   path: '/bills',
  //   name: 'Bills',
  //   icon: PostAddIcon,
  //   rtlName: 'ملف تعريفي للمستخدم',
  //   rtlMini: 'شع',
  //   component: Bills,
  //   layout: '/admin',
  // },
  // {
  //   path: '/analytics',
  //   name: 'Analytics',
  //   icon: BarChartIcon,
  //   rtlName: 'ملف تعريفي للمستخدم',
  //   rtlMini: 'شع',
  //   component: Analytics,
  //   layout: '/admin',
  // },
  // // {
  // //   path: "/leads/officer",
  // //   name: "LO Leads",
  // //   icon: EmojiPeopleIcon,
  // //   rtlName: "عالتسعير",
  // //   rtlMini: "ع",
  // //   component: LeadsCustom,
  // //   layout: "/admin",
  // //   role: "officer"
  // // },
  // {
  //   path: '/leads/explorer',
  //   name: 'Leads',
  //   icon: EmojiPeopleIcon,
  //   rtlName: 'عالتسعير',
  //   rtlMini: 'ع',
  //   component: ExplorerPage,
  //   layout: '/admin',
  //   role: 'analyst',
  // },
  // {
  //   path: '/customers/explorer',
  //   name: 'Customers',
  //   icon: SupervisedUserCircleIcon,
  //   rtlName: 'عالتسعير',
  //   rtlMini: 'ع',
  //   component: CustomerExplorerPage,
  //   layout: '/admin',
  //   role: 'analyst',
  // },
  {
    path: '/settings',
    name: 'Settings',
    icon: SettingsIcon,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: SettingsPage,
    layout: '/admin',
  },
  // {
  //   path: '/leads/notes',
  //   component: NotesExplorerPage,
  //   layout: '/admin',
  // },
  // {
  //   path: '/leads/tasks',
  //   component: TasksExplorerPage,
  //   layout: '/admin',
  // },
  // {
  //   path: '/customers/notes',
  //   component: NotesExplorerPage,
  //   layout: '/admin',
  // },
  // {
  //   path: '/customers/tasks',
  //   component: TasksExplorerPage,
  //   layout: '/admin',
  // },
  // {
  //   path: '/login-page',
  //   rtlName: 'هعذاتسجيل الدخول',
  //   rtlMini: 'هعذا',
  //   component: LoginPage,
  //   layout: '/auth',
  // },
  // {
  //   path: '/register-page/:email/:inviteID:/:invitedByUID',
  //   rtlName: 'تسجيل',
  //   rtlMini: 'صع',
  //   component: RegisterPage,
  //   layout: '/auth',
  // },
  // {
  //   path: '/register-page',
  //   rtlName: 'تسجيل',
  //   rtlMini: 'صع',
  //   component: RegisterPage,
  //   layout: '/auth',
  // },
  // {
  //   path: '/registration-confirmation',
  //   rtlName: 'تسجيل',
  //   rtlMini: 'صع',
  //   component: RegistrationConfirmation,
  //   layout: '/auth',
  // },
  // {
  //   path: '/leads/editor/:key',
  //   component: LeadsEditor,
  //   layout: '/admin',
  // },
  // {
  //   path: '/leads/new',
  //   component: NewLead,
  //   layout: '/admin',
  // },
  // {
  //   path: '/customers/editor/:key',
  //   component: CustomersEditor,
  //   layout: '/admin',
  // },
  // {
  //   path: '/customers/new',
  //   component: NewCustomer,
  //   layout: '/admin',
  // },
  // {
  //   path: '/user/editor',
  //   component: ProfileEditor,
  //   layout: '/admin',
  // },
  // {
  //   path: '/customer-detail/:id',
  //   component: CustomerDetail,
  //   layout: '/admin',
  // },
  // {
  //   path: '/customers/tasks',
  //   component: CustomerTasks,
  //   layout: '/admin',
  // },
  // {
  //   path: '/customers/negative-items/:key',
  //   component: NegativeItems,
  //   layout: '/admin',
  //   category: 'Manage your connected credit agencies.',
  // },
  // {
  //   path: '/negative-factors:key',
  //   name: 'Negative Factors',
  //   icon: ArrowDownwardIcon,
  //   component: NegativeFactors,
  //   layout: '/admin',
  //   category: 'Manage your connected credit agencies',
  // },
];
export default dashRoutes;
