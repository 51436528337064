import React from 'react'
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card.js';
import './NegativeFactors.css';

const NegativeCircles = () => {
    const factors = [
        {
          label: 'Late Payments',
          amount: 3,
        },
        {
          label: 'Credit History',
          years: '2yrs',
        },
        {
          label: 'Credit Usage',
          usage: '20%',
        },
      ];
  return (
    <GridItem
      id='grid-item-cards'
      xs={12}
      sm={12}
      md={8}
      lg={8}
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      {factors.map((f) => {
        return (
          <Card id='negativeCard'>
            <h3>
              {f.amount ? f.amount : f.years ? f.years : f.usage}
            </h3>
          <h5>
            {f.label}
          </h5>
          </Card>
        );
      })}
    </GridItem>
  )
}

export default NegativeCircles