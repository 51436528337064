import React from "react";

import BoundEditorForm from 'components/Explorer/BoundEditorForm';

import actionsForExplorer from 'store/actions/explorers/customers';
import accessorsForExplorer from 'store/reducers/explorers/customers';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

const EditorForm = BoundEditorForm(actionsForExplorer.editorForm, accessorsForExplorer.editorForm);

export default function CustomersEditor(props) {
    return (
        <div>
            <EditorForm order={["firstName", "middleName", "lastName", "suffix", "nickName", "plan", "marketing", "creditAnalyst", "creditReport", "estimatedPullDate", "joinDate", "loanOfficer", "address", "city", "state", "zipCode", "dateOfBirth", "socialSecurityNumber", "mainPhone", "altPhone", "emailAddress", "currentScore", "goalScore", "importantNotes", "description", "creditReportLogin", "negativeItemsLink"]} />
        </div>
    );
}