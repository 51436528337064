/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from "firebase/client";
import { NavLink, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import CardFooter from "components/Card/CardFooter";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Input from "@material-ui/core/Input";

// import CardFields from '../../../components/Card/CardFields';
// import CardField from '../../../components/Card/CardField';
import actionsForNavigation from "store/actions/navigation";
import { connect } from "react-redux";
import { MockUserData } from "variables/config";
import avatar from "assets/img/default-avatar.png";
import SearchBox from "components/SearchBox/SearchBox.jsx";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import DeleteIcon from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
import {
  getLeads,
  deleteLead,
  convertLeadToCustomer,
} from "store/actions/leadsActions";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import campaignsStyle from "assets/jss/material-dashboard-pro-react/views/campaignsStyle.js";

import AuthActions from "store/actions/auth";
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";

import InstagramStats from "./InstagramStats";
import LinkedInStats from "./LinkedInStats";
import EmailStats from "./EmailStats";
import SMSText from "./SMSTextStats";
import CampaignForm from "views/Components/Campaign/CampaignForm";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const mockData = [
  {
    name: "Campaign #1",
    remainingTime: "48h",
    actionsCompleted: "98",
    status: "Active",
  },
  {
    name: "Campaign #2",
    remainingTime: "48h",
    actionsCompleted: "98",
    status: "Paused",
  },
  {
    name: "Campaign #3",
    remainingTime: "48h",
    actionsCompleted: "98",
    status: "Draft",
  },
  {
    name: "Campaign #4",
    remainingTime: "48h",
    actionsCompleted: "98",
    status: "Ended",
  },
];

export function CampaignsPage(props) {
  const { classes, history } = props;
  const [campaignType, setCampaignType] = useState("Instagram");
  const [campaignStatus, setCampaignStatus] = useState("");
  const [showCampaign, setShowCampaign] = useState(false);

  return (
    <div className={classes.mainContainer}>
      {showCampaign ? <CampaignForm goBack={() => setShowCampaign(false)} /> :
      <>
      <Hidden className={classes.fullWidth} smDown implementation="css">
        <GridContainer>
          <GridItem xs={6}>
            <p className={classes.subTitle}>
              View campaign information and create new ones.
            </p>
          </GridItem>
          <GridItem xs={6} style={{ justifyContent: "flex-end", display: "flex", verticalAlign: "center", marginTop: "-50px", }}>
            <Button
              className={classes.newButton}
              onClick={() => setShowCampaign(true)}
            >
              Create Campaign
            </Button>
          </GridItem>
        </GridContainer>

      </Hidden>

      <GridContainer style={{ maxWidth: "100% !important" }}>
        <GridItem xs={12}>
          <Carousel
            autoPlay={true}
            autoPlaySpeed={3000}
            infinite={true}
            swipeable={true}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            draggable={false}
            showDots={false}
            responsive={responsive}
            // arrows={true}
            style={{ display: "flex" }}
          >
            {mockData.map((d, index) => {
              return (
                <Card
                  className={classes.campaignCard}
                  style={
                    d.status === "Active"
                      ? { background: "#13294C" }
                      : d.status === "Paused"
                        ? {
                          background:
                            "linear-gradient(180deg, #1D9BC9 0%, #005479 100%)",
                        }
                        : d.status === "Draft"
                          ? { background: "rgb(244 195 0)" }
                          : {
                            background:
                              "linear-gradient(180deg, #E60023 0%, #AC001A 100%)",
                          }
                  }
                >
                  <CardHeader>
                    <h5
                      style={{
                        color: "white",
                        fontFamily: "Abril Fatface",
                        fontSize: "24px",
                      }}
                    >
                      {d.name}
                    </h5>
                    <div className={classes.campaignHeader}></div>
                  </CardHeader>
                  <CardBody className={classes.campaignBody}>
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p style={{ color: "white", fontSize: "22px" }}>
                        Remaining Time:
                      </p>
                      <h5
                        style={{
                          display: "flex",
                          color: "white",
                          fontSize: "22px",
                          fontWeight: 500,
                          alignItems: "center",
                          marginTop: "0px",
                        }}
                      >
                        <AccessTimeIcon
                          style={{ color: "white", marginRight: "20px" }}
                        />
                        {d.remainingTime}
                      </h5>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p style={{ color: "white", fontSize: "22px" }}>
                        Actions Completed:
                      </p>
                      <h5
                        style={{
                          display: "flex",
                          color: "white",
                          fontSize: "22px",
                          fontWeight: 500,
                          alignItems: "center",
                          marginTop: "0px",
                        }}
                      >
                        <CheckCircleOutlineIcon
                          style={{ color: "white", marginRight: "20px" }}
                        />
                        {d.actionsCompleted}
                      </h5>
                    </div>
                  </CardBody>
                </Card>
              );
            })}
          </Carousel>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          xs={12}
        >
          <h3 className={classes.subHeader}> All {campaignStatus} Campaigns</h3>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "50%",
            }}
          >
            <GridContainer
              style={{
                width: "100%",
                display: "flex",
                fontSize: "19px",
                height: "60px",
                alignItems: "center",
              }}
            >
              <GridItem
                className={classes.campaignStatus}
                onClick={() => setCampaignStatus("Active")}
                xs={3}
                style={
                  campaignStatus === "Active"
                    ? {
                      background:
                        "linear-gradient(180deg, #005479 0%, #13294C 100%)",
                      color: "white",
                    }
                    : {}
                }
              >
                <div
                  style={{
                    background: "#13294C",
                    borderRadius: "100%",
                    height: "10px",
                    width: "10px",
                    marginRight: "20px",
                  }}
                ></div>
                <span>Active</span>
              </GridItem>
              <GridItem
                className={classes.campaignStatus}
                onClick={() => setCampaignStatus("Paused")}
                xs={3}
                style={
                  campaignStatus === "Paused"
                    ? {
                      background:
                        "linear-gradient(180deg, #005479 0%, #13294C 100%)",
                      color: "white",
                    }
                    : {}
                }
              >
                <div
                  style={{
                    background:
                      "linear-gradient(rgb(29, 155, 201) 0%, rgb(0, 84, 121) 100%)",
                    borderRadius: "100%",
                    height: "10px",
                    width: "10px",
                    marginRight: "20px",
                  }}
                ></div>
                <span>Paused</span>
              </GridItem>
              <GridItem
                className={classes.campaignStatus}
                onClick={() => setCampaignStatus("Draft")}
                xs={3}
                style={
                  campaignStatus === "Draft"
                    ? {
                      background:
                        "linear-gradient(180deg, #005479 0%, #13294C 100%)",
                      color: "white",
                    }
                    : {}
                }
              >
                <div
                  style={{
                    background: "rgb(244, 195, 0)",
                    borderRadius: "100%",
                    height: "10px",
                    width: "10px",
                    marginRight: "20px",
                  }}
                ></div>
                <span>Draft</span>
              </GridItem>
              <GridItem
                className={classes.campaignStatus}
                onClick={() => setCampaignStatus("Ended")}
                xs={3}
                style={
                  campaignStatus === "Ended"
                    ? {
                      background:
                        "linear-gradient(180deg, #005479 0%, #13294C 100%)",
                      color: "white",
                    }
                    : {}
                }
              >
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, #E60023 0%, #AC001A 100%)",
                    borderRadius: "100%",
                    height: "10px",
                    width: "10px",
                    marginRight: "20px",
                  }}
                ></div>
                <span>Ended</span>
              </GridItem>
            </GridContainer>
            <Button
              onClick={() => setCampaignStatus("")}
              style={
                campaignStatus !== ""
                  ? {
                    background: "transparent",
                    color: "black",
                    borderRadius: "5px",
                    height: "70%",
                    marginLeft: "30px",
                    border: "1px solid #13294C",
                  }
                  : {
                    background:
                      "linear-gradient(180deg, #005479 0%, #13294C 100%)",
                    color: "white",
                    borderRadius: "5px",
                    height: "70%",
                    marginLeft: "30px",
                  }
              }
            >
              All Campaigns
            </Button>
          </div>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader className={classes.cardHeader}>
              <div className={classes.buttonDiv}>
                <Button
                  className={classes.campaignBtn}
                  onClick={() => setCampaignType("Instagram")}
                  style={
                    campaignType === "Instagram"
                      ? {
                        background:
                          "linear-gradient(180deg, #005479 0%, #13294C 100%)",
                      }
                      : {}
                  }
                >
                  Instagram
                </Button>
              </div>
              <div className={classes.buttonDiv}>
                <Button
                  className={classes.campaignBtn}
                  onClick={() => setCampaignType("LinkedIn")}
                  style={
                    campaignType === "LinkedIn"
                      ? {
                        background:
                          "linear-gradient(180deg, #005479 0%, #13294C 100%)",
                      }
                      : {}
                  }
                >
                  LinkedIn
                </Button>
              </div>
              <div className={classes.buttonDiv}>
                <Button
                  className={classes.campaignBtn}
                  onClick={() => setCampaignType("Email")}
                  style={
                    campaignType === "Email"
                      ? {
                        background:
                          "linear-gradient(180deg, #005479 0%, #13294C 100%)",
                      }
                      : {}
                  }
                >
                  Email
                </Button>
              </div>
              <div className={classes.buttonDiv}>
                <Button
                  className={classes.campaignBtn}
                  onClick={() => setCampaignType("SMS Text")}
                  style={
                    campaignType === "SMS Text"
                      ? {
                        background:
                          "linear-gradient(180deg, #005479 0%, #13294C 100%)",
                      }
                      : {}
                  }
                >
                  SMS Text
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              {campaignType === "Instagram" ? (
                <InstagramStats />
              ) : campaignStatus === "LinkedIn" ? (
                <LinkedInStats />
              ) : campaignStatus === "Email" ? (
                <EmailStats />
              ) : campaignStatus === "SMS Text" ? (
                <SMSText />
              ) : null}
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12}></GridItem>
      </GridContainer>
      </>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(campaignsStyle)
  )(CampaignsPage)
);
