const formStepContainerStyle = (theme) => ({
    lastButton: {
        backgroundColor: "#f3684e",
        "&:hover": {
            backgroundColor: "#f3684e",
        }
    },
    nextButton: {
        backgroundColor: "#05c6a3",
        "&:hover": {
            backgroundColor: "#05c6a3",
        }
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-around",
    }
})

export default formStepContainerStyle;