/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';

import withStyles from "@material-ui/core/styles/withStyles";

import compose from "recompose/compose";

import { connect } from "react-redux";
import Style from "assets/jss/material-dashboard-pro-react/views/campaignsStyle.js";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import SmsTextPage from "./SmsTextBlast";
import LinkedInDM from "./LinkedInDM";
import InstagramDM from "./InstagramDM";
import AutoCommenting from "./AutoCommenting";

const useStyles = makeStyles(Style);

const CampaignForm = (props) => {
  const { goBack } = props;
  const classes = useStyles();
  const [campaignStep, setCampaignStep] = useState(1);
  const [campaignForm, setCampaignForm] = useState({ runLengthUnit: 'Days', linkedIn: {}, instagram: {}, sms: {}, email: {} });
  const [platforms, setPlatforms] = useState({});

  const handleChange = (field, value) => {
    setCampaignForm((prevState) => {
      let newState = { ...prevState };
      if (typeof field === 'object') {
        newState[field[0]][field[1]] = value;
      } else {
        newState[field] = value;
      }
      return newState;
    });
  }

  const handlePlatformSelect = (platform) => {
    if(Object.keys(platforms).length === 0){
      setPlatforms(() => {
        let newState = { };
        newState[platform] = !newState[platform];
        return newState;
      })
    }
    else{
      setPlatforms({})
      let newState = { };
      newState[platform] = !newState[platform];
      return newState;
    }
    
  }

  return (
    <>
      {campaignStep === 1 ?
      <Step1 setStep={setCampaignStep} campaignForm={campaignForm} handleChange={handleChange} setPlatforms={handlePlatformSelect} platforms={platforms} goBack={goBack} /> :
        campaignStep === 2 ?
      <Step2 setStep={setCampaignStep} campaignForm={campaignForm} handleChange={handleChange} setPlatforms={handlePlatformSelect} platforms={platforms}/> :
        campaignStep === 3 ?
      <Step3 setStep={setCampaignStep} campaignForm={campaignForm} handleChange={handleChange} setPlatforms={handlePlatformSelect} platforms={platforms}/> :
        campaignStep === 4 ?
      <SmsTextPage setStep={setCampaignStep} campaignForm={campaignForm} handleChange={handleChange} setPlatforms={handlePlatformSelect} platforms={platforms}/> :
        campaignStep === 5 ?
      <LinkedInDM setStep={setCampaignStep} campaignForm={campaignForm} handleChange={handleChange} setPlatforms={handlePlatformSelect} platforms={platforms}/> :
        campaignStep === 6 ?
      <InstagramDM setStep={setCampaignStep} campaignForm={campaignForm} handleChange={handleChange} setPlatforms={handlePlatformSelect} platforms={platforms}/> :
        campaignStep === 7 ?
      <AutoCommenting setStep={setCampaignStep} campaignForm={campaignForm} handleChange={handleChange} setPlatforms={handlePlatformSelect} platforms={platforms}/> : null
      }
    </>
  )
}

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(CampaignForm));
