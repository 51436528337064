/*eslint-disable*/
import React, {useState} from "react";

import BoundEditorForm from 'components/Explorer/BoundEditorForm';

import actionsForExplorer from 'store/actions/explorers/leads';
import { NavLink, withRouter } from 'react-router-dom';

import accessorsForExplorer from 'store/reducers/explorers/leads';

import DocUpload from "./DocUpload";
import StatusSelector from "./StatusSelector";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import avatar from 'assets/img/default-avatar.png';
import Hidden from "@material-ui/core/Hidden";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { connect } from "react-redux";
import styles from "assets/jss/material-dashboard-pro-react/views/customerDetailStyle";
import Slide from '@material-ui/core/Slide';
import Close from '@material-ui/icons/Close';

import envelopeLogo from 'assets/img/market-me/envelopeLogo.png'
import convertLogo from 'assets/img/market-me/convertLogo.png'
import houseLogo from 'assets/img/market-me/houseLogo.png'
import emailLogo from 'assets/img/market-me/emailLogo.png'
import phoneLogo from 'assets/img/market-me/phoneLogo.png'


import {convertCustomerToLead} from 'store/actions/customerActions'

const EditorForm = BoundEditorForm(actionsForExplorer.editorForm, accessorsForExplorer.editorForm);


const Transition = (props) => {
    return <Slide direction='down' {...props} />;
  };

export function CustomerDetail(props) {

    const { classes, history, uid } = props;

    const key = props.match.params.key;
    const customerData = props.location.state.customerData
    const [areYouSureConvertCustomerToLead, setAreYouSureConvertCustomerToLead] = useState(false)


    console.log('leaddddd', areYouSureConvertCustomerToLead)

    return (
        <div className={classes.mainContainer}>
            <Hidden className={classes.fullWidth} smDown implementation="css">
                <p className={classes.subTitle}>
                    View information associated with the selected customer.
                </p>
            </Hidden>

            <GridContainer style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
            <Hidden className={classes.fullWidth} mdUp implementation="css">
                    <GridItem style={{ display: "flex", marginTop: "50px" }} xs={12} >
                        <div style={{ marginBottom: "0px" }} className={classes.leadInfo}>
                            <Card style={{ marginBottom: "0px" }} className={classes.mobileProfilePicDiv}>
                                <CardHeader className={classes.mobileCardHeader}>
                                    <img className={classes.mobileImg} src={customerData.profilePic ? customerData.profilePic : avatar} />
                                </CardHeader>
                                <CardBody style={{ height: "100%" }}>
                                    <div className={classes.leadDetailInfoDiv} >
                                        <h5 style={{ textAlign: "center" }} className={classes.leadDetailName}>
                                            {customerData.Name? customerData.Name : customerData.firstName + ' ' + customerData.lastName}
                                        </h5>
                                        <div style={{ display: "flex", justifyContent: "center", marginTop: "12px" }}>
                                            <b>Account Status:</b>
                                            <p style={{ marginBottom: "15px", marginLeft: "10px" }} className={classes.leadDetailInfo}>{customerData.accountCreation}</p>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </GridItem>
                </Hidden>

                <Hidden className={classes.fullWidth} mdUp implementation="css">
                    <GridItem style={{ display: "flex" }} xs={12} sm={12} md={12}>
                        <Card style={{ marginBottom: "0px" }} className={classes.mobileProfileAboutDiv}>
                            <CardHeader className={classes.aboutHeaderMobile}>
                                Contact Information
                            </CardHeader>
                            <div style={{ padding: "0px 25px 25px 25px" }}>
                                <div style={{ display: "flex" }}>
                                    <b>Email:</b>
                                    <p style={{ marginBottom: "15px", marginLeft: "10px" }} className={classes.leadDetailInfo}> {customerData.Email? customerData.Email : customerData.email}</p>
                                </div>

                                <div style={{ display: "flex" }}>
                                    <b>Cell Phone:</b>
                                    <p style={{ marginBottom: "15px", marginLeft: "10px" }} className={classes.leadDetailInfo}>
                                        {customerData.PhoneNumber? customerData.PhoneNumber : customerData.cellPhone ? customerData.cellPhone : "N/A"}
                                    </p>
                                </div>


                                <div style={{ display: "flex" }}>
                                    <b>Office Phone:</b>
                                    <p style={{ marginBottom: "15px", marginLeft: "10px" }} className={classes.leadDetailInfo}>
                                        {customerData.officePhone ? customerData.officePhone : "N/A"}
                                    </p>
                                </div>

                                <div style={{ display: "flex" }}>
                                    <b>Company Name:</b>
                                    <p style={{ marginBottom: "15px", marginLeft: "10px" }} className={classes.leadDetailInfo}>
                                        {customerData.CompanyName? customerData.CompanyName : customerData.companyName ? customerData.companyName : "N/A"}
                                    </p>
                                </div>
                            </div>
                        </Card>
                    </GridItem>
                    <GridItem xs={12}>
                        <div className={classes.leadDetailInfoButtonDiv}>
                            <Button style={{ backgroundColor: "#274B73",}} onClick={() => {
                                setAreYouSureConvertCustomerToLead(true)
                            }} className={classes.convertBtn} >
                                Convert To Lead
                                <img style={{ marginLeft: "25px", width: "18px" }} src={convertLogo} />
                            </Button>
                            <Button onClick={() => window.location = `mailto:${customerData.email}`} className={classes.messageBtn} >
                                Message
                                <img style={{ marginLeft: "25px", width: "20px" }} src={envelopeLogo} />
                            </Button>
                        </div>
                    </GridItem>
                </Hidden>








                <Hidden className={classes.fullWidth} smDown implementation="css">
                <GridItem xs={12} sm={12} md={12}>
                    <Card style={{ padding: "30px", boxShadow: "0px 1px 6px grey" }}>
                        <div className={classes.leadInfo}>
                            <div className={classes.leadDetailInfoPicDiv}>
                                <img className={classes.img} src={customerData.profilePic ? customerData.profilePic : avatar} />
                            </div>
                            <div className={classes.leadDetailInfoDiv} >
                                <h5 className={classes.leadDetailName}>
                                    {customerData.Name? customerData.Name : customerData.firstName + ' ' + customerData.lastName}
                                </h5>
                                <p className={classes.leadDetailInfo}> <img className={classes.icon} src={houseLogo} />  {customerData.CompanyName? customerData.CompanyName : customerData.companyName}</p>
                                <p className={classes.leadDetailInfo}> <img className={classes.icon} src={emailLogo} /> {customerData.Email? customerData.Email : customerData.email}</p>
                            </div>
                            <div className={classes.leadDetailInfoDiv}>
                                <h5 style={{ visibility: "hidden" }}>  {customerData.cellPhone}</h5>
                                <p className={classes.leadDetailInfo}> <img className={classes.icon} src={phoneLogo} />  {customerData.PhoneNumber? customerData.PhoneNumber:customerData.cellPhone}</p>
                                <p className={classes.leadDetailInfo}> <b>STATUS:</b> {!customerData.accountCreation? "Pending" : customerData.accountCreation} </p>
                            </div>
                            <div className={classes.fullWithLeadDetailInfoButtonDiv}>
                                <Button onClick={() => setAreYouSureConvertCustomerToLead(true)} className={classes.convertBtn} >
                                    Convert To Lead
                                    <img style={{ marginLeft: "25px", width: "18px" }} src={convertLogo} />
                                </Button>
                                <Button onClick={() => window.location = `mailto:${customerData.email}`} className={classes.messageBtn} >
                                    Message
                                    <img style={{ marginLeft: "25px", width: "20px" }} src={envelopeLogo} />
                                </Button>
                            </div>
                        </div>
                        {/* <div style={{ display: "flex", justifyContent: "space-between", height: "80px" }}>
                            <Button onClick={() => history.push('/admin/customers/explorer')} style={{ backgroundColor: "rgba(94, 177, 227, 1)", width: "20%", fontSize: "17px" }}>
                                Back
                            </Button>
                            <Button style={{ width: "20%", fontSize: "17px" }}>
                                Edit
                            </Button>
                        </div> */}
                    </Card>
                </GridItem>
                </Hidden>






      <Dialog
        style={{ width: "100%" }}
        classes={{
          // root: classes.modalRoot,
          // paper: classes.modal + " " + classes.modalSmall
        }}
        fullWidth={true}
        open={areYouSureConvertCustomerToLead}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAreYouSureConvertCustomerToLead(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
                setAreYouSureConvertCustomerToLead(false)
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>

          <h3>Are You Sure You Want To Convert {customerData.Name? customerData.Name : customerData.firstName + ' ' + customerData.lastName} Back To A Lead?</h3>

        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.modalRoot,
          }}
          style={{ width: "100%" }}
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
            <Button onClick={() => {
                props.convertCustomerToLead(uid, customerData)
                setAreYouSureConvertCustomerToLead(false)
                history.push({
                    pathname: '/admin/contacts',
                    state: { succesfulConversionBackToLead: true }
                })
            }} style={{ backgroundColor: "rgb(86, 163, 166)" }}>
              Yes
            </Button>
            <Button onClick={() => setAreYouSureConvertCustomerToLead(false)}>
              No
            </Button>
          </div>

        </DialogContent>
      </Dialog>

            </GridContainer>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        uid: state.auth.user.uid,
    };
}

function mapDispatchToProps(dispatch) {
   return {
    convertCustomerToLead: (uid, customer) => dispatch(convertCustomerToLead(uid, customer))
   }
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles))
    (CustomerDetail));