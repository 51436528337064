/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { db, firebase, storage } from 'firebase/client';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Phone';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Box from '@material-ui/core/Box';
import Button from 'components/CustomButtons/Button.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import loader from 'assets/img/loader.gif';
import CardBody from 'components/Card/CardBody.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import actionsForNavigation from 'store/actions/navigation';
import Slide from '@material-ui/core/Slide';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';

import documentsStyle from 'assets/jss/material-dashboard-pro-react/views/documentsStyle.js';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { DateTime } from 'luxon';
import { isElementAccessExpression } from 'typescript';

import { updateUserData } from 'store/actions/auth.js';
import { addNewDocument, getDocuments } from 'store/actions/documentActions';

const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

export function ClientDocuments(props) {
  const { classes, uid, data, initDataRetrieved } = props;
  const [uploadDocumentModalOpen, setUploadDocumentModalOpen] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const [file, setFile] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [documentData, setDocumentData] = useState(false);

  const submitFile = () => {
    const storageRef = storage.ref(uid);
    const fileRef = storageRef.child(documentName || file.name);

    fileRef.put(file).then((snapshot) => {
      snapshot.ref
        .getDownloadURL()
        .then((downloadURL) => {
          props.addNewDocument(uid, {
            url: downloadURL,
            name: documentName ? documentName : file.name,
          });
          setUploadDocumentModalOpen(false);
        })
        .catch(() => {});
    });
  };

  useEffect(() => {
    if (!dataLoaded && uid) {
      props.getDocuments(uid);
      setDataLoaded(true);
    }
    if (dataLoaded) {
      setDocumentData(data);
    }
  }, [props]);

  return (
    <Box sx={{ maxWidth: '1300px' }}>
      <GridContainer
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ padding: '30px', boxShadow: '0px 1px 6px grey' }}>
            <CardHeader style={{ fontSize: '32px', fontWeight: 500 }}>
              Documents
            </CardHeader>
            <CardBody className={classes.cardBody}>
              {documentData.length > 0 ? (
                documentData.map((doc) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        margin: '30px',
                      }}
                    >
                      <img style={{ height: '200px' }} src={doc.url} />
                      <span>{doc.name}</span>
                    </div>
                  );
                })
              ) : documentData.length === 0 && initDataRetrieved ? (
                <h1
                  style={{
                    fontSize: '30px',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  You have no documents to display. Click the button below to
                  add them.
                </h1>
              ) : null}
            </CardBody>
          </Card>
        </GridItem>
        <Fab
          className={classes.newRecord}
          onClick={() => setUploadDocumentModalOpen(true)}
        >
          <AddIcon style={{ fontSize: '50px' }} />
        </Fab>

        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + ' ' + classes.modalSmall,
          }}
          fullWidth={true}
          open={uploadDocumentModalOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setUploadDocumentModalOpen(false)}
          aria-labelledby='shipment-modal-slide-title'
          aria-describedby='shipment-modal-slide-description'
        >
          <DialogTitle
            className={classes.updateProfileFormHeader}
            id='shipment-modal-slide-title'
            disableTypography
          >
            <Button
              onClick={() => {
                setUploadDocumentModalOpen(false);
              }}
              simple
              className={classes.modalCloseButton}
              key='close'
              aria-label='Close'
            >
              {' '}
              <Close
                style={{ fontSize: '25px' }}
                className={classes.modalClose}
              />
            </Button>

            <h3>Upload A Document</h3>
          </DialogTitle>
          <DialogContent
            id='shipment-modal-slide-description'
            className={classes.modalBody}
          >
            <form
              style={previewUrl ? { height: '400px' } : { height: '200px' }}
              className={classes.updateProfileForm}
              onSubmit={() => console.log('hi')}
            >
              <TextField
                fullWidth={true}
                onChange={(e) => setDocumentName(e.target.value)}
                value={documentName}
                id='Document Name'
                label='Document Name'
              />

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                {previewUrl ? (
                  <img style={{ height: '200px' }} src={previewUrl} />
                ) : null}

                <Button
                  style={{ width: '100%' }}
                  variant='contained'
                  component='label'
                >
                  Upload File
                  <input
                    onChange={(e) => {
                      var url = URL.createObjectURL(event.target.files[0]);
                      setPreviewUrl(url);
                      setFile(e.target.files[0]);
                    }}
                    type='file'
                    hidden
                  />
                </Button>
              </div>
            </form>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                margin: '25px 0px',
              }}
            >
              <Button
                onClick={() => setUploadDocumentModalOpen(false)}
                style={{ backgroundColor: '#f3684e' }}
              >
                Cancel
              </Button>
              <Button
                onClick={submitFile}
                style={{ backgroundColor: '#05c6a3' }}
              >
                Submit
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </GridContainer>
    </Box>
  );
}

ClientDocuments.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  console.log('stateeeeee', state);
  return {
    uid: state.auth.user.uid,
    data: state.documents.documents,
    initDataRetrieved: state.documents.initDataRetrieved,
  };
}

const mapDispatchToProps = (dispatch) => ({
  addNewDocument: (id, document) => dispatch(addNewDocument(id, document)),
  getDocuments: (id) => dispatch(getDocuments(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(documentsStyle)
)(ClientDocuments);
