/*eslint-disable*/
const leadDetailStyle = (theme) => ({
    buttonContainer: {
        display: "flex",
        justifyContent: "space-around",
    },
    headerButton: {
        backgroundColor: "#fff",
        color: "#000",
        borderRadius: "5px",
        border: "2px solid #ffb063"
    },
    header: {
        marginBottom: "30px",
        [theme.breakpoints.down("md")]: {
            marginBottom: "30px"
        }
    },
    leadInfo: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginBottom: "60px",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center !important"
        }
    },
    fullWidth: {
        width: "100%"
    },
    img: {
        height: "200px",
        width: "200px",
        borderRadius: "50%",
        [theme.breakpoints.down("md")]: {
            width: "200px",
        }
    },
    imgDiv: {
        width: "100%",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            display: "flex",
            justifyContent: "center"
        }
    },
    infoDiv: {
        width: "50%",
        display: "flex",
        flexDirection: 'column',
        justifyContent: "space-evenly",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        }
    },
    leadDetailName: {
        fontSize: "25px",
        fontWeight: 500,
        margin: "0px"
    },
    leadDetailInfoPicDiv: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "20%",
        alignItems: 'center',
        marginRight: "30px",
        fontSize: "16px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginRight: "0px",
        }
    },
    leadDetailInfoDiv: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "25%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            height: "70px"
        }
    },
    leadDetailInfoButtonDiv: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "25px"
    },
    fullWithLeadDetailInfoButtonDiv: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "25px",
        width: "30%"
    },
    leadDetailInfo: {
        margin: "0px"
    },
    convertBtn: {
        backgroundColor: "#274B73",
        color: "white",
        height: "60px",
        fontSize: "17px",
        "&:hover": {
            backgroundColor: "#274B73",
        }
    },
    messageBtn: {
        backgroundColor: "#3AC0C4",
        color: "white",
        height: "60px",
        fontSize: "17px",
        "&:hover": {
            backgroundColor: "#3AC0C4",
        }
    },
    icon: {
        width: "20px",
        marginRight: "20px"
    },
    mobileCardHeader: {
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            justifyContent: "center"
        }
    },
    mobileProfilePicDiv: {
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexDirection: "column",
            fontSize: "16px",
            padding: "20px"
        }
    },
    mobileImg: {
        [theme.breakpoints.down('sm')]: {
            height: "200px",
            width: "200px",
            borderRadius: "50%",
            marginTop: "-115px"
        }
    },
    mobileProfileAboutDiv: {
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexDirection: "column",
            fontSize: "16px",
            // padding: "25px 40px 25px 40px"
        }
    },
    aboutHeaderMobile: {
        [theme.breakpoints.down('sm')]: {
            padding: "0px",
            fontSize: "21px",
            textAlign: "center",
            fontWeight: 500,
            marginBottom: "25px",
            background: "linear-gradient(90.01deg, #274B73 0.48%, #3AC0C4 99.05%) !important",
            color: "white",
            height: "40px",
            display: 'flex',
            alignItems: "center",
            justifyContent: "center"
        }
    },
    updateProfileFormHeader: {
        textAlign: "center",
        fontWeight: 500,
        background: "#274B73",
        margin: "0px",
        padding: "20px",
        color: "white",
        fontSize: "17px",
        [theme.breakpoints.down('sm')]: {
          fontSize: "12px",
        }
      },
      modalCloseButton: {
        "&, &:hover": {
          color: "white"
        },
        "&:hover": {
          opacity: "1"
        },
        cursor: "pointer",
        padding: "1rem",
        margin: "-1rem -1rem -1rem auto",
        backgroundColor: "transparent",
        border: "0",
        WebkitAppearance: "none",
        float: "right",
        fontSize: "1.5rem",
        fontWeight: "500",
        lineHeight: "1",
        textShadow: "0 1px 0 #ffffff",
        opacity: ".5"
      },
})

export default leadDetailStyle;