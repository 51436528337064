import { connect } from 'react-redux';
import Form from './Form';

function mapStateToProps(_accessors) {
    return (_state, _ownProps) => ({
        // none yet
    })
}

function mapDispatchToProps(actions) {
    return (dispatch, _ownProps) => ({
        submit: () => dispatch(actions.submit()),
        cancel: () => dispatch(actions.cancel())
    });
}

export default (actions, accessors) => connect(mapStateToProps(accessors), mapDispatchToProps(actions))(Form);
