import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Remove from '@material-ui/icons/Remove';
import LaunchIcon from '@material-ui/icons/Launch';
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import CoinbaseLogo from './Coinbase.png';
import BinanceLogo from './Binance.png';
import CryptoDotComLogo from './CryptoDotCom.png';
import CoinList from './CoinList';
import 'assets/css/material-dashboard-pro-react.css';



const useStyles = makeStyles(styles);

const ExchangeList = (props) => {
  // const classes = useStyles();
  const { cryptoData, classes } = props;

  const actionButtons = [
    { color: 'info', icon: LaunchIcon },
    { color: 'danger', icon: Remove },
  ].map((prop, key) => {
    return (
      <Button color={prop.color} className={classes.actionButton} key={key}>
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  return (
    <GridContainer
      style={{
        alignItems: 'center',
        display: 'flex',
        flex: '1',
        justifyContent: 'space-between',
        // paddingLeft: '15px',
        //maxWidth: '1309px',
        // maxWidth: '1300px',
      }}
    >
      <GridItem
        sx={{
          direction: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
        xs={12}
        sm={12}
        md={6}
        lg={4}
      >
        <Card
          style={{
            // height: '450px',
            margin: '25px 0px 15px 0px',
          }}
        >
          <CardHeader color='primary'>
            <h3
              style={{
                textAlign: 'center',
                margin: '5px',
                fontFamily: "Metal Core",
                fontSize: "30px"
              }}
              className={classes.cardTitle}
            >
              Coinbase
            </h3>
          </CardHeader>
          <CardBody style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <img
                src={CoinbaseLogo}
                style={{
                  width: '100px',
                  marginTop: '10px',
                }}
              />
              <Button style={{ height: '40px' }}>Connected</Button>
            </div>
            <CoinList classes={classes} coins={cryptoData.exchanges[0].coins} />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem
        sx={{
          direction: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
        xs={12}
        sm={12}
        md={6}
        lg={4}
      >
        <Card
          style={{
            // height: '450px',
            margin: '25px 0px 15px 0px',
          }}
        >
          <CardHeader color='primary'>
            <h3
              style={{
                textAlign: 'center',
                margin: '5px',
                fontFamily: "Metal Core",
                fontSize: "30px"
              }}
              className={classes.cardTitle}
            >
              Binance
            </h3>
          </CardHeader>
          <CardBody style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <img
                src={BinanceLogo}
                style={{
                  width: '100px',
                  marginTop: '10px',
                }}
              />
              <Button style={{ height: '40px' }}>Connected</Button>
            </div>
            <CoinList classes={classes} coins={cryptoData.exchanges[1].coins} />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem
        sx={{
          direction: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
        xs={12}
        sm={12}
        md={6}
        lg={4}
      >
        <Card
          style={{
            // height: '450px',
            margin: '25px 0px 15px 0px',
          }}
        >
          <CardHeader color='primary'>
            <h3
              style={{
                textAlign: 'center',
                margin: '5px',
                fontFamily: "Metal Core",
                fontSize: "30px"
              }}
              className={classes.cardTitle}
            >
              Crypto.com
            </h3>
          </CardHeader>
          <CardBody style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <img
                src={CryptoDotComLogo}
                style={{
                  width: '100px',
                  marginTop: '10px',
                }}
              />
              <Button style={{ height: '40px' }}>Connected</Button>
            </div>
            <CoinList classes={classes} coins={cryptoData.exchanges[2].coins} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

ExchangeList.propTypes = {
  classes: PropTypes.object.isRequired,
  cryptoData: PropTypes.object.isRequired,
};

export default ExchangeList;
