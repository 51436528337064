import { 
    actionsForQueryDialog,
    actionsForQueryForm,
    actionsForEditorForm,
    actionsForNewForm
} from 'store/actions/recordset';

import { actionsForDialog } from 'store/actions/dialog';
import { actionsForForm } from 'store/actions/form';
import { config } from 'store/reducers/recordsets/customers';

import actionsForRecordset from 'store/actions/recordsets/customers';

export default { 
    recordset: actionsForRecordset, 
    queryDialog: actionsForQueryDialog(actionsForRecordset, actionsForDialog('customers/query'), actionsForForm(config, 'customers/query')), 
    queryForm: actionsForQueryForm(actionsForRecordset, actionsForDialog('customers/query'), actionsForForm(config, 'customers/query')), 
    editorForm: actionsForEditorForm(actionsForRecordset, actionsForForm(config, 'customers/editor')), 
    newForm: actionsForNewForm(actionsForRecordset, actionsForForm(config, 'customers/new')) 
};

