/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";

import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import CardFooter from "components/Card/CardFooter";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Input from "@material-ui/core/Input";

import actionsForNavigation from "store/actions/navigation";
import { connect } from "react-redux";
import { MockUserData } from 'variables/config';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import Close from "@material-ui/icons/Close";
import { getLeads, deleteLead, convertLeadToCustomer } from "store/actions/leadsActions"
import Slide from "@material-ui/core/Slide";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import campaignsStyle from "assets/jss/material-dashboard-pro-react/views/campaignsStyle.js";

import AuthActions from "store/actions/auth"
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Views = (props) => {
  const { classes, history } = props;
  const [ viewType, setViewType ] = useState('Day');

  return(
    <Card>
      <CardHeader className={classes.cardHeader}>
        <GridContainer
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}>
          <GridItem
            xs={3}
          >
            <h3
              className={classes.subHeader}
              style={{ color: "white" }}
            >
              Views
            </h3>
          </GridItem>
          <GridItem xs={3}/>
          <GridItem
            xs={6}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                borderRadius: "5px",
                background: "rgb(7, 74, 108)"
              }}
            >
            <div className={classes.buttonDiv}>
              <Button
                className={classes.campaignBtn}
                onClick={() => setViewType('Day')}
                style={
                  viewType === 'Day'
                    ? {
                      background:
                        "linear-gradient(180deg, rgb(33,141,188) 0%, rgb(20,40,70) 100%)",
                    } : {}
                }
              >
                Day
              </Button>
            </div>
            <div className={classes.buttonDiv}>
              <Button
                className={classes.campaignBtn}
                onClick={() => setViewType('Week')}
                style={
                  viewType === 'Week'
                    ? {
                      background:
                        "linear-gradient(180deg, rgb(33,141,188) 0%, rgb(20,40,70) 100%)",
                    } : {}
                }
              >
                Week
              </Button>
            </div>
            <div className={classes.buttonDiv}>
              <Button
                className={classes.campaignBtn}
                onClick={() => setViewType('Month')}
                style={
                  viewType === 'Month'
                    ? {
                      background:
                        "linear-gradient(180deg, rgb(33,141,188) 0%, rgb(20,40,70) 100%)",
                    } : {}
                }
              >
                Month
              </Button>
            </div>
            <div className={classes.buttonDiv}>
              <Button
                className={classes.campaignBtn}
                onClick={() => setViewType('Year')}
                style={
                  viewType === 'Year'
                    ? {
                      background:
                        "linear-gradient(180deg, rgb(33,141,188) 0%, rgb(20,40,70) 100%)",
                    } : {}
                }
              >
                Year
              </Button>
            </div>
            </div>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>

      </CardBody>
    </Card>
  )
}

export default withStyles(campaignsStyle)(Views);