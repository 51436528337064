import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import ChartistGraph from 'react-chartist';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import creditScoreStyle from 'assets/jss/material-dashboard-pro-react/views/creditScoreStyle.js';
import 'assets/css/material-dashboard-pro-react.css';



const SubscriptionsTotals = (props) => {
  const { classes, subscriptionsData } = props;

  const subscriptionsGraph = {
    data: {
      labels: [
        subscriptionsData.monthlyTotals[0].month +
          ' ' +
          subscriptionsData.monthlyTotals[0].year,
        subscriptionsData.monthlyTotals[1].month +
          ' ' +
          subscriptionsData.monthlyTotals[1].year,
        subscriptionsData.monthlyTotals[2].month +
          ' ' +
          subscriptionsData.monthlyTotals[2].year,
        subscriptionsData.monthlyTotals[3].month +
          ' ' +
          subscriptionsData.monthlyTotals[3].year,
        subscriptionsData.monthlyTotals[4].month +
          ' ' +
          subscriptionsData.monthlyTotals[4].year,
        subscriptionsData.monthlyTotals[5].month +
          ' ' +
          subscriptionsData.monthlyTotals[5].year,
      ],
      series: [
        [
          subscriptionsData.monthlyTotals[0].total,
          subscriptionsData.monthlyTotals[1].total,
          subscriptionsData.monthlyTotals[2].total,
          subscriptionsData.monthlyTotals[3].total,
          subscriptionsData.monthlyTotals[4].total,
          subscriptionsData.monthlyTotals[5].total,
        ],
      ],
    },
    options: {
      axisX: {
        showGrid: false,
      },
      low: 0,
      high: 200,
      chartPadding: {
        top: 0,
        right: 5,
        bottom: 0,
        left: 0,
      },
      height: '185px',
    },
    responsiveOptions: [
      [
        'screen and (max-width: 640px)',
        {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function (value) {
              return value[0];
            },
          },
        },
      ],
    ],
  };
  return (
    <GridContainer
      style={{
        display: 'flex',
        backgroundColor: '#fff',
        justifyContent: 'space-between',
        width: '100%',
        // maxWidth: '1300px',
        boxShadow: '0px 2px 8px -1px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        marginBottom: '50px',
        width: "100%",
        margin: "0px auto"
      }}
    >
      <div style={{ width: '100%' }}>
        <CardHeader sx={{ width: '100%', height: '100%' }} color='primary'>
          <h3
            style={{
              textAlign: 'center',
              padding: '5px',
              margin: '0',
              fontFamily: "Metal Core",
              fontSize: "30px"
            }}
            className={classes.cardTitle}
          >
            Total Monthly Subscriptions
          </h3>
        </CardHeader>
      </div>
      <GridItem
        sx={{
          direction: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Card
          style={{
            height: '250px',
            margin: '25px 0px 15px 0px',
            boxShadow: 'none',
            border: 'none',
          }}
        >
          <CardBody>
            <ChartistGraph
              data={subscriptionsGraph.data}
              type='Bar'
              options={subscriptionsGraph.options}
              responsiveOptions={subscriptionsGraph.responsiveOptions}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

SubscriptionsTotals.propTypes = {
  classes: PropTypes.object.isRequired,
  subscriptionsData: PropTypes.object.isRequired,
};

export default SubscriptionsTotals;
