import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
  dangerColor,
} from "assets/jss/material-dashboard-pro-react.js";

const campaignsStyle = (theme) => ({
  container: {
    ...container,
    zIndex: "4",
    color: "#000",
    padding: "10px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  cardTitle: {
    ...cardTitle,
    textAlign: "center",
    fontSize: "1.8rem",
    color: "#18528c",
    fontWeight: 400,
  },
  uploadText: {
    color: "#EEBD09",
    fontSize: "20px",
    fontWeight: 400,
    marginTop: "30px",
  },
  advanceStatus: {
    "&:hover": {
      backgroundColor: "#219ebc",
    },
    backgroundColor: "#023047",
  },
  mainExplorerCard: {
    padding: "30px",
    boxShadow: "0px 1px 6px grey",
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
  },
  remind: {
    "&:hover": {
      backgroundColor: "#ffb703",
    },
    backgroundColor: "#fb8500",
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "20px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  modalBody: {
    display: "flex",
  },
  largeImg: {
    height: "32vh",
    margin: "20px 0 0 0",
    display: "block",
    marginRight: "auto",
    marginLeft: "auto",
  },
  statusAnalytics: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    textAlign: "center",
  },
  attentionFlag: {
    color: dangerColor[0] + "!important",
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px 30px 0 0",
  },
  loanOffCard: {
    padding: "10px",
  },
  dateInfo: {
    color: "#05669b",
  },
  leadsCard: {
    boxShadow: "0px 1px 6px grey",
    padding: "30px",
    [theme.breakpoints.up("md")]: {
      padding: "30px",
    },
  },
  leadsContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      marginBottom: "0px",
      padding: "20px",
      justifyContent: "space-around",
    },
  },
  leadsRow: {
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize: "19px !important",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      fontSize: "25px",
      width: "25%",
    },
  },
  avatar: {
    width: "100px",
  },
  updateProfileFormHeader: {
    textAlign: "center",
    fontWeight: 500,
    background: "#274B73",
    margin: "0px",
    padding: "20px",
    color: "white",
    fontSize: "23px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  updateProfileForm: {
    display: "flex",
    flexDirection: "column",
    height: "205px",
    justifyContent: "space-between",
    paddingRight: "50px",
    [theme.breakpoints.down("sm")]: {
      height: "300px",
      paddingRight: "0px",
    },
  },
  modalCloseButton: {
    "&, &:hover": {
      color: "white",
    },
    "&:hover": {
      opacity: "1",
    },
    cursor: "pointer",
    padding: "1rem",
    margin: "-1rem -1rem -1rem auto",
    backgroundColor: "transparent",
    border: "0",
    WebkitAppearance: "none",
    float: "right",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 #ffffff",
    opacity: ".5",
  },
  subTitle: {
    fontSize: "19px",
  },
  cardBody: {
    marginLeft: "13px",
    minHeight: "600px",
    overflow: "scroll",
    background: "rgba(255, 255, 255, 1)",
  },
  cardHeader: {
    display: "flex",
    width: "100%",
    background: "#13294C",
    fontWeight: 400,
    fontSize: "25px",
    lineHeight: "32px",
    textAlign: "center",
    color: "white",
    padding: "10px",
  },
  tableHeader: {
    width: "20%",
  },
  tableCell: {
    width: "20%",
    fontSize: "18px",
    textAlign: "center",
  },
  customerCardBody: {
    height: "600px",
    overflowY: "scroll",
    overflowX: "hidden !important",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 6px grey",
  },
  newRecord: {
    "&:hover": {
      background: "linear-gradient(180deg, #5EB1E3 26.3%, #274B73 210.42%)",
    },
    background: "linear-gradient(180deg, #5EB1E3 26.3%, #274B73 210.42%)",
    borderRadius: "50%",
    color: "#fff",
    position: "fixed",
    bottom: 20,
    right: 40,
    zIndex: 3,
    height: "86px",
    fontSize: "50px",
    width: "86px",
    [theme.breakpoints.down("sm")]: {
      height: "85px",
      width: "85px",
      bottom: 80,
      right: 17,
    },
  },
  viewDetailsBtn: {
    width: "33%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    padding: "15px",
    justifyContent: "space-between",
  },
  customerBtns: {
    width: "50%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    padding: "15px",
    justifyContent: "space-between",
  },
  cancel: {
    backgroundColor: "#f5c107",
    width: "40%",
  },
  submit: {
    backgroundColor: "rgb(94, 177, 227)",
    color: "white",
    width: "40%",
  },
  campaignCard: {
    display: "flex",
    width: "87%",
    height: "200px",
    borderRadius: "10px",
    marginRight: "20px",
  },
  campaignHeader: {
    width: "100%",
    height: "2px",
    background:
      "linear-gradient(89.97deg, rgba(238, 189, 9, 1) 0.99%, rgba(86, 163, 166, 1) 99.97%)",
    margin: "20px 0px",
  },
  campaignBody: {
    display: "flex",
    width: "100%",
  },
  subHeader: {
    fontSize: "27px",
    fontWeight: 500,
    fontFamily: "Abril Fatface",
    color: "black",
  },
  buttonDiv: {
    width: "25%",
    margin: "0px 5px",
  },
  campaignBtn: {
    background: "inherit",
    boxShadow: "none",
    width: "100%",
    color: "white",
    fontSize: "14px",
  },
  campaignStatus: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    width: "25%",
    height: "70%",
    "&:hover": {
      cursor: "pointer",
      background: "rgb(182 182 182 / 87%)",
    },
  },
  mainStepContainer: {
    display: "flex",
    flexDirection: "column",
    border: "2px solid rgb(19,41,76)",
    borderRadius: "10px"
  },
  stepHeader: {
    display: "flex",
    color: "white",
    backgroundColor: "rgb(3,38,65)",
    fontSize: "16px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    width: "-webkit-fill-available",
    alignItems: "center"
  },
  stepContainerOuter: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "2px solid rgb(19,41,76)",
    margin: "0.625rem"
  },
  stepContainerInner: {
    display: "flex",
    flexDirection: "column",
    margin: "0.2rem 0.625rem 0.2rem 0.625rem",
    fontWeight: 800
  },
  buttonContainer: {
    display: "flex",
    flexWrap: "nowrap"
  },
  stepButton: {
    border: "1.5px solid #005479",
    borderRadius: "5px",
    width: "min-content",
    height: "3rem",
    backgroundColor: 'transparent'
  },
  stepButtonPressed: {
    background: "linear-gradient(180deg, rgba(0,84,121,1) 0%, rgba(19,41,76,1) 63%)",
    border: "1.5px solid #005479",
    borderRadius: "5px",
    width: "min-content",
    color: "white",
    height: "3rem"
  },
  stepIcon: {
    marginRight: "2rem",
    filter: "brightness(0) saturate(100%) invert(13%) sepia(78%) saturate(4765%) hue-rotate(186deg) brightness(93%) contrast(101%)"
  },
  stepIconPressed: {
    marginRight: "2rem"
  },
  calendarIcon: {
    marginLeft: "0.75rem",
    marginTop: "0.2rem",
    alignSelf: "self-start"
  },
  backButton: {
    background: "linear-gradient(270deg, #1D9BC9 0.14%, #13294C 100%)",
    borderRadius: "5px",
    width: "15rem",
    height: "3rem",
    color: "white"
  },
  nextButton: {
    background: "linear-gradient(180deg, #FEF61B 0%, #EDE51A 100%)",
    borderRadius: "5px",
    width: "15rem",
    height: "3rem"
  },
  nextButtonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem"
  },
  step3Container: {
    display: "flex",
    flexDirection: "column"
  },
  modal: {
    display: "none",
    position: "fixed",
    textAlign: "center",
    zIndex: 5000,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgb(0,0,0)",
    backgroundColor: "rgba(0,0,0,0.4)"
  },
  modalContent: {
    backgroundColor: "linear-gradient(180deg, #13294C 0%, #005479 100%)",
    margin: "5% auto",
    padding: "20px",
    width: "60%"
  },
  directMessageHeader: {
    marginLeft: "1rem",
    width: "1rem",
    minWidth: "27rem",
    border: "2px solid #13294C",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    padding: "3%"
  },
  directMessageQuill: {
    minHeight: "25rem",
    height: "1rem",
    marginLeft: "1rem",
    marginTop: "-3px",
    width: "1rem",
    minWidth: "27rem",
    marginBottom: "1rem",
    border: "2px solid #13294C",
    borderTop: "none",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px"
  },
  directMessageProfile: {
    marginLeft: "1rem",
    padding: "1rem",
    paddingTop: "0.5rem",
    borderRight: "2px solid",
    borderLeft: "2px solid",
    width: "1rem",
    minWidth: "27rem"
  },
  profilePictureCircle: {
    borderRadius: "100%",
    height: "65px"
  },
  instagramExample: {
    marginLeft: "1rem",
    border: "2px solid #13294C",
    borderTop: "none",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    width: "27rem",
    padding: "1rem",
    minHeight: "23.5rem"
  },
  instagramBlurb: {
    background: "#13294C",
    color: "white",
    fontWeight: 700,
    padding: "1rem",
    borderRadius: "19px",
    width: "65%",
    marginLeft: "0.5rem"
  },
  newButton :{
    height:"70px",
    width: "150px",
    background: "#1D9BC9",
    color:"white",
    borderRadius:"5px",
    "&:hover": {
      backgroundColor: "#1D9BC9",
      color: "white",
      // boxShadow: "2px 2px 0px 0px #e3470a"
    },
  },
});

export default campaignsStyle;
