/*eslint-disable*/
import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Phone';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PersonIcon from '@material-ui/icons/Person';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Hidden from "@material-ui/core/Hidden";
import avatar from 'assets/img/default-avatar.png';


import Charts from "views/Charts/Charts.js"

import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import TextField from '@material-ui/core/TextField';

import analyticsStyle from "assets/jss/material-dashboard-pro-react/views/analyticsStyle.js";

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { DateTime } from "luxon";
import { isElementAccessExpression } from "typescript";

import { updateUserData } from 'store/actions/auth.js'
import ProfileIcon from 'assets/img/market-me/profileIcon.png'


const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

const tableHeaders = ["Name", "Credit Score Source", "Reports Sent", "Subscriptions", "Documents Uploaded", "Actions"]

const mockData = [
  { firstName: "Mary", lastName: "Smith", creditScoreSource: "Experian", reportsSent: 5, subscriptions: 7, documentsUploaded: 4 },
  { firstName: "Jane", lastName: "Doe", creditScoreSource: "Experian", reportsSent: 3, subscriptions: 5, documentsUploaded: 2 },
  { firstName: "Alex", lastName: "Smith", creditScoreSource: "Experian", reportsSent: 2, subscriptions: 10, documentsUploaded: 8 },
  { firstName: "Bryan", lastName: "Callen", creditScoreSource: "Experian", reportsSent: 1, subscriptions: 1, documentsUploaded: 1 },
  { firstName: 'Brendan', lastName: "Schaub", creditScoreSource: "Experian", reportsSent: 0, subscriptions: 0, documentsUploaded: 0 },
]


export function Analytics(props) {
  const { classes, uid, customerData, initDataRetrieved } = props;

  const [header, setHeader] = useState('CUMULATIVE DATA')


  const renderTableHeader = () => {
    return tableHeaders.map((key, index) => {
      return <th style={{fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px"}} className={classes.tableHeader} key={index}>{key}  </th>
    })
  }

  const renderTableData = () => {
    return mockData.map((d, index) => {
      return (
        <tr style={index % 2 === 1 ? { background: "rgba(242, 242, 242, 1)", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" } : {fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px"}}
        // style={index % 2 === 1 ? { background: "rgba(242, 242, 242, 1)" } : {}}
        >
          <td className={classes.tableCell}>
            {d.firstName + ' ' + d.lastName}
          </td>
          <td className={classes.tableCell}>
            {d.creditScoreSource}
          </td>
          <td className={classes.tableCell}>
            {d.reportsSent}
          </td>
          <td className={classes.tableCell}>
            {d.subscriptions}
          </td>
          <td className={classes.tableCell}>
            {d.documentsUploaded}
          </td>
          <td style={{ display: "flex", width: "100%", justifyContent: "space-around" }} className={classes.tableCell}>
            <Button onClick={() => {
            }} style={{ display: "flex", width: "45%", justifyContent: "space-around", alignItems: "center", background: "rgba(39, 75, 115, 1)", color: "white" }}>
              <p style={{ margin: "0px" }}>Details</p>
              <OpenInNewIcon />
            </Button>
            <Button onClick={() => {
            }} style={{ display: "flex", width: "45%", justifyContent: "space-around", alignItems: "center" }}>
              <p style={{ margin: "0px" }}>Profile</p>
              <PersonIcon />
            </Button>
          </td>
        </tr>
      )
    })
  }



  return (
    <div className={classes.container}>

      <Hidden smDown implementation='css'>
      <p className={classes.subTitle}>
        Review client data and analytics.
      </p>
      </Hidden>

      <Hidden mdUp implementation='css'>
      <h5 className={classes.subHeader}>
        {header}
      </h5>
      </Hidden>




      <Hidden mdUp implementation="css">
        <GridContainer>
          <GridItem xs={12}>
            <Card className={classes.individualMobileKPIDiv}>
              <div className={classes.individualKPICircle}>
                <h1 className={classes.individualKPIText}>3.9%</h1>
              </div>
              <h5 className={classes.individualKPIHeader}>Customer Credit Growth</h5>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <Card className={classes.individualMobileKPIDiv}>
              <div className={classes.individualKPICircle}>
                <h1 className={classes.individualKPIText}>$1500</h1>
              </div>
              <h5 className={classes.individualKPIHeader}>Secure Card Spending</h5>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <Card className={classes.individualMobileKPIDiv}>
              <div className={classes.individualKPICircle}>
                <h1 className={classes.individualKPIText}>290 Coins</h1>
              </div>
              <h5 className={classes.individualKPIHeader}>Crypto Assets Total</h5>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <Card className={classes.individualMobileKPIDiv}>
              <div className={classes.individualKPICircle}>
                <h1 className={classes.individualKPIText}>54</h1>
              </div>
              <h5 className={classes.individualKPIHeader}>Total Customers</h5>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <Card className={classes.individualMobileKPIDiv}>
              <div className={classes.individualKPICircle}>
                <h1 className={classes.individualKPIText}>$250</h1>
              </div>
              <h5 className={classes.individualKPIHeader}>Total Earnings</h5>
            </Card>
          </GridItem>

          <GridItem xs={12}>
            <Card className={classes.customerCardBody}>
              <CardHeader className={classes.cardHeader}>
                Analytics Per Customer
              </CardHeader>
              <table style={{ width: "100%" }}>
                <tbody>
                  {mockData.map((d, index) => {
                    return (
                      <div style={{ margin: "10px auto" }} onClick={() => {
                        // setSelectedCustomer(d)

                      }}>

                        <div style={{ display: "flex", justifyContent: 'space-between', width: "90%", marginBottom: "50px", height: "270px", margin: "10px auto", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "15px" }}>
                          <div style={{ display: "flex", flexDirection: "column", height: "50px", width: "100%", padding: "5px", height: "150px" }}>
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <img style={{ width: "100px", borderRadius: "100%", marginBottom: "10px", height: 'auto' }} src={d.profilePic ? d.profilePic : avatar} />
                            </div>
                            <p>
                              <b style={{ fontSize: "16px", marginRight: "10px" }}>
                                Name:
                              </b>
                              {d.firstName + ' ' + d.lastName}
                            </p>
                            <p>
                              <b style={{ fontSize: "16px", marginRight: "10px" }}>
                                Credit Score Source:
                              </b>
                              {' ' + d.creditScoreSource}
                            </p>
                            <p>
                              <b style={{ fontSize: "16px", marginRight: "10px" }}>
                                Reports Sent:
                              </b>
                              {d.reportsSent ? d.reportsSent : "N/A"}
                            </p>
                            <p>
                              <b style={{ fontSize: "16px", marginRight: "10px" }}>
                                Subscriptions:
                              </b>
                              {d.subscriptions ? d.subscriptions : "N/A"}
                            </p>
                            <p>
                              <b style={{ fontSize: "16px", marginRight: "10px" }}>
                                Documents Uploaded:
                              </b>
                              {d.documentsUploaded ? d.documentsUploaded : "N/A"}
                            </p>
                          </div>
                        </div>





                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <div style={{
                            display: "flex",
                            padding: "0px 20px",
                            justifyContent: "space-between"
                          }}>
                            <Button onClick={() => {
                            }} style={{ display: "flex", width: "50%", justifyContent: "space-around", alignItems: "center", background: "rgba(39, 75, 115, 1)", color: "white" }}>
                              <p style={{ margin: "0px" }}>Details</p>
                              <OpenInNewIcon />
                            </Button>

                            <Button onClick={() => {
                            }} style={{ display: "flex", width: "50%", justifyContent: "space-around", alignItems: "center" }}>
                              <p style={{ margin: "0px" }}>Profile</p>
                              <PersonIcon />
                            </Button>
                          </div>
                        </div>
                        <div style={{ width: "90%", height: "2px", background: "linear-gradient(89.97deg, rgba(238, 189, 9, 1) 0.99%, rgba(86, 163, 166, 1) 99.97%)", margin: "30px auto" }}>
                        </div>
                      </div>
                    )
                  })}
                </tbody>
              </table>
            </Card>
          </GridItem>
        </GridContainer>

      </Hidden>




      <Hidden smDown implementation="css">

        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Card className={classes.individualKPIDiv}>
            <div className={classes.individualKPICircle}>
              <h1 className={classes.individualKPIText}>3.9%</h1>
            </div>
            <h5 className={classes.individualKPIHeader}>Customer Credit Growth</h5>
          </Card>

          <Card className={classes.individualKPIDiv}>
            <div className={classes.individualKPICircle}>
              <h1 className={classes.individualKPIText}>$1500</h1>
            </div>
            <h5 className={classes.individualKPIHeader}>Secure Card Spending</h5>
          </Card>

          <Card className={classes.individualKPIDiv}>
            <div className={classes.individualKPICircle}>
              <h1 className={classes.individualKPIText}>290 Coins</h1>
            </div>
            <h5 className={classes.individualKPIHeader}>Crypto Assets Total</h5>
          </Card>

          <Card className={classes.individualKPIDiv}>
            <div className={classes.individualKPICircle}>
              <h1 className={classes.individualKPIText}>54</h1>
            </div>
            <h5 className={classes.individualKPIHeader}>Total Customers</h5>
          </Card>

          <Card className={classes.individualKPIDiv}>
            <div className={classes.individualKPICircle}>
              <h1 className={classes.individualKPIText}>$250</h1>
            </div>
            <h5 className={classes.individualKPIHeader}>Total Earnings</h5>
          </Card>
        </div>

        <Card className={classes.documentCardBody}>
          <CardHeader className={classes.cardHeader}>
            Analytics Per Customer
          </CardHeader>
          <table style={{ width: "100%", padding: "0px 20px" }}>
            <tbody>
              <tr style={{ height: "50px", width: "100%" }}>{renderTableHeader()} </tr>
              {renderTableData()}
            </tbody>
          </table>
        </Card>
      </Hidden>
    </div >
  )
}

Analytics.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  console.log('stateeeeee', state)
  return {
    uid: state.auth.user.uid,
    firstName: state.auth.firstName ? state.auth.firstName : state.auth.user.firstName,
    lastName: state.auth.lastName ? state.auth.lastName : state.auth.user.lastName,
    userType: state.auth.user && state.auth.user.userType,
    email: state.auth.email ? state.auth.email : state.auth.user.email,
    dateOfBirth: state.auth.dateOfBirth ? state.auth.dateOfBirth : state.auth.user.dateOfBirth,
    phoneNumber: state.auth.user && state.auth.user.phoneNumber,
    cellPhone: state.auth.cellPhone ? state.auth.cellPhone : state.auth.user.cellPhone,
    officePhone: state.auth.officePhone ? state.auth.officePhone : state.auth.user.officePhone,
    companyName: state.auth.companyName ? state.auth.companyName : state.auth.user.companyName,
    about: state.auth.about ? state.auth.about : state.auth.user.about,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    edit: (key) => dispatch(actionsForNavigation.openChild("Profile Editor", `/admin/user/editor`)),
    updateUserData: (uid, newInfo) => dispatch(updateUserData(uid, newInfo))
  })
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(analyticsStyle)
)(Analytics);
