const informationModalStyle = {
  container: {
    display: "flex",
    position: "relative",
    marginLeft: "1rem",
    marginRight: "1rem"
  },
  logo: {
    width: "1.5rem"
  },
  textBox: {
    position: "absolute",
    background: "linear-gradient(180deg, #E60023 0%, #AC001A 100%)",
    borderRadius: "5px",
    color: "white",
    left: "1.5rem",
    top: "-3.5rem",
    padding: "0 2rem",
    width: "39rem",
    zIndex: 1
  },
  hidden: {
    display: "none"
  }
};

export default informationModalStyle;
