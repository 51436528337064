import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/views/investmentsStyle.jsx';
import Search from '@material-ui/icons/Search';
import Card from "components/Card/Card.js";
import CustomInput from 'components/CustomInput/CustomInput.js';

export const GlobalSearchBox = ({ classes, onInput, id, matchesPage }) => (

  <Card
  style={{visibility: "hidden"}}
    className={classes.matchesPageCard}
  >
    <div
      className={classes.searchBoxDiv}
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        margin: '0',
        padding: '0',
        width: '100%',
      }}

    >
      <Search
        style={{
          color: '#48b4f7',
          marginBottom: '-8px',
          marginRight: '10px',
          flex: '1',
        }}
        className={classes.headerLinksSvg + ' ' + classes.searchIcon}
      />

      <CustomInput
        searchBox={true}
        formControlProps={{
          className: classes.top + ' ' + classes.search,
          style: { width: '85%', paddingTop: '10px' },
        }}
        id={id || "query"}
        inputProps={{
          placeholder: 'Search',
          onChange: ({ target: { value } }) => onInput(value),
          inputProps: {
            'aria-label': 'Search',
            className: classes.searchInput,
          },
        }}
      />
    </div>
  </Card>
);

GlobalSearchBox.propTypes = {
  classes: PropTypes.object.isRequired,
  onInput: PropTypes.func.isRequired,
};

export default withStyles(Style)(GlobalSearchBox);
