import React from "react";
import BoundEditorForm from 'components/Explorer/BoundEditorForm';
import { connect } from "react-redux";
import actions from 'store/actions/forms/profile';
import accessors from 'store/reducers/forms/profile';

const EditorForm = BoundEditorForm(actions, accessors);

export function ProfileEditor(props) {

    const { initialize, uid, firstName, lastName, phoneNumber, cellPhone, email, officePhone, company } = props;

    React.useEffect(() => {
        if (uid) initialize();
    },[uid, initialize]);

    return (
        <EditorForm userInfo={{firstName, lastName, company, cellPhone, email, officePhone}} order={["firstName", "lastName", "company", "cellPhone", "email", "officePhone"]} />
    );
}

function mapStateToProps() {
    return (state)=> {
        return {
            uid: state.auth.uid,
            firstName: state.auth.firstName ? state.auth.firstName : state.auth.user.firstName,
            lastName: state.auth.lastName ? state.auth.lastName : state.auth.user.lastName,
            userType: state.auth.user && state.auth.user.userType,
            email: state.auth.email ? state.auth.email : state.auth.user.email,
            dateOfBirth: state.auth.dateOfBirth ? state.auth.dateOfBirth : state.auth.user.dateOfBirth,
            phoneNumber: state.auth.user && state.auth.user.phoneNumber,
            cellPhone: state.auth.cellPhone ? state.auth.cellPhone : state.auth.user.cellPhone,
            officePhone: state.auth.officePhone ? state.auth.officePhone : state.auth.user.officePhone,
            company: state.auth.companyName ? state.auth.companyName : state.auth.user.companyName,
        }
    };
}

function mapDispatchToProps(){
    return (dispatch) => {
        return {
            initialize: (mode, key)=>dispatch(actions.initialize(mode, key))
        };
    }
}

export default connect(mapStateToProps(), mapDispatchToProps())(ProfileEditor);