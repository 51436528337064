import React from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import NegativeItemsList from './NegativeItemsList.jsx';
import CreditLinesList from './CreditLinesList.jsx';
import 'assets/css/material-dashboard-pro-react.css';




const ReportResults = (props) => {
    const { classes, creditData } = props
  return (
    // <GridItem
    //   xs={12}
    //   sm={12}
    //   md={6}
    //   lg={6}
    //   sx={{
    // flex: '1',
    //   }}
    //   style={{flex: '2 702px', maxHeight: '244px', display: 'flex',}}
    //   id={'report-results'}
    //   >
        <Card
          style={{
            maxHeight: '100%',
            margin: '0',
            flex: '1' }}
        >
          <CardHeader color='primary' style={{ padding: '10px' }}>
            <h5
              style={{
                textAlign: 'center',
                margin: '10px 0',
                fontFamily: "Metal Core",
                fontSize: "30px"
              }}
              className={classes.cardTitle}
            >
              Report Results
            </h5>
          </CardHeader>
          <CardBody style={{ textAlign: 'center' }}>
            <GridContainer>
              <GridItem
                sx={{
                  direction: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  flex: '1',
                }}
                xs={6}
                sm={6}
                md={6}
                lg={6}
              >
                <NegativeItemsList creditData={creditData} />
              </GridItem>
              <GridItem
                sx={{
                  direction: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  flex: '1',
                }}
                xs={6}
                sm={6}
                md={6}
                lg={6}
              >
                <CreditLinesList creditData={creditData} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      // </GridItem>
  )
}

export default ReportResults