import { firebase } from "@firebase/app";
import "@firebase/firestore";
import "@firebase/storage";
import dotenv from 'dotenv';
import "firebase/functions"

// ensure config is read
dotenv.config();

// Initialize Firebase from .env files
const projectId = process.env.REACT_APP_PROJECT_ID;

console.log('projectIdprojectId', projectId)

const config = {
  apiKey: "AIzaSyApBpP9MAFvetiNrJ8KfispokWmiiB6iXg",
  authDomain: "market-me-346916.firebaseapp.com",
  projectId: "market-me-346916",
  storageBucket: "market-me-346916.appspot.com",
  messagingSenderId: "195628423079",
  appId: "1:195628423079:web:b6b33e00f0d5f609200882",
  measurementId: "G-MB681Q8DCP"
};

firebase.initializeApp(config);
firebase.firestore().settings({});

const db = firebase.firestore();
const storage = firebase.storage();
console.log('storage', storage)
const functions = firebase.functions();
var sendCustomerNotificationEmail = functions.httpsCallable('sendCustomerNotificationEmail');

var sendSMS = functions.httpsCallable('sendSMS');
var tokenExchange = functions.httpsCallable('tokenExchange');

var updateToken = functions.httpsCallable('update_token');
export {
  db,
  storage,
  firebase,
  functions,
  sendCustomerNotificationEmail,
  sendSMS,
  tokenExchange,
  updateToken,
  firebase as default
};