export function getCurrentTitle(state) {
    return state.navigation && state.navigation.pageStack.title;
}

const initState = { pageStack: { } };

export default (state = initState, action) => {
    switch (action.type) {
        case "NAVIGATION_OPEN_CHILD":
            return {
                ...state,
                pageStack: { parent: state.pageStack, title: action.title, url: action.url }
            }
        case "NAVIGATION_OPEN":
            return {
                ...state,
                pageStack: { title: action.title, url: action.url }
            }
        case "NAVIGATION_CLOSE":
            return {
                ...state,
                pageStack: state.pageStack.parent || {}
            }
        case "NAVIGATION_PAGESTACK":
            return {
                ...state,
                pageStack: {
                    url: action.url,
                    editForm: true
                }
            }
        default:
            return state;
    }
}
