/*eslint-disable*/
import React, { useState, useEffect, createRef } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { db, firebase, storage } from "firebase/client";
import { NavLink, withRouter } from "react-router-dom";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import SendIcon from "@material-ui/icons/Send";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import PinDropIcon from "@material-ui/icons/PinDrop";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Switch from "@material-ui/core/Switch";
import Slide from "@material-ui/core/Slide";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Box from "@material-ui/core/Box";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import loader from "assets/img/loader.gif";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";
import Close from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";

import StripeComponent from "../stripeapi/StripeContainer.js";

import documentsStyle from "assets/jss/material-dashboard-pro-react/views/documentsStyle.js";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { DateTime } from "luxon";
import { isElementAccessExpression } from "typescript";

import { updateUserData } from "store/actions/auth.js";
import { addNewDocument, getDocuments } from "store/actions/documentActions";
import { addDocumentsToCustomerCollectionForDF } from "store/actions/customerActions";
import { createLeadsFromCSV } from "store/actions/leadsActions";
import avatar from "assets/img/default-avatar.png";
import SearchBox from "components/SearchBox/SearchBox.jsx";

const planFeatures = [
  "Core Functionality",
  "Functionality 1",
  "Functionality 2",
  "Functionality 3",
  "Functionality 4",
  "Functionality 5",
  "Functionality 6",
];

const socialMediaFeatures = [
  "Instagram",
  "LinkedIn",
  "Text",
  "Email",
  "Limited Contacts",
];

const telecomFeatures = [
  "Instagram",
  "LinkedIn",
  "Text",
  "Email",
  "Unlimited Contacts",
];

const goldFeatures = [
  "All Social Campaigns",
  "Individual Support",
  "Unlimited Contacts",
  "Targeted Leads",
  "Company Research",
];

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

export function ChoosePlan(props) {
  const { classes } = props;
  const [planSelected, setPlanSelected] = useState("");
  const [openStripeModal, setOpenStripeModal] = useState(false);

  return (
    <GridContainer className={classes.choosePlanGridContainer}>
      <Card className={classes.choosePlanCard}>
        <CardHeader className={classes.choosePlanCardHeader}>
          Choose Your Plan
        </CardHeader>
        <CardBody className={classes.cardBodyChoosePlan}>
          <GridContainer style={{ height: "90%" }}>
            <GridItem
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                borderWidth: "0px 2px 0px 0px",
                borderStyle: "solid",
                borderImage:
                  "linear-gradient(180deg, #EEBD09 0%, #56A3A6 100%)",
                borderImageSlice: 1,
                border: "0px 1px 0px 0px",
              }}
              xs={4}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h2 className={classes.planText}>Social Media</h2>
                <p className={classes.planText}>$9.99</p>

                {socialMediaFeatures.map((f, index) => {
                  return <p className={classes.featureText}>{f}</p>;
                })}

                {planSelected && planSelected === "Social Media" ? (
                  <Button
                    style={{ background: "#EEBD09" }}
                    className={classes.selectedPlanButton}
                    onClick={() => setPlanSelected("Social Media")}
                  >
                    Selected
                  </Button>
                ) : (
                  <Button
                    className={classes.choosePlanButton}
                    onClick={() => setPlanSelected("Social Media")}
                  >
                    Choose Plan
                  </Button>
                )}
              </div>
            </GridItem>
            <GridItem
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
              xs={4}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h2 className={classes.planText}>Telecom</h2>
                <p className={classes.planText}>$49.99</p>

                {telecomFeatures.map((f, index) => {
                  return <p className={classes.featureText}>{f}</p>;
                })}

                {planSelected && planSelected === "Telecom" ? (
                  <Button
                    style={{ background: "#EEBD09" }}
                    className={classes.selectedPlanButton}
                    onClick={() => setPlanSelected("Telecom")}
                  >
                    Selected
                  </Button>
                ) : (
                  <Button
                    className={classes.choosePlanButton}
                    onClick={() => setPlanSelected("Telecom")}
                  >
                    Choose Plan
                  </Button>
                )}
              </div>
            </GridItem>
            <GridItem
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                borderWidth: "0px 0px 0px 2px",
                borderStyle: "solid",
                borderImage:
                  "linear-gradient(180deg, #EEBD09 0%, #56A3A6 100%)",
                borderImageSlice: 1,
                border: "0px 0px 0px 1px",
              }}
              xs={4}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h2 className={classes.planText}>Gold</h2>
                <p className={classes.planText}>$999.99</p>

                {goldFeatures.map((f, index) => {
                  return <p className={classes.featureText}>{f}</p>;
                })}

                {planSelected && planSelected === "Gold" ? (
                  <Button
                    style={{ background: "#EEBD09" }}
                    className={classes.selectedPlanButton}
                    onClick={() => setPlanSelected("Gold")}
                  >
                    Selected
                  </Button>
                ) : (
                  <Button
                    className={classes.choosePlanButton}
                    onClick={() => setPlanSelected("Gold")}
                  >
                    Choose Plan
                  </Button>
                )}
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          {planSelected ? (
            <Button
              onClick={() => setOpenStripeModal(true)}
              style={{ width: "100%", background: "#5EB1E3", color: "white" }}
            >
              Next
            </Button>
          ) : null}
        </CardFooter>
      </Card>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        fullWidth={true}
        open={openStripeModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenStripeModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setOpenStripeModal(false);
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            <Close
              style={{ fontSize: "25px" }}
              className={classes.modalClose}
            />
          </Button>
          <h3>Payment Info</h3>
        </DialogTitle>
        <DialogContent
          id="shipment-modal-slide-description"
          className={classes.modalBody}
        >
          <StripeComponent
            features={
              planSelected === "Telecom"
                ? telecomFeatures
                : planSelected === "Social Media"
                ? socialMediaFeatures
                : goldFeatures
            }
            setOpenStripeModal={setOpenStripeModal}
            amount={
              planSelected === "Telecom"
                ? 4999
                : planSelected === "Social Media"
                ? 999
                : 99999
            }
            planSelected={planSelected}
          />
        </DialogContent>
        {/* <div style={{display: "flex", alignItems: 'center', justifyContent: "center"}}>
              <Button onClick={() => setOpenStripeModal(false)}>
                Back
              </Button>
            </div> */}
      </Dialog>
    </GridContainer>
  );
}

ChoosePlan.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(documentsStyle)
  )(ChoosePlan)
);
