/*eslint-disable*/
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import moment from 'moment';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Phone';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import actionsForNavigation from 'store/actions/navigation';
import Slide from '@material-ui/core/Slide';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';

import AgenciesList from './AgenciesList';

import creditScoreStyle from 'assets/jss/material-dashboard-pro-react/views/creditScoreStyle.js';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { DateTime } from 'luxon';
import { isElementAccessExpression } from 'typescript';

import { updateUserData } from 'store/actions/auth.js';

const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

export function Agencies(props) {
  const date = moment();

  const agenciesData = [
    { name: 'Experian', connected: true },
    { name: 'Equifax', connected: false },
    { name: 'TransUnion', connected: true },
  ];

  const { classes } = props;
  return <AgenciesList classes={classes} agenciesData={agenciesData} />;
}

Crypto.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  console.log('stateeeeee', state);
  return {
    uid: state.auth.user.uid,
    firstName: state.auth.firstName
      ? state.auth.firstName
      : state.auth.user.firstName,
    lastName: state.auth.lastName
      ? state.auth.lastName
      : state.auth.user.lastName,
    userType: state.auth.user && state.auth.user.userType,
    email: state.auth.email ? state.auth.email : state.auth.user.email,
    dateOfBirth: state.auth.dateOfBirth
      ? state.auth.dateOfBirth
      : state.auth.user.dateOfBirth,
    phoneNumber: state.auth.user && state.auth.user.phoneNumber,
    cellPhone: state.auth.cellPhone
      ? state.auth.cellPhone
      : state.auth.user.cellPhone,
    officePhone: state.auth.officePhone
      ? state.auth.officePhone
      : state.auth.user.officePhone,
    companyName: state.auth.companyName
      ? state.auth.companyName
      : state.auth.user.companyName,
    about: state.auth.about ? state.auth.about : state.auth.user.about,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    edit: (key) =>
      dispatch(
        actionsForNavigation.openChild('Profile Editor', `/admin/user/editor`)
      ),
    updateUserData: (uid, newInfo) => dispatch(updateUserData(uid, newInfo)),
  });
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(creditScoreStyle)
)(Agencies);
