import React, { useState, useEffect, Component } from 'react';
import { db } from "firebase/client.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

class NegativeItemsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountName: '',
            accountNumber: '',
            code: '',
            transunion: '',
            experian: '',
            equifax: '',
            notes: ''
        }
    }

    // const [content, setContent] = useState('');


    
    createNegativeItem = (props) => {
        console.log('props', props)
        db.collection('customers').doc(props.customerId).collection('negativeItems').add({
            // Need to make this a docObj
            
            // accountName: this.state.accountName,
            // accountNumber: this.state.accountNumber,
            // code: this.state.code,
            // transunion: this.state.transunion,
            // experian: this.state.experian,
            // equifax: this.state.equifax,
            // notes: this.state.notes,
            // createdBy: this.props.user.firstName + " " + this.props.user.lastName,
            // createdByUID: this.props.user.uid,
            // createdAt: new Date()
        })
            .then(res => {
                console.log(res);
                // clear form
                this.setState({
                    accountName: '',
                    accountNumber: '',
                    code: '',
                    transunion: '',
                    experian: '',
                    equifax: '',
                    notes: ''
                })
            })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render() {


        return (
            <GridContainer>
                {console.log('state', this.state)}
                {console.log('props', this.props)}
                <GridItem xs={12}>
            <h4>Use this form to create a new Negative Item</h4>
                    <CustomInput
                        labelText="Account Name"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            id: "account-name",
                            name: "accountName",
                            value: this.state.accountName,
                            onChange: (e) => this.handleChange(e)
                        }}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <CustomInput
                        labelText="Account Number"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            id: "account-number",
                            name: "accountNumber",
                            value: this.state.accountNumber,
                            onChange: (e) => this.handleChange(e)
                        }}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <CustomInput
                        labelText="Code"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            id: "code",
                            name: "code",
                            value: this.state.code,
                            onChange: (e) => this.handleChange(e)
                        }}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <CustomInput
                        labelText="Transunion"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            id: "transunion",
                            name: "transunion",
                            value: this.state.transunion,
                            onChange: (e) => this.handleChange(e)
                        }}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <CustomInput
                        labelText="Experian"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            id: "experian",
                            name: "experian",
                            value: this.state.experian,
                            onChange: (e) => this.handleChange(e)
                        }}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <CustomInput
                        labelText="Equifax"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            id: "equifax",
                            name: "equifax",
                            value: this.state.equifax,
                            onChange: (e) => this.handleChange(e)
                        }}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <CustomInput
                        labelText="Notes"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            id: "notes",
                            name: "notes",
                            value: this.state.notes,
                            onChange: (e) => this.handleChange(e)
                        }}
                    />
                </GridItem>
                <GridItem>
                    <Button
                        color='primary'
                        onClick={this.createNegativeItem}
                    >
                        ADD THIS NEGATIVE ITEM
                </Button>
                </GridItem>
            </GridContainer>
        );
    }
}

export default NegativeItemsForm;