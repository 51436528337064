import React from 'react';
// material-ui components
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
// core components
import Button from 'components/CustomButtons/Button.js';
import Table from 'components/Table/Table.js';

import styles from 'assets/jss/material-dashboard-pro-react/modalStyle.js';
import Remove from '@material-ui/icons/Remove';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const CreditLinesList = (props) => {
  const [modal, setModal] = React.useState(false);
  const classes = useStyles();
  const { creditData } = props;

  const actionButtons = [
    { color: 'info', icon: LaunchIcon },
    { color: 'danger', icon: Remove },
  ].map((prop, key) => {
    return (
      <Button color={prop.color} className={classes.actionButton} key={key}>
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  const tableData = creditData.creditLines.map((line) => [
    line.reportedBy,
    line.type,
    line.amount,
    actionButtons,
  ]);

  return (
    <div>
      {/* <Button color='rose' round onClick={() => setModal(true)}>
        {creditData.creditLines}
      </Button> */}
      <Card onClick={() => setModal(true)} style={{ cursor: 'pointer' }}>
        <CardHeader color='primary' style={{ padding: '5px' }}>
          <h5
            style={{
              textAlign: 'center',
              margin: '5px',
            }}
            className={classes.cardTitle}
          >
            Credit Lines
          </h5>
        </CardHeader>
        <CardBody style={{ textAlign: 'center' }}>
          <h5
            style={{
              fontSize: '3.5rem',
            }}
          >
            {creditData.creditLines.length}
          </h5>
        </CardBody>
      </Card>

      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={modal}
        transition={Transition}
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby='modal-slide-title'
        aria-describedby='modal-slide-description'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={() => setModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Open Credit Lines</h4>
        </DialogTitle>
        <div style={{ padding: '10px' }}>
          <Table
            tableHead={['Reported By', 'Type', 'Amount', 'Action']}
            tableData={tableData}
            customCellClasses={[classes.center, classes.right, classes.right]}
            customClassesForCells={[0, 4, 5]}
            customHeadCellClasses={[
              classes.center,
              classes.right,
              classes.right,
            ]}
            customHeadClassesForCells={[0, 4, 5]}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default CreditLinesList;
