import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Redirect } from 'react-router-dom';

import PropTypes from "prop-types";

import { db } from "firebase/client.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-pro-react/components/createDocumentFormStyle";

class Form extends React.Component {
    state = {
        comment: '',
        messageSent: false,
        messageError: false
    }
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            fields: ['driverName', 'phone', 'email', 'truckNumber'],
            data: {}
        };
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
    }
    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }
    handleFieldChange = (e) => {
        this.setState({
                [e.target.id]: e.target.value,
        })
        console.log(this.state)
    }
    handleChange = (e) => {
        console.log(e.target.id)
        console.log(e.target.value)
        this.setState({
            data: {
                ...this.state.data,
                [e.target.id]: e.target.value
            }
        })
        console.log(this.state)
    }
    handleSelectChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        console.log(this.state)
    }
    handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
                imageChanged: true
            });
        };
        reader.readAsDataURL(file);
    }


    handleSubmit = (e) => {
        e.preventDefault();
        console.log(this.props)
        // update firestore document with PENDING and comment
        db.collection(this.props.title).add({
            createdDate: new Date(),
            ...this.state.data
        }).then((docRef) => {
            window.location.reload()
        }).catch((err) => {
            console.log(`${err}`)
        })
    }


    resetRequest = () => {
        // window.location.reload();
    }

    continueForm = () => {

        this.setState({
            continueForm: true,
        })

    }

    editForm = () => {

        this.setState({
            editForm: true,
        })

    }

    addNewField = (field) => {
        console.log(this.state.fields)
        var array = [field]
        var list = this.state.fields.concat(array)
        var name = this.state.data.newFieldName
        console.log(list)
        this.setState({
            fields: list,
            [name]: ''
        })
    }

    assignClient = (clientID, firstName, lastName, email) => {
        this.setState({
            clientID: clientID,
            clientFirstName: firstName,
            clientLastName: lastName,
            clientEmail: email
        })
    }


    render() {
        const { classes, modalClose, authUser, title, blogID } = this.props;
        // console.log(this.state)

        if (this.state.messageSent && (window.location.pathname == "/create-case")) {
            return <Redirect to='/legal-disclaimer' />
        }

        // reset modal when closed
        // if (this.state.messageSent) { this.resetRequest() }
        const states = ['Oregon', 'Washington', 'Colorado', 'Wyoming']
        const caseTypes = ['Criminal', 'Civil', 'Family']
        const serviceTypes = ['Document Preparation', 'Representation', 'Counsel']
        const dataTypes = ['string', 'number']

        return (
            <div>
                {this.state.messageSent ? (
                    <GridContainer justify="center">
                        <GridItem xs={11}>
                            <h4 className={classes.textPrimary}>Case Submitted!</h4>

                        </GridItem>
                    </GridContainer>
                ) : (
                        <div className={classes.formContainer}>
                            <form onSubmit={this.handleSubmit}
                            >

                                <GridContainer justify="center">
                                    <GridItem xs={11}>
                                        <h5 className={classes.bodyText} style={{ textAlign: "center", fontSize: "26px", margin: "0", fontFamily: "Raleway" }}><b>Add {(title.charAt(title.length - 1) == "s" ? title.charAt(0).toUpperCase() + title.slice(1, title.length - 1) : title.charAt(0).toUpperCase() + title.slice(1))}</b></h5>
                                        {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}

                                        <GridContainer style={{ marginTop: "20px" }}>
                                            {this.state.fields.map(field => {
                                                console.log(this.state.data)
                                                return (
                                                    <GridItem xs={12}>

                                                        <CustomInput
                                                            labelText={field}
                                                            id={field}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                required: true,
                                                                type: "text",
                                                                disableUnderline: true,
                                                                onChange: (event) => this.handleChange(event),
                                                            }}
                                                        />
                                                    </GridItem>

                                                )
                                            })}
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>

                                <Button
                                    type="submit"
                                    color="primary"
                                    style={{
                                        marginLeft: "10px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    SUBMIT
              </Button>

                                <Button
                                    onClick={() => this.editForm()}
                                    color="info"
                                    style={{
                                        marginLeft: "10px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    EDIT FORM
              </Button>

                            </form>
                            <GridContainer>
                                <GridItem xs={12}>
                                    {this.state.editForm &&
                                        this.state.fields.map(field => {
                                            return (
                                                <GridContainer>
                                                    <GridItem xs={2}>
                                                        <div
                                                            onClick={() => this.editForm(field)}

                                                        >
                                                            X
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={3}>
                                                        {field}
                                                    </GridItem>
                                                    <GridItem xs={7}>
                                                        <FormControl
                                                            fullWidth
                                                            className={classes.selectFormControl}>
                                                            <InputLabel
                                                                htmlFor="dataType"
                                                                className={classes.selectLabel}>
                                                                Data Type:
                                                            </InputLabel>
                                                            <Select
                                                                MenuProps={{
                                                                    className: classes.selectMenu
                                                                }}
                                                                classes={{
                                                                    select: classes.select
                                                                }}
                                                                required
                                                                value={this.state.dataType}
                                                                onChange={this.handleSelectChange}
                                                                inputProps={{
                                                                    id: 'dataType',
                                                                    name: 'dataType',
                                                                    focused: true
                                                                }}
                                                            >
                                                                {
                                                                    dataTypes.map((item) => (
                                                                        <MenuItem
                                                                            classes={{
                                                                                root: classes.selectMenuItem
                                                                            }}
                                                                            value={item}
                                                                            key={item}
                                                                        >{item}</MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </GridItem>
                                                </GridContainer>
                                            )
                                        })
                                    }
                                    {this.state.editForm &&
                                        <div>
                                            <CustomInput
                                                labelText="Field Name"
                                                id="newFieldName"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    required: true,
                                                    type: "text",
                                                    disableUnderline: true,
                                                    value: this.state.newFieldName,
                                                    onChange: (event) => this.handleFieldChange(event)
                                                }}
                                            />
                                            <Button
                                                onClick={() => this.addNewField(this.state.newFieldName)}
                                                color="primary"
                                                style={{
                                                    marginLeft: "10px",
                                                    marginBottom: "10px"
                                                }}
                                            >
                                                ADD NEW FIELD
                                 </Button>
                                        </div>}

                                </GridItem>
                            </GridContainer>
                        </div>

                    )}
            </div>
        );
    }
}

Form.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    //console.log(state)
    return {
        AuthBool: !!state.auth.user,
        authUser: state.auth.user
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(Form);
