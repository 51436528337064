import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const analyticsStyle = (theme) => ({
  container: {
    // ...container,
    zIndex: "4",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  subTitle: {
    fontSize: "19px",

  },
  subHeader: {
    letterSpacing: "0.17em !important",
    fontSize: "23px",
    fontWeight: 500
  },
  documentCardBody: {
    minHeight: "600px",
    overflow: "scroll",
    background: "rgba(255, 255, 255, 1)",
    marginTop: "0px",
    boxShadow: "0px 1px 6px grey"
  },
  cardHeader: {
    background: "linear-gradient(90.01deg, #274B73 0.48%, #3AC0C4 99.05%) !important",
    fontWeight: 400,
    fontSize: "30px",
    lineHeight: "32px",
    textAlign: "center",
    color: "white",
  },
  customerCardBody: {
    height: "600px",
    overflow: "scroll",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 6px grey",
  },
  individualKPIDiv: {
    display: "flex",
    flexDirection: 'column',
    width: "18%",
    alignItems: "center",
    marginBottom: "30px",
    boxShadow: "0px 1px 6px grey",
    padding: "20px",
    maxHeight: "180px"
  },
  individualMobileKPIDiv: {
    display: "flex",
    flexDirection: 'column',
    width: "100%",
    alignItems: "center",
    margin: "10px 0px",
    boxShadow: "0px 1px 6px grey",
    padding: "30px",
    maxHeight: "180px"
  },
  individualKPICircle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  individualKPIText: {
    fontWeight: 500,
    fontSize: "30px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "35px",
    },
  },
  individualKPIHeader: {
    fontWeight: 500,
    fontSize: "16px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "20px",
    },
  },
  tableHeader: {
    width: "16.6%",
  },
  tableCell: {
    width: "16.6%",
    fontSize: "18px",
    textAlign: "center"
  },
  sendBtn: {
    display: "flex",
    width: "35%",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#f5c107",
    "&:hover": {
      backgroundColor: "#f5c107",
    }
  },
});

export default analyticsStyle;
