import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Remove from '@material-ui/icons/Remove';
import LaunchIcon from '@material-ui/icons/Launch';
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';

import AppleMusicLogo from './apple-music.png';
import HBOMaxLogo from './hbomax.png';
import HuluLogo from './hulu.png';
import NetflixLogo from './netflix.png';
import TrashIcon from './trash.png';
import EditIcon from './edit.png';
import 'assets/css/material-dashboard-pro-react.css';


const useStyles = makeStyles(styles);

const SubscriptionsList = (props) => {
  const classes = useStyles();
  const { subscriptionsData } = props;

  const actionButtons = [
    { color: 'info', icon: LaunchIcon },
    { color: 'danger', icon: Remove },
  ].map((prop, key) => {
    return (
      <Button color={prop.color} className={classes.actionButton} key={key}>
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  return (
    // <Box
    //   sx={{
    //     display: 'flex',
    //     backgroundColor: 'lightGray',
    //     borderRadius: '5px',
    //     padding: '10px',
    //     margin: '10px 5px',
    //   }}
    // >
    <GridContainer
      style={{
        display: 'flex',
        backgroundColor: '#fff',
        justifyContent: 'space-between',
        width: '100%',
        // maxWidth: '1300px',
        boxShadow: '0px 2px 8px -1px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        width: "100%",
        margin: "0px auto",
        marginTop: "30px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      }}
    >
      <div style={{ width: '100%' }}>
        <CardHeader sx={{ width: '100%', height: '100%' }} color='primary'>
          <h3
            style={{
              textAlign: 'center',
              padding: '5px',
              margin: '0',
              fontFamily: "Metal Core",
              fontSize: "30px"
            }}
            className={classes.cardTitle}
          >
            Subscriptions
          </h3>
        </CardHeader>
      </div>
      <GridItem
        sx={{
          direction: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
        xs={12}
        sm={12}
        md={6}
        lg={4}
      >
        <Card
          style={{
            // height: '450px',
            margin: '25px 0px 15px 0px',
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <CardBody style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <div>
                <img
                  src={AppleMusicLogo}
                  style={{
                    width: '65px',
                    margin: '10px',
                  }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p>Apple Music</p>
                  <div>
                    <p>$9.99</p>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    style={{
                      height: '40px',
                      width: '105px',
                    }}
                  >
                    Details
                  </Button>
                  <Button
                    color='danger'
                    style={{ height: '40px', width: '105px', color: '#fff' }}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem
        sx={{
          direction: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
        xs={12}
        sm={12}
        md={6}
        lg={4}
      >
        <Card
          style={{
            // height: '450px',
            margin: '25px 0px 15px 0px',
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <CardBody style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <div>
                <img
                  src={HBOMaxLogo}
                  style={{
                    width: '65px',
                    margin: '10px',
                  }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p>HBO Max</p>
                  <div>
                    <p>$14.99</p>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    style={{
                      height: '40px',
                      width: '105px',
                    }}
                  >
                    Details
                  </Button>
                  <Button
                    color='danger'
                    style={{ height: '40px', width: '105px', color: '#fff' }}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem
        sx={{
          direction: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
        xs={12}
        sm={12}
        md={6}
        lg={4}
      >
        <Card
          style={{
            // height: '450px',
            margin: '25px 0px 15px 0px',
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <CardBody style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <div>
                <img
                  src={HuluLogo}
                  style={{
                    width: '65px',
                    margin: '10px',
                  }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p>Hulu</p>
                  <div>
                    <p>$12.99</p>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    style={{
                      height: '40px',
                      width: '105px',
                    }}
                  >
                    Details
                  </Button>
                  <Button
                    color='danger'
                    style={{ height: '40px', width: '105px', color: '#fff' }}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem
        sx={{
          direction: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
        xs={12}
        sm={12}
        md={6}
        lg={4}
      >
        <Card
          style={{
            // height: '450px',
            margin: '25px 0px 15px 0px',
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <CardBody style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <div>
                <img
                  src={NetflixLogo}
                  style={{
                    width: '65px',
                    margin: '10px',
                  }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p>Netflix</p>
                  <div>
                    <p>$16.99</p>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    style={{
                      height: '40px',
                      width: '105px',
                    }}
                  >
                    Details
                  </Button>
                  <Button
                    color='danger'
                    style={{ height: '40px', width: '105px', color: '#fff' }}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    // </Box>
  );
};

SubscriptionsList.propTypes = {
  classes: PropTypes.object.isRequired,
  subscriptionsData: PropTypes.object.isRequired,
};

export default SubscriptionsList;
