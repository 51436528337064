import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import form from './form';
import auth from './auth';
import dialog from './dialog';
import recordset from './recordset';
import system from './system';
import navigation from './navigation';
import { config as leads } from './recordsets/leads';
import { config as customers } from './recordsets/customers';
import { config as users } from './recordsets/users';
import documents from './documentsReducer'
import customersReducer from './customersReducer'
import leadsReducer from './leadsReducer'
import listsReducer from './listsReducer'
import windowReducer from './windowReducer'
import demographics from './demographicsReducers'
import customTab from './customTabReducer'

export default (history) => combineReducers({
    router: connectRouter(history),
    auth,
    dialog,
    system,
    navigation,
    form,
    documents,
    demographics,
    customTab,
    leads: leadsReducer,
    lists: listsReducer,
    customers: customersReducer,
    recordset: recordset({users, customers, leads}),
    window: windowReducer,
});