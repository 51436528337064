import React from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import VisibilityIcon from './visible.png';
import TrashIcon from './trash.png';
import './Bills.css';

const UpdatedBillsList = (props) => {
  const { billsData } = props;
  const { bills } = billsData;
  const gradients = [
    '#56A3A6,#EEBD09',
    '#274B73, #56A3A6',
    '#BC4646, #3AC0C4',
    '#56A3A6, #3AC0C4',
  ];
  console.log('bills data here:', billsData);
  return (
    <GridContainer
      xs={12}
      sm={12}
      md={12}
      style={{ margin: '0', width: '100%' }}
    >
      {bills.map((b, index) => {
        return (
          <div
            key={b.name}
            className='bill-divs'
            style={{
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius:
                index === 0
                  ? '12px 12px 0px 0px'
                  : index === bills.length - 1
                  ? '0px 0px 12px 12px'
                  : '0',
              margin: '0 3px',
              padding: '0 20px',
              display: 'flex',
              justifyContent: 'space-around',
              width: '100%',
              background: `linear-gradient(white, white) padding-box,
                  linear-gradient(to right, ${gradients[index]}) border-box`,
            }}
          >
            <div style={{ width: '40%' }}>
              <p className='bill-name'>{b.name}</p>
            </div>
            <div style={{ width: '20%' }}>
              <p className='bill-cost'>${b.amount}</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '40%',
                margin: '1%',
              }}
            >
              <Button
                style={{
                  background: '#EEBD09',
                  color: 'white',
                  margin: '1%',
                  height: '42px',
                  width: '42px',
                  padding: '0',
                }}
              >
                <img src={VisibilityIcon} style={{ width: '26px' }} />
              </Button>
              <Button
                style={{
                  background: '#BC4646',
                  color: 'white',
                  margin: '1%',
                  height: '42px',
                  width: '42px',
                  padding: '0',
                }}
              >
                <img
                  src={TrashIcon}
                  style={{ width: '16px', filter: 'invert(100%)' }}
                />
              </Button>
            </div>
          </div>
        );
      })}
    </GridContainer>
  );
};

export default UpdatedBillsList;
