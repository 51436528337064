import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db, firebase } from "firebase/client.js";
import { DateTime } from 'luxon';

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Fab from '@material-ui/core/Fab';

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import AddIcon from '@material-ui/icons/Add';

// core components
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.jsx";
// import Button from "components/CustomButtons/Button.jsx";
// import ProfilePicUpload from "components/CustomUpload/profilePicUpload";
// import CardBody from "components/Card/CardBody.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import Table from "components/Table/Table.jsx";
// import Switch from "@material-ui/core/Switch";
// import FormControlLabel from "@material-ui/core/FormControlLabel";

import styles from "assets/jss/material-dashboard-pro-react/components/explorerStyle";
import { Grid } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";


// // Firebase Redux Integration
// import {
//   tableToFirestoreObjects,
//   updateFirestoreWithOneObject,
//   updateFirestoreWithTableData,
//   addFieldToDocument,
// } from "store/actions/firestoreActions";
// import {
//   startSetActionsList,
//   startToggleCompleted,
//   setUserScore,
// } from "store/actions/actionsActions";
// import { startSetUsersList } from "store/actions/adminActions";
// import { startSetAllOrdersList } from "store/actions/ordersActions";
// import AllOrders from "./AllOrders";
// import CustomerNotesChild from "./CustomerNotesChild";

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

class CustomerNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      updateProfileModal: false,
      updateDispatchModal: false,
      updatePhotoModal: false,
      learnDashboardModal: false,
    };
  }

  handleClickOpen = (modal, ID, shipperID, status) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      ID,
      shipperID,
      status,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
    window.location.reload();
  };

  // loadNotes = (customerId) => {
  //   console.log("loading groups");
  //   db.collection("customers")
  //     .doc(customerId)
  //     .collection("notes")
  //     .get()
  //     .then(
  //       (snapshot) => {
  //         const newData = [];
  //         // Parse data into array like in firebase.js
  //         snapshot.forEach((doc) => {
  //           var docData = doc.data();
  //           // console.log(docData.pickUpDate)
  //           var docObj = {
  //             id: doc.id,
  //             ...docData,
  //           };
  //           newData.push(docObj);
  //         });
  //         return newData;
  //       },
  //       (error) => {
  //         console.log("error fetching data: ", error);
  //       }
  //     )
  //     .then((newData) => {
  //       this.setState({
  //         notesData: newData,
  //         notesDataLoaded: true,
  //       });
  //     });
  // };

  loadLeads = (customerId) => {
    console.log("loading groups");
    db.collection("leads")
      .onSnapshot(querySnapshot => {
        const snapshot = querySnapshot.docs;
        let leads = [];

        for (let i = 0; i < snapshot.length; i++) {
          const docData = {
              ...snapshot[i].data(),
              id: snapshot[i].id
          }
          leads.push(docData);
      }
      leads = leads.filter((lead) => {
        if (lead.loanOfficer) {
          if (lead.loanOfficer.uid === this.props.authUser.uid) {
            return true;
          } else {
            return lead.loanOfficer.includes(this.props.authUser.firstName);
          }
        }
      })
      this.setState({
        leadsData: leads,
        pendingLeads: leads.filter(lead => lead.status == "Pending"),
        engagedLeads: leads.filter(lead => lead.status == "Engaged"),
        contractLeads: leads.filter(lead => lead.status == "Contract Needed"),
        currentLeads: leads.filter(lead => lead.status == "Current"),
        declinedLeads: leads.filter(lead => lead.status == "Declined"),
        lapsedLeads: leads.filter(lead => lead.status == "Lapsed"),
        completedLeads: leads.filter(lead => lead.status == "Completed"),
        leadsDataLoaded: true,
      });
      })
  };


  render() {
    const {
      AuthBool,
      classes,
      authUser,
      user,
      orders,
      assessments,
    } = this.props;

    if (!this.state.leadsDataLoaded) {
      this.loadLeads();
    }

    // console.log(this.state);
    return this.state.leadsDataLoaded ? (
      <GridContainer>
          <GridItem xs={12}>
          Pending
        {this.state.pendingLeads.map((lead) => (
            <Card >

            <div className={classes.whiteCard}>
            <GridContainer>
            <GridItem xs={1}>
            </GridItem>
            <GridItem xs={10}>
              <br/>
            <p style={{fontWeight: "300", fontSize: "20px"}}><b>{lead.firstName + " " + lead.lastName}</b></p>
            <p style={{fontWeight: "300", color: "#00679b"}}><b>Join Date: {lead.joinDate && lead.joinDate.toDate().toDateString()}</b></p>
            <p style={{fontWeight: "300"}}>Description: {lead.description}</p>
            <p>Goal Score: {lead.goalScore} </p> <p>Current Score: {lead.currentScore} </p>
            <p>EPD: {lead.epd && lead.epd.toDate().toString()} </p>
            </GridItem>
            </GridContainer>
              </div>

            </Card>
        ))}
                  </GridItem>
                  <GridItem xs={12}>
          Engaged
        {this.state.engagedLeads.map((lead) => (
            <Card >

            <div className={classes.whiteCard}>
            <GridContainer>
            <GridItem xs={1}>
            </GridItem>
            <GridItem xs={10}>
              <br/>
            <p style={{fontWeight: "300", fontSize: "20px"}}><b>{lead.firstName + " " + lead.lastName}</b></p>
            <p style={{fontWeight: "300", color: "#00679b"}}><b>Join Date: {lead.joinDate && lead.joinDate.toDate().toDateString()}</b></p>
            <p style={{fontWeight: "300"}}>Description: {lead.description}</p>
            <p>Goal Score: {lead.goalScore} </p> <p>Current Score: {lead.currentScore} </p>
            <p>EPD: {lead.epd && lead.epd.toDate().toString()} </p>
            </GridItem>
            </GridContainer>
              </div>

            </Card>
        ))}
                  </GridItem>
                  <GridItem xs={12}>
          Contract Needed
        {this.state.contractLeads.map((lead) => (
            <Card >

            <div className={classes.whiteCard}>
            <GridContainer>
            <GridItem xs={1}>
            </GridItem>
            <GridItem xs={10}>
              <br/>
            <p style={{fontWeight: "300", fontSize: "20px"}}><b>{lead.firstName + " " + lead.lastName}</b></p>
            <p style={{fontWeight: "300", color: "#00679b"}}><b>Join Date: {lead.joinDate && lead.joinDate.toDate().toDateString()}</b></p>
            <p style={{fontWeight: "300"}}>Description: {lead.description}</p>
            <p>Goal Score: {lead.goalScore} </p> <p>Current Score: {lead.currentScore} </p>
            <p>EPD: {lead.epd && lead.epd.toDate().toString()} </p>
            </GridItem>
            </GridContainer>
              </div>

            </Card>
        ))}
                  </GridItem>
                  <GridItem xs={12}>
          Current
        {this.state.currentLeads.map((lead) => (
            <Card >

            <div className={classes.whiteCard}>
            <GridContainer>
            <GridItem xs={1}>
            </GridItem>
            <GridItem xs={10}>
              <br/>
            <p style={{fontWeight: "300", fontSize: "20px"}}><b>{lead.firstName + " " + lead.lastName}</b></p>
            <p style={{fontWeight: "300", color: "#00679b"}}><b>Join Date: {lead.joinDate && lead.joinDate.toDate().toDateString()}</b></p>
            <p style={{fontWeight: "300"}}>Description: {lead.description}</p>
            <p>Goal Score: {lead.goalScore} </p> <p>Current Score: {lead.currentScore} </p>
            <p>EPD: {lead.epd && lead.epd.toDate().toString()} </p>
            </GridItem>
            </GridContainer>
              </div>

            </Card>
        ))}
                  </GridItem>
                  <GridItem xs={12}>
          Declined
        {this.state.declinedLeads.map((lead) => (
            <Card >

            <div className={classes.whiteCard}>
            <GridContainer>
            <GridItem xs={1}>
            </GridItem>
            <GridItem xs={10}>
              <br/>
            <p style={{fontWeight: "300", fontSize: "20px"}}><b>{lead.firstName + " " + lead.lastName}</b></p>
            <p style={{fontWeight: "300", color: "#00679b"}}><b>Join Date: {lead.joinDate && lead.joinDate.toDate().toDateString()}</b></p>
            <p style={{fontWeight: "300"}}>Description: {lead.description}</p>
            <p>Goal Score: {lead.goalScore} </p> <p>Current Score: {lead.currentScore} </p>
            <p>EPD: {lead.epd && lead.epd.toDate().toString()} </p>
            </GridItem>
            </GridContainer>
              </div>

            </Card>
        ))}
                  </GridItem>
                  <GridItem xs={12}>
         Lapsed
        {this.state.lapsedLeads.map((lead) => (
            <Card >

            <div className={classes.whiteCard}>
            <GridContainer>
            <GridItem xs={1}>
            </GridItem>
            <GridItem xs={10}>
              <br/>
            <p style={{fontWeight: "300", fontSize: "20px"}}><b>{lead.firstName + " " + lead.lastName}</b></p>
            <p style={{fontWeight: "300", color: "#00679b"}}><b>Join Date: {lead.joinDate && lead.joinDate.toDate().toDateString()}</b></p>
            <p style={{fontWeight: "300"}}>Description: {lead.description}</p>
            <p>Goal Score: {lead.goalScore} </p> <p>Current Score: {lead.currentScore} </p>
            <p>EPD: {lead.epd && lead.epd.toDate().toString()} </p>
            </GridItem>
            </GridContainer>
              </div>

            </Card>
        ))}
                  </GridItem>
                  <GridItem xs={12}>
          Completed
        {this.state.completedLeads.map((lead) => (
            <Card >

            <div className={classes.whiteCard}>
            <GridContainer>
            <GridItem xs={1}>
            </GridItem>
            <GridItem xs={10}>
              <br/>
            <p style={{fontWeight: "300", fontSize: "20px"}}><b>{lead.firstName + " " + lead.lastName}</b></p>
            <p style={{fontWeight: "300", color: "#00679b"}}><b>Join Date: {lead.joinDate && lead.joinDate.toDate().toDateString()}</b></p>
            <p style={{fontWeight: "300"}}>Description: {lead.description}</p>
            <p>Goal Score: {lead.goalScore} </p> <p>Current Score: {lead.currentScore} </p>
            <p>EPD: {lead.epd && lead.epd.toDate().toString()} </p>
            </GridItem>
            </GridContainer>
              </div>

            </Card>
        ))}
                  </GridItem>

                  <Link to="/admin/leads/new"><Fab className={classes.newRecord} onClick={console.log("hi")}><AddIcon /></Fab></Link>

      </GridContainer>
    ) : (
      <p className={classes.body}>Loading...</p>
    );

  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    // AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    // user: state.profile.user,
    // assessments: state.assessment,
    // orders: state.orders.allOrders,
  };
};

const mapDispatchToProps = (dispatch) => {
  //   console.log(this.props)
  return {
    // startSetAllOrdersList: dispatch(startSetAllOrdersList(groups))
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserScore: (score) => dispatch(setUserScore(score))
//   }
// }

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CustomerNotes);
