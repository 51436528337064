import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
  dangerColor
} from "assets/jss/material-dashboard-pro-react.js";

const explorerPageStyle = (theme) => ({
  container: {
    ...container,
    zIndex: "4",
    color: "#000",
    padding: "10px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  cardTitle: {
    ...cardTitle,
    textAlign: "center",
    fontSize: '1.8rem',
    color: '#18528c',
    fontWeight: 400
  },
  uploadText: {
    color: "#EEBD09",
    fontSize: "20px",
    fontWeight: 400,
    marginTop: "30px"
  },
  advanceStatus: {
    "&:hover": {
      backgroundColor: "#219ebc",
    },
    backgroundColor: "#023047",
  },
  mainExplorerCard: {
    padding: "30px",
    boxShadow: "0px 1px 6px grey",
    [theme.breakpoints.down('md')]: {
      padding: "20px",
    }
  },
  remind: {
    "&:hover": {
      backgroundColor: "#ffb703",
    },
    backgroundColor: "#fb8500",
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "20px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  modalBody: {
    display: 'flex'
  },
  largeImg: {
    height: "32vh",
    margin: "20px 0 0 0",
    display: "block",
    marginRight: "auto",
    marginLeft: "auto"
  },
  statusAnalytics: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    textAlign: "center"
  },
  attentionFlag: {
    color: dangerColor[0] + "!important",
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px 30px 0 0"
  },
  loanOffCard: {
    padding: "10px"
  },
  dateInfo: {
    color: "#05669b"
  },
  leadsCard: {
    boxShadow: "0px 1px 6px grey",
    padding: "30px",
    [theme.breakpoints.up('md')]: {
      padding: "30px",
    }
  },
  leadsContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up('md')]: {
      display: "flex",
      flexDirection: "row",
      marginBottom: "0px",
      padding: "20px",
      justifyContent: "space-around"
    }
  },
  leadsRow: {
    [theme.breakpoints.down('md')]: {
      fontSize: "15px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize: "19px !important"
    },
    [theme.breakpoints.up('md')]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      fontSize: "25px",
      width: "25%"
    }
  },
  avatar: {
    width: "100px",
  },
  updateProfileFormHeader: {
    textAlign: "center",
    fontWeight: 500,
    background: "#274B73",
    margin: "0px",
    padding: "20px",
    color: "white",
    fontSize: "23px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "14px",
    }
  },
  updateProfileForm: {
    display: "flex",
    flexDirection: "column",
    height: "205px",
    justifyContent: "space-between",
    paddingRight: "50px",
    [theme.breakpoints.down('sm')]: {
      height: "300px",
      paddingRight: "0px",
    }
  },
  modalCloseButton: {
    "&, &:hover": {
      color: "white"
    },
    "&:hover": {
      opacity: "1"
    },
    cursor: "pointer",
    padding: "1rem",
    margin: "-1rem -1rem -1rem auto",
    backgroundColor: "transparent",
    border: "0",
    WebkitAppearance: "none",
    float: "right",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 #ffffff",
    opacity: ".5"
  },
  subTitle: {
    fontSize: "19px",
  },
  cardBody: {
    marginLeft: "13px",
    minHeight: "600px",
    overflow: "scroll",
    background: "rgba(255, 255, 255, 1)",
  },
  cardHeader: {
    background: "linear-gradient(108.56deg, #274B73 39.14%, #005479 64.5%)",
    fontWeight: 400,
    fontSize: "25px",
    lineHeight: "32px",
    textAlign: "center",
    color: "white",
    padding: "0px"
  },
  tableHeader: {
    width: "20%"
  },
  tableCell: {
    maxwidth: "15%",
    fontSize: "18px",
    textAlign: "center",
    padding:"10px"
  },
  customerCardBody: {
    height: "600px",
    overflowY: "scroll",
    overflowX: "hidden !important",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 6px grey",
  },
  newRecord: {
    "&:hover": {
      background: "linear-gradient(180deg, #5EB1E3 26.3%, #274B73 210.42%)",
    },
    background: "linear-gradient(180deg, #5EB1E3 26.3%, #274B73 210.42%)",
    borderRadius: "50%",
    color: "#fff",
    position: "fixed",
    bottom: 20,
    right: 40,
    zIndex: 3,
    height: "86px",
    fontSize: "50px",
    width: "86px",
    [theme.breakpoints.down("sm")]: {
      height: "85px",
      width: "85px",
      bottom: 80,
      right: 17,
    },
  },
  viewDetailsBtn: {
    width: "33%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    padding: "15px",
    justifyContent: "space-between"
  },
  customerBtns: {
    width: "50%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    padding: "15px",
    justifyContent: "space-between"
  },
  cancel: {
    backgroundColor: '#f5c107',
    width: "40%"
  },
  submit: {
    backgroundColor: 'rgb(94, 177, 227)', color: "white",
    width: "40%"
  },
  newButton :{
    height:"50px",
    width: "150px",
    background: "#1D9BC9",
    color:"white",
    borderRadius:"5px",
    "&:hover": {
      backgroundColor: "#1D9BC9",
      color: "white",
      // boxShadow: "2px 2px 0px 0px #e3470a"
    }, 
  }
});

export default explorerPageStyle;
