/*eslint-disable*/
import React, { useEffect, createRef, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase, storage } from 'firebase/client';


// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Slide from '@material-ui/core/Slide';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Hidden from "@material-ui/core/Hidden";
import Button from "components/CustomButtons/Button.js";

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import Phone from '@material-ui/icons/Phone';
import Timeline from '@material-ui/icons/Timeline';
import wizard from "assets/img/mortgage-ready/wizard-3.png";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import BadgeIcon from '@material-ui/core/Badge';
import PersonIcon from '@material-ui/icons/Person';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import loader from 'assets/img/loader.gif';
import LinearProgress from "@material-ui/core/LinearProgress";
import RegistrationActions from 'store/actions/forms/registration';
import { actionsForDialog } from 'store/actions/dialog';
import RegistrationAccessors from 'store/reducers/forms/registration';
import Input from "@material-ui/core/Input";
import classNames from "classnames";


import BoundInput from 'components/CustomInput/BoundInput';
import CustomSelect from 'components/CustomInput/CustomSelect';
import TermsAndConditionsDialog from 'views/Components/TermsAndConditionsDialog';

import Style from 'assets/jss/kordspace-template/views/pages/registration/stepStyle';
import avatar from 'assets/img/default-avatar.png';
import ProfileIcon from 'assets/img/market-me/profileIcon.png'

import { userTypes } from "variables/config";
import { updateuserType } from 'store/actions/auth.js'
import { makeStyles } from "@material-ui/core/styles";


import { config } from 'store/reducers/forms/registration';
import registrationActions from 'store/actions/forms/registration'
import { setUpdateRegistrationProfilePic } from 'store/actions/form'


const BasicDetailsCustomInput = BoundInput(RegistrationActions.basicDetails, RegistrationAccessors.basicDetails);
const TermsAndConditionsActions = actionsForDialog("termsAndConditions");

const CommonProfileCustomInput = BoundInput(RegistrationActions.commonProfile, RegistrationAccessors.commonProfile);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;


});


const useStyles = makeStyles({
    formControl: {
        "label + &": {
            marginTop: 0
        }
    }
});

const InputNoMargin = props => {
    const inputClasses = useStyles(props);
    return <Input {...props} classes={inputClasses} />;
};


function BasicDetails(props) {

    const { classes, error, openTermsAndConditions, history } = props;
    var prepopulatedEmail
    const profilePicRef = createRef(null)
    const inputClasses = useStyles(props)

    const [previewUrl, setPreviewUrl] = useState('')

    useEffect(() => {
        var splitParams = window.location.pathname.split('/')
        console.log('email, inviteID, invitedByUID', splitParams)
        if (splitParams[3]) {
            window.localStorage.setItem('emailForSignIn', splitParams[3])
            window.localStorage.setItem('inviteID', splitParams[4])
            window.localStorage.setItem('invitedByUID', splitParams[5])
            prepopulatedEmail = window.localStorage.getItem('emailForSignIn')
        }
    }, [])


    const handleImageUpload = (event, str) => {
        var previewUrl = URL.createObjectURL(event.target.files[0])
        var file = event.target.files[0]
        setPreviewUrl(previewUrl)
        props.setUpdateRegistrationProfilePic(file)
    }

    return (
        <GridContainer style={{ display: "flex", justifyContent: "center", height: "100%" }} className={classes.container}>
            <GridItem style={{ height: "100%" }} xs={12} sm={8} className={classes.inputContainer}>
                <Card className={classes.signupCard}>
                    <CardHeader>
                        <h2
                            className={classes.cardTitle}>
                            About You
                        </h2>
                    </CardHeader>
                    <CardBody className={classes.cardBody}>

                            <Hidden mdUp implementation="css">
                                <GridContainer className={classes.detailsGridContainer} style={{ height: "100%" }}>
                                    <GridItem xs={12} className={classes.noSpace}>
                                    <div className={classes.uploadPictureDiv}>
                                            <div onClick={() => profilePicRef.current.click()} style={{ backgroundColor: "#F4F6F8", borderRadius: "100%" }}>
                                                {previewUrl ? (
                                                    <img style={{ width: "150px", borderRadius: "100%" }} src={previewUrl} />
                                                ) : (
                                                    <PersonIcon style={{ fontSize: "150px", color: " #274B73" }} />
                                                )}
                                                <input onChange={(event) => {
                                                    handleImageUpload(event)
                                                }} multiple="multiple" type='file' id='basicNFTArt' ref={profilePicRef} style={{ display: 'none' }} />
                                            </div>
                                            <p className={classes.uploadText} >
                                                Upload Profile Picture
                                            </p>
                                        </div>
                                    </GridItem>
                                    <GridItem style={{ display: "flex", flexDirection: "column" }} xs={12} sm={9}>
                                        {error &&
                                            <InfoArea icon={Timeline} title='Error' description={error.message} />
                                        }
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <CommonProfileCustomInput
                                                labelText='First Name'
                                                name='firstName'

                                                inputProps={{
                                                    type: 'firstName',
                                                    disableUnderline: true,
                                                    className: classes.inputField,
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <PersonIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <CommonProfileCustomInput
                                                labelText='Last Name'
                                                name='lastName'

                                                inputProps={{
                                                    type: 'lastName',
                                                    disableUnderline: true,
                                                    className: classes.inputField,
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <PersonIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>


                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <CommonProfileCustomInput
                                                labelText='Company Name'
                                                name='companyName'
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'companyName',
                                                    disableUnderline: true,
                                                    className: classes.inputField,
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <BusinessCenterIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {window.location.pathname.split('/')[3] ? (
                                                <BasicDetailsCustomInput

                                                    prepopulatedEmailValue={window.location.pathname.split('/')[3]}
                                                    labelText='Email'
                                                    name='email'
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                        value: window.location.pathname.split('/')[3],
                                                        type: 'email',
                                                        disableUnderline: true,
                                                        className: classes.inputField,
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                <Email />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            ) : (
                                                <BasicDetailsCustomInput
                                                    prepopulatedEmail
                                                    labelText='Email'
                                                    name='email'
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                        type: 'email',
                                                        disableUnderline: true,
                                                        className: classes.inputField,
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                <Email />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        </div>


                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <BasicDetailsCustomInput
                                                labelText='Password'
                                                name='password'
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'password',
                                                    disableUnderline: true,
                                                    className: classes.inputField,
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <Icon>lock_outline</Icon>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <BasicDetailsCustomInput
                                                labelText='Confirm Password'
                                                name='confirmPassword'
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'password',
                                                    disableUnderline: true,
                                                    className: classes.inputField,
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <Icon>lock_outline</Icon>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>


                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                                            <CommonProfileCustomInput
                                                labelText='Cell Phone'
                                                name='cellPhone'
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'cellPhone',
                                                    disableUnderline: true,
                                                    className: classes.inputField,
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <Phone />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <CommonProfileCustomInput
                                                labelText='Office Phone'
                                                name='officePhone'
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'officePhone',
                                                    disableUnderline: true,
                                                    className: classes.inputField,
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <Phone />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>

                                        <div style={{ height: "8%" }}>
                                            <InputLabel
                                                style={{ marginBottom: "10px" }}
                                                htmlFor="About"
                                                className={classes.aboutLabel}>
                                                User Description
                                            </InputLabel>
                                            <TextField
                                                id='About'
                                                placeholder='Tell Us A Little Bit About You...'
                                                style={{ width: "98%", backgroundColor: "white" }}
                                                multiline
                                                rows={2}
                                                maxRows={4}
                                            />
                                        </div>


                                        <div>
                                            <h5 className={classes.disclaimerText}>
                                                This information is only used to improve your Market Me experience, we won't share or communicate your data to any third party.
                                            </h5>
                                        </div>
                                    </GridItem>
                                    <GridItem className={classes.gridItem} xs={12} sm={3}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <Button onClick={() => history.push('/auth/login-page')} className={classes.backBtn}>
                                                Back
                                            </Button>
                                            <Button onClick={() => props.submit()} className={classes.submitBtn}>
                                                Submit
                                            </Button>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </Hidden>




                            <Hidden smDown implementation="css">
                            <GridContainer className={classes.detailsGridContainer} style={{ height: "100%" }}>
                                <GridItem className={classes.gridItem} xs={12} sm={3}>
                                    <div className={classes.uploadPictureDiv}>
                                        <div onClick={() => profilePicRef.current.click()} style={{ backgroundColor: "#F4F6F8", borderRadius: "100%" }}>
                                            {previewUrl ? (
                                                <img style={{ width: "150px", borderRadius: "100%" }} src={previewUrl} />
                                            ) : (
                                                <PersonIcon style={{ fontSize: "150px", color: " #274B73" }} />
                                            )}
                                            <input onChange={(event) => {
                                                handleImageUpload(event)
                                            }} multiple="multiple" type='file' id='basicNFTArt' ref={profilePicRef} style={{ display: 'none' }} />
                                        </div>
                                        <p className={classes.uploadText} >
                                            Upload Profile Picture
                                        </p>
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Button onClick={() => history.push('/auth/login-page')} className={classes.backBtn}>
                                            Back
                                        </Button>
                                        <Button onClick={() => props.submit()} className={classes.submitBtn}>
                                            Submit
                                        </Button>
                                    </div>
                                </GridItem>
                                <GridItem style={{ display: "flex", flexDirection: "column" }} xs={12} sm={9}>
                                    {error &&
                                        <InfoArea icon={Timeline} title='Error' description={error.message} />
                                    }
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <CommonProfileCustomInput
                                            labelText='First Name'
                                            name='firstName'

                                            inputProps={{
                                                type: 'firstName',
                                                disableUnderline: true,
                                                className: classes.inputField,
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <BadgeIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <CommonProfileCustomInput
                                            labelText='Last Name'
                                            name='lastName'

                                            inputProps={{
                                                type: 'lastName',
                                                disableUnderline: true,
                                                className: classes.inputField,
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <BadgeIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>


                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <CommonProfileCustomInput
                                            labelText='Company Name'
                                            name='companyName'
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                type: 'companyName',
                                                disableUnderline: true,
                                                className: classes.inputField,
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <BusinessCenterIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {window.location.pathname.split('/')[3] ? (
                                            <BasicDetailsCustomInput

                                                prepopulatedEmailValue={window.location.pathname.split('/')[3]}
                                                labelText='Email'
                                                name='email'
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: window.location.pathname.split('/')[3],
                                                    type: 'email',
                                                    disableUnderline: true,
                                                    className: classes.inputField,
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <Email />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        ) : (
                                            <BasicDetailsCustomInput
                                                prepopulatedEmail
                                                labelText='Email'
                                                name='email'
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'email',
                                                    disableUnderline: true,
                                                    className: classes.inputField,
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <Email />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    </div>


                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <BasicDetailsCustomInput
                                            labelText='Password'
                                            name='password'
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                type: 'password',
                                                disableUnderline: true,
                                                className: classes.inputField,
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <Icon>lock_outline</Icon>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <BasicDetailsCustomInput
                                            labelText='Confirm Password'
                                            name='confirmPassword'
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                type: 'password',
                                                disableUnderline: true,
                                                className: classes.inputField,
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <Icon>lock_outline</Icon>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>


                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                                        <CommonProfileCustomInput
                                            labelText='Cell Phone'
                                            name='cellPhone'
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                type: 'cellPhone',
                                                disableUnderline: true,
                                                className: classes.inputField,
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <Phone />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <CommonProfileCustomInput
                                            labelText='Office Phone'
                                            name='officePhone'
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                type: 'officePhone',
                                                disableUnderline: true,
                                                className: classes.inputField,
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <Phone />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>

                                    <div style={{ height: "8%" }}>
                                        <InputLabel
                                            style={{ marginBottom: "10px" }}
                                            htmlFor="About"
                                            className={classes.aboutLabel}>
                                            User Description
                                        </InputLabel>
                                        <TextField
                                            id='About'
                                            placeholder='Tell Us A Little Bit About You...'
                                            style={{ width: "98%", backgroundColor: "white" }}
                                            multiline
                                            rows={2}
                                            maxRows={4}
                                        />
                                    </div>


                                    <div>
                                        <h5 style={{ margin: "50px 0px 0px 0px", color: "white", fontSize: "22px" }}>
                                            This information is only used to improve your Market Me experience, we won't share or communicate your data to any third party.
                                        </h5>
                                    </div>
                                </GridItem>
                            </GridContainer>


                            </Hidden>

                    </CardBody>
                </Card>



                {/* <Card className={classes.inputCard}>
                    <CustomInput
                        labelText='Job Title'
                        name='userType'
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Group />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Card> */}
                {/* <Card className={classes.inputCard}>
                    <CustomInput
                        labelText={
                            <span className={classes.bodyText}>
                                I agree to the{' '}
                                <span
                                    onClick={() => openTermsAndConditions()}
                                    className={classes.linkText}
                                >
                                    terms and conditions
                                </span>
                                .
                            </span>}
                        name='acceptedTerms'
                        formControlProps={{
                            fullWidth: true,
                        }}
                    />
                </Card>
                <TermsAndConditionsDialog TransitionComponent={Transition} /> */}
            </GridItem>
        </GridContainer>
    );
}

const mapDispatchToProps = (dispatch) => ({

    openTermsAndConditions: () => dispatch(TermsAndConditionsActions.openDialog()),
    updateuserType: (userType) => dispatch(updateuserType(userType)),
    submit: () => dispatch(registrationActions.submit()),
    setUpdateRegistrationProfilePic: (file) => dispatch(setUpdateRegistrationProfilePic(file))
});

const mapStateToProps = (state, ownProps) => {
    console.log('registrationActions', registrationActions)
    return {
        error: RegistrationAccessors.basicDetails.getError(state)
    };
};

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(BasicDetails));