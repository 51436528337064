/*eslint-disable*/
import React from 'react'
import { db, firebase, storage, sendCustomerNotificationEmail } from 'firebase/client';
import {setTabName} from 'store/actions/customTabsActions'
import {getCustomers} from './customerActions'


export const createLeadsFromCSV = (uid, array) => {
  return (dispatch) => {
    var leadsBatch = db.batch()
    array.forEach((newLead, i) => {
      var docId = db
      .collection('demographics')
      .doc().id
      newLead.id = docId
      var docRef = db
        .collection('demographics')
        .doc(docId)
        leadsBatch.set(docRef, newLead);
    })
    leadsBatch.commit()
    .then(() => {
      dispatch(getLeads(uid))
    })
  }
}



export const convertLeadToCustomer = (leadData, uid, user) => {
  var updatedLead = { ...leadData }
  updatedLead.inviteID = leadData.id
  updatedLead.accountCreation = 'Pending'
  delete updatedLead.id
  updatedLead.documents = []
  updatedLead.readyToSend = false
  return (dispatch) => {
    return db.collection('users').doc(uid).collection('leads').doc(leadData.id).delete().then(() => {
      return db.collection('users').doc(uid).collection('customers').doc(leadData.id).set(updatedLead, { merge: true }).then(() => {
        updatedLead.uid = uid
        updatedLead.sentBy = user.firstName + ' ' + user.lastName
        // sendCustomerNotificationEmail(updatedLead)
        dispatch(setTabName('Leads'))
        dispatch(getLeads(uid))
        dispatch(getCustomers(uid))
      })
    })
  }
}


export const deleteLead = (uid, leadId) => {
  return (dispatch) => {
    return db.collection('users').doc(uid).collection('leads').doc(leadId).delete()
      .then(() => {
        dispatch(getLeads(uid))
      })
  }
}

export const getLeads = (uid) => {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(uid)
      .collection('leads').get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch(setLeads([], []))
        } else {
          snapshot = snapshot.docs
          var toReturn = []
          var ids = []
          snapshot.forEach((doc) => {
            var docId = doc.id
            ids.push(docId)
            doc = doc.data()
            doc.id = docId
            toReturn.push(doc)
          })
          dispatch(setLeads(toReturn, ids))
        }
      })
  }
}

export const addLead = (uid, newLead, allLeads) => {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(uid)
      .collection('leads').doc(newLead.id).set(newLead)
      .then(() => {
        dispatch(getLeads(uid))
      })
  }
}

export const setLeads = (data, ids) => {
  return {
    type: "SET_LEADS",
    data,
    ids
  }
}