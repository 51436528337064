import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Style from "assets/jss/material-dashboard-pro-react/components/formStyle"
import { OpenMode } from "variables/constants";

/**
 *
 * A dialog box which contains all the fields specified in a config parameter.
 *
 */
export function EditorForm(props) {
    // console.log(props)
    const { classes, order, children, Form, FormFields, mode, userInfo } = props;
    console.log('childreeeeen', children, userInfo)
    return (
        <Form readOnly={mode === OpenMode.UPDATE} classes={classes}>
            <FormFields order={order}>
                { children }
            </FormFields>
        </Form>
    );
}

export default withStyles(Style)(EditorForm);