/*eslint-disable*/
const leads = []
const initDataRetrieved = false
const leadsIds = []

export const initState = {
  leads,
  initDataRetrieved,
  leadsIds
}


export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_LEADS':
      var toReturn = {...state}
      toReturn.leads = action.data
      toReturn.initDataRetrieved = true
      toReturn.leadsIds = [...action.ids]
      return toReturn

    default:
      return state
  }
}
