/*eslint-disable*/
const tabName = 'Leads'

export const initState = {
  tabName
}


export default (state = initState, action) => {
  switch (action.type) {
    case "SET_TAB_NAME":
      var toReturn = {...state}
      toReturn.tabName = action.tabName
      return toReturn

    default:
      return state
  }
}
