/*eslint-disable*/
import React from 'react'
import { db, firebase, storage, sendCustomerNotificationEmail } from 'firebase/client';
import {setTabName} from 'store/actions/customTabsActions'
import {getLeads} from './leadsActions'


export const convertCustomerToLead = (uid, customer) => {
  return (dispatch) => {
    return db.collection('users').doc(uid).collection('customers').doc(customer.id).delete().then(() => {
      return db.collection('users').doc(uid).collection('leads').doc(customer.id).set(customer).then(() => {
        dispatch(setTabName('Leads'))
        dispatch(getLeads(uid))
        dispatch(getCustomers(uid))
      })
    })
  }
}


export const sendDocumentsToCustomer = (customerUID, documents, userUID, customerId) => {
  console.log('customerUID, document', customerUID, documents)
  var documentBatch = db.batch()
  return (dispatch) => {
    documents.forEach((obj) => {
      var docRef = db
        .collection('users')
        .doc(customerUID)
        .collection('documents').doc(obj.documentId)
      documentBatch.set(docRef, obj);
    })
    documentBatch.commit().then(() => {
      return db
        .collection('users')
        .doc(customerUID).update({
          documents: documents
        }).then(() => {
          return db.collection('users').doc(userUID).collection('customers').doc(customerId).update({
            lastDateSent: firebase.firestore.Timestamp.now(),
            readyToSend: false
          }).then(() => {

          })
        })
    })
  }
}


export const addDocumentsToCustomerCollectionForDF = (customerUID, downloadUrlsAndNames, userId, customerId, selectedCustomer) => {
  return (dispatch) => {
    if (!selectedCustomer.documents) {
      selectedCustomer.documents = []
    }
    downloadUrlsAndNames.forEach((obj) => {
      var docRef = db
        .collection('users')
        .doc(customerUID)
        .collection('documents').doc()
      var doc = {
        documentId: docRef.id,
        fileUrl: obj.url,
        dateAndTimeCreated: firebase.firestore.Timestamp.now(),
        fileName: obj.name
      }
      selectedCustomer.documents.push(doc)
    })
    return db.collection('users').doc(userId).collection('customers').doc(customerId).update({
      // lastDateSent: firebase.firestore.Timestamp.now(),
      documents: [...selectedCustomer.documents]
    }).then(() => {
      console.log('all done :)')
    })
  }
}



export const deleteCustomer = (uid, id) => {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(uid)
      .collection('customers').doc(id).delete()
      .then(() => {

      })
  }
}




export const getCustomers = (uid) => {
  console.log('getting')
  return (dispatch) => {
    return db
      .collection('users')
      .doc(uid)
      .collection('customers').onSnapshot((snapshot) => {
        if (snapshot.empty) {
          dispatch(setCustomers([]))
        } else {
          snapshot = snapshot.docs
          var toReturn = []
          snapshot.forEach((doc) => {
            var docId = doc.id
            doc = doc.data()
            doc.id = docId
            toReturn.push(doc)
          })
          dispatch(setCustomers(toReturn))
        }
      })
  }
}

export const setCustomers = (data) => {
  return {
    type: "SET_CUSTOMERS",
    data
  }
}