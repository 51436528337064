/*eslint-disable*/
import React, { useState, useEffect, createRef } from "react";
import PropTypes from "prop-types";
import { db, firebase, storage } from 'firebase/client';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DemographicsPage from "./DemographicsPage";
import compose from "recompose/compose";
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { IconButton, Menu, MenuItem, ListItemIcon } from "@material-ui/core";
import avatar from 'assets/img/default-avatar.png';
import Hidden from "@material-ui/core/Hidden";
import { CSVLink, CSVDownload } from "react-csv";

import CSVUploadModalContent from "./CSVUploadModalContent.jsx"
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


import explorerPageStyle from "assets/jss/material-dashboard-pro-react/views/explorerPageStyle.js";

import { getLeads, addLead } from 'store/actions/leadsActions'

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

const csvData = [
  ["firstName", "lastName", "email", 'companyName', 'cellPhone', 'officePhone', 'profilePic'],
];



export function DemographicsExplorer(props) {

  const { classes, uid, data, initDataRetrieved } = props;
  const [addLeadModal, setAddLeadModal] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [cellPhone, setCellPhone] = useState('')
  const [officePhone, setOfficePhone] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [file, setFile] = useState(false)
  const [previewUrl, setPreviewUrl] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [leadsData, setLeadsData] = useState([])
  const [demographicsData, setDemographicsData] = useState([])
  console.log('demographicsData', demographicsData)
  const [selectMethodModal, setSelectMethodModal] = useState(false)
  const [method, setMethod] = useState('')
  const [csvLeadsSuccesful, setCsvLeadsSuccesful] = useState(false)

  const profilePicRef = createRef(null)
  const mobilePicRef = createRef(null)


  useEffect(() => {
    if (initDataRetrieved && !dataLoaded) {
      setDemographicsData(data)
      setDataLoaded(true)
    }

  }, [initDataRetrieved])



  const submitFile = () => {
    var toSubmit = {
      acceptedTerms: "Pending", cellPhone,
      companyName, credentials: email, email,
      firstName,
      lastName,
      officePhone,
      userType: "Customer", profilePic: ""
    }


    if (file) {
      const storageRef = storage.ref(uid)
      const leadsRef = storageRef.child('leads')
      const fileRef = leadsRef.child(firstName + ' ' + lastName + Math.floor(Math.random() * (1000 - 1 + 1)) + 1 || file.name)

      fileRef.put(file).then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          toSubmit.profilePic = downloadURL
          var demoDataCopy = [toSubmit, ...demographicsData]
          props.addLead(uid, toSubmit, demoDataCopy)
          setAddLeadModal(false)
        })
          .catch(() => {

          })
      })
    } else {
      var demoDataCopy = [toSubmit, ...demographicsData]
      props.addLead(uid, toSubmit, demoDataCopy)
      setAddLeadModal(false)
    }
  }



  const typeChoices = ['Individually', 'CSV Upload', "Download CSV Template"]


  return (
    <GridContainer style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
      <GridItem xs={12} sm={12} md={12}>
        <DemographicsPage dataLoaded={dataLoaded} demographicsData={demographicsData}></DemographicsPage>
      </GridItem>
      {/* <Fab onClick={() => setSelectMethodModal(true)} className={classes.newRecord}><AddIcon style={{ fontSize: "50px" }} /></Fab> */}



      <Dialog
        style={{ width: "100%" }}
        classes={{
          // root: classes.modalRoot,
          // paper: classes.modal + " " + classes.modalSmall
        }}
        fullWidth={true}
        open={selectMethodModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSelectMethodModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          className={classes.updateProfileFormHeader}
          id="shipment-modal-slide-title"
          disableTypography
        >
          <Button
            onClick={() => {
              setSelectMethodModal(false)
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
          </Button>

          <h3>Select A Method To Add Leads</h3>

        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.modalRoot,
          }}
          style={{ width: "100%" }}
          id="shipment-modal-slide-description"
        // className={classes.modalBody}
        >
          <FormControl style={{ width: "100%" }}>
            <InputLabel>Type</InputLabel>
            <Select
              fullWidth
              style={{ marginBottom: "20px", width: "100% !important" }}
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select,
                root: classes.selectRoot
              }}
              value={method}
              onChange={event => {
                console.log('eventtt', event.target.value)
                if (!event.target.value) {
                  setSelectMethodModal(false)
                  setMethod('')
                  setAddLeadModal(false)
                } else {
                  setMethod(event.target.value)
                  setSelectMethodModal(false)
                  setAddLeadModal(true)
                }
              }}
              inputProps={{
                name: "pageSelect",
                id: "page-select"
              }}
            >




              {typeChoices.map((prop, key) => {
                if (prop === 'Download CSV Template') {
                  return (
                    <CSVLink style={{
                      color: "black",
                      textDecoration: "none",
                      width: "auto",
                      overflow: "hidden",
                      fontSize: "1rem",
                      boxSizing: "border-box",
                      minHeight: "48px",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: 400,
                      lineHeight: 2.5,
                      paddingTop: "6px",
                      whiteSpace: "nowrap",
                      letterSpacing: "0.00938em",
                      paddingBottom: "6px",
                      paddingLeft: "16px",
                      paddingRight: "16px"
                    }}
                      filename={"Credit_Scope_Lead_CSV"}
                      data={csvData}>Download CSV Template</CSVLink>
                  )
                } else {
                  return (
                    <MenuItem
                      key={key}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={prop}
                    >
                      {prop}
                    </MenuItem>
                  )
                }
              })}
            </Select>
          </FormControl>
        </DialogContent>
      </Dialog>







      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall
        }}
        fullWidth={true}
        open={addLeadModal}
        TransitionComponent={Transition}
        keepMounted
        maxWidth='md'
        onClose={() => setAddLeadModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >

        {method === 'Individually' ? (
          <div>
            <DialogTitle
              className={classes.updateProfileFormHeader}
              id="shipment-modal-slide-title"
              disableTypography
            >
              <Button
                onClick={() => {
                  setAddLeadModal(false)
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
              </Button>


              <h3 >Add A Lead</h3>

            </DialogTitle>


            <Hidden smDown implementation='css'>
              <DialogContent
                id="shipment-modal-slide-description"
                className={classes.modalBody}
              >
                <div style={{ display: 'flex', alignItems: "center", flexDirection: "column", width: "30%", justifyContent: "space-evenly" }}>
                  {previewUrl ? (
                    <div>
                      <img onClick={() => {
                        console.log('profilePicRef', profilePicRef)
                        profilePicRef.current.click()
                      }} style={{ height: "140px", borderRadius: "100%" }} src={previewUrl} />
                      <input
                      ref={profilePicRef}
                        onChange={(e) => {
                          var url = URL.createObjectURL(event.target.files[0])
                          setPreviewUrl(url)
                          setFile(e.target.files[0])
                        }}
                        type="file"
                        hidden
                      />
                    </div>
                  ) : (
                    <div>
                      <img onClick={() => {
                        console.log('profilePicRef', profilePicRef)
                        profilePicRef.current.click()
                      }} style={{ height: "140px", borderRadius: "100%" }} src={avatar} />
                      <input
                      ref={profilePicRef}
                        onChange={(e) => {
                          var url = URL.createObjectURL(event.target.files[0])
                          setPreviewUrl(url)
                          setFile(e.target.files[0])
                        }}
                        type="file"
                        hidden
                      />
                    </div>
                  )}
                  <p className={classes.uploadText} >
                    Upload Profile Picture
                  </p>
                </div>
                <form style={{ width: "70%" }} className={classes.updateProfileForm}>
                  <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <TextField
                      style={{ width: "45%" }}
                      fullWidth={true}
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName} id="First Name" label="First Name" />
                    <TextField
                      style={{ width: "45%" }}
                      fullWidth={true}
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName} id="Last Name" label="Last Name" />
                  </div>
                  <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <TextField
                      style={{ width: "45%" }}
                      fullWidth={true}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email} id="Email" label="Email" />
                    <TextField
                      style={{ width: "45%" }}
                      fullWidth={true}
                      onChange={(e) => setCellPhone(e.target.value)}
                      value={cellPhone} id="cellPhone" label="Cell Phone" />
                  </div>
                  <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <TextField
                      style={{ width: "45%" }}
                      fullWidth={true}
                      onChange={(e) => setCompanyName(e.target.value)}
                      value={companyName} id="companyName" label="Company" />
                    <TextField
                      style={{ width: "45%" }}
                      fullWidth={true}
                      onChange={(e) => setOfficePhone(e.target.value)}
                      value={officePhone} id="officePhone" label="Office Phone" />
                  </div>
                </form>
              </DialogContent>
              <div style={{ display: "flex", justifyContent: "space-evenly", margin: "25px 0px" }}>
                <Button className={classes.cancel} onClick={() => setAddLeadModal(false)} >Cancel</Button>
                <Button className={classes.submit} onClick={submitFile} >Submit</Button>
              </div>
            </Hidden>


            <Hidden mdUp implementation='css'>
              <DialogContent
                id="shipment-modal-slide-description"
              >
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%" }}>
                  <input
                          ref={mobilePicRef}
                    onChange={(e) => {
                      var url = URL.createObjectURL(event.target.files[0])
                      setPreviewUrl(url)
                      setFile(e.target.files[0])
                    }}
                    type="file"
                    hidden
                  />
                  {previewUrl ? (
                    <img onClick={() => {
                      mobilePicRef.current.click()
                    }} style={{ height: "140px", borderRadius: "100%" }} src={previewUrl} />
                  ) : (
                    <img onClick={() => {
                      mobilePicRef.current.click()
                    }} style={{ height: "140px", borderRadius: "100%" }} src={avatar} />
                  )}
                  <p className={classes.uploadText} >
                    Upload Profile Picture
                  </p>
                </div>
                <form style={{ justifyContent: "space-around" }} className={classes.updateProfileForm} onSubmit={() => console.log('hi')}>
                  <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <TextField
                      style={{ width: "45%" }}
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName} id="First Name" label="First Name" />
                    <TextField
                      style={{ width: "45%" }}
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName} id="Last Name" label="Last Name" />
                  </div>


                  <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <TextField
                      style={{ width: "45%" }}
                      fullWidth={true}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email} id="Email" label="Email" />
                    <TextField
                      style={{ width: "45%" }}
                      fullWidth={true}
                      onChange={(e) => setCellPhone(e.target.value)}
                      value={cellPhone} id="cellPhone" label="Cell Phone" />
                  </div>


                  <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <TextField
                      style={{ width: "45%" }}
                      onChange={(e) => setCompanyName(e.target.value)}
                      value={companyName} id="companyName" label="Company" />
                    <TextField
                      style={{ width: "45%" }}
                      onChange={(e) => setOfficePhone(e.target.value)}
                      value={officePhone} id="officePhone" label="Office Phone" />
                  </div>
                </form>

                <div style={{ display: "flex", justifyContent: "space-between", margin: "25px 0px" }}>
                  <Button onClick={() => setAddLeadModal(false)} style={{ backgroundColor: '#f5c107' }}>Cancel</Button>
                  <Button onClick={submitFile} style={{ backgroundColor: 'rgb(94, 177, 227)', color: "white" }}>Submit</Button>
                </div>
              </DialogContent>
            </Hidden>
          </div>
        ) : method === 'CSV Upload' ? (
          <div>
            <DialogTitle
              className={classes.updateProfileFormHeader}
              id="shipment-modal-slide-title"
              disableTypography
            >
              <Button
                onClick={() => {
                  setAddLeadModal(false)
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close style={{ fontSize: "25px" }} className={classes.modalClose} />
              </Button>


              <h3>Upload CSV</h3>

            </DialogTitle>
            <DialogContent
              id="shipment-modal-slide-description"
            >
              <CSVUploadModalContent setCsvLeadsSuccesful={setCsvLeadsSuccesful} setAddLeadModal={setAddLeadModal} />
            </DialogContent>
          </div>
        ) : (
          null
        )}

      </Dialog>

      <Snackbar open={csvLeadsSuccesful} onClose={() => setCsvLeadsSuccesful(false)}>
        <Alert onClose={() => setCsvLeadsSuccesful(false)} severity='success'>
          CSV Leads upload was succesful.
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

DemographicsExplorer.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  console.log('stateeeee', state.demographics)
  return {
    uid: state.auth.user.uid,
    data: state.demographics.demographics,
    initDataRetrieved: state.demographics.initDataRetrieved
  };
}


const mapDispatchToProps = (dispatch) => ({
  addLead: (uid, newLead, allLeads) => dispatch(addLead(uid, newLead, allLeads))
});


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(explorerPageStyle)
)(DemographicsExplorer);

