import React from "react";

import Table from "@material-ui/core/Table";
import ReactTable from "react-table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const row = (x, i, header) =>
    <TableRow key={`tr-${i}`}>
        {header.map((y, k) =>
            <TableCell key={`trc-${k}`}>
                {x[y.prop]}
            </TableCell>
        )}
    </TableRow>;

export default ({ data, header }) =>
    <Table>
        <TableHead>
            <TableRow>
                {header.map((x, i) =>
                    <TableCell key={`thc-${i}`}>
                        {x.name}
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map((x, i) => row(x, i, header))}
        </TableBody>
    </Table>;

{/* <ReactTable
    data={this.state.data}
    filterable
    defaultFilterMethod={filterCaseInsensitive}
    columns={this.props.shippers.columns}
    defaultPageSize={10}
    defaultSorted={[
        {
            id: "status",
            desc: false
        }
    ]}
    defaultFiltered={[
        {
            id: 'groups',
            value: 'Logistics Partner'
        }
    ]}
    //showPaginationTop
    showPaginationBottom
    style={{
        color: "#000"
    }}
    className="-striped -highlight"
/> 

columns: [
    {
      Header: "DETAILS",
      accessor: "details",
      Cell: ({value}) => (<Link to={`/shipper/${value}`}><button>Details</button></Link>),
      sortable: false,
      filterable: false
    },
    {
      Header: "SHIPPER ID",
      accessor: "id"
    },
    {
      Header: "SHIPPER NAME",
      accessor: "name"
    }
  ], 

function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      return (
        row[id] !== undefined ?
        String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        :
          true
      );
    }*/}