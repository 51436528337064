const tasksExplorerPageStyle = () => ({
    title: {
        color: "#e87402",
        fontWeight: 600,
        margin: "20px 0 0 20px"
    },
    taskCard: {
        padding: "10px"
    },
    newNoteButton: {
        width: "100%",
        backgroundColor: "#18528c",
        fontWeight: 600,
        fontSize: "25px"
    },
    dueDate: {
        color: "#18528c",
        fontWeight: 600
    },
    completedCard: {
        backgroundColor: "#a8a7a7",
        padding: "10px"
    }
});

export default tasksExplorerPageStyle;