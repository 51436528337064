import React from 'react';
// material-ui components
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
// core components
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-dashboard-pro-react/modalStyle.js';
import ViewIcon from './view.png';
import AddIcon from './addIcon.png';
import Remove from '@material-ui/icons/Remove';
import LaunchIcon from '@material-ui/icons/Launch';

import ClientDocuments from './ClientDocuments';

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const ClientListDocumentsModal = (props) => {
  const [modal, setModal] = React.useState(false);
  const classes = useStyles();
  const { creditData } = props;

  return (
    <div>
      {props.image === 'view' ? (
        <img
          src={ViewIcon}
          style={{
            width: '25px',
            borderRadius: '0px',
            marginRight: '20px',
            cursor: 'pointer',
          }}
          onClick={() => setModal(true)}
        />
      ) : (
        <img
          src={AddIcon}
          style={{ width: '16px', marginRight: '20px', cursor: 'pointer' }}
          onClick={() => setModal(true)}
        />
      )}

      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={modal}
        transition={Transition}
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby='modal-slide-title'
        aria-describedby='modal-slide-description'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={() => setModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>View or Upload Documents</h4>
        </DialogTitle>
        <div style={{ padding: '10px' }}>
          <ClientDocuments />
        </div>
      </Dialog>
    </div>
  );
};

export default ClientListDocumentsModal;
