import { 
    actionsForRecordset, 
    addDatasource 
} from 'store/actions/recordset';

import { getDatasourceFor } from 'firebase/datasource';
import { config } from 'store/reducers/recordsets/customers';

export default addDatasource(actionsForRecordset('customers', config), getDatasourceFor(config));


