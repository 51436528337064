/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";

import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CardFooter from "components/Card/CardFooter";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Input from "@material-ui/core/Input";
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

// import CardFields from '../../../components/Card/CardFields';
// import CardField from '../../../components/Card/CardField';
import TextEditor from "components/TextEditor/TextEditor";
import actionsForNavigation from "store/actions/navigation";
import { connect } from "react-redux";
import { MockUserData } from 'variables/config';
import avatar from 'assets/img/default-avatar.png';
import SearchBox from 'components/SearchBox/SearchBox.jsx';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import Close from "@material-ui/icons/Close";
import { getLeads, deleteLead, convertLeadToCustomer } from "store/actions/leadsActions"
import Slide from "@material-ui/core/Slide";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import campaignsStyle from "assets/jss/material-dashboard-pro-react/views/campaignsStyle.js";

import NumberBubble from "components/NumberBubble/NumberBubble";
import InformationModal from "components/InformationModal/InformationModal";
import InstagramIcon from "assets/img/market-me/instagramIcon.png";
import LinkedInIcon from "assets/img/market-me/linkedInLogo.png";
import EmailIcon from "assets/img/market-me/emailIcon.png";
import TextIcon from "assets/img/market-me/textIcon.png";
import CalendarIcon from "assets/img/market-me/calendarIcon.png";

import AuthActions from "store/actions/auth"
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Comment from "./Comment";

const useStyles = makeStyles(campaignsStyle);

const AutoCommenting = (props) => {
  const classes = useStyles();
  const { setStep, campaignForm, handleChange, setPlatforms, platforms, goBack } = props;
  const [ comments, setComments ] = useState(['', '']);

  const placeholder = "Add engaging comment content for variations";
  const modules = {
    toolbar: false
  };
  const runTimeUnits = ['Days', 'Weeks', 'Months'];

  const setCommentText = (text, i) => {
    setComments((prevState) => {
      let newState = [...prevState];
      newState[i] = text;
      return newState;
    })
  }

  const handleAddComment = () => {
    if (comments.length < 10) {
      setComments((prevState) => {
        let newState = [...prevState];
        newState.push('');
        return newState;
      })
    }
  }

  return (
    <>
      <GridContainer className={classes.mainStepContainer}>
        <GridItem
          className={classes.stepHeader}
          style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}
        >
          <NumberBubble color="yellow" value="5." size="2.5rem" />
          <h4 style={{ fontWeight: 600, marginLeft: "1rem" }}>Configure Auto-Commenting</h4>
        </GridItem>
        <GridItem style={{ display: "flex", marginTop: "-1rem", alignItems: "end" }}>
          <GridItem xs={3}>
            <GridContainer className={classes.step3Container}>
              <GridItem style={{ display: "flex", alignItems: "center" }}>
                <h4 style={{ fontWeight: 800 }}>Time Intervals</h4>
                <InformationModal infoText="Specify when you want the next comment to send out." />
              </GridItem>
              <GridItem style={{ display: "flex" }}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    style: {
                      paddingTop: "0px"
                    }
                  }}
                  inputProps={{
                    placeholder: "20, 40, 60",
                    required: true,
                    type: "text",
                    disableUnderline: true,
                    onChange: (e) => handleChange('autoCommentingInterval', e.target.value),
                    style: {
                      border: "2px solid #E60023",
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px"
                    }
                  }}
                />
                <CustomSelect
                  values={runTimeUnits}
                  formControlProps={{
                    style: {
                      paddingTop: "0px"
                    }
                  }}
                  inputProps={{
                    defaultValue: 0,
                    disableUnderline: true,
                    onChange: (e) => handleChange('autoCommentingUnit', runTimeUnits[e.target.value]),
                    style: {
                      border: "2px solid #E60023",
                      borderLeft: "none",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      "& .MuiSelect-root": {
                          color: "white",
                      },
                    }
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={1} />
          <GridItem xs={3} style={{ display: "flex", alignItems: "baseline" }}>
            <GridContainer className={classes.step3Container}>
              <GridItem style={{ display: "flex", alignItems: "center" }}>
                <h4 style={{ fontWeight: 800 }}>Number of Posts</h4>
              </GridItem>
              <GridItem style={{ display: "flex" }}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    style: {
                      paddingTop: "0px"
                    }
                  }}
                  inputProps={{
                    placeholder: "Target up to 200 posts",
                    required: true,
                    type: "text",
                    disableUnderline: true,
                    onChange: (e) => handleChange('autoCommentingNumberPosts', e.target.value),
                    style: {
                      border: "2px solid #E60023",
                      borderRadius: "5px"
                    }
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={5} />
        </GridItem>
        <GridItem>
          <GridContainer style={{ display: "flex", flexDirection: "column" }}>
            <GridItem>
              {comments.map((comment, i) => {
                return <Comment number={i+1} setEditorText={(text)=>setCommentText(text, i)}/>
              })}
            </GridItem>
            <GridItem>
              {comments.length < 10 ?
                <Button
                  style={{ background: "#005479", color: "white", width: "35%", marginLeft: "1rem", marginBottom: "1rem" }}
                  onClick={handleAddComment}
                  >ADD COMMENT</Button> : null}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Button onClick={()=>setStep((prev) => (prev-1))} className={classes.backButton}>Back</Button>
        <Button onClick={()=>setStep((prev) => (prev+1))} className={classes.nextButton}>Next</Button>
      </GridContainer>
    </>
  )
}

function mapStateToProps(state) {
  return {
  };
}

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(campaignsStyle)
)(AutoCommenting));