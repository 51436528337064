import React from 'react'
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import './NegativeFactors.css'

const Suggestions = () => {
  return (
    <GridItem id='suggestionsContainer'>
          <Card id='suggestionsCard'>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column',}}>
              <h3
                style={{
                  fontSize: '31px',
                  fontWeight: 'bold',
                  paddingLeft: '20px',
                }}
              >
                Suggestions
              </h3>
            </div>
            <p>
              Based  on the information we have access to, these are our top recommendations.
            </p>
            
            <CardBody style={{display: 'flex', flexWrap: 'wrap'}}>
              <ul className='suggestionsList'>
                <li className='suggestionsItem'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore el dolore magna aliqua.
                </li>
                <li className='suggestionsItem'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore el dolore magna aliqua.
                </li>
                <li className='suggestionsItem'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore el dolore magna aliqua.
                </li>
              </ul>
            </CardBody>
          </Card>
        </GridItem>
  )
}

export default Suggestions