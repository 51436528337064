import React, { useState, useEffect } from "react";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

const Comment = (props) => {
  return (
    <>
      <GridContainer style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }}>
        <GridItem style={{ display: "flex", marginLeft: "1rem" }}>
          <h4 style={{ fontWeight: 800 }}>Comment Option #{props.number}</h4>
          {props.number === 1 ? <h4 style={{ marginLeft: "1rem" }}>&#40;10 Maximum&#41;</h4> : null}
        </GridItem>
        <GridItem style={{ marginTop: "1rem", display: "flex" }}>
          <ReactQuill
            style={{ width: "40rem", marginLeft: "1rem" }}
            placeholder={"Add engaging comment content for variations"}
            modules={{ toolbar: false }}
            theme="snow"
            onChange={props.setEditorText}
          />
          <Button style={{
            background: "#005479",
            borderRadius: "5px",
            width: "20%",
            marginLeft: "11rem",
            color: "white"
          }}>SAVE</Button>
        </GridItem>
      </GridContainer>
    </>
  )
}

export default Comment;