import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import wizard from "assets/img/mortgage-ready/wizard-1.png";

// actions
import AuthActions from "store/actions/auth"
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import LoginPageBackground from "assets/img/market-me/loginBackground.png"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      passwordNotice: null,
      updateSignUpModal: false,
      updateLogInModal: false,
      authInfo: {
        email: '',
        password: ''
      }
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleSubmitSignUp = (e) => {
    e.preventDefault()

    if (this.state.authInfo.password !== this.state.authInfo.confirmPassword) {
      this.setState({
        passError: true
      })
      return;
    }

    if (this.state.checked[0] !== "accepted terms") {
      this.setState({
        termsError: true
      })
      return;
    }

    this.setState({
      signupError: false
    })

    this.props.emailSignup(this.state.authInfo)
  }

  handleSubmitLogIn = (e) => {
    e.preventDefault()
    this.props.emailSignIn(this.state.authInfo)
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      authInfo: {
        ...this.state.authInfo,
        [e.target.id]: e.target.value
      }
    })
  }

  handlePasswordReset = (e) => {
    e.preventDefault()
    this.props.passwordReset(this.state.authInfo.email)
    this.setState({
      ...this.state,
      passwordNotice: "Password reset link sent. Please check your email."
    })
  }


  render() {
    const { authError, classes, startGoogleLogin } = this.props;
    const { passwordNotice } = this.state

    return (
      <div style={{width: "100%", margin: "0px"}} className={classes.container}>
        <GridContainer justify="center">
          <GridItem style={{width: "100%", margin: "0px"}} xs={12} sm={6} md={4} className={classes.formContainer}>
            <img src={wizard} alt="wizard" className={classes.largeImg} />

            <br /> <br />
            <p className={classes.subdescription}>Welcome to the best way to pipeline customer credit worthiness. Sign up today and find out how to make your customers take aim on their Market Me</p>
            <br />
            <div>
              <Link to="/auth/register-page">
                <Button className={classes.signUpButton}><b>Sign Up</b></Button>
              </Link>
              <br />
              <Button className={classes.logInButton}
                onClick={() => this.handleClickOpen("updateLogInModal")}
              ><b>Log In</b></Button>
            </div>
            {/*<div style={{width: "20rem", margin:"0 auto"}}>
            <Button
            style={{backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/golegalese.appspot.com/o/googlelogin.png?alt=media&token=94c33cb1-6957-4b4c-bcb3-ced5f8b4af22)", backgroundSize: "cover", width: "100%", height: "4.4rem"}}
            onClick={startGoogleLogin}
            className={classes.customButtonClass}
            >
            </Button>
            </div>*/}

            {/* UPDATE LOG IN MODAL */}
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal + " " + classes.modalSmall
              }}
              open={this.state.updateLogInModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => this.handleClose("updateLogInModal")}
              aria-labelledby="shipment-modal-slide-title"
              aria-describedby="shipment-modal-slide-description"
            >
              <DialogContent
                id="shipment-modal-slide-description"
                className={classes.modalBody}
              >
                {/* FORM */}
                <form onSubmit={this.handleSubmitLogIn}>
                  <Card login className={classes[this.state.cardAnimaton]}>
                    <CardBody>
                      <p>New? <Link to="/auth/register-page" className={classes.linkText}>Create an account now.</Link></p>
                      <Card className={classes.emailInput}>
                        <CustomInput
                          labelText="Email"
                          id="email"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: this.handleChange,
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Card>
                      <Card>
                        <CustomInput
                          labelText="Password"
                          id="password"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: this.handleChange,
                            type: "password",
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon>
                                  lock_outline
                              </Icon>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Card>
                      {authError ? (
                        <div className={classes.justifyContentCenter}>
                          <p className={classes.textDanger}><br /><b>Error:</b> {authError.message}<br /><br /><b>Did you forget your password?</b></p>
                          <Button
                            style={{ backgroundColor: "rgb(14, 178, 216)", fontWeight: "600", color: "#FFFFFF" }}
                            type="button"
                            onClick={this.handlePasswordReset}
                            color="danger"
                            simple size="lg"
                            block>
                            Reset Password
                            </Button>
                        </div>
                      ) : null}
                      {passwordNotice ? <p className={classes.textDanger}>{passwordNotice}</p> : null}
                    </CardBody>
                    <CardFooter style={{ padding: "0px 5px 10px 5px" }} className={classes.justifyContentCenter}>
                      <Button
                        className={classes.continueButton}
                        style={{ }}
                        type="submit"
                        color="primary"
                        simple size="lg"
                        block>
                        Let's Go
                      </Button>
                    </CardFooter>
                  </Card>
                </form>
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error
  }
}

const mapDispatchToProps = (dispatch) => ({
  startGoogleLogin: () => dispatch(AuthActions.startGoogleLogin()),
  emailSignIn: (credentials) => dispatch(handleError(chain(AuthActions.startEmailLogin(credentials), push('/admin/user-page')), AuthActions.setError)),
  passwordReset: (email) => dispatch(AuthActions.startPasswordReset(email))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(loginPageStyle)
)(LoginPage);
